import React from "react";
import {
  YoutubeCountsBarGraphMetadata,
  WebActivityHistogramGraphMetadata,
  WebActivityBarGraphMetadata,
} from "../../../../helpers/graphsMetadata";
import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";

export const ContentPage = (): JSX.Element => {
  const consumeGraphs = [
    YoutubeCountsBarGraphMetadata.id,
    WebActivityHistogramGraphMetadata.id,
    WebActivityBarGraphMetadata.id,
  ];

  return <GraphListWrapper graphNameList={consumeGraphs} />;
};
