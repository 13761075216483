import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { postAction } from "../../helpers/actions/actions";
import {
  API_ACCESS_TOKEN,
  API_REGISTER_USER_BY_CODE,
  DASHBOARD,
  LOGIN,
  USER_SERVICE_URL,
} from "../../api/apiConstants";
import { IdentityContext } from "../../types/general/IdentityContext";
import { Google } from "./providersConfig";
import { ErrorResponseType, LoginResponseType } from "../../types/responses/LoginResponseType";
import { ButtonEnabledInterface } from "../../containers/pages/Login/LoginPage";

export const LoginCallback = React.memo(({ setButtonEnabled }: ButtonEnabledInterface) => {
  const { setUserData } = useContext(IdentityContext);
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    /**
     * Calls endpoint to register or login user from Access Code
     * @param queryParameters Object with keys / values to be passed as query parameters
     * @param data  Setter method for the returned data
     * @returns AxiosResponse.data
     */
    setButtonEnabled(false);
    const registerUserFromCode = (queryParameters: any, data: any) => {
      postAction(API_REGISTER_USER_BY_CODE, USER_SERVICE_URL, queryParameters, data)
        .then((response: LoginResponseType | ErrorResponseType) => {
          // Check if the response was successful
          // eslint-disable-next-line no-extra-parens
          if ((response as LoginResponseType).user) {
            const loginResponse = response as LoginResponseType;
            setUserData({
              accessToken: loginResponse[API_ACCESS_TOKEN],
              userName: loginResponse.user.display_name,
              userEmail: loginResponse.user.primary_email,
              userPicURL: loginResponse.user.picture_link,
              userSettings: loginResponse.user.settings,
            });
            history.push(`/${DASHBOARD}`);
            // eslint-disable-next-line no-extra-parens
          } else if ((response as ErrorResponseType).detail) {
            const errorResponse = response as ErrorResponseType;
            // Currently only happens if a user not on our whitelist is selected
            history.push(`/${LOGIN}`);
            enqueueSnackbar(`Error: ${JSON.stringify(errorResponse.detail)}`, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(`Error: ${JSON.stringify(error)}`, {
            variant: "error",
          });
        })
        .finally(() => {
          setButtonEnabled(true);
        });
    };

    const code = (location.search.match(/code=([^&]+)/) || [])[1];
    const state = (location.search.match(/state=([^&]+)/) || [])[1];

    const queryParameters = {
      provider: Google.PROVIDER,
    };

    const data = {
      client: Google.CLIENT,
      code,
      redirect_uri: Google.REDIRECT_URI,
      state,
    };

    try {
      registerUserFromCode(queryParameters, data);
    } catch (error) {
      history.push(`/${LOGIN}`);
    }
  }, []);

  return null;
});
