import { ContainerType } from "./multiGraphTypes";
import { genericOptions } from "./multiGraphHelper";

export const multiGraphCombiner = (outputData: any, graphs: ContainerType[]) => {
  // Combine datasets
  const allDatasets: any[] = [];
  // Combine labels
  const allLabels: number[] = [];
  // Combine X,Y axes in options
  const allYScales: any[] = [];

  outputData.forEach((dataObj: any) => {
    // Combine datasets

    dataObj?.datasets?.forEach((dataset: any) => {
      allDatasets.push(dataset);
    });

    // Combine labels
    dataObj?.labels?.forEach((label: number) => {
      allLabels.push(label);
    });
  });

  graphs.forEach((graph: ContainerType) => {
    const option = graph.initialOptions?.();
    // Combine yAxes
    option?.scales?.yAxes?.forEach((axis: any) => {
      allYScales.push(axis);
    });
    // No need to combine xAxes
  });

  // Put scales together and create a single object
  const outputOptions = genericOptions();
  allYScales?.forEach((scale: any) => {
    outputOptions.scales.yAxes.push(scale);
  });

  const dataObject = {
    labels: allLabels,
    datasets: allDatasets,
  };

  return {
    dataObject,
    outputOptions,
  };
};
