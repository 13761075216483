import { stepSizeCalc } from "../../../../helpers/timeConstants";

export const initialWeightHistogramDataset = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "bar",
        label: "Weight",
        data: [] as Array<number>,
        fill: false,
        yAxisID: "y-axis-weight-hist",
        xAxisID: "x-axis",
      },
    ] as Array<any>,
  };
};

export const weightHistogramOptions = (histogramMaxCount: number, min: number, max: number, stepBucketSize: number) => {
  let minX = Math.floor(min / stepBucketSize) * stepBucketSize;
  minX -= stepBucketSize;
  let maxX = Math.ceil(max / stepBucketSize) * stepBucketSize;
  maxX += stepBucketSize;
  return {
    scales: {
      yAxes: [
        {
          id: "y-axis-weight-hist",
          type: "linear",
          ticks: {
            stepSize: stepSizeCalc(histogramMaxCount),
            max: histogramMaxCount + 3,
          },
          scaleLabel: {
            display: true,
            labelString: `Counts`,
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          ticks: {
            min: minX,
            max: maxX,
            stepSize: stepBucketSize,
          },
          scaleLabel: {
            display: true,
            labelString: `Weight (lb)`,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: true,
    },
    tooltips: {
      intersect: true,
      mode: "x",
      callbacks: {
        title(tooltipItem: any, data: any): string {
          const initValue = tooltipItem[0].label;
          const nextValue = +initValue + +data.stepBucketSize;
          return `Between ${initValue} - ${nextValue} lb`;
        },
        label(tooltipItem: any): string {
          return `Count: ${tooltipItem.value}`;
        },
      },
    },
  };
};
