// const TAG_MULTICLOUD = "Multicloud";
export const TAG_YOUTUBE = "Youtube";
export const TAG_NETFLIX = "Netflix";
// const TAG_FACEBOOK = "FaceBook";
export const TAG_ENTERTAINMENT = "Entertainment";
export const TAG_GOOGLE = "Google";
export const TAG_AMBEE = "Ambee";
export const TAG_LLIF = "LLIF";
// const TAG_SEARCH = "Search";
export const TAG_EXERCISE = "Exercise";
export const TAG_HEALTH = "Health";
export const TAG_FITBIT = "Fitbit";
export const TAG_GARMIN = "Garmin";
export const TAG_FACEBOOK = "Facebook";
export const TAG_SOCIAL = "Social";

export const TAG_COVID = "Covid";
export const TAG_MOVE = "Move";
export const TAG_SHOP = "Shop";
// const TAG_AMAZON = "Amazon";
export const TAG_SLEEP = "Sleep";
export const TAG_AIR = "Air";
export const TAG_ALLERGY = "Allergy";
export const TAG_ENVIRONMENT = "Environment";
export const TAG_DOCTOR = "Doctor";
export const TAG_LOCATION = "Location";
export const TAG_BODY = "Body";
export const TAG_WEATHER = "Weather";
export const TAG_EVENT = "Event";
export const TAG_TAG = "Tag";
export const TAG_LOG = "Log";
export const TAG_SYMPTOM = "Symptom";
export const TAG_CARDIOVASCULAR = "Cardiovascular";
export const TAG_FEMALE = "Female";
export const TAG_REPRODUCTIVE = "Reproductive";
export const TAG_TRANSPORTATION = "Transportation";
export const TAG_TRAVEL = "Travel";
export const TAG_CAR = "Car";
export const TAG_AUTO = "Auto";
export const TAG_PLANE = "Plane";
export const TAG_TRAIN = "Train";
export const TAG_BOAT = "Boat";
export const TAG_STREAMING = "Streaming";
export const TAG_VIDEO = "Video";
export const TAG_TV = "Tv";
export const TAG_TELEVISION = "Television";
export const TAG_MOVIES = "Movies";
export const TAG_SHOWS = "Shows";
export const TAG_DOCUMENTARIES = "Documentaries";
export const TAG_VIEWING = "Viewing";
export const TAG_ONLINE = "Online";
export const TAG_SCREEN_TIME = "Screen Time";
