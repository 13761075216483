import React, { useContext } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { DefaultQueryParamsType } from "../../../../types/graphs/DefaultQueryParamsType";
import { CalendarDateRangeSelector } from "./CalendarTimescaleSelector";
import { CalendarResetButton } from "./CalendarResetButton";
import { CalendarIntervalSelector } from "./CalendarIntervalSelector";
import { CalendarLeftArrow } from "./CalendarLeftArrow";
import { CalendarRightArrow } from "./CalendarRightArrow";
import { CalendarRange } from "../GraphCalendar/calendarBodyHelper";
import { SideNavigationContext } from "../../../../helpers/context/contextVariables";
import { twoColBreakpointPx } from "../../../navigation/navigationDrawer/SideNavigation";

interface CalendarAndShifterProps {
  dateRange: CalendarRange;
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>>;
  selectedInterval: string | undefined;
  setSelectedInterval: React.Dispatch<React.SetStateAction<string>> | undefined;
  initialQueryParams: DefaultQueryParamsType | undefined;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

export const QuickNav = ({
  dateRange,
  setDateRange,
  selectedInterval,
  setSelectedInterval,
  initialQueryParams,
  setRefetch,
}: CalendarAndShifterProps): JSX.Element => {
  const { isSideNavOpen } = useContext(SideNavigationContext);
  const mediumPx = isSideNavOpen ? 1150 : 920;
  const smallPx = isSideNavOpen ? 1000 : 800;

  const isMedium = useMediaQuery(`(max-width:${mediumPx}px)`, { noSsr: true });
  const isSmall = useMediaQuery(`(max-width:${smallPx}px)`, { noSsr: true });

  const isOneColLarge = useMediaQuery(`(max-width:${twoColBreakpointPx}px)`, { noSsr: true });
  const isOneColMed = useMediaQuery(`(max-width:${twoColBreakpointPx - 300}px)`, { noSsr: true });
  const isOneColSmall = useMediaQuery(`(max-width:${twoColBreakpointPx - 400}px)`, { noSsr: true });

  const StackedQuickNav = (
    <Grid container direction="column" justifyContent="center">
      {/* ARROWS */}
      <Grid item>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={3} style={{ textAlign: "right" }}>
            <CalendarLeftArrow dateRange={dateRange} setDateRange={setDateRange} />
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <CalendarResetButton
              initialQueryParams={initialQueryParams}
              setDateRange={setDateRange}
              setSelectedInterval={setSelectedInterval}
              setRefetch={setRefetch}
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: "left" }}>
            <CalendarRightArrow dateRange={dateRange} setDateRange={setDateRange} />
          </Grid>
        </Grid>
      </Grid>

      <Box m={0.5} />

      {/* BUTTONS */}
      <Grid item>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <CalendarDateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
          <Typography variant="caption">Date Range</Typography>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <CalendarIntervalSelector setSelectedInterval={setSelectedInterval} selectedInterval={selectedInterval} />
          <Typography variant="caption">Interval</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const MediumQuickNav = (
    <>
      <Grid item>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={3} style={{ textAlign: "right" }}>
            <CalendarLeftArrow dateRange={dateRange} setDateRange={setDateRange} />
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <CalendarResetButton
              initialQueryParams={initialQueryParams}
              setDateRange={setDateRange}
              setSelectedInterval={setSelectedInterval}
              setRefetch={setRefetch}
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: "left" }}>
            <CalendarRightArrow dateRange={dateRange} setDateRange={setDateRange} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="center">
        <Grid item>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <CalendarDateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
            <Typography variant="caption">Date Range</Typography>
          </Grid>
        </Grid>

        <Grid item xs={1} />

        <Grid item>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <CalendarIntervalSelector setSelectedInterval={setSelectedInterval} selectedInterval={selectedInterval} />
            <Typography variant="caption">Interval</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const LargeQuickNav = (
    <>
      <Grid container direction="row" justifyContent="center">
        <Grid item>
          <Grid container direction="column">
            <CalendarLeftArrow dateRange={dateRange} setDateRange={setDateRange} />
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <CalendarDateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
            <Typography variant="caption">Date Range</Typography>
          </Grid>
        </Grid>

        <Grid item>
          <CalendarResetButton
            initialQueryParams={initialQueryParams}
            setDateRange={setDateRange}
            setSelectedInterval={setSelectedInterval}
            setRefetch={setRefetch}
          />
        </Grid>

        <Grid item>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <CalendarIntervalSelector setSelectedInterval={setSelectedInterval} selectedInterval={selectedInterval} />
            <Typography variant="caption">Interval</Typography>
          </Grid>
        </Grid>

        <Grid item>
          <CalendarRightArrow dateRange={dateRange} setDateRange={setDateRange} />
        </Grid>
      </Grid>
    </>
  );

  const OneColumnQuickNav = () => {
    if (isOneColSmall) {
      return StackedQuickNav;
    }
    if (isOneColMed) {
      return MediumQuickNav;
    }
    return LargeQuickNav;
  };

  const RenderQuickNav = () => {
    if (isOneColLarge) {
      return <OneColumnQuickNav />;
    }
    if (isSmall) {
      return StackedQuickNav;
    }
    if (isMedium) {
      return MediumQuickNav;
    }
    return LargeQuickNav;
  };

  return <RenderQuickNav />;
};
