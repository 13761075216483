import React from "react";
import { Bar } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { defaultGraphWidth, defaultGraphHeight, TITLE_MOVEMENT } from "../../../../helpers/graphConstants";
import { API_ACTIVITY_MOVEMENT } from "../../../../api/apiConstants";
import { useProcessStackedBar } from "./useProcessStackedBar";
import { stackedMovementOptions } from "./stackedBarHelper";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { MovementStackedBarMetadata } from "../../../../helpers/graphsMetadata";

export const MovementStackedBarGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(ActivityQueryParams.defaultMovementTopStatesQueryParams, API_ACTIVITY_MOVEMENT);
  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const { outputBarData } = useProcessStackedBar(graphData);

  const graphDisplay = (
    <Bar
      data={outputBarData}
      options={stackedMovementOptions(selectedInterval)}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
    />
  );
  const description = [
    "Time in Movement is derived from your Google map data and is a good indicator of your travel quality of life.  ",
  ];
  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_MOVEMENT}
        title={TITLE_MOVEMENT}
        calendarProps={graphObject}
        id={MovementStackedBarMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_MOVEMENT} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
