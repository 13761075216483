import React from "react";
import { Button } from "@material-ui/core";

interface FallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
  title?: string;
}

export const ErrorBoundaryFallback = ({ error, resetErrorBoundary, title }: FallbackProps) => {
  return (
    <div role="alert">
      <p>Something went wrong{title ? ` loading ${title}` : ""}</p>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </div>
  );
};
