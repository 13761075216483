import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { defaultGraphHeight, defaultGraphWidth, TITLE_PRESSURE } from "../../../../helpers/graphConstants";
import { API_WEATHER, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { WeatherPressureLineGraphMetadata } from "../../../../helpers/graphsMetadata";
import { useProcessPressureData } from "./useProcessPressureData";

export const PressureGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(LocationQueryParams.defaultWeatherParams, API_WEATHER, DATA_SERVICE_URL);
  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessPressureData(graphData);
  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  // TODO: Link https://en.wikipedia.org/wiki/Atmospheric_pressure
  const description = [
    "Barometric pressure is closely approximated by the hydrostatic pressure caused by the weight of air above the" +
      " measurement point. As elevation increases, there is less overlying atmospheric mass, so that atmospheric pressure decreases with increasing elevation.  ",
    "Your personal barometric pressure is recorded so that you can better understand the impact it has on your life (e.g.  outdoor time, exercise frequency, exercise performance, migraines, headaches, seizures, mood, etc.). ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_PRESSURE}
        title={TITLE_PRESSURE}
        calendarProps={graphObject}
        id={WeatherPressureLineGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_PRESSURE} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
