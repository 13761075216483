import React from "react";
import { Bar } from "react-chartjs-2";

import { Box, LinearProgress } from "@material-ui/core";
import { API_CONSUME_YOUTUBE_COUNTS } from "../../../../api/apiConstants";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { defaultGraphWidth, defaultGraphHeight, TITLE_YOUTUBE_COUNTS } from "../../../../helpers/graphConstants";
import { useProcessYoutubeData } from "./useProcessYoutubeData";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { YoutubeCountsBarGraphMetadata } from "../../../../helpers/graphsMetadata";

export const YoutubeCountsBarGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(ActivityQueryParams.defaultYoutubeCountsQueryParams, API_CONSUME_YOUTUBE_COUNTS);

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const { outputData, outputOptions } = useProcessYoutubeData(graphData, selectedInterval);
  const graphDisplay = (
    <Bar data={outputData} options={outputOptions} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_YOUTUBE_COUNTS}
        title={TITLE_YOUTUBE_COUNTS}
        calendarProps={graphObject}
        id={YoutubeCountsBarGraphMetadata.id}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_YOUTUBE_COUNTS} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
