import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { postAction } from "../../../../../../helpers/actions/actions";
import { API_GET_GRANT_ACCESS_BY_AUTH_CODE, CLIENT_URL, USER_SERVICE_URL } from "../../../../../../api/apiConstants";
import { Google } from "../../../../../../components/login/providersConfig";

export const GoogleLinkCallback = React.memo(() => {
  const history = useHistory();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    /**
     * Calls endpoint to attach google account
     * @param queryParameters Object with keys / values to be passed as query parameters
     * @param data Body of the request
     * @returns AxiosResponse.data
     */
    const registerUserGoogleConnection = (queryParameters: any, data: any) => {
      postAction(API_GET_GRANT_ACCESS_BY_AUTH_CODE, USER_SERVICE_URL, queryParameters, data)
        .then((response) => {
          // Check if the response was successful
          if (response?.message) {
            enqueueSnackbar(`${response.message}`, {
              variant: "success",
            });
          } else {
            enqueueSnackbar(`Error: Something went wrong linking your account to Google`, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(`Error: ${JSON.stringify(error)}`, {
            variant: "error",
          });
        })
        .finally(() => {
          history.push("/settings/profile-data-link/");
        });
    };

    const code = (location.search.match(/code=([^&]+)/) || [])[1];
    const state = (location.search.match(/state=([^&]+)/) || [])[1];

    const queryParameters = {
      provider: Google.PROVIDER,
    };

    const data = {
      client: Google.CLIENT,
      code,
      redirect_uri: `${CLIENT_URL}settings/profile-data-link/callback`,
      state,
    };

    registerUserGoogleConnection(queryParameters, data);
  }, []);

  return null;
});
