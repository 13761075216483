import { stepSizeCalc } from "../../../../../helpers/timeConstants";
import { minutesToHourMinute } from "../../../../../helpers/timeHelpers";

export const initialMoveHistDataset = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "bar",
        label: "Movement Count",
        data: [] as Array<number>,
        fill: false,
        yAxisID: "y-axis-move-hist",
        xAxisID: "x-axis",
      },
    ] as Array<any>,
  };
};

/**
 * Given the move phase or "all" return a label for the x axis for the move histogram graph
 * @param moveType - The user selected move type
 */
const moveTypeToLabel = (moveType: string): string => {
  if (moveType === "duration") {
    return "Time";
  }
  if (moveType === "distance") {
    return "Distance (km)";
  }
  return "";
};

const displayUnitsHandler = (label: string | number, moveType: string, fractionDigits = 1) => {
  if (moveType === "distance") {
    // m to km
    return `${(Number(label) / 1000).toFixed(fractionDigits)}`;
  }
  // else it's duration
  // ms to minute conversion is 60,000
  const millisToMin = Number(label) / 60_000;
  return minutesToHourMinute(Math.ceil(Number(millisToMin)), true, true, true);
};

export const movementHistogramOptions = (
  histogramMaxCount: number,
  moveType: string,
  min: number,
  max: number,
  stepBucketSize: number,
) => {
  let minX = Math.floor(min / stepBucketSize) * stepBucketSize;
  minX -= stepBucketSize;
  let maxX = Math.ceil(max / stepBucketSize) * stepBucketSize;
  maxX += stepBucketSize;
  return {
    scales: {
      yAxes: [
        {
          id: "y-axis-move-hist",
          type: "linear",
          ticks: {
            stepSize: stepSizeCalc(histogramMaxCount),
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          ticks: {
            callback(label: any): string | number {
              return displayUnitsHandler(label, moveType, 0) || 0;
            },
            min: minX,
            max: maxX,
            stepSize: stepBucketSize,
          },
          scaleLabel: {
            display: true,
            labelString: `${moveTypeToLabel(moveType)}`,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: true,
    },
    tooltips: {
      intersect: true,
      mode: "x",
      callbacks: {
        title(tooltipItem: any, data: any): string {
          const initValue = tooltipItem[0].label;
          const nextValue = +initValue + +data.stepBucketSize;
          const unit = moveType === "distance" ? "km" : "";
          return `Between ${displayUnitsHandler(initValue, moveType, 2) || 0} - ${displayUnitsHandler(
            nextValue,
            moveType,
            2,
          )} ${unit}`;
        },
        label(tooltipItem: any): string {
          return `Count: ${tooltipItem.value}`;
        },
      },
    },
  };
};
