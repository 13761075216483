import React from "react";
import { differenceInCalendarDays } from "date-fns";
import { Button, ButtonGroup, Tooltip } from "@material-ui/core";
import {
  graphRangeLastMonth,
  graphRangeLastWeek,
  graphRangeLastYear,
  graphRangeToday,
  graphStaticRanges,
} from "../calendarConstants";
import { CalendarRange } from "../GraphCalendar/calendarBodyHelper";

interface CalendarTimescaleSelectorProps {
  dateRange: CalendarRange;
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>>;
}

export const CalendarDateRangeSelector = ({ dateRange, setDateRange }: CalendarTimescaleSelectorProps) => {
  const numDaysSelected =
    dateRange?.startDate && dateRange.endDate ? differenceInCalendarDays(dateRange.endDate, dateRange.startDate) : 0;

  return (
    <Tooltip title="Adjust the date range">
      <ButtonGroup color="primary" size="small" aria-label="date range selector">
        <Button
          variant={numDaysSelected === 0 ? "contained" : "outlined"}
          onClick={() => {
            setDateRange(graphStaticRanges.find((item) => item.label === graphRangeToday).range());
          }}
        >
          D
        </Button>
        <Button
          variant={numDaysSelected === 7 ? "contained" : "outlined"}
          onClick={() => {
            setDateRange(graphStaticRanges.find((item) => item.label === graphRangeLastWeek).range());
          }}
        >
          W
        </Button>
        <Button
          variant={numDaysSelected === 30 || numDaysSelected === 31 ? "contained" : "outlined"}
          onClick={() => {
            setDateRange(graphStaticRanges.find((item) => item.label === graphRangeLastMonth).range());
          }}
        >
          M
        </Button>
        <Button
          variant={numDaysSelected === 365 ? "contained" : "outlined"}
          onClick={() => {
            setDateRange(graphStaticRanges.find((item) => item.label === graphRangeLastYear).range());
          }}
        >
          Y
        </Button>
      </ButtonGroup>
    </Tooltip>
  );
};
