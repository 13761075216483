import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { defaultGraphHeight, defaultGraphWidth, TITLE_WEIGHT_HISTOGRAM } from "../../../../helpers/graphConstants";
import { BodyQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_MEASURE_WEIGHT, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { WeightHistogramGraphMetadata } from "../../../../helpers/graphsMetadata";
import { useProcessWeightHistogram } from "./useProcessWeightHistogram";

export const WeightHistogramGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    BodyQueryParams.defaultWeightHistogramQueryParams,
    API_MEASURE_WEIGHT,
    DATA_SERVICE_URL,
  );
  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessWeightHistogram(graphData);
  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  const description = [
    "With some diseases like type 2 diabetes, body weight variability is associated with worse outcomes.  The body weight histograms and standard deviation  help you understand your weight variability.",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_WEIGHT_HISTOGRAM}
        title={TITLE_WEIGHT_HISTOGRAM}
        calendarProps={graphObject}
        id={WeightHistogramGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_WEIGHT_HISTOGRAM} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
