import { createContext } from "react";
import { MetadataFieldInterface } from "../graphsMetadata";
import { navigationTree, RenderTree } from "../../components/navigation/NavigationRoutes";

export interface GraphInfoInterface {
  graph: JSX.Element;
  render: boolean;
  metadataField: MetadataFieldInterface;
}

export interface AllGraphsInterface {
  [key: string]: GraphInfoInterface;
}

export const AllGraphsContext = createContext<AllGraphsInterface>({});

export const SearchContext = createContext({
  searchValue: "",
  setSearchValue: (_value: any): void => {
    // return
  },
  range: "",
  setRange: (_value: any): void => {
    // return
  },
});

export const SideNavigationContext = createContext({
  sideNavItems: navigationTree.live.children as RenderTree[] | RenderTree,
  setSideNavItems: (_value: any): void => {
    // return
  },
  sideNavElRefs: {} as any,
  isSideNavOpen: false,
  setSideNavOpen: (_value: any): void => {
    // return
  },
});
