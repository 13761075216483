import React from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { ErrorBoundary } from "react-error-boundary";
import { StepsPage } from "../containers/pages/Live/BodyPage/StepsPage";
import { CustomIFrame } from "./customComponents/CustomIframe/CustomIframe";
import { DashboardPage } from "../containers/pages/Live/DashboardPage/DashboardPage";
import { SleepPage } from "../containers/pages/Live/BodyPage/SleepPage";
import { LearnPage } from "../containers/pages/Learn/LearnPage";
import { FindPage } from "../containers/pages/Find/FindPage";
import { PrivacyPage } from "../containers/pages/Live/SettingsPages/Privacy";
import { AmazonAuth } from "../containers/pages/Login/AmazonAuth";
import NotFoundPage from "../containers/pages/NotFoundPage";
import { ErrorBoundaryFallback } from "./customComponents/ErrorBoundaryFallback";
import { GoogleLinkCallback } from "../containers/pages/Live/SettingsPage/DataLink/GoogleDataLink/GoogleLinkCallback";
import { FavoritesPage } from "../containers/pages/Live/FavoritesPage/FavoritesPage";
import { WeatherPage } from "../containers/pages/Live/EnviromentPage/WeatherPage";
import { AirPage } from "../containers/pages/Live/EnviromentPage/AirPage";
import { ProfilePage } from "../containers/pages/Live/SettingsPages/Profile";
import { LinkAccountCallback } from "./login/LinkAccountCallback";
import { UploadPage } from "../containers/pages/Live/SettingsPage/UploadPage";
import { DataGeneratorPage } from "../containers/pages/Live/SettingsPage/DataGenerator/DataGeneratorPage";
import { NutritionPage } from "../containers/pages/Live/NutritionPage/NutritionPage";
import { ContentPage } from "../containers/pages/Live/MindPage/ContentPage";
import { HeartRatePage } from "../containers/pages/Live/MeasurementPage/HeartRatePage";
import { WeightPage } from "../containers/pages/Live/MeasurementPage/WeightPage";
import { HealthPage } from "../containers/pages/Live/HealthPage/HealthPage";
import { MindPage } from "../containers/pages/Live/MindPage/MindPage";
import { BodyPage } from "../containers/pages/Live/BodyPage/BodyPage";
import { MeasurementPage } from "../containers/pages/Live/MeasurementPage/MeasurementPage";
import { EnviromentPage } from "../containers/pages/Live/EnviromentPage/EnviromentPage";

const MetadataWrapper = (props: { title: string; children: JSX.Element; description?: string }) => {
  const suffix = "Best Life Software Dashboard - Live Learn Innovate Foundation";

  return (
    <>
      <Helmet>
        {props.description?.length ? (
          <meta name="description" content={props.description} />
        ) : (
          <meta name="description" content="Live Learn Innovate Foundation | Data Driven Insights" />
        )}
        <title>{`${props?.title} - ${suffix}`}</title>
      </Helmet>
      {props.children}
    </>
  );
};

/**
 * Top level paths: [/live, /use-cases, /innovate, /find, /settings]
 */
export const PageRouter = (): JSX.Element => (
  <ErrorBoundary fallbackRender={(props) => <ErrorBoundaryFallback {...props} title="the page router" />}>
    <Switch>
      {/* LIVE ROUTES */}
      <Route
        exact
        path="/live/favorites"
        render={() => (
          <MetadataWrapper title="Favorites">
            <FavoritesPage />
          </MetadataWrapper>
        )}
      />
      {/* >> Mind Netflix and content */}
      <Route
        exact
        path="/live/mind"
        render={() => (
          <MetadataWrapper title="Mind">
            <MindPage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/live/mind/content"
        render={() => (
          <MetadataWrapper title="Content">
            <ContentPage />
          </MetadataWrapper>
        )}
      />
      {/* >> Body sleep and steps */}
      <Route
        exact
        path="/live/body"
        render={() => (
          <MetadataWrapper title="Body">
            <BodyPage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/live/body/sleep"
        render={() => (
          <MetadataWrapper title="Sleep">
            <SleepPage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/live/body/steps"
        render={() => (
          <MetadataWrapper title="Steps">
            <StepsPage />
          </MetadataWrapper>
        )}
      />
      {/* >> Nutrition */}
      <Route
        exact
        path="/live/nutrition"
        render={() => (
          <MetadataWrapper title="Nutrition">
            <NutritionPage />
          </MetadataWrapper>
        )}
      />
      {/* >> Health */}
      <Route
        exact
        path="/live/health"
        render={() => (
          <MetadataWrapper title="Health">
            <HealthPage />
          </MetadataWrapper>
        )}
      />
      {/* >> Measurements Heart Rate and weight */}
      <Route
        exact
        path="/live/measurements"
        render={() => (
          <MetadataWrapper title="Measurements">
            <MeasurementPage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/live/measurements/heartrate"
        render={() => (
          <MetadataWrapper title="Heartrate">
            <HeartRatePage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/live/measurements/weight"
        render={() => (
          <MetadataWrapper title="Weight">
            <WeightPage />
          </MetadataWrapper>
        )}
      />
      {/* >> Enviroment Air and Weather */}
      <Route
        exact
        path="/live/environment"
        render={() => (
          <MetadataWrapper title="Enviroment">
            <EnviromentPage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/live/environment/air"
        render={() => (
          <MetadataWrapper title="Air">
            <AirPage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/live/environment/weather"
        render={() => (
          <MetadataWrapper title="Weather">
            <WeatherPage />
          </MetadataWrapper>
        )}
      />
      {/* >> SETTINGS */}
      <Route
        exact
        path="/settings"
        render={() => (
          <MetadataWrapper
            title="Settings"
            description="Manage your personal preferences for your personal data. The creators of Best Life Health Diary mobile app bring you an online personal data dashboard. Use the online dashboard for more detailed data visualizations, health data insights and more. Live your best life today."
          >
            <ProfilePage />
          </MetadataWrapper>
        )}
      />

      <Route
        exact
        path="/settings/data-upload"
        render={() => (
          <MetadataWrapper
            title="Data Upload"
            description="Upload your data takeout from data providers for insights. The creators of Best Life Health Diary mobile app bring you an online personal data dashboard. Use the online dashboard for more detailed data visualizations, health data insights and more. Live your best life today."
          >
            <UploadPage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/settings/privacy/"
        render={() => (
          <MetadataWrapper
            title="Privacy"
            description="Update your privacy preferences for the Live Learn Innovate Foundation nonprofit personal data dashboard. The creators of Best Life Health Diary mobile app bring you an online personal data dashboard. Use the online dashboard for more detailed data visualizations, health data insights and more. Live your best life today."
          >
            <PrivacyPage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/settings/data-generator/"
        render={() => (
          <MetadataWrapper title="Data Generator">
            <DataGeneratorPage />
          </MetadataWrapper>
        )}
      />
      <Route
        exact
        path="/settings/profile/"
        render={() => (
          <MetadataWrapper title="Profile">
            <ProfilePage />
          </MetadataWrapper>
        )}
      />
      {/* >>> OTHERS */}

      {/* TOP LEVEL ROUTES */}
      {/* START TOP-LEVEL LIVE */}
      <Route
        exact
        path="/live"
        render={() => (
          <MetadataWrapper title="Dashboard">
            <DashboardPage />
          </MetadataWrapper>
        )}
      />
      <Route exact path="/">
        <Redirect to="/live" />
      </Route>
      <Route exact path="/dashboard">
        <Redirect to="/live" />
      </Route>
      {/* END TOP-LEVEL LIVE */}
      {/* START TOP-LEVEL LEARN */}
      <Route exact path="/use-cases/graph">
        <LearnPage />
      </Route>
      <Route
        exact
        path="/use-cases"
        render={() => (
          <MetadataWrapper title="Use cases">
            <CustomIFrame title="Use cases" src="https://llif.org/use-cases" />
          </MetadataWrapper>
        )}
      />
      {/* END TOP-LEVEL LEARN */}
      {/* START TOP-LEVEL INNOVATE */}
      <Route
        path="/innovate"
        render={() => (
          <MetadataWrapper title="Innovate">
            <CustomIFrame title="innovate" src="https://www.llif.org/innovate-life" />
          </MetadataWrapper>
        )}
      />
      {/* END TOP-LEVEL INNOVATE */}
      {/* START TOP-LEVEL FIND */}
      <Route
        exact
        path="/find"
        render={() => (
          <MetadataWrapper title="Find">
            <FindPage />
          </MetadataWrapper>
        )}
      />
      {/* END TOP-LEVEL FIND */}
      {/* START TOP-LEVEL ENGAGE */}
      <Route
        path="/engage"
        render={() => (
          <MetadataWrapper title="Engage">
            <CustomIFrame title="engage" src="https://www.llif.org/engage-life" />
          </MetadataWrapper>
        )}
      />

      {/* OAUTH CALLBACKS */}
      <Route
        path="/link_account_callback"
        render={() => (
          <MetadataWrapper title="Linking Account">
            <LinkAccountCallback />
          </MetadataWrapper>
        )}
      />
      <Route path="/login/oauth_callback">
        {/* Show circular process if user gets stuck on the oAuth page */}
        <CircularProgress />
      </Route>
      <Route path="/login/ios_oauth_callback">
        {/* Show circular process if user gets stuck on the oAuth page */}
        <CircularProgress />
      </Route>
      <Route
        path="/amazon_callback"
        render={() => (
          <MetadataWrapper title="Linking Amazon">
            <AmazonAuth />
          </MetadataWrapper>
        )}
      />

      <Route
        path="/settings/profile-data-link/callback"
        render={() => (
          <MetadataWrapper title="Linking Google">
            <GoogleLinkCallback />
          </MetadataWrapper>
        )}
      />
      {/* END TOP-LEVEL ENGAGE */}

      {/* 404 PAGE */}
      <Route
        render={() => (
          <MetadataWrapper title="404 - Page Not Found">
            <NotFoundPage />
          </MetadataWrapper>
        )}
      />
    </Switch>
  </ErrorBoundary>
);
