const NO2_COLOR = "#ff4f75";
const O3_COLOR = "#5032a8";
const PM10_COLOR = "#a8329a";
const PM25_COLOR = "#a88732";

export const initialElementsData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "line",
        label: "NO2",
        data: [] as Array<number>,
        yAxisID: "o3-so2-no2-y-axis",
        xAxisID: "x-axis",
        fill: false,
        borderColor: NO2_COLOR,
        backgroundColor: NO2_COLOR,
        pointBackgroundColor: NO2_COLOR,
        pointBorderColor: NO2_COLOR,
      },
      {
        type: "line",
        label: "OZONE",
        data: [] as Array<number>,
        yAxisID: "o3-so2-no2-y-axis",
        xAxisID: "x-axis",
        fill: false,
        borderColor: O3_COLOR,
        backgroundColor: O3_COLOR,
        pointBackgroundColor: O3_COLOR,
        pointBorderColor: O3_COLOR,
      },
      {
        type: "line",
        label: "SO2",
        data: [] as Array<number>,
        yAxisID: "o3-so2-no2-y-axis",
        xAxisID: "x-axis",
        fill: false,
      },
      {
        type: "line",
        label: "CO",
        data: [] as Array<number>,
        yAxisID: "co-y-axis",
        xAxisID: "x-axis",
        fill: false,
      },
    ] as Array<any>,
  };
};

export const initialParticulateData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "line",
        label: "PM10",
        data: [] as Array<number>,
        yAxisID: "pm10-y-axis",
        xAxisID: "x-axis",
        fill: false,
        borderColor: PM10_COLOR,
        backgroundColor: PM10_COLOR,
        pointBackgroundColor: PM10_COLOR,
        pointBorderColor: PM10_COLOR,
      },
      {
        type: "line",
        label: "PM25",
        data: [] as Array<number>,
        yAxisID: "pm25-y-axis",
        xAxisID: "x-axis",
        fill: false,
        borderColor: PM25_COLOR,
        backgroundColor: PM25_COLOR,
        pointBackgroundColor: PM25_COLOR,
        pointBorderColor: PM25_COLOR,
      },
    ] as Array<any>,
  };
};

export const initialAQIData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "line",
        label: "AQI",
        data: [] as Array<number>,
        yAxisID: "aqi-y-axis",
        xAxisID: "x-axis",
        fill: false,
      },
    ] as Array<any>,
  };
};
