import { useMemo } from "react";
import * as chartjs from "chart.js";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { useAddSimpleMovingAverage } from "../../../customHooks/useAddSimpleMovingAverage";

export const humidityProcessor = (graphData: NewESResponseDataType, data: any) => {
  if (graphData?.Values && haveKeys([graphData.Values])) {
    data.labels = graphData.Values.map((val) => +new Date(val[timestampKey]));
    data.datasets[0].data = graphData.Values.map((val) => {
      return { x: +new Date(val[timestampKey]), y: val.humidity };
    });
  }

  return data;
};

export const useProcessHumidityData = (
  graphData: NewESResponseDataType,
  selectedInterval: string,
): { outputData: any; outputOptions: any } => {
  // Populate data
  const outputData = useMemo(() => {
    const data = initialHumidityData();

    return humidityProcessor(graphData, data);
  }, [graphData]);

  const outputOptions = initialHumidityOptions(selectedInterval);
  useAddSimpleMovingAverage(outputData);

  return { outputData, outputOptions };
};

export const initialHumidityData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Humidity",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-humidity",
        xAxisID: "x-axis",
        type: "line",
      },
    ],
  };
};

export const initialHumidityOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-humidity",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Humidity",
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          position: "bottom",
          ticks: {
            source: "labels",
            autoSkip: true,
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
