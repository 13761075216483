import { DefaultQueryParamsType } from "../types/graphs/DefaultQueryParamsType";

// Dates
export const dateNow = new Date();

// IsoFormat Timestamps
export const timestampNow = new Date().toISOString();

// TimeFormat
export const localDateFormatMonthDay = "MMM D";
export const localDateFormatWithYear = "MM/DD/YY";
export const localDateFormatNoYear = "MM/DD";
export const localTimeFormatWithSeconds = "H:mm:ss";
export const localTimeFormatNoSeconds = "H:mm";

const oneHourAgoDate = new Date();
const oneDayAgoDate = new Date();
const sevenDaysAgoDate = new Date();
const oneMonthAgoDate = new Date();
const threeMonthsAgoDate = new Date();
const sixMonthsAgoDate = new Date();
const oneYearAgoDate = new Date();

oneHourAgoDate.setHours(oneHourAgoDate.getHours() - 1);
oneDayAgoDate.setDate(oneDayAgoDate.getDate() - 1);
sevenDaysAgoDate.setDate(sevenDaysAgoDate.getDate() - 7);
oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1);
threeMonthsAgoDate.setMonth(threeMonthsAgoDate.getMonth() - 3);
sixMonthsAgoDate.setMonth(sixMonthsAgoDate.getMonth() - 6);
oneYearAgoDate.setDate(oneYearAgoDate.getDate() - 365);

// Constants in user local time. Conversion to UTC done by graphAPIFetch
export const now = new Date();

export const perMinute = "1m";
export const perHour = "1h";
export const perDay = "1d";
export const perWeek = "1w";
export const perMonth = "1M";
export const perYear = "1y";

export const yearString = "One year";
export const monthString = "One month";
export const weekString = "One week";
export const dayString = "One day";
export const hourString = "One hour";
export const minuteString = "One minute";

export const secondsInDay = 86400;

export const stepSizeCalc = (size: number) => {
  if (size <= 10) return 1;
  if (size <= 25) return 5;
  if (size <= 75) return 10;
  if (size <= 100) return 25;
  return 1;
};
// QueryParameters constants
export const hourAgoPerMinute: DefaultQueryParamsType = {
  time_gte: oneHourAgoDate,
  time_lte: now,
  interval: perMinute,
  re_fetch_most_recent: true,
};
export const dayAgoHourly: DefaultQueryParamsType = {
  time_gte: oneDayAgoDate,
  time_lte: now,
  interval: perHour,
  re_fetch_most_recent: true,
};
export const weekAgoHourly: DefaultQueryParamsType = {
  time_gte: sevenDaysAgoDate,
  time_lte: now,
  interval: perHour,
  re_fetch_most_recent: true,
};
export const weekAgoDaily: DefaultQueryParamsType = {
  time_gte: sevenDaysAgoDate,
  time_lte: now,
  interval: perDay,
  re_fetch_most_recent: true,
};
export const sleepTimeParams: DefaultQueryParamsType = {
  time_gte: sevenDaysAgoDate,
  time_lte: now,
  interval: "",
  re_fetch_most_recent: true,
};
export const monthAgoDaily: DefaultQueryParamsType = {
  time_gte: oneMonthAgoDate,
  time_lte: now,
  interval: perDay,
  re_fetch_most_recent: true,
};
export const monthAgoWeekly: DefaultQueryParamsType = {
  time_gte: oneMonthAgoDate,
  time_lte: now,
  interval: perWeek,
  re_fetch_most_recent: true,
};
export const quarterAgoWeekly: DefaultQueryParamsType = {
  time_gte: threeMonthsAgoDate,
  time_lte: now,
  interval: perWeek,
  re_fetch_most_recent: true,
};

export const quarterAgoMonthly: DefaultQueryParamsType = {
  time_gte: threeMonthsAgoDate,
  time_lte: now,
  interval: perMonth,
  re_fetch_most_recent: true,
};

export const sixMonthsAgoDaily: DefaultQueryParamsType = {
  time_gte: sixMonthsAgoDate,
  time_lte: now,
  interval: perDay,
  re_fetch_most_recent: true,
};
export const yearAgoHourly: DefaultQueryParamsType = {
  time_gte: oneYearAgoDate,
  time_lte: now,
  interval: "",
  re_fetch_most_recent: false,
};
export const yearAgoDaily: DefaultQueryParamsType = {
  time_gte: oneYearAgoDate,
  time_lte: now,
  interval: perDay,
  re_fetch_most_recent: true,
};
export const yearAgoWeekly: DefaultQueryParamsType = {
  time_gte: oneYearAgoDate,
  time_lte: now,
  interval: perWeek,
  re_fetch_most_recent: true,
};
export const yearAgoWeeklyNoAgg: DefaultQueryParamsType = {
  time_gte: oneYearAgoDate,
  time_lte: now,
  interval: "",
  re_fetch_most_recent: true,
};
export const yearAgoMonthly: DefaultQueryParamsType = {
  time_gte: oneYearAgoDate,
  time_lte: now,
  interval: perMonth,
  re_fetch_most_recent: true,
};
