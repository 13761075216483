import { validateFileExtension, validateFileSize } from "../../../../helpers/services/fileUploaderService";

export const providerDetector = (file: File, supportedProviders: string[]) => {
  return supportedProviders.find((provider) => file.name.toLowerCase().includes(provider.toLowerCase()));
};
export const validateUpload = (file: File): boolean => {
  if (!file?.name) {
    return false;
  }

  let result;

  result = validateFileExtension(file);
  result = result && validateFileSize(file);

  return result;
};

export const INIT_STATE = "INIT_STATE";
export const SET_PROVIDER_STATE = "SET_USED";
export const UNSET_PROVIDER_STATE = "SET_UNUSED";

export const reducer = (state: any, action: { type: string; supportedProviders?: string[]; provider?: string }) => {
  switch (action.type) {
    case INIT_STATE:
      if (!action?.supportedProviders) {
        return state;
      }
      return Object.fromEntries(action.supportedProviders.map((provider) => [provider, { used: false }]));
    case SET_PROVIDER_STATE:
      return { ...state, [action.provider as string]: { used: true } };
    case UNSET_PROVIDER_STATE:
      return { ...state, [action.provider as string]: { used: false } };
    default:
      return state;
  }
};
