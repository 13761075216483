import { initialMoveHistDataset, movementHistogramOptions } from "./movementHistogramHelper";
import { ObjectType } from "../../../../../types/graphs/ObjectType";
import { NewESResponseDataType } from "../../../../../types/responses/NewESResponseDataType";
import { useHistogramGenerator } from "../../../../customHooks/useHistogramGenerator";
import { dataConstants } from "../../../location/locationConstants";
import { activityTypeList, moveTypeList } from "./MovementHistogramSelector";
import { haveKeys } from "../../../../../helpers/components/graphHelpers";

export const useProcessMoveHistogram = (
  fetchedMoveData: NewESResponseDataType,
  moveType: string,
  activityType: string,
): any => {
  const moveInitialHistogramDatasets = initialMoveHistDataset();

  // If "all" is selected in the dropdown, sum all the move states
  const index = moveType === moveTypeList[0] ? "distance_total_m" : "duration_total_ms";

  let moveValues: number[] = [];
  if (fetchedMoveData?.Values && haveKeys(fetchedMoveData.Values)) {
    if (activityType === activityTypeList[0]) {
      moveValues = fetchedMoveData.Values.map((obj: ObjectType) =>
        obj.activities?.reduce((acc: any, val: ObjectType) => acc + val[index], 0),
      ).filter((num) => num > 0);
    } else {
      moveValues = fetchedMoveData.Values.map((obj: ObjectType) =>
        obj.activities
          ?.filter((activity: ObjectType) => dataConstants[activity.activity_type] === activityType)
          ?.reduce((acc: any, val: ObjectType) => acc + val[index], 0),
      ).filter((num) => num > 0);
    }
  }

  const { buckets, largestBucket, min, max, stepBucketSize } = useHistogramGenerator(
    moveValues,
    moveInitialHistogramDatasets,
    20,
  );
  moveInitialHistogramDatasets.datasets[0].data = buckets;
  const histogramLargestBucket = largestBucket;

  const options = movementHistogramOptions(histogramLargestBucket, moveType, min, max, stepBucketSize);

  return { data: moveInitialHistogramDatasets, options };
};
