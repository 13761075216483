/**
 * CURRENTLY
 * Given the NavigationRoutes.ts tree and the desired path, find the title that should be used for breadcrumbs
 *
 * @param tree The navigation routes tree containing names of pages and their routes
 * @param location The desired location's route to get name from
 */
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const drawerWidth = 200;

export const locationToTitle = (tree: any, location: any): string => {
  // If the path is a parent node, just read off the id.
  const title = tree.find((element: any) => {
    return element.id === location;
  })?.value;

  if (!title) {
    // If we haven't found it, its either a child, or DNE.
    const nodes = tree.filter((element: any) => element.nodes);

    let newTitle = "";
    // Search through each child node of the parents
    nodes.find((childNodes: any) => {
      return childNodes.nodes.find((node: any) => {
        if (node.id === location) {
          // If found concat it's value with the parent's
          newTitle = `${childNodes.value} ${node.value}`;
          return node;
        }
        return undefined;
      });
    });

    return newTitle;
  }
  return "Home";
};

export const useNavigationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },

    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
  }),
);
export const useNavigationDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 0, 0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "space-between",
      borderBottom: "1px solid #eeeded",
    },
  }),
);
export const navigationBarStyles = makeStyles({
  root: {
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  rightEnd: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
});
