import React, { useState } from "react";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import { useGraphSetup } from "../../../../customHooks/useGraphSetup";
import { ActivityQueryParams } from "../../../../../helpers/components/defaultQueryParams";
import { API_ACTIVITY_SHOPPING, DATA_SERVICE_URL } from "../../../../../api/apiConstants";
import { GraphHeader } from "../../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { defaultGraphHeight, defaultGraphWidth, TITLE_SHOPPING_HISTOGRAM } from "../../../../../helpers/graphConstants";
import { ShoppingHistogramGraphMetadata } from "../../../../../helpers/graphsMetadata";
import { GraphContainer } from "../../../../customComponents/GraphContainer/GraphContainer";
import { useProcessShoppingHist } from "./useProcessShoppingHist";
import { dataTypeList, shopDataType, ShopHistogramSelector } from "./ShopHistogramSelector";

export const ShoppingHistogramGraph = () => {
  const [dataType, setDataType] = useState<shopDataType>(dataTypeList[0]);
  const [categoryList, setCategoryList] = useState<string[]>(["All"]);
  const [selectedCategory, setSelectedCategory] = useState<string>("All");

  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultShoppingHistogramQueryParams,
    API_ACTIVITY_SHOPPING,
    DATA_SERVICE_URL,
  );

  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessShoppingHist(
    graphData,
    dataType,
    categoryList,
    selectedCategory,
    setCategoryList,
  );

  const description = [
    "Your shopping spend over time helps to maintain your budget but may also help to identify emotional spending related patterns",
  ];
  const graphDisplay = <Bar data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_SHOPPING_HISTOGRAM}
        title={TITLE_SHOPPING_HISTOGRAM}
        calendarProps={graphObject}
        id={ShoppingHistogramGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_SHOPPING_HISTOGRAM} graphDisplay={graphDisplay} calendarProps={graphObject}>
          <Grid container>
            <ShopHistogramSelector
              dataType={dataType}
              setDataType={setDataType}
              categoryList={categoryList}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </Grid>
        </GraphContainer>
      </GraphHeader>
    </div>
  );
};
