import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import { Dict } from "../../../helpers/components/graphHelpers";
import { DefaultQueryParamsType } from "../../../types/graphs/DefaultQueryParamsType";
import { CalendarRange } from "./GraphCalendar/calendarBodyHelper";
import { CalendarModal } from "./GraphCalendar/CalendarModal";
import { QuickNav } from "./GraphFooter/QuickNav";

export const useSkeletonStyles = makeStyles(() => ({
  graphSkeletonContainer: {
    height: 0,
    overflow: "hidden",
    paddingTop: "75%",
    position: "relative",
  },
  graphLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

interface CalendarModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  intervals: Dict;
  isVisible: boolean;
  isLoaded: boolean;
  selectedInterval: string | undefined;
  setSelectedInterval: React.Dispatch<React.SetStateAction<string>> | undefined;
  dateRange: CalendarRange;
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>>;
  initialQueryParams: DefaultQueryParamsType | undefined;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

interface CalendarProps {
  id: string;
  calendarProps: CalendarModalProps;
  graphDisplay: JSX.Element | undefined;
  // eslint-disable-next-line react/require-default-props
  children?: any;
}

export const GraphContainer = ({ id, calendarProps, graphDisplay, children }: CalendarProps): JSX.Element => {
  const classes = useSkeletonStyles();

  const {
    isOpen,
    setOpen,
    intervals,
    selectedInterval,
    setSelectedInterval,
    dateRange,
    setDateRange,
    initialQueryParams,
    setRefetch,
    isVisible,
    isLoaded,
  }: CalendarModalProps = calendarProps;

  return (
    <>
      {!isVisible && !isLoaded ? (
        <div className={classes.graphSkeletonContainer}>
          <Skeleton variant="rect" animation="wave" className={classes.graphLoader} />
        </div>
      ) : (
        <>
          {graphDisplay}
          {children}
          {/* Quick nav */}
          <Box m={2} />
          <QuickNav
            dateRange={dateRange}
            setDateRange={setDateRange}
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
            initialQueryParams={initialQueryParams}
            setRefetch={setRefetch}
          />
        </>
      )}

      {/* Popup modal when calendar clicked */}
      <CalendarModal
        dateRange={dateRange}
        graphDisplay={graphDisplay}
        id={id}
        intervals={intervals}
        isOpen={isOpen}
        selectedInterval={selectedInterval}
        setDateRange={setDateRange}
        setOpen={setOpen}
        setSelectedInterval={setSelectedInterval}
      />
    </>
  );
};
