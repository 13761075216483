import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import {
  NetflixInitialStackedBarType,
  ProfileDurationType,
  ProfileList,
  ShowType,
  TimestampBucketType,
} from "./netflixTypes";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { haveKeys } from "../../../../helpers/components/graphHelpers";

// Constants
export const PROFILE_ALL_STRING = "All";
export const NUM_SHOWS = 5;
export const MOVING_AVG_DAYS = 7;

/**
 * Given a ProfileDurationType object (viewingDurations),
 * check if viewingDurations has an object containing a specific (title).
 *
 * If it does, add (duration) to the existing title, otherwise, add the show (title, duration, timestamp) to viewingDurations[profileName]
 * @param viewingDurations A mapping between profiles and the shows watched on it
 * @param profileName The index to viewingDurations, aka, the netflix profile of interest
 * @param title The name of the show
 * @param duration The duration of the show
 * @param timestamp The timestamp the show was watched
 */
export const addToProfile = (
  viewingDurations: ProfileDurationType,
  profileName: string,
  title: string,
  duration: number,
  timestamp?: number,
): void => {
  if (!viewingDurations[profileName]) {
    viewingDurations[profileName] = [];
  }
  const updateElement = viewingDurations[profileName].find((element) => element.title === title);
  if (!updateElement) {
    viewingDurations[profileName].push({ title, duration, timestamp });
  } else {
    updateElement.duration += duration;
  }
};

/**
 * Given the fetched data from NetflixGraph Endpoint, extract the profiles and show associated with them
 *
 * Converts from API Fetched form
 * [{timestamp, profiles: [SohamProfile:[], JimProfile: []]}]
 * to
 * {SohamProfile: [], JimProfile: []}
 *
 * With this result, the Object.keys can be used to get a list of all profiles,
 * while the values can be used for durations and timestamps
 *
 * @param fetchedStepsData Data straight from the API endpoint
 */
export const populateProfileList = (fetchedStepsData: NewESResponseDataType): ProfileList => {
  const profileData: ProfileList = {};

  if (fetchedStepsData?.Values && haveKeys(fetchedStepsData.Values)) {
    fetchedStepsData.Values.forEach((netflixObj) => {
      if (Array.isArray(netflixObj?.profiles)) {
        const timestamp: number = +new Date(netflixObj[timestampKey]);
        const { profiles } = netflixObj;

        profiles.forEach((key) => {
          const profileName = Object.keys(key)[0];
          if (!profileData[profileName]) {
            profileData[profileName] = [];
          }

          key[profileName].forEach((title: ShowType) => {
            profileData[profileName].push({ timestamp, duration: title.duration, title: title.title });
          });
        });
      }
    });
  }

  return profileData;
};

/**
 * Given a list of objects (dataset), see if dataset has an object containing a field (datasetField) that matches (target)
 * If it does, return it. If not, push an object (data) to dataset and return the object pushed.
 * @param datasets A list of objects
 * @param datasetField The target field in the list
 * @param target What to compare the target field in the object to
 * @param data The object pushed to the dataset if it doesnt already exist
 */
export const pushUnique = (datasets: any[], datasetField: string | number, target: string | number, data: any) => {
  let targetDataset = datasets.find((dataset) => dataset[datasetField] === target);
  if (targetDataset) {
    return targetDataset;
  }
  datasets.push(data);
  targetDataset = datasets.find((dataset) => dataset[datasetField] === target);
  if (targetDataset) {
    return targetDataset;
  }
  throw new Error("Couldn't push unique element to array");
};

/**
 * Stacked bars must have UNIQUE timestamps, and each dataset must contain data for ALL the points
 * Follow https://github.com/chartjs/Chart.js/issues/5405#issuecomment-380719253 for reference
 * In short:
 * 1. Timestamps must be unique per dataset. A dataset cannot have 2 TupleData points with the same timestamp
 * 2. Each dataset must have every show. If the show doesn't exist for that timestamp, the duration should be 0
 */
export const generateTimestampBuckets = (
  viewingDurations: ProfileDurationType,
  profile: string,
): TimestampBucketType => {
  const timestampBucket: TimestampBucketType = {};
  viewingDurations?.[profile]?.forEach((show) => {
    if (show?.timestamp) {
      const { timestamp, duration, title } = show;

      // Create unique datasets for each title
      if (timestampBucket[timestamp]) {
        // If a bucket exists, add the title, timestamp, duration to it. Else, create one
        timestampBucket[timestamp]?.push({ title, x: timestamp, y: duration });
      } else {
        timestampBucket[timestamp] = [{ title, x: timestamp, y: duration }];
      }
    }
  });

  return timestampBucket;
};

/**
 * With timestampBuckets created, push them into the appropriate dataset after summing duration for the show
 * @param timestampBucket Aggregation of unique timestamps with corresponding Shows
 * @param initialData Target dataset container
 */
export const pushDataToDatasets = (
  timestampBucket: TimestampBucketType,
  initialData: NetflixInitialStackedBarType,
): void => {
  Object.keys(timestampBucket).forEach((key) => {
    initialData.datasets.forEach((dataset) => {
      const targetShow = timestampBucket[+key].find((show) => show.title === dataset.label);
      let duration = 0;

      if (targetShow) {
        let sum = 0;
        timestampBucket[+targetShow?.x].forEach((show) => {
          if (show.title === targetShow.title) sum += show.y;
        });
        duration = sum;
      }

      dataset.data.push({ x: +key, y: duration });
    });
  });
};

/**
 * Generate one dataset for every show in the profile
 * Visit https://github.com/chartjs/Chart.js/issues/5405#issuecomment-380719253 to see why necessary
 *
 * @param initialData Target dataset container
 * @param viewingDurations All the shows for the profile selected
 * @param profile The profile the user has selected
 */
export const generateDatasetPerShow = (
  initialData: NetflixInitialStackedBarType,
  viewingDurations: ProfileDurationType,
  profile: string,
): void => {
  // Generate a dataset for each show
  viewingDurations?.[profile]?.forEach((show) => {
    // Check if the dataset already exists for it, if not, make it
    if (!initialData.datasets.find((datasetObj) => datasetObj.label === show.title)) {
      initialData.datasets.push({
        label: show.title,
        data: [] as Array<TupleDataType>,
      });
    }
  });
};

/**
 * Get all the shows for the profile selected
 * ProfileName: [{ title; duration; timestamp? }]
 * ProfileName2: [{ ShowObj }, {ShowObj}]
 *
 * @param profileData A ProfileList object
 * @param profile The profile the user has selected
 */
export const calcDurationPerProfile = (profileData: ProfileList, profile: string): ProfileDurationType => {
  const viewingDurations: ProfileDurationType = {};

  if (profileData)
    Object.entries(profileData).forEach((key) => {
      const profileName = key[0];
      const profileData = key[1];
      // Only aggregate shows for the profile user has selected. Special case "All" selected
      if (profile === PROFILE_ALL_STRING || profileName === profile) {
        profileData.forEach((show) => {
          // For working grouping but broken average
          const showSplit = show?.title.split(/[_:]/);
          let title: string = showSplit[0] as string;

          if (title.includes("Trailer")) {
            // Special case for trailers
            title += showSplit[1] ?? "";
          }

          const duration = show?.duration / 60;
          const timestamp = +new Date(show?.[timestampKey]);

          if (!viewingDurations[profile]) {
            viewingDurations[profile] = [];
          }
          viewingDurations[profile].push({ title, duration, timestamp });
        });
      }
    });

  return viewingDurations;
};
