import { addHours, format, subSeconds } from "date-fns";

export const ISO_TIMESTAMP_TIMEZONE_FORMAT = `yyyy-MM-dd'T'HH:mm:ss.SSSxxxx`;

export const normalizeTimestamp = (dateObject: Date): string | undefined => {
  if (!dateObject) return undefined;
  if (!(dateObject instanceof Date)) return undefined;

  return format(dateObject, ISO_TIMESTAMP_TIMEZONE_FORMAT);
};

export const millisToMinutes = (milliseconds: number): number => {
  return Math.floor(milliseconds / 60000);
};

/**
 * Convert minutes into hours and minutes
 * For example,
 * 60 -> "1 hour"
 * 123 -> "2 hours and 3 minutes"
 * If displayHourString is true, the word "hours"/"hour" is displayed.
 * This boolean is useful for Axis labeling where the stepsize is set to 60 but you dont want the word "hours" written
 * TODO(spk): Replace with https://www.npmjs.com/package/humanize-duration
 * @param numOfMinutes the number in minutes
 * @param displayHourString Should "hours" or "hour" be displayed?
 * @param displayMinuteString Should any units be displayed?
 * @param shortForm Whether to display "h" or "hour" "m" or "minutes"
 */
export const minutesToHourMinute = (
  numOfMinutes: number,
  displayHourString = true,
  displayMinuteString = true,
  shortForm = false,
): string => {
  const hours = numOfMinutes / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  const hourString = shortForm ? "h " : " hour ";
  const hoursString = shortForm ? "h " : " hours ";
  const minString = shortForm ? " m" : " minute";
  const minsString = shortForm ? " m" : " minutes";

  let result = "";

  if (rhours > 0) {
    result += rhours;
    if (displayHourString) {
      result += rhours === 1 ? hourString : hoursString;
    }
  }
  if (rminutes > 0) {
    result += rminutes;
    if (displayMinuteString) {
      result += rminutes === 1 ? minString : minsString;
    }
  }

  return result;
};

/**
 * Converts a Unix timestamp to a string by using the current or specified locale.
 * Ex: 8/19/2020
 * @param UNIXTimestamp Epoch time
 */
export const timestampToLocaleDateString = (UNIXTimestamp: string | number): string => {
  const date = new Date(Number(UNIXTimestamp));
  return date.toLocaleDateString();
};

/**
 * The standard we're using for rounding is:
 * If int(x) < 0-1, then 3 decimal points.
 * If int(x) < 10, then 2 decimal points.
 * if int(x) 10-100, then 1 decimal point.
 * If int(x) >100, then zero decimal points.
 * @param input Take this number and round it according to the standard
 * @returns rounded number if successful, and -1 if failure
 */
export const unitRounder = (input: number | string): number => {
  let result = 0;
  const absInput = Math.abs(+input);
  if (absInput >= 0 && absInput < 1) {
    result = +absInput.toFixed(3);
  } else if (absInput >= 1 && absInput <= 10) {
    // Two decimal places
    result = +absInput.toFixed(2);
  } else if (absInput > 10 && absInput <= 100) {
    // One decimal place
    result = +absInput.toFixed(1);
  } else if (absInput > 100) {
    // Round to whole
    result = +Math.round(absInput);
  }

  return input < 0 ? result * -1 : result;
};

export type BucketType = { startDate: Date; endDate: Date };

/**
 * Given a start date, end date, and number, split the date range into size specified by optional args
 * Default is 4 hour batches/buckets
 * According to benchmarking done in mobile PR #283, it takes approx ~10 seconds to process ~100 days of info.
 * In order to bring the process time to 1/60th of a second, we should use ~ 4 hours or less.
 * The lower the number, the smoother the UI, but the longer it takes
 *
 * @param start - The start date of the range to split
 * @param end - The end date of the range to split
 * @param batchSize - The number of "units" to split the range into
 * @param addUnits - The unit of time to split the range into. Default is "hours"
 * @param subtractUnits - The unit of time to subtract at the end of a bucket.
 *      Default is "seconds". This will change whether the bucket ends at 23:59:59 or 23:59:00
 * @param subtractAmount - The amount to subtract at the end of a bucket.
 *      Default is 1. This will change whether the bucket ends at 23:59:59 or 23:59:00
 * @returns [{startbatch: Date, endBatch: Date}, {...}, ...]
 */
export const dateRangeBucketer = (
  start: Date,
  end: Date,
  batchSize = 1,
  addUnits = addHours,
  subtractUnits = subSeconds,
  subtractAmount = 1,
): BucketType[] => {
  const res: BucketType[] = [];

  let startDate = new Date(start.getTime());
  let endDate = subtractUnits(addUnits(startDate, batchSize), subtractAmount);
  res.push({ startDate, endDate: endDateChecker(endDate, end) });

  while (+endDate <= +subtractUnits(end, subtractAmount)) {
    // Start date is one unit after the last end batch
    startDate = addUnits(startDate, batchSize);
    // end batch is `batchSize` units after the new start batch
    endDate = subtractUnits(addUnits(startDate, batchSize), subtractAmount);
    res.push({ startDate, endDate: endDateChecker(endDate, end) });
  }

  return res;
};

const endDateChecker = (endDate: Date, finalEndDate: Date) => {
  if (+endDate >= +finalEndDate) {
    return finalEndDate;
  }
  return endDate;
};
