import React from "react";
import { addDays, differenceInCalendarDays, endOfDay, startOfDay, subDays } from "date-fns";
import { CalendarRange } from "./GraphCalendar/calendarBodyHelper";

export const numSelectedDays = (currentRange: CalendarRange) => {
  return currentRange?.startDate && currentRange.endDate
    ? differenceInCalendarDays(currentRange.endDate, currentRange.startDate)
    : 0;
};

export const shiftRight = (
  currentRange: CalendarRange,
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>>,
): void => {
  const shiftAmount = numSelectedDays(currentRange);

  const newRange: CalendarRange = {
    startDate: startOfDay(currentRange.endDate ?? new Date()),
    endDate: endOfDay(addDays(currentRange.endDate ?? new Date(), shiftAmount)),
  };
  setDateRange(newRange);
};

export const shiftLeft = (
  currentRange: CalendarRange,
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>>,
) => {
  const shiftAmount = numSelectedDays(currentRange);

  const newRange: CalendarRange = {
    startDate: startOfDay(subDays(currentRange.startDate ?? new Date(), shiftAmount)),
    endDate: endOfDay(currentRange.startDate ?? new Date()),
  };
  setDateRange(newRange);
};
