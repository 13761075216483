import * as chartjs from "chart.js";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { minutesToHourMinute, unitRounder } from "../../../../helpers/timeHelpers";
import { DatasetStackedType, NetflixInitialStackedBarType } from "./netflixTypes";
import { averageSuffix } from "../../../customHooks/useAddMultiDatasetMovingAverage";

// Totals Bar
export const initialNetflixBarData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [] as Array<DatasetStackedType>,
  };
};
export const initialNetflixBarOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      callbacks: {
        title(tooltipItem: any[], data: any): string {
          const index = tooltipItem[0]?.datasetIndex;

          return data.datasets[index].label;
        },
        label(tooltipItem: any): string {
          return `${minutesToHourMinute(Number(tooltipItem.yLabel), true, true)}`;
        },
        footer(toolTipItems: any[]) {
          return toolTipItems[0].xLabel;
        },
      },
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-netflix",
          type: "linear",
          stacked: true,
          ticks: {
            min: 0,
            callback(label: any): string {
              return Number(label) % 60 === 0 ? minutesToHourMinute(Number(label), false, false) : "";
            },
            stepSize: 60, // 60 minutes
          },
          scaleLabel: {
            display: true,
            labelString: "Time Watched (hours)",
          },
        },
      ],
      xAxes: [
        {
          offset: true,
          type: "time",
          id: "x-axis",
          stacked: true,

          ticks: {
            minor: {
              autoSkip: true,
              source: "data",
            },
            major: {
              enabled: true,
            },
          },
          time: {
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
            tooltipFormat: "lll",
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: true,
    },
  } as chartjs.ChartOptions;
};

// Stacked Bar
export const initialNetflixStackedBarData = (): NetflixInitialStackedBarType => {
  return {
    labels: [] as Array<number>,
    datasets: [] as Array<DatasetStackedType>,
  };
};
export const initialStackedBarOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      intersect: true,
      mode: "nearest",
      callbacks: {
        label(toolTipItem: any, data: any): string {
          return `${data.datasets[toolTipItem.datasetIndex].label}: `;
        },
        afterLabel(toolTipItem: any): string[] {
          return [`${minutesToHourMinute(Number(toolTipItem.yLabel)) || "< 1 minute"}`];
        },
        footer: (toolTipItem: any, data: any): string => {
          const index = toolTipItem[0].datasetIndex;
          if (data.datasets[index].label.includes(averageSuffix)) {
            return "";
          }
          return "Click to view on IMDB";
        },
      },
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: true,
    scales: {
      xAxes: [
        {
          id: "x-axis",
          offset: true,
          stacked: true,
          type: "time",
          time: {
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
          },
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-netflix",
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback(label: any): string {
              return +label % 60 === 0 ? minutesToHourMinute(Number(label), false, false) : "";
            },
            stepSize: 30, // 30 minutes
          },
          scaleLabel: {
            display: true,
            labelString: "Hours",
          },
        },
        {
          id: "y-axis-netflix-avg",
          stacked: false,
          position: "right",
          ticks: {
            beginAtZero: true,
            callback(label: any): string {
              return +label % 60 === 0 ? minutesToHourMinute(Number(label), false, false) : "";
            },
            stepSize: 30, // 30 minutes
            min: 0,
          },
          scaleLabel: {
            display: true,
            labelString: "Avg",
          },
        },
      ],
    },
  } as chartjs.ChartOptions;
};

// Pie
export const initialNetflixPieData = () => {
  return {
    labels: [] as Array<string>,
    datasets: [] as Array<any>,
  };
};
export const initialNetflixPieOptions = () => {
  return {
    tooltips: {
      callbacks: {
        label(tooltipItem: any, data: any): string {
          try {
            let label = `${data.labels[tooltipItem.index]}:` || "Unknown";

            const sum = data.datasets[0].data.reduce((accumulator: any, curValue: any) => {
              return accumulator + curValue;
            });
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            label += ` ${unitRounder(Number((value / sum) * 100))}%`;
            return label;
          } catch (error) {
            return "Unknown";
          }
        },
        footer: (): string => {
          return "Click to view on IMDB";
        },
      },
    },
  };
};
