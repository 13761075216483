import { useMemo } from "react";
import * as chartjs from "chart.js";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { CELSIUS, FAHRENHEIT } from "../../../../helpers/settingsContstants";

const convertTemperature = (value: number, tempUnit: string) => {
  // eslint-disable-next-line no-mixed-operators
  if (tempUnit === FAHRENHEIT) return (value * 1.8 + 32).toFixed(2);
  return value;
};
export const temperatureProcessor = (graphData: NewESResponseDataType, data: any, tempUnit = CELSIUS) => {
  if (graphData?.Values && haveKeys([graphData.Values])) {
    data.labels = graphData.Values.map((val) => +new Date(val[timestampKey]));
    data.datasets[0].data = graphData.Values.map((val) => {
      return {
        x: +new Date(val[timestampKey]),
        y: val.temperature ? convertTemperature(val.temperature.toFixed(2), tempUnit) : null,
      };
    });
    data.datasets[1].data = graphData.Values.map((val) => {
      return {
        x: +new Date(val[timestampKey]),
        y: val.apparentTemperature ? convertTemperature(val.apparentTemperature.toFixed(2), tempUnit) : null,
      };
    });
  }

  return data;
};

export const useProcessTemperatureData = (
  graphData: NewESResponseDataType,
  selectedInterval: string,
  tempUnit = CELSIUS,
): { outputData: any; outputOptions: any } => {
  // Populate data
  const outputData = useMemo(() => {
    const data = initialTemperatureData(tempUnit);

    return temperatureProcessor(graphData, data, tempUnit);
  }, [graphData]);

  const outputOptions = initialTemperatureOptions(selectedInterval);

  return { outputData, outputOptions };
};

export const initialTemperatureData = (temp: string) => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: `Temperature (${temp})`,
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-temp",
        xAxisID: "x-axis",
        type: "line",
      },
      {
        label: `Feels Like (${temp})`,
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-temp",
        xAxisID: "x-axis",
        type: "line",
      },
    ],
  };
};

export const initialTemperatureOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-temp",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Temperature",
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          position: "bottom",
          ticks: {
            source: "labels",
            autoSkip: true,
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
