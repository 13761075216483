/* eslint-disable no-mixed-operators */
/* eslint-disable camelcase */
/* eslint-disable no-extra-parens */
import { addSeconds, parseISO } from "date-fns";
import _ from "lodash";
import { dateRangeBucketer, normalizeTimestamp } from "../../../../../helpers/timeHelpers";
import { ObjectType } from "../../../../../types/graphs/ObjectType";
import { API_UPLOAD_EVENTS, DATA_SERVICE_1_0 } from "../../../../../api/apiConstants";
import { apiPost } from "../../../../../api/actions";

type EventType = {
  name: string;
  timestamp: string;
  end_time: string | null;
  duration: number | null;
  tags: string[] | null;
  is_standard: boolean;
  plan_extension: ObjectType | null;
  metadata: ObjectType | null;
};

type TagObject = {
  tag: string;
};

export const uploadEvents = async (events: EventType[]) => {
  const generatorMetadata = {
    organization: "llif",
    data_integrity: 0,
    service: "data_generator",
    data_quality: 1,
  };

  const result = await apiPost(
    API_UPLOAD_EVENTS,
    {},
    {
      metadata: generatorMetadata,
      documents: events,
    },
    {},
    DATA_SERVICE_1_0,
  );

  return result;
};

export const processRows = (rows: RowType[]) => {
  const results: EventType[] = [];

  rows
    .filter((row) => row.file?.content?.length && row.file.name?.length)
    .forEach((row) => {
      let validatedJSON;

      try {
        validatedJSON = JSON.parse(row.file?.content.trim() as string);
        results.push(...addRowsEventToResult(row, validatedJSON));
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(`Invalid JSON in ${row.file.name}\n${e}`);
      }
    });

  return results;
};

const randomNum = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

const handleVariations = (inputRow: RowType, validatedJSON: EventType): EventType => {
  const row = _.cloneDeep(inputRow);

  if (!row.variations) {
    return validatedJSON;
  }

  const newJSONEvent = _.cloneDeep(validatedJSON);

  row.variations.forEach((variation) => {
    const fieldName = variation.fieldName.trim();

    if (fieldName === "timestamp" || fieldName === "end_time") {
      const varyAmt = randomNum(variation.min as number, variation.max as number);

      const currValue = _.get(newJSONEvent, fieldName);
      if (!currValue) {
        return;
      }

      const currValueDate = parseISO(currValue);
      const newValue = normalizeTimestamp(addSeconds(currValueDate, varyAmt));

      _.set(newJSONEvent, fieldName, newValue);
    } else {
      const currValue = _.get(newJSONEvent, fieldName) || 0;

      const varyAmt = randomNum(variation.min as number, variation.max as number);
      const newValue = currValue + varyAmt;

      _.set(newJSONEvent, fieldName, newValue);
    }
  });

  return newJSONEvent;
};

const addRowsEventToResult = (row: RowType, event: EventType): EventType[] => {
  const results: EventType[] = [];
  const buckets = rowToBuckets(row);

  // Duplicate first bucket
  const firstBucket = _.cloneDeep(buckets[0]);
  // Push first bucket to the front
  buckets.unshift(firstBucket);

  buckets.forEach((bucket, i) => {
    event.timestamp = normalizeTimestamp(bucket.startDate) as string;

    const tags = event?.tags;

    // Mark is_standard as true for first bucket (that was duplicate above)
    event.is_standard = i === 0;

    // remove plan extension if exists
    if (event.plan_extension) {
      event.plan_extension = null;
    }

    if (event.metadata) {
      event.metadata = null;
    }

    // Fix tags if in old format
    if (tags?.length && typeof tags[0] === "object") {
      const convertedTags: string[] = (tags as unknown as TagObject[]).map((tagObject) => tagObject.tag);
      event.tags = convertedTags?.length ? convertedTags : null;
    } else if (!tags?.length) {
      event.tags = null;
    }

    const eventWithVariations = handleVariations(row, event);

    if (eventWithVariations?.duration) {
      const currTimestamp = parseISO(eventWithVariations.timestamp);
      const newEndTime = addSeconds(currTimestamp, Math.abs(eventWithVariations.duration));
      const newEndTimeStr = normalizeTimestamp(newEndTime) as string;

      eventWithVariations.end_time = newEndTimeStr as string;
    } else {
      eventWithVariations.end_time = null;
    }

    results.push(_.cloneDeep(eventWithVariations));
  });

  return results;
};

export const rowToBuckets = (row: RowType) => {
  if (row.schedule.unit === null || row.schedule.quantity === null) {
    return [];
  }

  return dateRangeBucketer(
    row.schedule.startDate,
    row.schedule.endDate,
    row.schedule.quantity * row.schedule.unit,
    addSeconds,
  );
};

export type VariationType = {
  variationId: string;
  fieldName: string;
  min: number;
  max: number;
};

export type RowType = {
  id: string;
  file: {
    name: string;
    content: string;
  } | null;
  schedule: {
    startDate: Date;
    endDate: Date;
    unit: number | null;
    quantity: number | null;
  };
  variations: VariationType[] | null;
};

export const secondsToHumanReadable = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours.toString().padStart(2, "0")}h : ${minutes.toString().padStart(2, "0")}m : ${remainingSeconds
    .toString()
    .padStart(2, "0")}s`;
};
