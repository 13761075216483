import React, { useContext } from "react";
import { Divider, IconButton, Menu, MenuItem, Typography, useMediaQuery } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { graphStaticRanges } from "../../customComponents/GraphContainer/calendarConstants";
import { useSearchBarStyles } from "./navigationStyles";
import { SearchContext } from "../../../helpers/context/contextVariables";

export const TopNavSearchBoxRangeSelector = () => {
  // STATE VARIABLE
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // CONTEXT VARIABLE
  const { range, setRange } = useContext(SearchContext);

  const classes = useSearchBarStyles();
  const isDesktop = useMediaQuery("(min-width:1300px)"); // More than 1300

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const rangeDates: string[] = graphStaticRanges.map((range) => range.label);

  return (
    <>
      <Divider className={classes.divider} orientation="vertical" />

      <IconButton className={classes.iconButton} aria-label="directions" onClick={handleClick}>
        {isDesktop ? (
          <Typography variant="body1" style={{ marginRight: 5 }}>
            {range.toString()}
          </Typography>
        ) : (
          <></>
        )}

        <FilterListIcon color="secondary" />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {rangeDates.map((key: any) => (
          <MenuItem
            key={key}
            value={key}
            onClick={() => {
              setRange(key);
              handleClose();
            }}
          >
            {key}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
