import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { IdentityContext } from "../../types/general/IdentityContext";
import { LoginGoogleProvidersResponse } from "../../types/responses/LinkingResponseType";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface LinkingAcountTableInterface {
  users: Array<LoginGoogleProvidersResponse>;
  unlinkAccount: (id: string, provider: string) => void;
}

const UsersTable = ({ users, unlinkAccount }: LinkingAcountTableInterface) => {
  const classes = useStyles();
  const { userData } = useContext(IdentityContext);
  const getProvider = (row: LoginGoogleProvidersResponse) => {
    if (row.google_id) return "google";
    return "apple";
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {users
            .filter((v: any) => v.user_data.email !== userData.userEmail)
            .map((row: any) => (
              <TableRow key={row.google_id ? row.google_id : row.apple_id}>
                <TableCell component="th" scope="row">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar alt="Amazon logo" src={row.user_data.picture} />
                    <p style={{ marginLeft: "25px" }}>{row.user_data.email}</p>
                  </div>
                </TableCell>

                <TableCell align="right">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      unlinkAccount(row.google_id ? row.google_id : row.apple_id, getProvider(row));
                    }}
                  >
                    Unlink
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
