import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { useHistogramGenerator } from "../../../customHooks/useHistogramGenerator";
import { initialRHRHistDatasets, rhrHistogramOptions } from "./rhrHistogramHelper";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { RHRObject } from "../RestingHeartRateLineGraph/useProcessRestingHeartRateData";

export const useProcessRHRHistogram = (fetchedHRData: NewESResponseDataType): any => {
  const initialRHRDatasets = initialRHRHistDatasets();

  let hrValues: number[] = [];
  if (fetchedHRData?.Values && haveKeys(fetchedHRData.Values)) {
    // eslint-disable-next-line no-extra-parens
    hrValues = (fetchedHRData.Values as RHRObject[])
      .map((obj: RHRObject) => obj.bpm_avg)
      .filter((hr) => hr !== null) as number[];
  }

  const { buckets, largestBucket, min, max, stepBucketSize } = useHistogramGenerator(hrValues, initialRHRDatasets, 30);

  initialRHRDatasets.datasets[0].data = buckets;
  const histogramLargestBucket = largestBucket;

  const options = rhrHistogramOptions(histogramLargestBucket, min, max, stepBucketSize);

  return { data: initialRHRDatasets, options };
};
