import {
  Box,
  createStyles,
  LinearProgress,
  LinearProgressProps,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { defaultGraphWidth } from "../../helpers/graphConstants";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
    },
  }),
)(LinearProgress);

export const LinearProgressWithLabel = (props: LinearProgressProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={defaultGraphWidth / 2}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value as number)}%`}</Typography>
      </Box>
    </Box>
  );
};
