import { useMemo } from "react";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { dataConstants, inAir, inLand, inWater, onFoot, still } from "../../location/locationConstants";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { millisToMinutes, minutesToHourMinute } from "../../../../helpers/timeHelpers";
import { TITLE_MOVEMENT_STATES } from "../../../../helpers/graphConstants";
import { CalendarRange } from "../../../customComponents/GraphContainer/GraphCalendar/calendarBodyHelper";

export const useProcessPieData = (fetchedData: NewESResponseDataType, dateRange: CalendarRange): any => {
  const outputBarData = useMemo(() => {
    const initialData = data();

    if (fetchedData?.Values && haveKeys([fetchedData.Values])) {
      const values: ObjectType = { [onFoot]: 0, [inLand]: 0, [inWater]: 0, [inAir]: 0 };

      fetchedData.Values.forEach((element) => {
        const activities = element?.activities as any[];
        // populate dates, which later becomes labels of graph
        if (activities) {
          activities.forEach((activity) => {
            values[dataConstants[activity.activity_type]] += activity.duration_total_ms;
          });
        }
      });

      // Only display values that are more than 0
      initialData.datasets[0].data = Object.values(values).filter((val) => val > 0);

      // Time spent "still"is the remaining time.
      const totalMovementTime = initialData.datasets[0].data.reduce((a: number, b: number) => a + b, 0);
      // now get the time still
      if (dateRange.endDate && dateRange.startDate) {
        const totalTime = +dateRange.endDate - +dateRange.startDate;
        const stillTime = totalTime - totalMovementTime;
        initialData.datasets[0].data[4] = stillTime;
      }
    }

    return initialData;
  }, [fetchedData]);

  return { outputBarData };
};

const data = (): any => {
  return {
    labels: [onFoot, inLand, inWater, inAir, still],
    datasets: [
      {
        data: [0, 0, 0, 0, 0] as Array<number>,
        label: TITLE_MOVEMENT_STATES,
        backgroundColor: ["rgb(255, 99, 132)", "rgb(255, 205, 86)", "rgb(54, 162, 235)", "rgb(200,200,200)"],
      },
    ],
  };
};

export const options = (): any => {
  return {
    tooltips: {
      callbacks: {
        label(tooltipItem: any, data: any): string {
          const totalValue = data.datasets[0].data.reduce((acc: any, currVal: any) => acc + currVal);
          const { index } = tooltipItem;
          const currentValue = minutesToHourMinute(millisToMinutes(data.datasets[0].data[index]));
          const percentOfTotal = ((data.datasets[0].data[index] / totalValue) * 100).toFixed(2);

          return `${data.labels[index]}: ${currentValue} (${percentOfTotal}%)`;
        },
      },
    },
    // Only show datasets that have data in their datasets
    legend: {
      display: true,
      labels: {
        filter(legendItem: any, data: any) {
          const { index } = legendItem;
          return data.datasets[0].data[index] > 0;
        },
      },
    },
    type: "pie",
  };
};
