import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { drawerWidth } from "../navigationHelpers";

const MOBILE_BREAKPOINT = "md";
const SEARCH_BREAKPOINT = "sm";

export const useAppBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabLarge: {
      maxWidth: 100,
    },
    tabMed: {
      minWidth: 100,
    },
    tabSmall: {
      minWidth: 20,
      fontSize: "0.1em",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up(SEARCH_BREAKPOINT)]: {
        display: "block",
      },
    },
    darkModeToggleIcon: {
      marginLeft: theme.spacing(0),
      marginRight: 0,
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        display: "none",
      },
    },
    userDetail: { display: "flex", justifyContent: "center", alignItems: "center" },
  }),
);
export const useSearchBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: "40%",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 5,
      paddingTop: 10,
      paddingBottom: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);
