import { useMemo } from "react";
import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { stepsValueStr } from "./StepsHelper";
import { initialStepsLine, initialStepsLineOptions, StepsProcessedType } from "./StepsOptions";
import { useAddSimpleMovingAverage } from "../../../customHooks/useAddSimpleMovingAverage";

export const stepsProcessor = (fetchedStepsData: NewESResponseDataType, stepsLineData: any) => {
  if (fetchedStepsData?.Values && haveKeys([fetchedStepsData.Values])) {
    // Populate data
    fetchedStepsData.Values.forEach((stepObj) => {
      if (stepObj[stepsValueStr] !== null && +stepObj[stepsValueStr] > 0) {
        const timestamp = +new Date(stepObj[timestampKey]);
        stepsLineData.labels.push(timestamp);

        pushTupleData(stepsLineData, 0, {
          x: timestamp,
          y: Math.round(+stepObj[stepsValueStr]),
        });
      }
    });
  }
  return { stepsLineData };
};

export const useProcessStepsGraphData = (fetchedStepsData: NewESResponseDataType, selectedInterval: string): any => {
  const stepsLineData = initialStepsLine();

  const outputStepsData = useMemo(() => {
    const data: StepsProcessedType = stepsProcessor(fetchedStepsData, stepsLineData);
    const options = initialStepsLineOptions(data, selectedInterval);

    return { data, options };
  }, [fetchedStepsData, selectedInterval]);

  useAddSimpleMovingAverage(outputStepsData.data.stepsLineData);

  return { data: outputStepsData?.data, options: outputStepsData?.options };
};
