import React, { useState, useEffect } from "react";
import { getActionPromise } from "../helpers/actions/actions";
import { initialValues, NewESResponseDataType } from "../types/responses/NewESResponseDataType";
import { CalendarRange } from "../components/customComponents/GraphContainer/GraphCalendar/calendarBodyHelper";

export const useGraphAPIFetch = (
  dateRange: CalendarRange,
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>> | undefined,
  selectedInterval: string,
  API_ENDPOINT: string,
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  refetch: boolean,
  setRefetch: React.Dispatch<React.SetStateAction<boolean>> | undefined,
  baseURL: string,
  isVisible: boolean,
): NewESResponseDataType => {
  const [graphData, setGraphData] = useState<NewESResponseDataType>(initialValues);

  useEffect(() => {
    setIsLoaded(false);
    // eslint-disable-next-line camelcase
    const newParams = {
      time_gte: dateRange?.startDate?.toISOString() ?? "",
      time_lte: dateRange?.endDate?.toISOString() ?? "",
      timezone_offset: new Date().getTimezoneOffset(),
      interval: selectedInterval || undefined,
      re_fetch_most_recent: refetch,
    };

    // Only fetch if the graph is visible on the graph
    if (isVisible) {
      // TODO: setGraphData onSuccess once refactored to use only one query
      getActionPromise(API_ENDPOINT, undefined, newParams, baseURL).then((response) => {
        const refetchedTimeQueries = response?.data?.Status?.NewTimeQueries;

        if (refetchedTimeQueries?.time_gte && refetchedTimeQueries?.time_lte) {
          // After the initial backend-refetch, don't do it again
          if (setRefetch) setRefetch(false);

          if (setDateRange) {
            const newDates = {
              startDate: new Date(refetchedTimeQueries.time_gte),
              endDate: new Date(refetchedTimeQueries.time_lte),
            };
            setDateRange(newDates);
          }
        } else {
          // TODO: Undo once refactored to only one queryS
          setGraphData(response?.data ?? initialValues);
        }
        setIsLoaded(true);
      });
    }
  }, [dateRange, selectedInterval, isVisible]);

  return graphData;
};
