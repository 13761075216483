import React, { useCallback, useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { getAction } from "../../../../helpers/actions/actions";
import { API_LATEST_EXERCISE_DETAIL, DATA_SERVICE_URL_OLD } from "../../../../api/apiConstants";
import { ExerciseTrackResponseType, InitialValues } from "../../../../types/graphs/ExerciseDetailGraphPropsType";
import { ExerciseDetail } from "./ExerciseDetail";
import { graphDisplayStyles } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";

export const LatestExercise = (): JSX.Element => {
  const [latestExercise, setLatestExercise] = useState<ExerciseTrackResponseType>(InitialValues);

  // eslint-disable-next-line
  const date = new Date((latestExercise.Values as any)?.Summary?.["Start Time"]);
  // eslint-disable-next-line
  const exerciseDetail = (latestExercise.Values as any)?.Summary?.["Activity Note"];

  const fetchLatestExercise = useCallback(() => {
    getAction(API_LATEST_EXERCISE_DETAIL, setLatestExercise, DATA_SERVICE_URL_OLD);
  }, [setLatestExercise]);

  useEffect(() => {
    fetchLatestExercise();
  }, [fetchLatestExercise]);

  return (
    <Paper variant="outlined" className={graphDisplayStyles().paper}>
      <Typography variant="h6" className={graphDisplayStyles().textCenter}>
        {latestExercise ? `${exerciseDetail} on ${date.toDateString()}` : ""}
      </Typography>

      <ExerciseDetail exerciseDetailData={latestExercise} reducedVersion />
    </Paper>
  );
};
