import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { initialPressureData, initialPressureOptions } from "./pressureHelper";

export const useProcessPressureData = (graphData: NewESResponseDataType) => {
  const data = initialPressureData();
  if (graphData?.Values && haveKeys([graphData.Values])) {
    data.labels = graphData.Values.map((val) => +new Date(val[timestampKey]));
    data.datasets[0].data = graphData.Values.map((val) => {
      return { x: +new Date(val[timestampKey]), y: val.pressure };
    });
  }

  return { data, options: initialPressureOptions() };
};
