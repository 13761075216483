import { TupleDataType } from "../../types/graphs/lineGraphs/TupleDataType";
import { ObjectType } from "../../types/graphs/ObjectType";
import { unitRounder } from "../timeHelpers";

export type Dict = { [key: string]: string | number };

export const onlyUnique = (value: any, index: any, self: any): boolean => {
  return self.indexOf(value) === index;
};

export function pushTupleData(
  dataObject: { datasets: Array<{ data: Array<TupleDataType> }> },
  datasetIndex: number,
  value: TupleDataType,
): void {
  dataObject?.datasets?.[datasetIndex]?.data.push(value);
}

/**
 * Function to check whether given objects all have keys.
 * @param objects Array of objects
 * @returns true if all objects have some keys, false otherwise
 */
export const haveKeys = (objects: Array<ObjectType> | undefined): boolean => {
  if (!objects || !objects?.length) {
    // Can be simplified to if(!objects?.length) - but this is more clear
    return false;
  }

  return objects?.every((object) => {
    if (object) {
      return Object.keys(object)?.length > 0;
    }
    return false;
  });
};

/**
 * Given a array, calculate the min, max, and average for it.
 * @param dataArr Array of values numbers to run min/max/avg calculation on
 */
export const datasetStats = (dataArr: any): { min: number; max: number; avg: number } => {
  if (dataArr.length === 0) {
    return { min: 0, max: 0, avg: 0 };
  }

  let max = dataArr[0];
  let min = dataArr[0];
  let sum = 0;

  for (let i = 0; i < dataArr.length; i++) {
    const curr = dataArr[i];
    // Calc max
    if (curr > max) {
      max = dataArr[i];
    }
    // Calc min
    if (curr < min) {
      min = dataArr[i];
    }
    sum += dataArr[i];
  }

  return { min, max, avg: sum / dataArr.length };
};

/**
 * After clicking on a chartjs place object, open Google maps to that location
 * @param element The chartjs element interacted with
 * @param outputBarData The chart data object
 * @param datasetIndexStr string to index in the dataset
 * @param dataIndexStr string used to index the data inside the dataset object
 */
export const placePieGoogleMapsURL = (
  element: any,
  outputBarData: any,
  datasetIndexStr = "_datasetIndex",
  dataIndexStr = "_index",
): string | undefined => {
  const datasetIndex = element?.[0]?.[datasetIndexStr];
  if (datasetIndex !== undefined) {
    const dataIndex = element?.[0]?.[dataIndexStr];
    const mapsPrefix = "https://www.google.com/maps/search/?api=1&query=";
    const mapsSuffix = outputBarData.datasets[datasetIndex].rawData[dataIndex].location;

    return mapsPrefix + mapsSuffix;
  }
  return undefined;
};

/**
 * After clicking on a chartjs place object, open Google maps to that location
 * @param element The chartjs element interacted with
 * @param outputBarData The chart data object
 * @param datasetIndexStr string to index in the dataset
 * @param dataIndexStr string used to index the data inside the dataset object
 */
export const placeGoogleMapsURL = (
  element: any,
  outputBarData: any,
  datasetIndexStr = "_datasetIndex",
  dataIndexStr = "_index",
): string | undefined => {
  const datasetIndex = element?.[0]?.[datasetIndexStr];
  if (datasetIndex !== undefined) {
    const dataIndex = element?.[0]?.[dataIndexStr];
    const mapsPrefix = "https://www.google.com/maps/search/?api=1&query=";
    const mapsSuffix = outputBarData.datasets[datasetIndex].data[dataIndex].z;

    return mapsPrefix + mapsSuffix;
  }
  return undefined;
};

/**
 * After clicking on a chartjs place object, open IMDB that query
 * @param element The chartjs element interacted with
 * @param outputBarData The chart data object
 * @param indexString dataset object's data index
 */
export const netflixPieIMDBURL = (element: any, outputBarData: any, indexString: string): string | undefined => {
  if (element?.[0]?.[indexString] !== undefined) {
    const mapsPrefix = "https://www.imdb.com/find?q=";
    const name = outputBarData.labels[element[0][indexString]];

    return mapsPrefix + name;
  }
  return undefined;
};

/**
 * After clicking on a chartjs place object, open IMDB that query
 * @param element The chartjs element interacted with
 * @param outputBarData The chart data object
 * @param indexString what string to index in the dataset data array
 */
export const netflixStackedBarIMDBURL = (element: any, outputBarData: any, indexString: string): string | undefined => {
  if (element?.[0]?.[indexString] !== undefined) {
    const mapsPrefix = "https://www.imdb.com/find?q=";
    const name = outputBarData.datasets[element[0][indexString]].label;
    if (name.includes("Pt Avg")) {
      return undefined;
    }

    return mapsPrefix + name;
  }
  return undefined;
};

/**
 * Calculation for standard deviation
 * @param values Array of numbers
 */
export const standardDeviation = (values: number[]) => {
  const avg = average(values);

  const squareDiffs = values.map((value) => {
    return (value - avg) ** 2;
  });

  const avgSquareDiff = average(squareDiffs);

  return Math.sqrt(avgSquareDiff);
};

/**
 * Calculate the average of an array of numbers
 * @param data Array to average
 */
export const average = (data: number[]) => {
  const sum = data.reduce((sum, value) => {
    return sum + value;
  }, 0);

  return sum / data.length;
};

/**
 * Given a dataset data array, calculate the simple moving average
 * @param arr Array to calculate simple moving average from
 * @param daysSelected Number of days selected
 * @param ignoreNullZero Whether or not to ignore null values, default true. Skip null/zero
 * @returns in form [{x: timestamp: number, y: value: number}, ...]
 */
export const simpleMovingAverage = (
  arr: Array<TupleDataType>,
  daysSelected: number,
  ignoreNullZero = true,
): Array<TupleDataType> => {
  const nDayMovingAvg = daysSelected || arr.length;
  const result = [] as Array<TupleDataType>;

  const len = arr.length + 1;

  let startingIndex = 0;
  if (ignoreNullZero) {
    startingIndex = arr.findIndex((value) => value.y !== null && +value.y > 0);
  }

  for (let i = startingIndex + 1; i < len; i++) {
    const yValue = avg(arr, i, i < nDayMovingAvg ? i : Math.floor(nDayMovingAvg), ignoreNullZero);
    const xValue = arr?.[i - 1]?.x;

    if (ignoreNullZero) {
      if (yValue && arr[i - 1].y) {
        result.push({ x: xValue, y: unitRounder(yValue) });
      }
    } else {
      result.push({ x: xValue, y: unitRounder(+yValue as number) });
    }
  }

  result.sort((a, b) => (a.x > b.x ? 1 : -1));

  return result;
};

/**
 * Given an array, calculate the average
 *
 *  Example:
 *  avg([1, 2, 3, 4, 5, 6, 7, 8, 9], 5, 4)
 * //=> 3.5
 *
 * @param arr the array to get range from
 * @param idx index of element that needs to be calculated
 * @param range the size of the range to calculate
 * @param ignoreNullZero If we're ignoring nulls and zeroes or not
 */
export const avg = (arr: Array<TupleDataType>, idx: number, range: number, ignoreNullZero: boolean): number => {
  let slicedRange = arr.slice(idx - range, idx);
  if (ignoreNullZero) {
    slicedRange = slicedRange.filter((val) => val.y > 0);
    range = slicedRange.length;
  }
  return sum(slicedRange) / range;
};

/**
 * Calculate the sum of the array
 * Ensure we grab the 'y' value of the objects in the array.
 * @param arr Array
 */
export const sum = (arr: Array<TupleDataType>): number => {
  return arr.reduce((acc, { y }) => acc + y, 0);
};
