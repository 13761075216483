import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { initialStepsHistogram, initialStepsHistogramOptions } from "./StepsOptions";
import { stepsValueStr } from "./StepsHelper";
import { useHistogramGenerator } from "../../../customHooks/useHistogramGenerator";
import { haveKeys } from "../../../../helpers/components/graphHelpers";

export const useProcessStepsHistogram = (fetchedStepsData: NewESResponseDataType): any => {
  const stepsInitialHistogramData = initialStepsHistogram();

  let stepsValues: number[] = [];

  if (fetchedStepsData?.Values && haveKeys([fetchedStepsData.Values])) {
    stepsValues = fetchedStepsData.Values.map((obj) => {
      return +obj[stepsValueStr];
    }).filter((num) => num > 0);
  }
  const { buckets, largestBucket, min, max, stepBucketSize } = useHistogramGenerator(
    stepsValues,
    stepsInitialHistogramData,
    10,
  );
  stepsInitialHistogramData.datasets[0].data = buckets;
  const histogramLargestBucket = largestBucket;
  const options = initialStepsHistogramOptions(histogramLargestBucket, min, max, stepBucketSize);

  return { data: stepsInitialHistogramData, options };
};
