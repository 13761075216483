import { Input } from "@material-ui/core";
import React from "react";
import { RowType } from "../handleUpload";

interface Props {
  row: RowType;
  currRowIndex: number;
  setRows: React.Dispatch<React.SetStateAction<RowType[]>>;
}
export const GeneratorFileInput = ({ row, currRowIndex: i, setRows }: Props) => {
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      setRows((prev) => {
        const newRows = [...prev];
        newRows[index].file = {
          name: file.name,
          content: event.target.result,
        };
        return newRows;
      });
    };

    reader.readAsText(file);
  };

  return <Input required type="file" error={!row.file?.content?.length} onChange={(e) => handleFileSelect(e, i)} />;
};
