import React from "react";
import { Pie } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { defaultGraphWidth, defaultGraphHeight, TITLE_SHOPPING_CATEGORY } from "../../../../helpers/graphConstants";
import { useProcessCategoryData } from "./useProcessCategoryData";
import { categoryOptions } from "./useShoppingDefaults";
import { API_ACTIVITY_SHOPPING, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { ShoppingCategoriesGraphMetadata } from "../../../../helpers/graphsMetadata";

export const ShoppingCategoriesGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultShoppingCategoryQueryParams,
    API_ACTIVITY_SHOPPING,
    DATA_SERVICE_URL,
    true,
  );

  const { graphData, isLoaded, elemRef } = graphObject;
  const { outputData } = useProcessCategoryData(graphData);

  const graphDisplay = (
    <Pie data={outputData} options={categoryOptions()} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  const description = [
    "Your shopping percent spend by category is a good indicator of whether your actual spend is aligned with your intended.",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_SHOPPING_CATEGORY}
        title={TITLE_SHOPPING_CATEGORY}
        calendarProps={graphObject}
        id={ShoppingCategoriesGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_SHOPPING_CATEGORY} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
