import { useMemo } from "react";
import { ObjectType } from "../../types/graphs/ObjectType";
import { simpleMovingAverage } from "../../helpers/components/graphHelpers";
import { TupleDataType } from "../../types/graphs/lineGraphs/TupleDataType";
import { averageSuffix } from "./useAddMultiDatasetMovingAverage";

const nDayAvgDefinition = (numPoints: number) => {
  return {
    type: "line",
    label: `${numPoints} ${averageSuffix}`,
    data: [] as Array<TupleDataType>,
    order: -1,
    fill: false,
  };
};

export const useAddSimpleMovingAverage = (
  data: { labels: number[]; datasets: ObjectType[] },
  datasetIndex = 0,
  ignoreNullZero = true,
  movingAverageDuration = 5,
) => {
  return useMemo(() => {
    const totalDataPoints: number = data.labels.length;
    if (totalDataPoints > 0) {
      const nullZeroPoints: number = data.datasets[datasetIndex].data.filter((val: any) => {
        return val === undefined || val === null || val === 0;
      }).length;

      let movingAverageDataPoints = totalDataPoints;
      movingAverageDataPoints -= nullZeroPoints * (ignoreNullZero ? 1 : 0);
      movingAverageDataPoints = Math.floor(movingAverageDataPoints / movingAverageDuration);

      if (movingAverageDataPoints > 1) {
        const movingAverageDataset = nDayAvgDefinition(movingAverageDataPoints);

        const movingAverage = simpleMovingAverage(
          data.datasets[datasetIndex].data,
          movingAverageDataPoints,
          ignoreNullZero,
        );
        if (movingAverage !== undefined && movingAverage.length) {
          movingAverageDataset.data = movingAverage;
        }

        data.datasets.push(movingAverageDataset);
      }
    }
    return data;
  }, [data]);
};
