import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Amazon } from "../../../components/login/providersConfig";
import { API_CONNECT_ACCOUNT, CLIENT_URL, SETTING_DATA_PROVIDERS, USER_SERVICE_URL } from "../../../api/apiConstants";
import { postAction } from "../../../helpers/actions/actions";
import { ErrorResponseType, ConnectResponseType } from "../../../types/responses/LoginResponseType";
import { useLocalStorage } from "../../../components/customHooks/useLocalStorage";

export const AmazonAuth = () => {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const { enqueueSnackbar } = useSnackbar();
  const [isAmazonConnected, setConnected] = useLocalStorage<boolean>("amazon", false);
  const connectAmazonAccount = (queryParameters: any, data: any) => {
    postAction(API_CONNECT_ACCOUNT, USER_SERVICE_URL, queryParameters, data)
      .then((response: ErrorResponseType | ConnectResponseType) => {
        // Check if the response was successful
        // eslint-disable-next-line no-extra-parens
        if ((response as ConnectResponseType).message !== "") {
          if (!isAmazonConnected) setConnected(true);
          // After setting the tokens, username, email, redirect user to dashboard
          window.location.replace(`${CLIENT_URL}${SETTING_DATA_PROVIDERS}`);

          // eslint-disable-next-line no-extra-parens
        } else if ((response as ErrorResponseType).detail) {
          const errorResponse = response as ErrorResponseType;
          // Currently only happens if a user not on our whitelist is selected
          enqueueSnackbar(`Error: ${JSON.stringify(errorResponse.detail)}`, {
            variant: "error",
          });
          window.location.replace(`${CLIENT_URL}${SETTING_DATA_PROVIDERS}`);
        }
      })
      .catch((error: any) => {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, {
          variant: "error",
        });
        window.location.replace(`${CLIENT_URL}${SETTING_DATA_PROVIDERS}`);
      });
  };

  useEffect(() => {
    const error = params.get("error_description");
    const code = params.get("code");
    const state = "amazon";
    const queryParameters = {
      provider: Amazon.PROVIDER,
    };

    const data = {
      client: Amazon.CLIENT,
      code,
      redirect_uri: Amazon.REDIRECT_URI,
      state,
    };

    if (error) {
      enqueueSnackbar(`Error: ${error}`, {
        variant: "error",
      });
      window.location.replace(`${CLIENT_URL}${SETTING_DATA_PROVIDERS}`);
    } else {
      try {
        connectAmazonAccount(queryParameters, data);
      } catch (error) {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, {
          variant: "error",
        });
      }
    }
  }, []);

  return (
    <div
      style={{
        width: "100px",
        height: "100px",
        position: "absolute",
        top: "0px",
        right: "0px",
        left: "0px",
        bottom: "0px",
        margin: "auto",
      }}
    >
      <CircularProgress
        style={{
          width: "100px",
          height: "100px",
        }}
      />
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Connecting
      </h2>
    </div>
  );
};

export default AmazonAuth;
