import { subDays } from "date-fns";
import { RowType } from "../../handleUpload";
import {
  bagelContent,
  dietCokeContent,
  eggsContent,
  salmonContent,
  subwayContent,
  turkeySlicesContent,
  yellowCurryContent,
  lasagnaContent,
} from "./food.content";

const DAYS_AGO_START = 90;

const breakfastAt7am = new Date();
breakfastAt7am.setHours(7);

const lunchAt2pm = new Date();
lunchAt2pm.setHours(14);

const dinnerAt6pm = new Date();
dinnerAt6pm.setHours(18);

export const foodTemplate: RowType[] = [
  {
    id: "1",
    schedule: {
      startDate: subDays(breakfastAt7am, DAYS_AGO_START - 0),
      endDate: breakfastAt7am,
      unit: 60 * 60 * 24, // This breakfast every other morning
      quantity: 2,
    },
    file: {
      name: "bagel.json",
      content: bagelContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -300,
        max: 1800,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.quantity",
        min: 0,
        max: 1,
      },
    ],
  },
  {
    id: "2",
    schedule: {
      startDate: subDays(breakfastAt7am, DAYS_AGO_START - 1),
      endDate: breakfastAt7am,
      unit: 60 * 60 * 24, // This breakfast every other morning
      quantity: 2,
    },
    file: {
      name: "eggs.json",
      content: eggsContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -300,
        max: 1800,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.quantity",
        min: -1,
        max: 1,
      },
    ],
  },
  // Lunch
  {
    id: "2.5",
    schedule: {
      startDate: subDays(lunchAt2pm, DAYS_AGO_START - 0),
      endDate: lunchAt2pm,
      unit: 60 * 60 * 24, // Coke every day with lunch
      quantity: 1,
    },
    file: {
      name: "dietCoke.json",
      content: dietCokeContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -60,
        max: 60,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.quantity",
        min: -1,
        max: 0,
      },
    ],
  },
  {
    id: "3",
    schedule: {
      startDate: subDays(lunchAt2pm, DAYS_AGO_START - 0),
      endDate: lunchAt2pm,
      unit: 60 * 60 * 24, // This lunch every other third day
      quantity: 3,
    },
    file: {
      name: "subway.json",
      content: subwayContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3600,
        max: 3600,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.protein",
        min: -5,
        max: 5,
      },
      {
        variationId: "12",
        fieldName: "consumables_extension.saturated_fat",
        min: -3,
        max: 3,
      },
    ],
  },
  {
    id: "4",
    schedule: {
      startDate: subDays(lunchAt2pm, DAYS_AGO_START - 1),
      endDate: lunchAt2pm,
      unit: 60 * 60 * 24, // This lunch every other third day
      quantity: 3,
    },
    file: {
      name: "salmon.json",
      content: salmonContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -1800,
        max: 1800,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.protein",
        min: -2,
        max: 10,
      },
      {
        variationId: "12",
        fieldName: "consumables_extension.fat",
        min: -3,
        max: 3,
      },
      {
        variationId: "13",
        fieldName: "consumables_extension.quantity",
        min: 0,
        max: 1,
      },
    ],
  },
  {
    id: "5",
    schedule: {
      startDate: subDays(lunchAt2pm, DAYS_AGO_START - 2),
      endDate: lunchAt2pm,
      unit: 60 * 60 * 24, // This lunch every other third day
      quantity: 3,
    },
    file: {
      name: "turkeySlices.json",
      content: turkeySlicesContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -1800,
        max: 1800,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.protein",
        min: -2,
        max: 10,
      },
      {
        variationId: "13",
        fieldName: "consumables_extension.quantity",
        min: 0,
        max: 1,
      },
    ],
  },
  // Dinner
  {
    id: "5.5",
    schedule: {
      startDate: subDays(dinnerAt6pm, DAYS_AGO_START - 0),
      endDate: dinnerAt6pm,
      unit: 60 * 60 * 24, // Coke every day with dinner
      quantity: 1,
    },
    file: {
      name: "dietCoke.json",
      content: dietCokeContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -60,
        max: 60,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.quantity",
        min: -1,
        max: 0,
      },
    ],
  },
  {
    id: "6",
    schedule: {
      startDate: subDays(dinnerAt6pm, DAYS_AGO_START - 0),
      endDate: dinnerAt6pm,
      unit: 60 * 60 * 24, // This lunch every other day
      quantity: 2,
    },
    file: {
      name: "yellowCurry.json",
      content: yellowCurryContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -1800,
        max: 1800,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.protein",
        min: -2,
        max: 10,
      },
      {
        variationId: "12",
        fieldName: "consumables_extension.fat",
        min: -3,
        max: 3,
      },
    ],
  },
  {
    id: "7",
    schedule: {
      startDate: subDays(dinnerAt6pm, DAYS_AGO_START - 1),
      endDate: dinnerAt6pm,
      unit: 60 * 60 * 24, // This lunch every other day
      quantity: 2,
    },
    file: {
      name: "lasagna.json",
      content: lasagnaContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -1800,
        max: 1800,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.protein",
        min: -2,
        max: 10,
      },
    ],
  },
];
