import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import * as chartjs from "chart.js";

export const loginStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100vh",
      textAlign: "center",
    },
    paper: {
      padding: 15,
    },
    grid: {
      display: "flex",
      flexDirection: "column",
    },
    buttonCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    fullPercent: {
      width: "100%",
      height: "100%",
    },
    blackText: {
      color: "#000",
    },
    rightHalf: {
      backgroundColor: "#79B6EC",
      width: "100%",
      height: "100%",
      padding: 1,
    },
    boxStyles: {
      padding: "1em",
    },
    hr: {
      width: "100%",
      border: 0,
      height: 1,
      backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))",
    },
  }),
);

export const animationOptions = [
  "linear",
  "easeInQuad",
  "easeOutQuad",
  "easeInOutQuad",
  "easeInCubic",
  "easeOutCubic",
  "easeInOutCubic",
  "easeInQuart",
  "easeOutQuart",
  "easeInOutQuart",
  "easeInQuint",
  "easeOutQuint",
  "easeInOutQuint",
  "easeInSine",
  "easeOutSine",
  "easeInOutSine",
  "easeInExpo",
  "easeOutExpo",
  "easeInOutExpo",
  "easeInCirc",
  "easeOutCirc",
  "easeInOutCirc",
  "easeInElastic",
  "easeOutElastic",
  "easeInOutElastic",
  "easeInBack",
  "easeOutBack",
  "easeInOutBack",
  "easeInBounce",
  "easeOutBounce",
  "easeInOutBounce",
];

export const labels = [
  "Week 1",
  "Week 2",
  "Week 3",
  "Week 4",
  "Week 5",
  "Week 6",
  "Week 7",
  "Week 8",
  "Week 9",
  "Week 10",
];

export const options = (): chartjs.ChartOptions => {
  const animation: string = animationOptions[Math.floor(Math.random() * animationOptions.length)];
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      easing: animation,
      duration: 1500,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: 50,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            fontColor: "black",
            display: true,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },

    tooltips: {
      intersect: false,
      backgroundColor: "rgba(0,0,0,0.3)",
      xPadding: 10,
      yPadding: 10,
    },
  } as chartjs.ChartOptions;
};

export const chartData = (graph: string): chartjs.ChartData => {
  const datasets: chartjs.ChartDataSets[] = [
    {
      label: graph,
      fill: false,
      data: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
      borderColor: "#000000",
      pointBorderWidth: 0,
      pointBorderColor: "#000000",
      pointBackgroundColor: "#000000",
      borderWidth: 7,
      lineTension: 0,
      borderDash: [0, 0],
    },
    {
      label: `${graph} Bytes`,
      fill: false,
      data: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
      borderColor: "#ffffff",
      pointBorderWidth: 0,
      pointBorderColor: "#000000",
      pointBackgroundColor: "#ffffff",
      borderWidth: 7,
      borderDash: [1, 2],

      // tension: Math.random(),
    },
  ];

  if (Math.random() > 0.5) {
    datasets.push({
      lineTension: 0,
      label: `${graph} Sample`,
      fill: false,
      data: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
      borderColor: "#ffffff",
      pointBorderWidth: 0,
      pointBorderColor: "#000000",
      pointBackgroundColor: "#ffffff",
      borderWidth: 7,
      borderDash: [10, 5],
    });
  }

  return {
    labels,
    datasets,
  } as chartjs.ChartData;
};
