/* eslint-disable camelcase */
import { startOfDay } from "date-fns";
import { NewESResponseDataType } from "../../../../../types/responses/NewESResponseDataType";
import { haveKeys } from "../../../../../helpers/components/graphHelpers";
import { secondsInDay } from "../../../../../helpers/timeConstants";
import { initialSleepTimeData, NORMALIZATION_HOURS } from "./sleepTimeHelper";

type SleepEventType = {
  _id: string;
  timestamp: string;
  end_time: string;
  duration: number;
  provider: unknown;
  sleep_events: SleepEventsType[];
  sleep_summary: SleepSummaryType;
};
type SleepEventsType = { timestamp: string; end_time: string; duration: number; sleep_detail: SleepDetailType[] };
type SleepStageType = "asleep" | "awake" | "light" | "deep" | "rem" | "restless" | "unknown";
type SleepDetailType = { timestamp: string; end_time: string; duration: number; stage: SleepStageType };
type SleepSummaryType = {
  efficiency: number | null;
  is_main_sleep: boolean | null;
  events_count: number | null;
  fall_asleep_seconds: number | null;
  after_wakeup_seconds: number | null;
  awake_seconds: number | null;
  asleep_seconds: number | null;
  in_bed_seconds: number | null;
  deep_seconds: number | null;
  light_seconds: number | null;
  rem_seconds: number | null;
};
export const useProcessSleepTime = (fetchedSleepData: NewESResponseDataType): any => {
  const sleepLineData = initialSleepTimeData();

  if (fetchedSleepData?.Values && haveKeys([fetchedSleepData.Values])) {
    // eslint-disable-next-line no-extra-parens
    (fetchedSleepData.Values as SleepEventType[]).forEach((obj: SleepEventType) => {
      // Across all sleep events, find the first occurring sleep detail with stage "asleep"
      let first: SleepDetailType | undefined;
      let last: SleepDetailType | undefined;

      obj.sleep_events.forEach((sleepEvent: SleepEventsType) => {
        sleepEvent.sleep_detail.forEach((sleepDetail: SleepDetailType) => {
          if (sleepDetail.stage === "asleep") {
            if (first === undefined) {
              first = sleepDetail;
            }
            last = sleepDetail;
          }
        });
      });

      const timestamp = +startOfDay(new Date(obj.end_time));

      // SPK THIS SHOULD BE REMOVE IN THE FUTURE.
      // HACK TO ENSURE THAT BE DOESN'T SEND DOUBLE OF THE SAME TIMESTAMP
      if (!sleepLineData.labels.includes(timestamp)) {
        // use the day that the sleep ended as the reference/day to plot
        sleepLineData.labels.push(timestamp);

        if (first && last) {
          // Going to convert this to seconds in today.
          const startDate = new Date(first.timestamp);
          const endDate = new Date(last.end_time);

          // Get the hr, min, sec, and convert all to seconds.
          // Example: 8:27:07pm, Hour = 8. 27 = minute. 7 seconds
          const startHours = startDate.getHours();
          const startMinutes = startDate.getMinutes();

          let startTimeSeconds = -startDate.getSeconds();
          startTimeSeconds -= startHours * 3600; // Convert Hours to seconds
          startTimeSeconds -= startMinutes * 60; // Convert Minutes to seconds

          // Repeat with end time
          const endHours = endDate.getHours();
          const endMinutes = endDate.getMinutes();

          let endTimeSeconds = -endDate.getSeconds();
          endTimeSeconds -= endHours * 3600; // Convert Hours to seconds
          endTimeSeconds -= endMinutes * 60; // Convert Minutes to seconds

          // Set the start and date based on which comes first.
          const newStart =
            startTimeSeconds < endTimeSeconds
              ? (startTimeSeconds - NORMALIZATION_HOURS) % -secondsInDay
              : (endTimeSeconds - NORMALIZATION_HOURS) % -secondsInDay;

          const newEnd =
            startTimeSeconds < endTimeSeconds
              ? (endTimeSeconds - NORMALIZATION_HOURS) % -secondsInDay
              : (startTimeSeconds - NORMALIZATION_HOURS) % -secondsInDay;

          // Case where you sleep before midnight (sleep the day before)

          if (startDate.getUTCDay() !== endDate.getUTCDay()) {
            // Need multiple datasets overlapped to create the effect of a contiguous bar
            if (newStart > newEnd) {
              sleepLineData.datasets[1].data.push([newEnd, newStart]);
            } else {
              // Logic for when the datasets span different dates.
              sleepLineData.datasets[1].data.push([newStart, -secondsInDay]);
              sleepLineData.datasets[2].data.push([0, newEnd]);
            }
          } else {
            sleepLineData.datasets[0].data.push([newStart, newEnd]);
          }
        }
      }
    });
  }

  return { sleepLineData };
};
