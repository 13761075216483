import React from "react";
import { GridColDef, GridSortDirection } from "@material-ui/data-grid";
import { Box, LinearProgress } from "@material-ui/core";
import { ErrorBoundary } from "react-error-boundary";
import { XGrid } from "@material-ui/x-grid";
import { ErrorBoundaryFallback } from "../../../components/customComponents/ErrorBoundaryFallback";
import { timestampKey } from "../../../types/responses/NewESResponseDataType";

// Sort by both timestamp AND score
const sortModel = [
  {
    field: "_score",
    sort: "desc" as GridSortDirection,
  },
  {
    field: timestampKey,
    sort: "desc" as GridSortDirection,
  },
];

// TODO: Props interface
export const FindDataTable = (props: {
  data: any;
  indexName: string;
  columnDefs: GridColDef[];
  tableRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <Box m={3}>
      <div ref={props.tableRef}>
        <h1>{props.indexName}</h1>
      </div>
      <ErrorBoundary
        fallbackRender={(fbProps) => (
          <ErrorBoundaryFallback {...fbProps} title={`find data table for ${props.indexName}`} />
        )}
      >
        {props.data.length ? (
          <div style={{ height: 500, width: "100%" }}>
            <XGrid
              rowHeight={25}
              sortModel={sortModel}
              rows={props.data}
              columns={props.columnDefs}
              disableSelectionOnClick
              getRowId={(row) => row._id}
              pagination
              autoPageSize
            />
          </div>
        ) : (
          <LinearProgress />
        )}
      </ErrorBoundary>
    </Box>
  );
};
