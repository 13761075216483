import * as chartjs from "chart.js";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { millisToMinutes, minutesToHourMinute } from "../../../../helpers/timeHelpers";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { haveKeys } from "../../../../helpers/components/graphHelpers";

export const generateAllPlaces = (locationPlaceData: NewESResponseDataType) => {
  const placesSet = new Set<string>();
  if (locationPlaceData?.Values && haveKeys([locationPlaceData.Values])) {
    locationPlaceData.Values.filter((placeObject: ObjectType) => placeObject?.places?.length).forEach(
      (placeObjectRaw: ObjectType) => {
        const placeObject = placeObjectRaw as { timestamp: string; places: any[] };
        placeObject.places.forEach((place) => {
          placesSet.add(place.name as string);
        });
      },
    );
  }

  return { placesSet };
};
/**
 * Given a list of unique places, add unique datasets into the place dataset
 * @param uniquePlaces List of unique place names
 * @param placeDataset Chartjs containing datasets and labels
 */
export const createUniqueDatasets = (uniquePlaces: string[], placeDataset: ObjectType) => {
  // Create a dataset per each unique place
  uniquePlaces.forEach((place: string) => {
    placeDataset.datasets.push({
      label: place,
      data: [],
      yAxisID: "y-axis-time-place",
      xAxisID: "x-axis",
      type: "bar",
    });
  });
};
export const populateDatasets = (
  locationPlaceData: NewESResponseDataType,
  placeDataset: ObjectType,
  uniquePlaces: string[],
) => {
  if (locationPlaceData?.Values && haveKeys([locationPlaceData.Values])) {
    locationPlaceData.Values.filter((placeObject: ObjectType) => placeObject?.places?.length).forEach(
      (placeObjectRaw: ObjectType) => {
        const placeObject = placeObjectRaw as { timestamp: string; places: any[] };
        const timestamp = +new Date(placeObject[timestampKey]);
        placeDataset.labels.push(timestamp);

        const perTimestampSum = () => {
          const result: any = {};
          uniquePlaces.forEach((place: string) => {
            result[place] = { time: 0, location: null };
          });
          return result;
        };

        const sum = perTimestampSum();
        placeObject.places.forEach((place: ObjectType) => {
          sum[place.name as string].time = millisToMinutes(place.TimeSpent);
          sum[place.name as string].location = place.location;
        });

        Object.entries(sum).forEach((entry: any) => {
          placeDataset.datasets
            .find((ds: ObjectType) => ds.label === entry[0])
            .data.push({ x: timestamp, y: entry[1].time, z: entry[1].location });
        });
      },
    );
  }
};
export const initialDatasets = () => {
  return {
    labels: [] as Array<number>,
    datasets: [] as any,
  };
};
export const datasetReducer = (t: any, { y }: ObjectType) => t + y;
export const sortDatasets = (placeDataset: any) => {
  // sort datasets
  placeDataset.datasets.sort((a: ObjectType, b: ObjectType) =>
    a.data.reduce(datasetReducer, 0) > b.data.reduce(datasetReducer, 0) ? -1 : 1,
  );
};
export const initialBarOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      mode: "x",
      intersect: true,
      filter(tooltipItem: any) {
        return +tooltipItem.yLabel > 0;
      },
      callbacks: {
        label(tooltipItem: any, data: any): string {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${minutesToHourMinute(
            Number(tooltipItem.yLabel),
            true,
          )}`;
        },
      },
    },
    legend: {
      display: true,
      labels: {
        filter(legendItem: any, data: any) {
          const { datasetIndex } = legendItem;
          return data.datasets[datasetIndex].data.reduce(datasetReducer, 0) > 100;
        },
      },
    },
    responsive: true,

    scales: {
      xAxes: [
        {
          id: "x-axis",
          offset: true,
          type: "time",
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
          time: {
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
            tooltipFormat: "lll",
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          id: "y-axis-time-place",
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: "Actions (hours)",
          },
          ticks: {
            beginAtZero: true,
            callback(label: any): string {
              return +label % 60 === 0 ? minutesToHourMinute(Number(label), false) : "";
            },
            stepSize: 60,
          },
        },
        // {
        //   id: "y-axis-time-place-avg",
        //   stacked: false,
        //   position: "right",
        //   scaleLabel: {
        //     display: true,
        //     labelString: "Avg",
        //   },
        // },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
export const sumPieTimeInPlaces = (locationPlaceData: NewESResponseDataType, allPlaces: any) => {
  locationPlaceData?.Values?.filter((value: any) => value?.places?.length > 0).forEach((entry: any) => {
    entry.places.forEach((place: any) => {
      allPlaces[place.name].time += place.TimeSpent;
      allPlaces[place.name].location = place.location;
    });
  });
};

export type sortedLocationObjectAsArray = [{ time: number; location: string; label: string }];

export const removeExcessDataPoints = (toSortObj: sortedLocationObjectAsArray, numPlaces: number) => {
  const others = toSortObj.slice(numPlaces);
  const othersSum = others.reduce((a, b) => a + b.time, 0);
  const newSortObj = toSortObj.slice(0, numPlaces);

  newSortObj.sort((a: ObjectType, b: ObjectType) => {
    return b.time - a.time;
  });

  newSortObj.push({ time: othersSum, label: "Others", location: "" });

  return newSortObj;
};

export const populatePieDataset = (placeDataset: any, allPlaces: any) => {
  Object.entries(allPlaces).forEach((entry: [string, unknown]) => {
    placeDataset.labels.push(entry[0]);
    const data = entry[1] as ObjectType;
    const { time, location } = data;
    placeDataset.datasets[0].data.push({ time, location });
  });
};
export const initialPieData = () => {
  return {
    labels: [] as Array<number | string>,
    datasets: [
      {
        data: [] as Array<number>,
        rawData: [] as Array<ObjectType>,
        label: "All",
        type: "pie",
      },
    ] as any,
  };
};
export const initialPieOptions = () => {
  return {
    tooltips: {
      callbacks: {
        label(tooltipItem: any, data: any): string {
          try {
            let label = `${data.labels[tooltipItem.index]}:` || "";

            const sum = data.datasets[0].data.reduce((accumulator: any, curValue: any) => {
              return accumulator + curValue;
            });
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            label += ` ${Number((value / sum) * 100).toFixed(2)}%`;
            return label;
          } catch (error) {
            return "Unknown";
          }
        },
        footer: (): string => {
          return "Click to view on Google maps";
        },
      },
    },
    legend: {
      display: true,
    },
  };
};
