import {
  ActivityQueryParamsType,
  BodyQueryParamsType,
  EventQueryParamsType,
  LocationQueryParamsType,
} from "../../types/graphs/DefaultQueryParamsType";
import {
  weekAgoDaily,
  monthAgoWeekly,
  weekAgoHourly,
  yearAgoWeekly,
  quarterAgoWeekly,
  monthAgoDaily,
  yearAgoDaily,
  yearAgoHourly,
  sleepTimeParams,
} from "../timeConstants";

// Individual Graphs where it makes sense

export const BodyQueryParams: BodyQueryParamsType = {
  // Cardiovascular
  defaultWeightQueryParams: monthAgoDaily,
  defaultWeightHistogramQueryParams: yearAgoDaily,
  defaultOxygenQueryParams: monthAgoDaily,
  defaultHeartRateQueryParams: monthAgoDaily,
  defaultRestingHeartRateQueryParams: monthAgoDaily,
  defaultRestingHeartRateHistogramQueryParams: yearAgoDaily,
  defaultHeartRateHistogramQueryParams: yearAgoDaily,

  // Female
  defaultFemaleCyclesQueryParams: quarterAgoWeekly,
  defaultFemaleSymptomsQueryParams: quarterAgoWeekly,
};

export const LocationQueryParams: LocationQueryParamsType = {
  defaultPlaceStatesQueryParams: weekAgoDaily,
  defaultPlacePercentQueryParams: weekAgoDaily,
  // Air
  defaultAirQueryParams: monthAgoDaily,
  defaultWeatherParams: monthAgoDaily,
  defaultHumidityParams: monthAgoDaily,
  defaultOzoneParams: monthAgoDaily,
  defaultTemperatureParams: monthAgoDaily,
};

export const ActivityQueryParams: ActivityQueryParamsType = {
  // Consume
  defaultYoutubeCountsQueryParams: weekAgoDaily,
  defaultYoutubeTimeQueryParams: weekAgoDaily,
  defaultSearchGoogleQueryParams: weekAgoHourly,
  defaultNetflixViewingQueryParams: weekAgoDaily,
  // Shopping
  defaultShoppingTotalQueryParams: yearAgoWeekly,
  defaultShoppingCategoryQueryParams: yearAgoWeekly,
  defaultShoppingHistogramQueryParams: yearAgoDaily,
  // Sleep
  defaultSleepStatesQueryParams: weekAgoDaily,
  defaultSleepTimeQueryParams: sleepTimeParams,
  defaultSleepHistogramQueryParams: yearAgoDaily,
  // Steps
  defaultStepsHistogramQueryParams: yearAgoDaily,
  defaultDailyStepsQueryParams: weekAgoDaily,
  // Movement
  defaultMovementStatesQueryParams: weekAgoDaily,
  defaultMovementTopStatesQueryParams: monthAgoWeekly,
  defaultMovementHistogramQueryParams: yearAgoDaily,

  // Social
  defaultFacebookFrequency: monthAgoDaily,
  // Web Activity
  defaultWebActivity: monthAgoDaily,
  defaultWebActivityHistogram: yearAgoDaily,
};

export const EventsQueryParams: EventQueryParamsType = {
  defaultTagsQueryParams: yearAgoHourly,
};
