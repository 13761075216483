import { useContext } from "react";
import { API_LOGOUT, USER_SERVICE_URL, CLIENT_URL, API_LOGOUT_EVERYWHERE } from "../../api/apiConstants";
import { postAction } from "../../helpers/actions/actions";
import { generateCookies } from "../../helpers/generateCookies";
import { IdentityContext, initialUserDataState } from "../../types/general/IdentityContext";

export const useLogout = () => {
  const { setUserData } = useContext(IdentityContext);

  const logout = async () => {
    const cookies = generateCookies();
    await postAction(API_LOGOUT, USER_SERVICE_URL);

    delete cookies.csrftoken;

    document.cookie = Object.keys(cookies)
      .map((property) => `${property}=${cookies[property]}`)
      .join(";");

    setUserData(initialUserDataState);
    window.location.replace(`${CLIENT_URL}`);
  };

  const logoutEverywhere = async () => {
    postAction(API_LOGOUT_EVERYWHERE, USER_SERVICE_URL);
    setTimeout(() => {
      logout();
    }, 2500); // 2.5 seconds
  };

  return { logout, logoutEverywhere };
};
