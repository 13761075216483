import * as chartjs from "chart.js";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { timestampToLocaleDateString } from "../../../../helpers/timeHelpers";
import { minYValue, maxYValue } from "./loadMenstrualCycleData";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";

const cycleTitle = "Cycle Event";

export const initialData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "line",
        label: "Heartrate",
        data: [] as Array<number>,
      },
      {
        type: "scatter",
        label: "Conditions",
        data: [] as Array<number>,
      },
      {
        type: "scatter",
        label: "Cycle Altering Event",
        data: [] as Array<number>,
      },
      {
        type: "scatter",
        label: "Flow",
        data: [] as Array<number>,
      },
      {
        type: "scatter",
        label: "Fluids",
        data: [] as Array<number>,
      },
      {
        type: "scatter",
        label: "Mood",
        data: [] as Array<number>,
      },
      {
        type: "scatter",
        label: "Ovulation Test",
        data: [] as Array<number>,
      },
      {
        type: "scatter",
        label: "Sex",
        data: [] as Array<number>,
      },
    ] as Array<any>,
  };
};

export const initialOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    scales: {
      yAxes: [
        {
          position: "left",
          gridLines: {
            display: false,
          },

          ticks: {
            display: false,
            // These are default values that will be overwritten when data is loaded
            min: minYValue,
            max: maxYValue,
          },
        },
      ],
      xAxes: [
        {
          type: "time",
          ticks: {
            minor: {
              autoSkip: true,
              source: "data",
            },
            major: {
              enabled: true,
            },
          },
          gridLines: {
            display: true,
          },
          position: "bottom",
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: selectedIntervalGraphXAxisUnit[selectedInterval],
          },
          scaleLabel: {
            display: false,
            labelString: "Date",
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
      callbacks: {
        // https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-callbacks
        title(): string[] {
          return [cycleTitle];
        },

        label(toolTipItem: any, data: any): string[] {
          // If it's heart rate data, set the label and append BPM
          return data.datasets[toolTipItem.datasetIndex].label;
        },
        /**
         * The goal here is to display the start AND end date in the tooltip for cycle, period, and fertile window.
         * each toolTipItem will tell us which index the current (hovered over) point is in.
         *
         * If it shows 0, it means we are in the first (startDate) point.
         * If it shows 1, it means we are in the second (endDate) point.
         * Accordingly, get the other point.
         */
        afterLabel(toolTipItem: any, data: any): string[] {
          const { label } = data.datasets[toolTipItem.datasetIndex];

          // For period, cycle, and fertile, show the start and end date
          if (label === "Period" || label === "Cycle" || label === "Fertile Window") {
            let startDate = "";
            let endDate = "";

            const setIndex = toolTipItem.datasetIndex;
            const dataIndex = toolTipItem.index;

            // dataIndex 0 means this point is the start date, so the next point is the end date
            if (dataIndex === 0) {
              startDate = data.datasets[setIndex].data[dataIndex].x;
              endDate = data.datasets[setIndex].data[dataIndex + 1].x; // Next point

              // dataIndex 1 means this point is the end date, previous point was start date.
            } else if (dataIndex === 1) {
              startDate = data.datasets[setIndex].data[dataIndex - 1].x; // Prev point
              endDate = data.datasets[setIndex].data[dataIndex].x;
            }
            return [timestampToLocaleDateString(startDate), "to", timestampToLocaleDateString(endDate)];
          }

          const setIndex = toolTipItem.datasetIndex;
          const dataIndex = toolTipItem.index;
          const startDate = data.datasets[setIndex].data[dataIndex].x;

          if (label === "Sex") {
            return [timestampToLocaleDateString(startDate), data.datasets[setIndex].data[dataIndex].z];
          }

          if (label === "Heartrate") {
            return toolTipItem.label;
          }

          return [timestampToLocaleDateString(startDate)];
        },
      },
    },
  } as chartjs.ChartOptions;
};
