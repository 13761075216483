import React from "react";
import { Bar } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { defaultGraphWidth, defaultGraphHeight, TITLE_SLEEP_STATES } from "../../../../helpers/graphConstants";
import { sleepOptions, useProcessSleepData } from "./useProcessSleepData";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { API_ACTIVITY_SLEEP, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { SleepBarGraphMetadata } from "../../../../helpers/graphsMetadata";

export const SleepBarGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultSleepStatesQueryParams,
    API_ACTIVITY_SLEEP,
    DATA_SERVICE_URL,
  );

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const outputSleepData = useProcessSleepData(graphData);

  const graphDisplay = (
    <Bar
      data={outputSleepData.outputSleepData}
      options={sleepOptions(selectedInterval)}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
    />
  );

  const description = [
    "Sleep is divided into two broad types: non-rapid eye movement (non-REM or NREM) sleep and" +
      " rapid eye movement (REM) sleep. ",
    "Non-REM and REM sleep are so different that physiologists identify them as distinct behavioral states. Non-REM" +
      " sleep occurs first and after a transitional period is called slow-wave sleep or deep sleep. ",
    "During this phase, body temperature and heart rate fall, and the brain uses less energy. REM sleep, also known as" +
      " paradoxical sleep, represents a smaller portion of total sleep time.",
    " It is the main occasion for dreams (or nightmares), and is associated with desynchronized and fast brain waves, eye movements, loss of muscle tone, and suspension of homeostasis",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_SLEEP_STATES}
        title={TITLE_SLEEP_STATES}
        calendarProps={graphObject}
        id={SleepBarGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_SLEEP_STATES} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
