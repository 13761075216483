import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { RHRAnnotationsType, RHRDataType, RHROptionsType, RHRScalesType } from "./restingHeartRateTypes";
import { average, standardDeviation } from "../../../../helpers/components/graphHelpers";

export const RHR_DATASET_LABEL = "Resting Heart Rate";
const PERSONAL_RANGE_STRING = "In Personal Range";
const NUM_OF_STD_DEVIATIONS = 2.0;

const annotationPluginOptions = (title: string, rangeMin: number, rangeMax: number): RHRAnnotationsType => {
  return {
    annotations: [
      // CREATE BOX FOR PERSONAL RANGE
      {
        type: "box",
        // ID of the X and y scale to bind onto, default is 'x' and 'y'
        backgroundColor: "rgba(0, 0, 200, 0.15)",
        yScaleID: "y-axis-rhr",
        yMin: rangeMin,
        yMax: rangeMax,
      },
      // CREATE LINE AT TOP OF PERSONAL RANGE
      {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-rhr",
        value: rangeMax,
        borderColor: "black",

        // LABEL
        label: {
          content: title,
          enabled: true,
        },
      },
      // CREATE LINE AT BOTTOM OF PERSONAL RANGE
      {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-rhr",
        value: rangeMin,
        borderColor: "black",
      },
    ],
  };
};

const scalesOptions = (selectedInterval: string): RHRScalesType => {
  return {
    yAxes: [
      {
        id: "y-axis-rhr",
        position: "left",
        scaleLabel: {
          display: true,
          labelString: "Heart Rate (bpm)",
        },
      },
    ],
    xAxes: [
      {
        id: "x-axis",
        type: "time",
        position: "bottom",
        ticks: {
          minor: {
            autoSkip: true,
            source: "auto",
          },
          major: {
            enabled: true,
          },
        },
        time: {
          displayFormats: { day: localDateFormatMonthDay },
          tooltipFormat: "lll",
          unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
        },
      },
    ],
    maintainAspectRatio: false,
  };
};

export const initialRHROptions = (selectedInterval: string, data?: RHRDataType): RHROptionsType => {
  if (data) {
    // Find the std deviation
    const rhrData = data.datasets[0].data
      .map((tupleObj: TupleDataType) => tupleObj.y)
      .filter((y: number) => y !== null);

    // We first calculate your baseline RHR with up to 30 days of data.
    const baselineRHR = average(rhrData);
    const stdDev = standardDeviation(rhrData);

    // Your personal range is plus or minus 2 standard deviations from your baseline.
    let personalRangeLower = baselineRHR;
    personalRangeLower -= stdDev * NUM_OF_STD_DEVIATIONS;

    let personalRangeUpper = baselineRHR;
    personalRangeUpper += stdDev * NUM_OF_STD_DEVIATIONS;

    return {
      tooltips: {
        intersect: false,
      },
      responsive: true,
      annotation: data?.datasets[0].data?.length
        ? annotationPluginOptions(PERSONAL_RANGE_STRING, personalRangeLower, personalRangeUpper)
        : undefined,
      scales: scalesOptions(selectedInterval),
    };
  }

  return {
    tooltips: {
      intersect: false,
    },
    responsive: true,
    scales: scalesOptions(selectedInterval),
  };
};
