import { stepSizeCalc } from "../../../../helpers/timeConstants";
import { minutesToHourMinute } from "../../../../helpers/timeHelpers";

export const initialSleepDatasets = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "bar",
        label: "Sleep",
        data: [] as Array<number>,
        fill: false,
        yAxisID: "y-axis-sleep-hist",
        xAxisID: "x-axis",
      },
    ] as Array<any>,
  };
};

/**
 * Given the sleep phase or "all" return a label for the x axis for the sleep histogram graph
 * @param sleepType - The user selected sleep type
 */
const sleepTypeToLabel = (sleepType: string): string => {
  const sleepTypeLower = sleepType.toLowerCase();

  if (sleepTypeLower === "rem_seconds") {
    return "Time in REM Sleep";
  }
  if (sleepTypeLower === "light_seconds") {
    return "Time in Light Sleep";
  }
  if (sleepTypeLower === "deep_seconds") {
    return "Time in Deep Sleep";
  }
  if (sleepTypeLower === "awake_seconds") {
    return "Time Awake";
  }
  return "Time in All Sleep States";
};
export const sleepHistogramOptions = (
  histogramMaxCount: number,
  sleepType: string,
  min: number,
  max: number,
  stepBucketSize: number,
) => {
  let minX = Math.floor(min / stepBucketSize) * stepBucketSize;
  minX -= stepBucketSize;
  let maxX = Math.ceil(max / stepBucketSize) * stepBucketSize;
  maxX += stepBucketSize;
  return {
    scales: {
      yAxes: [
        {
          id: "y-axis-sleep-hist",
          type: "linear",
          ticks: {
            stepSize: stepSizeCalc(histogramMaxCount),
          },
          scaleLabel: {
            display: true,
            labelString: `Counts`,
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          ticks: {
            min: minX,
            max: maxX,
            stepSize: stepBucketSize,
            callback(label: any): string {
              return minutesToHourMinute(Math.ceil(Number(label)), true, true, true);
            },
          },
          scaleLabel: {
            display: true,
            labelString: `${sleepTypeToLabel(sleepType)} (min)`,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: false,
    },
    tooltips: {
      intersect: true,
      mode: "x",
      callbacks: {
        title(tooltipItem: any, data: any): string {
          const initValue = tooltipItem[0].label;
          const nextValue = +initValue + +data.stepBucketSize;

          const initTime = minutesToHourMinute(Math.ceil(Number(initValue)), true, true, true);
          const endTime = minutesToHourMinute(Math.ceil(Number(nextValue)), true, true, true);

          return `Between ${initTime} - ${endTime}`;
        },
        label(tooltipItem: any): string {
          return `Count: ${tooltipItem.value}`;
        },
      },
    },
  };
};
