import { subDays } from "date-fns";
import { RowType } from "../../handleUpload";
import { antibioticContent, ibuprofenContent, thyroidContent, tylenolContent } from "./medicine.content";

const DAYS_AGO_START = 90;

const todayAt6am = new Date();
todayAt6am.setHours(6);

const todaytAtNoon = new Date();
todaytAtNoon.setHours(12);

const todayAt6pm = new Date();
todayAt6pm.setHours(18);

const todayAt8am = new Date();
todayAt8am.setHours(8);

const todayAt4pm = new Date();
todayAt4pm.setHours(16);

export const medicineTemplate: RowType[] = [
  {
    id: "1",
    schedule: {
      startDate: subDays(todayAt6pm, DAYS_AGO_START - 0),
      endDate: todayAt6pm,
      unit: 60 * 60 * 24, // Ibuprofen every other
      quantity: 2,
    },
    file: {
      name: "ibuprofen.json",
      content: ibuprofenContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3000,
        max: 3000,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.amount",
        min: -100,
        max: 100,
      },
    ],
  },
  {
    id: "2",
    schedule: {
      startDate: subDays(todayAt6pm, DAYS_AGO_START - 1),
      endDate: todayAt6pm,
      unit: 60 * 60 * 24, // Tylenol every other
      quantity: 2,
    },
    file: {
      name: "tylenol.json",
      content: tylenolContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3000,
        max: 3000,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.amount",
        min: -100,
        max: 100,
      },
    ],
  },
  {
    id: "3",
    schedule: {
      startDate: subDays(todayAt8am, DAYS_AGO_START - 2),
      endDate: todayAt8am,
      unit: 60 * 60 * 24, // One antibiotic at 8am
      quantity: 1,
    },
    file: {
      name: "antibiotic.json",
      content: antibioticContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -30,
        max: 30,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.amount",
        min: -100,
        max: 100,
      },
    ],
  },
  {
    id: "4",
    schedule: {
      startDate: subDays(todayAt4pm, DAYS_AGO_START - 2),
      endDate: todayAt4pm,
      unit: 60 * 60 * 24, // Antibiotic twice a day
      quantity: 1,
    },
    file: {
      name: "antibiotic.json",
      content: antibioticContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -30,
        max: 30,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.amount",
        min: -100,
        max: 100,
      },
    ],
  },
  {
    id: "5",
    schedule: {
      startDate: subDays(todayAt6am, DAYS_AGO_START - 3),
      endDate: todayAt6am,
      unit: 60 * 60 * 24, // Every morning
      quantity: 1,
    },
    file: {
      name: "thyroid.json",
      content: thyroidContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -30,
        max: 30,
      },
      {
        variationId: "11",
        fieldName: "consumables_extension.amount",
        min: -100,
        max: 100,
      },
    ],
  },
];
