import { stepSizeCalc } from "../../../../../helpers/timeConstants";
import { dataTypeList } from "./ShopHistogramSelector";

export const initialShoppingHistDataset = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "bar",
        label: "Shopping Histogram",
        data: [] as Array<number>,
        fill: false,
        yAxisID: "y-axis-shop-hist",
        xAxisID: "x-axis",
      },
    ] as Array<any>,
  };
};

export const shoppingHistogramOptions = (
  histogramMaxCount: number,
  dataType: string,
  min: number,
  max: number,
  stepBucketSize: number,
) => {
  let minX = Math.floor(min / stepBucketSize) * stepBucketSize;
  minX -= stepBucketSize;
  let maxX = Math.ceil(max / stepBucketSize) * stepBucketSize;
  maxX += stepBucketSize;
  return {
    scales: {
      yAxes: [
        {
          id: "y-axis-shop-hist",
          type: "linear",
          ticks: {
            stepSize: stepSizeCalc(histogramMaxCount),
          },
          scaleLabel: {
            display: true,
            labelString: `Counts`,
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          offset: true,
          ticks: {
            min: minX,
            max: maxX,
            stepSize: stepBucketSize,
          },
          scaleLabel: {
            display: true,
            labelString: `${dataType === dataTypeList[0] ? "Amount Spent" : "Items per Category"}`,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: false,
    },
    tooltips: {
      intersect: true,
      mode: "x",
      callbacks: {
        title(tooltipItem: any, data: any): string {
          const initValue = tooltipItem[0].label;
          const nextValue = +initValue + +data.stepBucketSize;
          const prefix = dataType === dataTypeList[0] ? "$" : "";
          const suffix = dataType === dataTypeList[1] ? "items" : "";
          return `Between ${prefix}${initValue} - ${prefix}${nextValue} ${suffix}`;
        },
        label(tooltipItem: any): string {
          return `Count: ${tooltipItem.value}`;
        },
      },
    },
  };
};
