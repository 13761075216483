import {
  TITLE_ACTIVITY_WEB,
  TITLE_ACTIVITY_WEB_HISTOGRAM,
  TITLE_AIR_AQI,
  TITLE_AIR_ELEMENTS,
  TITLE_AIR_PM,
  TITLE_EVENTS_TAGS,
  TITLE_EXERCISE_ACTIVITIES,
  TITLE_EXERCISE_LATEST,
  TITLE_FACEBOOK_INTERACTIONS,
  TITLE_HEARTRATE,
  TITLE_MENSTRUAL_CYCLE,
  TITLE_MOVEMENT,
  TITLE_MOVEMENT_STATES,
  TITLE_NETFLIX_BAR_TITLE,
  TITLE_NETFLIX_PIE_TITLE,
  TITLE_NETFLIX_TOTAL_VIEWING_TIME,
  TITLE_PLACES_DAILY,
  TITLE_PLACES_PIE,
  TITLE_RESTING_HEARTRATE,
  TITLE_SHOPPING,
  TITLE_SHOPPING_CATEGORY,
  TITLE_SLEEP_STATES,
  TITLE_SLEEP_TIME,
  TITLE_STEPS_HIST,
  TITLE_STEPS_LINE,
  TITLE_WEIGHT,
  TITLE_YOUTUBE_COUNTS,
  TITLE_TEMPERATURE,
  TITLE_HUMIDITY,
  TITLE_OZONE,
  TITLE_SLEEP_HISTOGRAM,
  TITLE_MOVEMENT_HISTOGRAM,
  TITLE_PRESSURE,
  TITLE_WIND,
  TITLE_POLLEN,
  TITLE_VISIBILITY,
} from "./graphConstants";
import {
  TAG_AIR,
  TAG_ALLERGY,
  TAG_AUTO,
  TAG_BOAT,
  TAG_BODY,
  TAG_CAR,
  TAG_CARDIOVASCULAR,
  TAG_COVID,
  TAG_DOCTOR,
  TAG_DOCUMENTARIES,
  TAG_ENTERTAINMENT,
  TAG_ENVIRONMENT,
  TAG_EVENT,
  TAG_EXERCISE,
  TAG_FACEBOOK,
  TAG_FEMALE,
  TAG_FITBIT,
  TAG_GARMIN,
  TAG_GOOGLE,
  TAG_HEALTH,
  TAG_LLIF,
  TAG_LOCATION,
  TAG_LOG,
  TAG_MOVE,
  TAG_MOVIES,
  TAG_NETFLIX,
  TAG_ONLINE,
  TAG_PLANE,
  TAG_REPRODUCTIVE,
  TAG_SCREEN_TIME,
  TAG_SHOP,
  TAG_SHOWS,
  TAG_SLEEP,
  TAG_SOCIAL,
  TAG_STREAMING,
  TAG_SYMPTOM,
  TAG_TAG,
  TAG_TELEVISION,
  TAG_TRAIN,
  TAG_TRANSPORTATION,
  TAG_TRAVEL,
  TAG_TV,
  TAG_VIDEO,
  TAG_VIEWING,
  TAG_YOUTUBE,
  TAG_WEATHER,
  TAG_AMBEE,
} from "./tagConstants";

const HEART_RATE_OXYGEN_LINE_GRAPH_ID = "HeartRateOxygenLineGraphId";
const HEART_RATE_HISTOGRAM_GRAPH_ID = "HeartRateHistogramGraphId";
const FACEBOOK_INTERACTIONS_LINE_GRAPH_ID = "FacebookInteractionsLineGraphId";
const WEB_ACTIVITY_INTERACTIONS = "WebActivityInteractionsGraphId";
const WEB_ACTIVITY_INTERACTIONS_HISTOGRAM = "WebActivityInteractionsHistogramGraphId";
const RESTING_HEART_RATE_LINE_GRAPH_ID = "RestingHeartRateLineGraphId";
const RESTING_HEART_RATE_HISTOGRAM_GRAPH_ID = "RestingHeartRateHistogramGraphId";
const TAGS_GRAPH_ID = "TagsGraphId";
const SLEEP_BAR_GRAPH_ID = "SleepBarGraphId";
const SLEEP_TIME_GRAPH_ID = "SleepTimeGraphId";
const SLEEP_HIST_GRAPH_ID = "SleepHistogramGraphId";
const DAILY_STEPS_GRAPHS_ID = "DailyStepsGraphId";
const STEPS_HISTOGRAM_ID = "StepsHistogramId";
const AQI_LINE_ID = "AQILineId";
const PM_LINE_ID = "PMLineId";
const AIR_ELEMENTS_LINE_ID = "AELineId";
const MOVEMENT_BAR_ID = "MovementBarGraphId";
const MOVEMENT_PIE_ID = "MovementPieGraphId";
const MOVEMENT_STACKED_BAR_GRAPH_ID = "MovementStackedBarGraphId";
const MOVEMENT_HISTOGRAM_GRAPH_ID = "MovementHistogramGraphId";
const PLACE_BAR_GRAPH_ID = "PlaceBarGraphId";
const PLACE_PIE_GRAPH_ID = "PlacePieGraphId";
const SHOPPING_SPEND_GRAPH_ID = "ShoppingSpendGraphId";
const SHOPPING_CATEGORIES_GRAPH_ID = "ShoppingCategoriesGraphId";
const SHOPPING_HISTOGRAM_GRAPH_ID = "ShoppingHistogramGraphId";
const YOUTUBE_COUNT_BAR_GRAPH_ID = "YoutubeCountsBarGraphId";
const NETFLIX_TOTAL_BAR_ID = "NetflixTotalBarId";
const NETFLIX_PIE_CHART_ID = "NetflixPieChartId";
const NETFLIX_TIME_STACKED_BAR_ID = "NetflixTimeStackedBarId";
const WEIGHT_BMI_LINE_GRAPH_ID = "WeightBMILineGraphId";
const WEIGHT_HISTOGRAM_GRAPH_ID = "WeightHistogramGraphId";
const MENSTRUAL_LINE_GRAPH_ID = "MenstrualLineGraphId";
const EXERCISE_LINE_GRAPH_ID = "ExerciseGraphId";
const EXERCISE_LATEST_LINE_GRAPH_ID = "ExerciseLatestGraphId";
const WEATHER_TEMPERATURE_LINE_GRAPH_ID = "TemperatureLineGraphId";
const WEATHER_HUMIDITY_LINE_GRAPH_ID = "HumidityLineGraphId";
const WEATHER_OZONE_LINE_GRAPH_ID = "OzoneLineGraphId";
const WEATHER_CLOUD_COVER_LINE_GRAPH_ID = "CloudCoverLineGraphsId";
const WEATHER_PRESSURE_LINE_GRAPH_ID = "PressureLineGraphId";
const WEATHER_WIND_LINE_GRAPH_ID = "WindLineGraphId";
const WEATHER_POLLEN_LINE_GRAPH_ID = "PollenLineGraphId";

export interface MetadataFieldInterface {
  title: string;
  graphTopicsTags: string[];
  graphCloudTags: string[];
  xLabel: string;
  yLabel: string;
}

export interface MetadataInterface {
  id: string;
  metadataField: MetadataFieldInterface;
}

export const WeatherTemperatureLineGraphMetadata: MetadataInterface = {
  id: WEATHER_TEMPERATURE_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_TEMPERATURE,
    graphTopicsTags: [TAG_WEATHER],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const WeatherOzoneLineGraphMetadata: MetadataInterface = {
  id: WEATHER_OZONE_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_OZONE,
    graphTopicsTags: [TAG_WEATHER],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const WeatherHumidityLineGraphMetadata: MetadataInterface = {
  id: WEATHER_HUMIDITY_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_HUMIDITY,
    graphTopicsTags: [TAG_WEATHER],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const WeatherCloudCoverVisibilityGraphMetaData: MetadataInterface = {
  id: WEATHER_CLOUD_COVER_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_VISIBILITY,
    graphTopicsTags: [TAG_WEATHER],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const WeatherPressureLineGraphMetadata: MetadataInterface = {
  id: WEATHER_PRESSURE_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_PRESSURE,
    graphTopicsTags: [TAG_WEATHER],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const WeatherWindLineGraphMetadata: MetadataInterface = {
  id: WEATHER_WIND_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_WIND,
    graphTopicsTags: [TAG_WEATHER],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const WeatherPollenLineGraphMetadata: MetadataInterface = {
  id: WEATHER_POLLEN_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_POLLEN,
    graphTopicsTags: [TAG_WEATHER, TAG_AIR, TAG_ENVIRONMENT],
    graphCloudTags: [TAG_AMBEE],
    xLabel: "",
    yLabel: "",
  },
};

export const HeartRateOxygenLineGraphMetadata: MetadataInterface = {
  id: HEART_RATE_OXYGEN_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_HEARTRATE,
    graphTopicsTags: [TAG_HEALTH, TAG_DOCTOR, TAG_COVID, TAG_BODY, TAG_CARDIOVASCULAR],
    graphCloudTags: [TAG_FITBIT, TAG_GOOGLE, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const HeartRateHistogramGraphMetadata: MetadataInterface = {
  id: HEART_RATE_HISTOGRAM_GRAPH_ID,
  metadataField: {
    title: TITLE_HEARTRATE,
    graphTopicsTags: [TAG_HEALTH, TAG_DOCTOR, TAG_COVID, TAG_BODY, TAG_CARDIOVASCULAR],
    graphCloudTags: [TAG_FITBIT, TAG_GOOGLE, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const FacebookLineGraphMetadata: MetadataInterface = {
  id: FACEBOOK_INTERACTIONS_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_FACEBOOK_INTERACTIONS,
    graphTopicsTags: [TAG_SOCIAL],
    graphCloudTags: [TAG_FACEBOOK],
    xLabel: "",
    yLabel: "",
  },
};

export const WebActivityBarGraphMetadata: MetadataInterface = {
  id: WEB_ACTIVITY_INTERACTIONS,
  metadataField: {
    title: TITLE_ACTIVITY_WEB,
    graphTopicsTags: [TAG_SOCIAL],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const WebActivityHistogramGraphMetadata: MetadataInterface = {
  id: WEB_ACTIVITY_INTERACTIONS_HISTOGRAM,
  metadataField: {
    title: TITLE_ACTIVITY_WEB_HISTOGRAM,
    graphTopicsTags: [TAG_SOCIAL],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const RestingHeartRateLineGraphMetadata: MetadataInterface = {
  id: RESTING_HEART_RATE_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_RESTING_HEARTRATE,
    graphTopicsTags: [TAG_HEALTH, TAG_DOCTOR, TAG_COVID, TAG_BODY, TAG_CARDIOVASCULAR],
    graphCloudTags: [TAG_FITBIT, TAG_GOOGLE, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const RestingHeartRateHistogramGraphMetadata: MetadataInterface = {
  id: RESTING_HEART_RATE_HISTOGRAM_GRAPH_ID,
  metadataField: {
    title: TITLE_RESTING_HEARTRATE,
    graphTopicsTags: [TAG_HEALTH, TAG_DOCTOR, TAG_COVID, TAG_BODY, TAG_CARDIOVASCULAR],
    graphCloudTags: [TAG_FITBIT, TAG_GOOGLE, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const TagsGraphMetadata: MetadataInterface = {
  id: TAGS_GRAPH_ID,
  metadataField: {
    title: TITLE_EVENTS_TAGS,
    graphTopicsTags: [TAG_HEALTH, TAG_DOCTOR, TAG_COVID, TAG_EVENT, TAG_TAG, TAG_LOG, TAG_SYMPTOM],
    graphCloudTags: [TAG_LLIF],
    xLabel: "",
    yLabel: "",
  },
};

export const SleepBarGraphMetadata: MetadataInterface = {
  id: SLEEP_BAR_GRAPH_ID,
  metadataField: {
    title: TITLE_SLEEP_STATES,
    graphTopicsTags: [TAG_HEALTH, TAG_SLEEP, TAG_DOCTOR, TAG_COVID],
    graphCloudTags: [TAG_FITBIT, TAG_GOOGLE, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const SleepTimeGraphMetadata: MetadataInterface = {
  id: SLEEP_TIME_GRAPH_ID,
  metadataField: {
    title: TITLE_SLEEP_TIME,
    graphTopicsTags: [TAG_HEALTH, TAG_SLEEP, TAG_DOCTOR, TAG_COVID],
    graphCloudTags: [TAG_FITBIT, TAG_GOOGLE, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const SleepHistogramGraphMetadata: MetadataInterface = {
  id: SLEEP_HIST_GRAPH_ID,
  metadataField: {
    title: TITLE_SLEEP_HISTOGRAM,
    graphTopicsTags: [TAG_HEALTH, TAG_SLEEP, TAG_DOCTOR, TAG_COVID],
    graphCloudTags: [TAG_FITBIT, TAG_GOOGLE, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const DailyStepsGraphMetadata: MetadataInterface = {
  id: DAILY_STEPS_GRAPHS_ID,
  metadataField: {
    title: TITLE_STEPS_LINE,
    graphTopicsTags: [TAG_EXERCISE, TAG_ENTERTAINMENT, TAG_DOCTOR],
    graphCloudTags: [TAG_FITBIT, TAG_GOOGLE, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const StepsHistogramMetadata: MetadataInterface = {
  id: STEPS_HISTOGRAM_ID,
  metadataField: {
    title: TITLE_STEPS_HIST,
    graphTopicsTags: [TAG_EXERCISE, TAG_ENTERTAINMENT, TAG_DOCTOR],
    graphCloudTags: [TAG_FITBIT, TAG_GOOGLE, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const AQIMetadata: MetadataInterface = {
  id: AQI_LINE_ID,
  metadataField: {
    title: TITLE_AIR_AQI,
    graphTopicsTags: [TAG_ENVIRONMENT, TAG_AIR, TAG_DOCTOR, TAG_ALLERGY, TAG_COVID],
    graphCloudTags: [],
    xLabel: "",
    yLabel: "",
  },
};

export const PMMetadata: MetadataInterface = {
  id: PM_LINE_ID,
  metadataField: {
    title: TITLE_AIR_PM,
    graphTopicsTags: [TAG_ENVIRONMENT, TAG_AIR, TAG_DOCTOR, TAG_ALLERGY, TAG_COVID],
    graphCloudTags: [],
    xLabel: "",
    yLabel: "",
  },
};

export const AirElementsMetaData: MetadataInterface = {
  id: AIR_ELEMENTS_LINE_ID,
  metadataField: {
    title: TITLE_AIR_ELEMENTS,
    graphTopicsTags: [TAG_ENVIRONMENT, TAG_AIR, TAG_DOCTOR, TAG_ALLERGY, TAG_COVID],
    graphCloudTags: [],
    xLabel: "",
    yLabel: "",
  },
};

export const MovementBarGraphMetadata: MetadataInterface = {
  id: MOVEMENT_BAR_ID,
  metadataField: {
    title: TITLE_MOVEMENT_STATES,
    graphTopicsTags: [TAG_MOVE, TAG_TRANSPORTATION, TAG_TRAVEL, TAG_CAR, TAG_AUTO, TAG_BOAT, TAG_PLANE, TAG_TRAIN],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const MovementPieGraphMetadata: MetadataInterface = {
  id: MOVEMENT_PIE_ID,
  metadataField: {
    title: TITLE_MOVEMENT_STATES,
    graphTopicsTags: [TAG_MOVE, TAG_TRANSPORTATION, TAG_TRAVEL, TAG_CAR, TAG_AUTO, TAG_BOAT, TAG_PLANE, TAG_TRAIN],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const MovementStackedBarMetadata: MetadataInterface = {
  id: MOVEMENT_STACKED_BAR_GRAPH_ID,
  metadataField: {
    title: TITLE_MOVEMENT_HISTOGRAM,
    graphTopicsTags: [TAG_MOVE, TAG_TRANSPORTATION, TAG_TRAVEL, TAG_CAR, TAG_AUTO, TAG_BOAT, TAG_PLANE, TAG_TRAIN],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const MovementHistogramMetadata: MetadataInterface = {
  id: MOVEMENT_HISTOGRAM_GRAPH_ID,
  metadataField: {
    title: TITLE_MOVEMENT,
    graphTopicsTags: [TAG_MOVE, TAG_TRANSPORTATION, TAG_TRAVEL, TAG_CAR, TAG_AUTO, TAG_BOAT, TAG_PLANE, TAG_TRAIN],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const PlaceBarGraphMetadata: MetadataInterface = {
  id: PLACE_BAR_GRAPH_ID,
  metadataField: {
    title: TITLE_PLACES_DAILY,
    graphTopicsTags: [TAG_LOCATION, TAG_COVID, TAG_ALLERGY, TAG_ENVIRONMENT],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const PlacePieGraphMetadata: MetadataInterface = {
  id: PLACE_PIE_GRAPH_ID,
  metadataField: {
    title: TITLE_PLACES_PIE,
    graphTopicsTags: [TAG_LOCATION, TAG_COVID, TAG_ALLERGY, TAG_ENVIRONMENT],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const ShoppingSpendGraphMetadata: MetadataInterface = {
  id: SHOPPING_SPEND_GRAPH_ID,
  metadataField: {
    title: TITLE_SHOPPING,
    graphTopicsTags: [TAG_SHOP],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const ShoppingCategoriesGraphMetadata: MetadataInterface = {
  id: SHOPPING_CATEGORIES_GRAPH_ID,
  metadataField: {
    title: TITLE_SHOPPING_CATEGORY,
    graphTopicsTags: [TAG_SHOP],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const ShoppingHistogramGraphMetadata: MetadataInterface = {
  id: SHOPPING_HISTOGRAM_GRAPH_ID,
  metadataField: {
    title: TITLE_SHOPPING_CATEGORY,
    graphTopicsTags: [TAG_SHOP],
    graphCloudTags: [TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const YoutubeCountsBarGraphMetadata: MetadataInterface = {
  id: YOUTUBE_COUNT_BAR_GRAPH_ID,
  metadataField: {
    title: TITLE_YOUTUBE_COUNTS,
    graphTopicsTags: [
      TAG_VIDEO,
      TAG_TV,
      TAG_TELEVISION,
      TAG_STREAMING,
      TAG_MOVIES,
      TAG_SHOWS,
      TAG_DOCUMENTARIES,
      TAG_VIEWING,
      TAG_ONLINE,
      TAG_SCREEN_TIME,
    ],
    graphCloudTags: [TAG_YOUTUBE, TAG_GOOGLE],

    xLabel: "",
    yLabel: "",
  },
};

export const NetflixTotalBarMetadata: MetadataInterface = {
  id: NETFLIX_TOTAL_BAR_ID,
  metadataField: {
    title: TITLE_NETFLIX_TOTAL_VIEWING_TIME,
    graphTopicsTags: [
      TAG_VIDEO,
      TAG_TV,
      TAG_TELEVISION,
      TAG_STREAMING,
      TAG_MOVIES,
      TAG_SHOWS,
      TAG_DOCUMENTARIES,
      TAG_VIEWING,
      TAG_ONLINE,
      TAG_SCREEN_TIME,
    ],
    graphCloudTags: [TAG_NETFLIX],
    xLabel: "",
    yLabel: "",
  },
};

export const NetflixPieChartMetadata: MetadataInterface = {
  id: NETFLIX_PIE_CHART_ID,
  metadataField: {
    title: TITLE_NETFLIX_PIE_TITLE,
    graphTopicsTags: [
      TAG_VIDEO,
      TAG_TV,
      TAG_TELEVISION,
      TAG_STREAMING,
      TAG_MOVIES,
      TAG_SHOWS,
      TAG_DOCUMENTARIES,
      TAG_VIEWING,
      TAG_ONLINE,
      TAG_SCREEN_TIME,
    ],
    graphCloudTags: [TAG_NETFLIX],
    xLabel: "",
    yLabel: "",
  },
};

export const NetflixTimeStackedBarMetadata: MetadataInterface = {
  id: NETFLIX_TIME_STACKED_BAR_ID,
  metadataField: {
    title: TITLE_NETFLIX_BAR_TITLE,
    graphTopicsTags: [
      TAG_VIDEO,
      TAG_TV,
      TAG_TELEVISION,
      TAG_STREAMING,
      TAG_MOVIES,
      TAG_SHOWS,
      TAG_DOCUMENTARIES,
      TAG_VIEWING,
      TAG_ONLINE,
      TAG_SCREEN_TIME,
    ],
    graphCloudTags: [TAG_NETFLIX],

    xLabel: "",
    yLabel: "",
  },
};

export const WeightBMILineGraphMetadata: MetadataInterface = {
  id: WEIGHT_BMI_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_WEIGHT,
    graphTopicsTags: [TAG_HEALTH, TAG_BODY, TAG_DOCTOR, TAG_GOOGLE, TAG_GARMIN],
    graphCloudTags: [TAG_GOOGLE, TAG_FITBIT, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const WeightHistogramGraphMetadata: MetadataInterface = {
  id: WEIGHT_HISTOGRAM_GRAPH_ID,
  metadataField: {
    title: TITLE_WEIGHT,
    graphTopicsTags: [TAG_HEALTH, TAG_BODY, TAG_DOCTOR, TAG_GOOGLE, TAG_GARMIN],
    graphCloudTags: [TAG_GOOGLE, TAG_FITBIT, TAG_GARMIN],
    xLabel: "",
    yLabel: "",
  },
};

export const MenstrualLineGraphMetadata: MetadataInterface = {
  id: MENSTRUAL_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_MENSTRUAL_CYCLE,
    graphTopicsTags: [TAG_HEALTH, TAG_BODY, TAG_DOCTOR, TAG_FEMALE, TAG_REPRODUCTIVE],
    graphCloudTags: [TAG_FITBIT],
    xLabel: "",
    yLabel: "",
  },
};

export const ExerciseLineGraphMetadata: MetadataInterface = {
  id: EXERCISE_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_EXERCISE_ACTIVITIES,
    graphTopicsTags: [TAG_EXERCISE, TAG_HEALTH],
    graphCloudTags: [TAG_FITBIT, TAG_GARMIN, TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};

export const ExerciseLatestMetadata: MetadataInterface = {
  id: EXERCISE_LATEST_LINE_GRAPH_ID,
  metadataField: {
    title: TITLE_EXERCISE_LATEST,
    graphTopicsTags: [TAG_EXERCISE, TAG_HEALTH],
    graphCloudTags: [TAG_FITBIT, TAG_GARMIN, TAG_GOOGLE],
    xLabel: "",
    yLabel: "",
  },
};
