import React from "react";
import { Close, Delete } from "@material-ui/icons";
import _ from "lodash";
import { Box, IconButton } from "@material-ui/core";
import { RowType } from "./handleUpload";
import { VariationMaxInput, VariationMinInput } from "./Components/VariationMinMaxInput";
import { VariationFieldNameInput } from "./Components/VariationFieldNameInput";
import { VariationHelper } from "./Components/VariationHelper";

interface Props {
  rows: RowType[];
  currRowIndex: number;
  setRows: React.Dispatch<React.SetStateAction<RowType[]>>;
}

export const DataVariationsInput = React.memo(({ rows, currRowIndex, setRows }: Props) => {
  const handleRemoveVariationRow = (index: number, variationIndex: number) => {
    setRows((prev) => {
      const newRows = [...prev];
      newRows[index].variations.splice(variationIndex, 1);
      if (newRows[index].variations.length === 0) {
        newRows[index].variations = null;
      }
      return newRows;
    });
  };

  if (!rows[currRowIndex].variations?.length) {
    return null;
  }

  return rows[currRowIndex].variations.map((variation, vi) => {
    const isDateVariation = variation.fieldName === "timestamp" || variation.fieldName === "end_time";

    const content = rows[currRowIndex].file?.content?.trim();
    let parsedContent;

    try {
      parsedContent = JSON.parse(content as string);
    } catch (e) {
      // Intentional empty catch. We dont want to throw an error if user is still typing
    }

    let varyValue = parsedContent ? _.get(parsedContent, variation.fieldName) : null;
    if (isDateVariation) {
      varyValue = new Date(varyValue).toLocaleString();
    }

    return (
      <Box marginBottom={5} key={`Variation ${variation.variationId} Container`}>
        <VariationFieldNameInput
          varyValue={varyValue}
          variation={variation}
          setRows={setRows}
          currRowIndex={currRowIndex}
          varyIndex={vi}
        />

        <VariationMinInput
          variation={variation}
          varyValue={varyValue}
          setRows={setRows}
          currRowIndex={currRowIndex}
          varyIndex={vi}
        />

        <VariationMaxInput
          variation={variation}
          setRows={setRows}
          currRowIndex={currRowIndex}
          varyIndex={vi}
          varyValue={varyValue}
        />

        <IconButton color="secondary" onClick={() => handleRemoveVariationRow(currRowIndex, vi)}>
          <Delete />
        </IconButton>

        {/*  Indivate current value plus or minus the min max input */}
        {variation.fieldName && (
          <VariationHelper isDateVariation={isDateVariation} variation={variation} varyValue={varyValue} />
        )}
      </Box>
    );
  });
});
