import * as chartjs from "chart.js";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";

export const initialFacebookData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Sent",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-facebook-messages",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Received",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-facebook-messages",
        xAxisID: "x-axis",
        type: "bar",
      },
    ],
  };
};

export const initialFacebookOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-facebook-messages",
          position: "left",

          scaleLabel: {
            display: true,
            labelString: "Facebook",
          },
        },
      ],
      xAxes: [
        {
          offset: true,

          id: "x-axis",
          type: "time",
          position: "bottom",
          ticks: {
            minor: {
              autoSkip: true,
              source: "labels",
            },
            major: {
              enabled: true,
            },
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
