import React from "react";
import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";
import {
  HeartRateOxygenLineGraphMetadata,
  RestingHeartRateLineGraphMetadata,
  HeartRateHistogramGraphMetadata,
  RestingHeartRateHistogramGraphMetadata,
  WeightBMILineGraphMetadata,
  WeightHistogramGraphMetadata,
} from "../../../../helpers/graphsMetadata";

export const MeasurementPage = (): JSX.Element => {
  const bodyGraphs = [
    HeartRateOxygenLineGraphMetadata.id,
    RestingHeartRateLineGraphMetadata.id,
    HeartRateHistogramGraphMetadata.id,
    RestingHeartRateHistogramGraphMetadata.id,
    WeightBMILineGraphMetadata.id,
    WeightHistogramGraphMetadata.id,
  ];

  return <GraphListWrapper graphNameList={bodyGraphs} />;
};
