import React, { useState } from "react";
import { createStyles, FormGroup, Paper } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { MultiGraphCalendar } from "./MultiGraphCalendar";
import { MultiGraphInputType, MultiGraphWrapperType } from "./multiGraphTypes";
import { CheckboxWrapper } from "./CheckboxWrapper";

export const MultiGraphWrapper = ({ initialButtons, groupName }: MultiGraphWrapperType) => {
  const [allGraphStates, setAllGraphStates] = useState<MultiGraphInputType>(initialButtons as MultiGraphInputType);
  const hasGraphsActive = Object.values(allGraphStates).some((g) => g.active);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
      },
    }),
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={10} className={classes.paper}>
        {/* Paper container for graphs */}
        <FormGroup row>
          {/* Check boxes for the graph */}
          {Object.values(allGraphStates).map((graph) => {
            return (
              <CheckboxWrapper
                key={graph.title}
                allGraphStates={allGraphStates}
                title={graph.title}
                setAllGraphStates={setAllGraphStates}
                hasActive={hasGraphsActive}
                disabled={graph.disabled}
                disabledWhenNoSelect={graph.disabledUntilSelection && !hasGraphsActive}
              />
            );
          })}
          {/* End Check boxes for the graph */}
        </FormGroup>

        {/* MultiGraph with Calendar */}
        <MultiGraphCalendar allGraphStates={allGraphStates} groupName={groupName} />
        {/* End Paper container for graphs */}
      </Paper>
    </div>
  );
};
