import { endOfDay, startOfDay, isSameDay, subDays, subYears, subMonths } from "date-fns";

const rangeDefinitions = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),

  startOfLastWeek: startOfDay(subDays(new Date(), 7)),

  startOfLastMonth: startOfDay(subMonths(new Date(), 1)),

  startOfLastQuarter: startOfDay(subMonths(new Date(), 3)),

  startOfLastYear: startOfDay(subYears(new Date(), 1)),
  startOfLast2Year: startOfDay(subYears(new Date(), 2)),
  startOfLast4Year: startOfDay(subYears(new Date(), 4)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range: any) {
    const definedRange = range;
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

export function createStaticRanges(ranges: any): any[] {
  return ranges.map((range: any) => ({ ...staticRangeHandler, ...range }));
}

export const graphRangeToday = "Today";
export const graphRangeLastWeek = "Last Week";
export const graphRangeLastMonth = "Last Month";
export const graphRangeLastQuarter = "Last Quarter";
export const graphRangeLastYear = "Last Year";
export const graphRangeLast2Year = "Last 2 Years";
export const graphRangeLast4Year = "Last 4 Years";

export const graphStaticRanges = createStaticRanges([
  {
    label: graphRangeToday,
    range: () => ({
      startDate: rangeDefinitions.startOfToday,
      endDate: rangeDefinitions.endOfToday,
    }),
  },
  {
    label: graphRangeLastWeek,
    range: () => ({
      startDate: rangeDefinitions.startOfLastWeek,
      endDate: rangeDefinitions.endOfToday,
    }),
  },
  {
    label: graphRangeLastMonth,
    range: () => ({
      startDate: rangeDefinitions.startOfLastMonth,
      endDate: rangeDefinitions.endOfToday,
    }),
  },
  {
    label: graphRangeLastQuarter,
    range: () => ({
      startDate: rangeDefinitions.startOfLastQuarter,
      endDate: rangeDefinitions.endOfToday,
    }),
  },
  {
    label: graphRangeLastYear,
    range: () => ({
      startDate: rangeDefinitions.startOfLastYear,
      endDate: rangeDefinitions.endOfToday,
    }),
  },
  {
    label: graphRangeLast2Year,
    range: () => ({
      startDate: rangeDefinitions.startOfLast2Year,
      endDate: rangeDefinitions.endOfToday,
    }),
  },
  {
    label: graphRangeLast4Year,
    range: () => ({
      startDate: rangeDefinitions.startOfLast4Year,
      endDate: rangeDefinitions.endOfToday,
    }),
  },
]);
