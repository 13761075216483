import { ContainerType, GenericProcessedType } from "./multiGraphTypes";

/**
 * Given a list of fetched graph data, call their processing function and store it in a new array
 * @param processedState A list of API fetched
 * @param graphs The list of all graphs desired
 */
export const genericProcessor = (processedState: any[], graphs: ContainerType[]): GenericProcessedType[] => {
  const result: any[] = [];

  for (let i = 0; i < processedState?.length; i++) {
    if (graphs?.[i] && processedState?.[i]) {
      // Get the data and the useProcess function
      const graph = graphs[i];
      const { data } = processedState[i];
      const processFunction = graph.processor;

      // Call the useProcess function on the fetched data, using the initialData function as initial values
      if (processFunction && graph.initialData) {
        const res = processFunction(data, graph.initialData());
        // Push result
        result.push(res);
      }
    }
  }
  return result;
};

/**
 * The default options that will be used for multi graphs.
 * We don't want multiple x-axes. Just this one.
 */
export const genericOptions = () => {
  return {
    scales: {
      yAxes: [] as Array<any>,
      xAxes: [
        {
          id: "x-axis",
          offset: true,
          type: "time",
          ticks: {
            source: "labels",
            autoSkip: true,
          },
          time: {
            unit: "day",
            tooltipFormat: "lll",
          },
          stacked: true,
        },
      ] as Array<any>,
      maintainAspectRatio: false,
    },
  };
};
