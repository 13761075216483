import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { useProcessWeightFatData } from "./useProcessWeightBmiData";
import { defaultGraphHeight, defaultGraphWidth, TITLE_WEIGHT } from "../../../../helpers/graphConstants";
import { BodyQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_MEASURE_WEIGHT, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { WeightBMILineGraphMetadata } from "../../../../helpers/graphsMetadata";

export const WeightBMILineGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(BodyQueryParams.defaultWeightQueryParams, API_MEASURE_WEIGHT, DATA_SERVICE_URL);

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const { outputData, options } = useProcessWeightFatData(graphData, selectedInterval);

  const graphDisplay = (
    <Line data={outputData} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  const description = [
    "Your body weight is how heavy you are, measured in units such as kilograms or pounds.  Ideal Body Weight (IBW) is estimated by your height and sex.  ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_WEIGHT}
        title={TITLE_WEIGHT}
        calendarProps={graphObject}
        id={WeightBMILineGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_WEIGHT} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
