export type NewESResponseDataType = {
  Values: Array<{ [key: string]: any }> | undefined;
};

export const timestampKey = "timestamp";

export const initialValues: NewESResponseDataType = {
  Values: [],
};

export type ESHit = {
  _id: string;
  _index: string;
  _type: string;
  _score: number;
  _source: {
    Title: string;
    [timestampKey]: string;
  };
};

export type ESSearchResponse = {
  Values: {
    hits: {
      hits: ESHit[];
    };
    took: number;
  };
};
