import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { useHistogramGenerator } from "../../../customHooks/useHistogramGenerator";
import { hrHistogramOptions, initialHRHistDatasets } from "./hrHistogramHelper";
import { haveKeys } from "../../../../helpers/components/graphHelpers";

export const useProcessHRHistogram = (fetchedHRData: NewESResponseDataType): any => {
  const initialHRDatasets = initialHRHistDatasets();

  let hrValues = [];
  if (fetchedHRData.Values && haveKeys(fetchedHRData.Values)) {
    hrValues = fetchedHRData.Values.map((obj: ObjectType) => obj.bpm_avg).filter((num) => num > 0);
  }
  const { buckets, largestBucket, min, max, stepBucketSize } = useHistogramGenerator(hrValues, initialHRDatasets, 15);
  initialHRDatasets.datasets[0].data = buckets;
  const histogramLargestBucket = largestBucket;
  const options = hrHistogramOptions(histogramLargestBucket, min, max, stepBucketSize);
  return { data: initialHRDatasets, options };
};
