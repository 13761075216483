import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme, CssBaseline, Typography, useMediaQuery } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { LicenseInfo } from "@material-ui/x-grid";
import {
  IdentityContext,
  IdentityContextType,
  initialUserDataState,
  UserDataInterface,
} from "../types/general/IdentityContext";

import {
  initialSettingsState,
  LOCAL_STORAGE_THEME,
  UserSettingsContext,
  UserSettingsContextType,
  UserSettingsInterface,
  WebsiteTheme,
} from "../types/general/UserSettingsContext";

import { AuthenticationGuard } from "../components/login/AuthenticationGuard";
import { LoginCallback } from "../components/login/LoginCallback";
import { Navigation } from "../components/navigation/Navigation";
import { ButtonEnabledInterface, LoginPage } from "./pages/Login/LoginPage";
import { defaultSettingsValue } from "../types/settings/settings";
import { LoginIOSCallback } from "../components/login/LoginIosCallback";

export const App = (): JSX.Element => {
  const [userData, setUserData] = useState<UserDataInterface>(initialUserDataState);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const localTheme =
    WebsiteTheme[window.localStorage.getItem(LOCAL_STORAGE_THEME) as keyof typeof WebsiteTheme] || WebsiteTheme.System;

  const [userSettings, setUserSettings] = useState<UserSettingsInterface>({
    websiteTheme: localTheme,
    settings: initialSettingsState,
  });

  const userSettingsContextValue: UserSettingsContextType = {
    userSettings,
    setUserSettings,
  };

  const contextValue: IdentityContextType = {
    userData,
    setUserData,
  };

  useEffect(() => {
    const copySettings = { ...userSettings };

    if (userData.userSettings) {
      copySettings.settings = userData.userSettings;
      setUserSettings(copySettings);
    } else if (userData.accessToken !== "") {
      copySettings.settings = { ...defaultSettingsValue };
      setUserSettings(copySettings);
    }
  }, [userData.userSettings]);

  useEffect(() => {
    // Set Material-UI license key
    LicenseInfo.setLicenseKey(
      "26252699899dcba75b0c092eef56476aT1JERVI6MjcwMjUsRVhQSVJZPTE2NTc3MTc4ODIwMDAsS0VZVkVSU0lPTj0x",
    );
  }, []);

  const storedTheme = userSettings.websiteTheme;
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: storedTheme === WebsiteTheme.Dark ? "dark" : "light",
        },
      }),
    [userSettings.websiteTheme, prefersDarkMode],
  );

  return (
    <>
      <UserSettingsContext.Provider value={userSettingsContextValue}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IdentityContext.Provider value={contextValue}>
            <SnackbarProvider maxSnack={5}>
              <Router>
                <AuthenticationGuard setButtonEnabled={setButtonEnabled} />
                {userData.accessToken ? (
                  <Authenticated />
                ) : (
                  <NotAuthenticated buttonEnabled={buttonEnabled} setButtonEnabled={setButtonEnabled} />
                )}
              </Router>
            </SnackbarProvider>
          </IdentityContext.Provider>
        </ThemeProvider>
      </UserSettingsContext.Provider>
    </>
  );
};

const Authenticated = (): JSX.Element => {
  return (
    <>
      <div className="mainScreen">
        <CssBaseline />
        <Navigation />
        <footer className="footer">
          <Typography variant="body2" color="textPrimary" align="center">
            Copyright © Live Learn Innovate Foundation {new Date().getFullYear()}
          </Typography>
        </footer>
      </div>
    </>
  );
};

const NotAuthenticated = React.memo(({ buttonEnabled, setButtonEnabled }: ButtonEnabledInterface): JSX.Element => {
  return (
    <div className="loginScreen">
      <CssBaseline />
      <LoginPage buttonEnabled={buttonEnabled} setButtonEnabled={setButtonEnabled} />
      <Switch>
        <Route
          path="/login/oauth_callback"
          render={() => <LoginCallback buttonEnabled={buttonEnabled} setButtonEnabled={setButtonEnabled} />}
        />
        <Route path="/login/ios_oauth_callback" render={() => <LoginIOSCallback />} />
      </Switch>
    </div>
  );
});
