import React from "react";
import { Grid, Paper, styled, Typography } from "@material-ui/core";
import { RowType } from "./handleUpload";
import { exerciseTemplate } from "./templates/exercise/exercise.template";
import { medicineTemplate } from "./templates/medicine/medicine.template";
import { foodTemplate } from "./templates/food/food.template";

interface Props {
  setRows: React.Dispatch<React.SetStateAction<RowType[]>>;
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const DataTemplates = ({ setRows }: Props) => {
  return (
    <>
      <Typography variant="h6">Data templates:</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Item onClick={() => setRows(exerciseTemplate)}>Varying exercise template</Item>
        </Grid>
        <Grid item xs={4}>
          <Item onClick={() => setRows(medicineTemplate)}>Varying medication template</Item>
        </Grid>
        <Grid item xs={4}>
          <Item onClick={() => setRows(foodTemplate)}>Varying food template</Item>
        </Grid>
      </Grid>
    </>
  );
};
