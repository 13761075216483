import { GridCellParams, GridColDef, GridValueGetterParams } from "@material-ui/data-grid";
import { Link, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { timestampKey } from "../../../types/responses/NewESResponseDataType";

export const webActivityColDef: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Date",
    flex: 1,
    type: "dateTime",
    valueGetter: (params: GridValueGetterParams) => {
      return `${new Date(params.row._source[timestampKey]).toDateString()}`;
    },
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      return +new Date(cellParams1.value as string) > +new Date(cellParams2.value as string) ? 1 : 0;
    },
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.destination.action;
    },
  },
  {
    field: "domain",
    headerName: "Domain",
    flex: 2,
    renderCell: (params: GridCellParams) => (
      <Link target="_blank" rel="noreferrer" href={params.row._source.destination.title_url.split("&usg")[0]}>
        {params.row._source.destination.entity}
      </Link>
    ),
  },
  {
    field: "title",
    headerName: "Title",
    flex: 3,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.destination.title;
    },
  },
  {
    field: "subtitle",
    headerName: "Subtitle",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.destination.subtitle ?? "-";
    },
  },
  {
    field: "mediaType",
    headerName: "Media Type",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.destination.media_type;
    },
  },
  {
    field: "provider",
    headerName: "Cloud Provider",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return `${params.row._source.provider.entity} ${params.row._source.provider.product}`;
    },
  },
  {
    field: "_score",
    headerName: "Score",
    type: "number",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return +params.row._score.toFixed(2);
    },
  },
];

export const amazonShoppingColDef: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Date",
    flex: 1,
    type: "dateTime",
    valueGetter: (params: GridValueGetterParams) => {
      return `${new Date(params.row._source[timestampKey]).toDateString()}`;
    },
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      return +new Date(cellParams1.value as string) > +new Date(cellParams2.value as string) ? 1 : 0;
    },
  },
  {
    field: "orderID",
    headerName: "Order ID",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source["Order ID"];
    },
  },
  {
    field: "title",
    headerName: "Title",
    flex: 2,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.Title;
    },
  },
  {
    field: "UNSPSC",
    headerName: "UNSPSC Code",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source["UNSPSC Code"];
    },
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.Quantity;
    },
  },
  {
    field: "itemTotal",
    headerName: "Total",
    type: "number",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source["Item Total"];
    },
  },
  {
    field: "category",
    headerName: "Category",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.Category;
    },
  },
  {
    field: "_score",
    headerName: "Score",
    type: "number",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return +params.row._score.toFixed(2);
    },
  },
];

export const facebookMessagesColDef: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Date",
    flex: 1,
    type: "dateTime",
    valueGetter: (params: GridValueGetterParams) => {
      return `${new Date(params.row._source[timestampKey]).toDateString()}`;
    },
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      return +new Date(cellParams1.value as string) > +new Date(cellParams2.value as string) ? 1 : 0;
    },
  },
  {
    field: "senderName",
    headerName: "Sender",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.sender_name;
    },
  },
  {
    field: "participants",
    headerName: "Participants",
    flex: 2,
    renderCell: (params: GridCellParams) => {
      const participants = params.row._source.participants
        .map((participant: { name: string }) => participant.name)
        .filter((name: string) => name !== params.row._source.sender_name) // EXCLUDE SENDER
        .join(", ");

      return (
        <Tooltip title={participants}>
          <Typography variant="body2">{participants}</Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "message_type",
    headerName: "Type",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.message_type;
    },
  },
  {
    field: "message_scope",
    headerName: "Scope",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.message_scope;
    },
  },
  {
    field: "_score",
    headerName: "Score",
    type: "number",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return +params.row._score.toFixed(2);
    },
  },
];

export const netflixViewingActivityColDef: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Start Time",
    flex: 1,
    type: "dateTime",
    valueGetter: (params: GridValueGetterParams) => {
      return `${new Date(params.row._source[timestampKey]).toDateString()}`;
    },
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      return +new Date(cellParams1.value as string) > +new Date(cellParams2.value as string) ? 1 : 0;
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.Duration;
    },
  },
  {
    field: "title",
    headerName: "Title",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.Title;
    },
  },
  {
    field: "profile",
    headerName: "Profile",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source["Profile Name"];
    },
  },
  {
    field: "deviceType",
    headerName: "Profile",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source["Device Type"];
    },
  },
  {
    field: "_score",
    headerName: "Score",
    type: "number",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return +params.row._score.toFixed(2);
    },
  },
];

export const diaryEventsColDefs: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Start Time",
    flex: 2,
    type: "dateTime",
    valueGetter: (params: GridValueGetterParams) => {
      const date = new Date(params.row._source[timestampKey]);
      return `${date.toDateString()} ${date.toLocaleTimeString()}`;
    },
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      return +new Date(cellParams1.value as string) > +new Date(cellParams2.value as string) ? 1 : 0;
    },
  },

  {
    field: "title",
    headerName: "Title",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.event_detail.title;
    },
  },
  {
    field: "note",
    headerName: "Note",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.event_detail.note;
    },
  },

  {
    field: "type",
    headerName: "Type",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.event_detail.type;
    },
  },
  {
    field: "product",
    headerName: "Product",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.provider.product;
    },
  },
  {
    field: "_score",
    headerName: "Score",
    type: "number",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return +params.row._score.toFixed(2);
    },
  },
];

export const locationHistoryColDefs: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Start Time",
    flex: 1,
    type: "dateTime",
    valueGetter: (params: GridValueGetterParams) => {
      return `${new Date(params.row._source[timestampKey]).toDateString()}`;
    },
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      return +new Date(cellParams1.value as string) > +new Date(cellParams2.value as string) ? 1 : 0;
    },
  },
  {
    field: "entity",
    headerName: "Entity",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.provider.entity;
    },
  },
  {
    field: "acquisition_method",
    headerName: "Acquisition Method",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.provider.acquisition_method;
    },
  },
  {
    field: "coordinates",
    headerName: "Coordinates",
    flex: 1,
    renderCell: (params: GridCellParams) => {
      // https://www.google.com/maps/search/?api=1&query=35.9331522,%20-78.5677043
      const coords = params.row._source.coordinates.split(", ");
      const lat = coords[0];
      const long = coords[1];
      const mapsURL = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;

      const latLong: string[] = params.row._source.coordinates.split(", ");
      const displayString = `${(+latLong[0])?.toFixed(4)}, ${(+latLong[1])?.toFixed(4)}`;
      return (
        <Link target="_blank" rel="noreferrer" href={mapsURL}>
          {displayString}
        </Link>
      );
    },
  },
  {
    field: "_score",
    headerName: "Score",
    type: "number",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return +params.row._score.toFixed(2);
    },
  },
];

export const googleSemanticLocationColDef: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Start Time",
    flex: 1,
    type: "dateTime",
    valueGetter: (params: GridValueGetterParams) => {
      return `${new Date(params.row._source.timestampStart).toDateString()}`;
    },
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      return +new Date(cellParams1.value as string) > +new Date(cellParams2.value as string) ? 1 : 0;
    },
  },
  {
    field: "endTime",
    headerName: "End Time",
    flex: 1,
    type: "dateTime",
    valueGetter: (params: GridValueGetterParams) => {
      return `${new Date(params.row._source.timestampEnd).toDateString()}`;
    },
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      return +new Date(cellParams1.value as string) > +new Date(cellParams2.value as string) ? 1 : 0;
    },
  },
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.name;
    },
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row._source.address;
    },
  },
  {
    field: "confidence",
    headerName: "Confidence",
    flex: 1,
    type: "number",

    valueGetter: (params: GridValueGetterParams) => {
      // eslint-disable-next-line
      return +((+params.row._source.locationConfidence).toFixed(2));
    },
  },
  {
    field: "latlong",
    headerName: "Coordinates",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      const latLong: string[] = params.row._source.center.split(", ");
      return `${(+latLong[0])?.toFixed(4)}, ${(+latLong[1])?.toFixed(4)}`;
    },
  },
  {
    field: "_score",
    headerName: "Score",
    type: "number",
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return +params.row._score.toFixed(2);
    },
  },
];
