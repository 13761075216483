/* eslint-disable camelcase */
import { useMemo } from "react";
import * as chartjs from "chart.js";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { useAddSimpleMovingAverage } from "../../../customHooks/useAddSimpleMovingAverage";
import { firstLastNonNull } from "../../../../helpers/firstLastNonNull";

type HRObject = {
  timestamp: string;
  end_time: string;
  duration: number;
  bpm_avg: number | null;
  bpm_max: number | null;
  bpm_min: number | null;
};

export const heartrateProcessor = (graphData: NewESResponseDataType, data: any) => {
  if (graphData?.Values && haveKeys([graphData.Values])) {
    const hrGraphData = graphData.Values as HRObject[];

    const firstLast = firstLastNonNull(hrGraphData.map((val) => val.bpm_avg));

    data.labels = hrGraphData.slice(firstLast[0], firstLast[1] + 1).map((val: any) => +new Date(val[timestampKey]));
    data.datasets[0].data = hrGraphData.slice(firstLast[0], firstLast[1] + 1).map((val) => {
      return { x: +new Date(val[timestampKey]), y: val.bpm_avg ? Math.round(val.bpm_avg) : null };
    });
  }

  return data;
};

export const useProcessHeartRateOxygenData = (
  graphData: NewESResponseDataType,
  selectedInterval: string,
): { outputData: any; outputOptions: any } => {
  // Populate data
  const outputData = useMemo(() => {
    const data = initialHRData();

    return heartrateProcessor(graphData, data);
  }, [graphData]);

  let min = 100;
  let max = 0;

  graphData?.Values?.forEach((value) => {
    const bpm = value.bpm_avg;
    if (typeof bpm === "number") {
      min = Math.min(bpm, min);
      max = Math.max(bpm, max);
    }
  });

  const outputOptions = initialHROptions(selectedInterval, min || 60, max || 100);
  useAddSimpleMovingAverage(outputData);

  return { outputData, outputOptions };
};

export const initialHRData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Heart Rate",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-bpm",
        xAxisID: "x-axis",
        type: "line",
        spanGaps: false,
      },
    ],
  };
};

export const initialHROptions = (selectedInterval: string, min: number, max: number): chartjs.ChartOptions => {
  return {
    tooltips: {
      intersect: false,
      callbacks: {
        label(tooltipItem: any): string {
          return `Heart Rate: ${Math.round(tooltipItem.yLabel)} bpm`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-bpm",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Heart Rate (bpm)",
          },
          ticks: {
            max: Math.round(max + 5),
            min: Math.round(min - 5),
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          position: "bottom",
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
