import React from "react";
import EcoIcon from "@material-ui/icons/Eco";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SearchIcon from "@material-ui/icons/Search";
import FavouriteIcon from "@material-ui/icons/FavoriteOutlined";
import SettingsIcon from "@material-ui/icons/Settings";

export interface RenderTree {
  id: string;
  name: string;
  parentName?: string;
  searchID?: string;
  icon?: JSX.Element;
  style?: any;
  disabled?: boolean;
  children?: RenderTree[];
}

export const dashboardLabel = "Dashboard";
export const graphsLabel = "Graphs";

export type NavigationTreeType = {
  live: RenderTree;
  learn: RenderTree;
  innovate: RenderTree;
  find: RenderTree;
  engage: RenderTree;
  settings: RenderTree[];
};

export const navigationTree: NavigationTreeType = {
  live: {
    id: "/live",
    name: dashboardLabel, // LIVE
    searchID: dashboardLabel,
    icon: (
      <ListItemIcon style={{ color: "#d8993b" }}>
        <DashboardIcon />
      </ListItemIcon>
    ),
    children: [
      {
        id: "/live/favorites",
        name: "Favorites",
        searchID: "Favorites",
        icon: (
          <ListItemIcon style={{ color: "red" }}>
            <FavouriteIcon />
          </ListItemIcon>
        ),
        style: { paddingLeft: "16px" },
        children: [],
      },
      {
        id: "/live/mind",
        name: "Mind",
        searchID: "Mind",
        children: [
          { name: "Content", id: "/live/mind/content", searchID: "Content", parentName: "Mind" },
          { name: "Netflix", id: "/live/mind/netflix", searchID: "Netflix", parentName: "Mind" },
        ],
      },
      {
        id: "/live/body",
        name: "Body",
        searchID: "Body",
        children: [
          { name: "Sleep", id: "/live/body/sleep", searchID: "Sleep", parentName: "Body" },
          { name: "Steps", id: "/live/body/steps", searchID: "Steps", parentName: "Body" },
        ],
      },
      {
        id: "/live/nutrition",
        name: "Nutrition",
        searchID: "Nutrition",
        style: { paddingLeft: "16px" },
        children: [],
      },
      {
        id: "/live/health",
        name: "Health",
        searchID: "Health",
        style: { paddingLeft: "16px" },
        children: [],
      },
      {
        id: "/live/measurements",
        name: "Measurements",
        searchID: "Measurements",
        style: { paddingLeft: "16px" },
        children: [
          {
            name: "Heart Rate",
            id: "/live/measurements/heartrate",
            searchID: "Heart Rate",
            parentName: "Measurements",
          },
          { name: "Weight", id: "/live/measurements/weight", searchID: "Weight", parentName: "Measurements" },
        ],
      },
      {
        id: "/live/environment",
        name: "Environment",
        searchID: "Environment",
        style: { paddingLeft: "16px" },
        children: [
          { name: "Weather", id: "/live/environment/weather", searchID: "Weather", parentName: "Environment" },
          { name: "Air", id: "/live/environment/air", searchID: "Air", parentName: "Environment" },
        ],
      },
    ],
  },
  learn: {
    name: "Learn",
    id: "/use-cases",
    children: [
      { name: "Plan", id: "/use-cases/plan" },
      { name: "Graph", id: "/use-cases/graph" },
      { name: "Notify", id: "/use-cases/notify" },
      { name: "Correlate", id: "/use-cases/correlate" },
    ],
  },
  innovate: {
    name: "Innovate",
    id: "/innovate",
    children: [{ name: "Coming Soon", id: "?" }],
  },
  find: {
    name: "Find",
    id: "/find",
    icon: (
      <ListItemIcon>
        <SearchIcon />
      </ListItemIcon>
    ),
    children: [],
  },
  engage: {
    name: "Engage",
    id: "/engage",
    children: [
      { name: "Inbox", id: "/engage/inbox" },
      {
        name: "Subscriptions",
        id: "/engage/subscriptions",
        children: [{ name: "Track", id: "/engage/subscriptions/track" }],
      },
      { name: "Income", id: "/engage/income" },
    ],
  },
  settings: [
    {
      name: "Settings",
      id: "/settings",
      icon: (
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
      ),
      children: [
        { name: "Profile", id: "/settings/profile" },
        { name: "Privacy", id: "/settings/privacy" },
        { name: "Upload", id: "/settings/data-upload" },
        // { name: "General", id: "/settings/general" },
        // {
        //   name: "General",
        //   id: "/settings/general",
        // },
        // { name: "Data Providers", id: "/settings/providers" },
        // { name: "Privacy", id: "/settings/privacy" },
      ],
    },
  ],
};
