/* eslint-disable camelcase */
import { useMemo } from "react";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { initialRHROptions, RHR_DATASET_LABEL } from "./restingHeartRateOptions";
import { RHRProcessedDataType, RHRDataType } from "./restingHeartRateTypes";
import { useAddSimpleMovingAverage } from "../../../customHooks/useAddSimpleMovingAverage";
import { firstLastNonNull } from "../../../../helpers/firstLastNonNull";

export type RHRObject = {
  timestamp: string;
  end_time: string;
  duration: number;
  bpm_avg: number | null;
  bpm_max: number | null;
  bpm_min: number | null;
};

export const restingHeartrateProcessor = (graphData: NewESResponseDataType, data: any) => {
  if (graphData?.Values && haveKeys([graphData.Values])) {
    const rhrGraphData = graphData.Values as RHRObject[];

    const firstLast = firstLastNonNull(rhrGraphData.map((val) => val.bpm_avg));

    data.labels = rhrGraphData.slice(firstLast[0], firstLast[1] + 1).map((val: any) => +new Date(val[timestampKey]));
    data.datasets[0].data = rhrGraphData.slice(firstLast[0], firstLast[1] + 1).map((val) => {
      return { x: +new Date(val[timestampKey]), y: val.bpm_avg ? Math.round(val.bpm_avg) : null };
    });
  }

  return data;
};

export const useProcessRestingHeartRateData = (
  graphData: NewESResponseDataType,
  selectedInterval: string,
): RHRProcessedDataType => {
  // Populate data
  const outputData = useMemo(() => {
    const data: RHRDataType = restingHeartrateProcessor(graphData, initialRHRData());

    // Options after data is loaded to get std.dev + personal range
    const options = initialRHROptions(selectedInterval, data);

    return { data, options };
  }, [graphData]);

  useAddSimpleMovingAverage(outputData.data);

  return { data: outputData.data, options: outputData.options };
};

export const initialRHRData = (): RHRDataType => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "line",
        label: RHR_DATASET_LABEL,
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-rhr",
        xAxisID: "x-axis",
      },
    ],
  };
};
