import React, { useCallback, useEffect, useState } from "react";

import {
  Box,
  createStyles,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
  Paper,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Theme } from "@material-ui/core/styles";
import { API_EXERCISE_LIST, API_EXERCISE_DETAIL, DATA_SERVICE_URL_OLD } from "../../../../api/apiConstants";
import { ExerciseDetail } from "./ExerciseDetail";
import { getAction } from "../../../../helpers/actions/actions";
import { ExerciseTrackResponseType } from "../../../../types/graphs/ExerciseDetailGraphPropsType";
import { minutesToHourMinute } from "../../../../helpers/timeHelpers";
import { initialValues, NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { haveKeys } from "../../../../helpers/components/graphHelpers";

type queryParameterType = {
  _id: string;
};

export const ExerciseGraph = (): JSX.Element => {
  const [exerciseList, setExerciseList] = useState<NewESResponseDataType>(initialValues);
  const [openedExerciseList, setOpenedExerciseList] = useState<Array<string>>([]);
  const [exerciseDetailList, setExerciseDetailList] = useState<Array<ExerciseTrackResponseType>>([]);

  const headerRow = ["Date and Time", "Type", "Duration", "Distance"];

  const fetchExerciseList = useCallback(() => {
    getAction(API_EXERCISE_LIST, setExerciseList, DATA_SERVICE_URL_OLD);
  }, [setExerciseList]);

  const fetchExerciseDetail = async (queryParameters: queryParameterType) => {
    const data: ExerciseTrackResponseType = await getAction(
      API_EXERCISE_DETAIL,
      undefined,
      DATA_SERVICE_URL_OLD,
      queryParameters,
    );
    setExerciseDetailList([...exerciseDetailList, data]);
  };

  useEffect(() => {
    fetchExerciseList();
  }, [fetchExerciseList]);

  const ToggleExerciseDetail = (id: string): void => {
    if (openedExerciseList.includes(id)) {
      setOpenedExerciseList(openedExerciseList.filter((openedId) => openedId !== id));
    } else {
      setOpenedExerciseList([...openedExerciseList, id]);
      fetchExerciseDetail({ _id: id });
    }
  };

  let exerciseListOutput = null;

  const formatOutputList = (): void => {
    if (exerciseList?.Values && haveKeys([exerciseList.Values])) {
      exerciseList.Values.forEach((exerciseObj) => {
        // Format the distance
        const dist = exerciseObj.DistanceMeters;
        exerciseObj.DistanceMeters = `${dist}`.slice(-1) === "m" ? dist : `${Math.round(+dist).toString()} m`;

        // Format the timestamps
        const timestamp = exerciseObj[timestampKey] as string;
        const options: Intl.DateTimeFormatOptions = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
        exerciseObj[timestampKey] = new Date(timestamp).toLocaleDateString(undefined, options);

        // Format the durations
        const dura = exerciseObj.Duration;
        exerciseObj.Duration = `${dura}`.includes("minutes") ? dura : minutesToHourMinute(+dura / 60, true);
      });
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const setOutputList = (): void => {
    if (exerciseList.Values && Object.keys(exerciseList.Values).length) {
      formatOutputList();

      const keyList = [timestampKey, "Activity Note", "duration", "DistanceMeters"];

      // eslint-disable-next-line no-mixed-operators
      exerciseListOutput = exerciseList.Values.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
        (exerciseObj) => {
          return (
            <>
              <TableRow key={exerciseObj._id as string}>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={(): void => ToggleExerciseDetail(exerciseObj._id as string)}
                  >
                    {openedExerciseList.includes(exerciseObj._id as string) ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
                {Object.keys(exerciseObj)
                  .filter((fieldName) => keyList.includes(fieldName))
                  .map((fieldName) => {
                    return (
                      <TableCell scope="row" key={fieldName}>
                        {exerciseObj[fieldName].toString()}
                      </TableCell>
                    );
                  })}
              </TableRow>
              {openedExerciseList.includes(exerciseObj._id as string) &&
                ExerciseDetail({
                  exerciseDetailData: exerciseDetailList.filter((item) => item.Values._id === exerciseObj._id)[0],
                  reducedVersion: false,
                })}
            </>
          );
        },
      );
    }
  };

  setOutputList();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }),
  )(TableCell);

  return (
    <Paper elevation={3} variant="outlined">
      <Box m={3} />

      <TableContainer>
        <Table aria-label="exercise table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              {headerRow.map((fieldName, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableCell key={`${fieldName}${i}`}>{fieldName}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{exerciseListOutput}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={exerciseList?.Values?.length ?? 5}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
