import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Typography } from "@material-ui/core";

import { isValid } from "date-fns";
import { RenderedGraphs } from "./RenderedGraphs";
import { Dict } from "../../../helpers/components/graphHelpers";
import { BodyQueryParams } from "../../../helpers/components/defaultQueryParams";
import { MultiGraphType } from "./multiGraphTypes";
import { selectedIntervalGraphXAxisUnit } from "../../../helpers/timeGraphUnitConstants";
import { GraphContainer } from "../GraphContainer/GraphContainer";
import { applyRules, intervalsAll } from "../GraphContainer/calendarRules";
import { CalendarRange } from "../GraphContainer/GraphCalendar/calendarBodyHelper";

export const MultiGraphCalendar = ({ allGraphStates, groupName }: MultiGraphType) => {
  // Find a graph in the list that HAS an initial query param set.
  // The only time this would return undefined is if we have a mocked/dummy graph
  const initialGraphRange = Object.values(allGraphStates).find((graph) => graph.initialQueryParams)?.initialQueryParams;
  // Default set to the same as resting HR. Month ago daily. Since for multi graph a wider range makes sense
  const fallbackParams = _.cloneDeep(BodyQueryParams.defaultRestingHeartRateQueryParams);

  /**
   *   State Variables
   */
  const [isOpen, setOpen] = useState<boolean>(false);
  const [intervals, setIntervals] = useState<Dict>(_.cloneDeep(intervalsAll));
  const [dateRange, setDateRange] = useState<CalendarRange>(
    initialGraphRange // if there's a graph with initialQueryParams set, use them. Otherwise use fallback
      ? {
          startDate: new Date(initialGraphRange.time_gte),
          endDate: new Date(initialGraphRange.time_lte),
        }
      : {
          startDate: new Date(fallbackParams.time_gte),
          endDate: new Date(fallbackParams.time_lte),
        },
  );
  const [selectedInterval, setSelectedInterval] = useState<string>(fallbackParams.interval ?? "1d");

  /**
   * If dateRange or Interval changes, re-apply applyRules function
   */
  useEffect(() => {
    if (isValid(dateRange.startDate) && isValid(dateRange.endDate)) {
      applyRules(dateRange, setIntervals, selectedInterval, setSelectedInterval);
    }
  }, [dateRange, intervals]);

  const calendarModalProps = {
    isOpen,
    setOpen,
    intervals,
    selectedInterval,
    setSelectedInterval,
    dateRange,
    setDateRange,
    initialQueryParams: undefined,
    setRefetch: undefined,
    isVisible: true,
    isLoaded: true,
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {/* Title of the graph */}
        {groupName}
      </Typography>
      <div>
        {/* The Graphs */}
        <RenderedGraphs
          graphs={Object.values(allGraphStates).filter((obj) => obj.active)}
          dateRange={dateRange}
          setDateRange={setDateRange}
          selectedInterval={selectedInterval}
        />
      </div>
      {/* Text for current range and interval */}
      <div>
        <Typography variant="subtitle1" gutterBottom>
          Selected Range {dateRange.startDate?.toLocaleDateString()} - {dateRange.endDate?.toLocaleDateString()}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <p>Averaging by {selectedIntervalGraphXAxisUnit[selectedInterval]}</p>
        </Typography>
      </div>
      {/* Calendar Icon and Interval Selector */}
      <GraphContainer id={groupName} graphDisplay={undefined} calendarProps={calendarModalProps} />
    </div>
  );
};
