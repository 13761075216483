import { startOfDay, subDays } from "date-fns";
import { RowType } from "../../handleUpload";
import {
  exerciseP90xChestShoulderTriceps,
  exercisePickleballContent,
  exerciseRunContent,
  exerciseWalkContent,
  exerciseYogaContent,
  exerciseP90xLegsBack,
} from "./exercise.content";

const todayAt6pm = startOfDay(new Date());
todayAt6pm.setHours(18);

const todayAt4pm = startOfDay(new Date());
todayAt4pm.setHours(16);

const todayAt2pm = startOfDay(new Date());
todayAt2pm.setHours(14);

const DAYS_AGO_START = 90;

export const exerciseTemplate: RowType[] = [
  {
    id: "1",
    schedule: {
      startDate: subDays(todayAt6pm, DAYS_AGO_START - 0),
      endDate: todayAt6pm,
      unit: 1 * 60 * 60 * 24 * 7,
      quantity: 1,
    },
    file: {
      name: "exercise_run.json",
      content: exerciseRunContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3000,
        max: 6000,
      },
      {
        variationId: "96",
        fieldName: "duration",
        min: -2400,
        max: 2400,
      },
      {
        variationId: "121",
        fieldName: "custom_data[0].value",
        min: 0,
        max: 10,
      },
    ],
  },
  {
    id: "2",
    schedule: {
      startDate: subDays(todayAt4pm, DAYS_AGO_START - 1),
      endDate: todayAt4pm,
      unit: 1 * 60 * 60 * 24 * 7,
      quantity: 1,
    },
    file: {
      name: "exercise_walk.json",
      content: exerciseWalkContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3000,
        max: 6000,
      },
      {
        variationId: "96",
        fieldName: "duration",
        min: -1500,
        max: 2400,
      },
      {
        variationId: "99",
        fieldName: "custom_data[0].value",
        min: 0,
        max: 10,
      },
    ],
  },
  {
    id: "3",
    schedule: {
      startDate: subDays(todayAt6pm, DAYS_AGO_START - 2),
      endDate: todayAt6pm,
      unit: 1 * 60 * 60 * 24 * 7,
      quantity: 1,
    },
    file: {
      name: "exercise_p90x_chest_shoulder_triceps.json",
      content: exerciseP90xChestShoulderTriceps,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3000,
        max: 6000,
      },
      {
        variationId: "96",
        fieldName: "duration",
        min: -2400,
        max: 2400,
      },
      {
        variationId: "11",
        fieldName: "intensity",
        min: -3,
        max: 3,
      },
    ],
  },
  {
    id: "4",
    schedule: {
      startDate: subDays(todayAt2pm, DAYS_AGO_START - 3),
      endDate: todayAt2pm,
      unit: 1 * 60 * 60 * 24 * 7,
      quantity: 1,
    },
    file: {
      name: "exercise_yoga.json",
      content: exerciseYogaContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3000,
        max: 6000,
      },
      {
        variationId: "96",
        fieldName: "duration",
        min: -1800,
        max: 2400,
      },
    ],
  },
  {
    id: "5",
    schedule: {
      startDate: subDays(todayAt4pm, DAYS_AGO_START - 4),
      endDate: todayAt4pm,
      unit: 1 * 60 * 60 * 24 * 7,
      quantity: 1,
    },
    file: {
      name: "exercise_p90x_legs_back.json",
      content: exerciseP90xLegsBack,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3000,
        max: 6000,
      },
      {
        variationId: "96",
        fieldName: "duration",
        min: -2400,
        max: 2400,
      },
      {
        variationId: "999",
        fieldName: "intensity",
        min: -3,
        max: 3,
      },
    ],
  },
  {
    id: "6",
    schedule: {
      startDate: subDays(todayAt6pm, DAYS_AGO_START - 5),
      endDate: todayAt6pm,
      unit: 1 * 60 * 60 * 24 * 7,
      quantity: 1,
    },
    file: {
      name: "exercise_run.json",
      content: exerciseRunContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3000,
        max: 6000,
      },
      {
        variationId: "96",
        fieldName: "duration",
        min: -2400,
        max: 2400,
      },
      {
        variationId: "99",
        fieldName: "custom_data[0].value",
        min: 0,
        max: 10,
      },
    ],
  },
  {
    id: "7",
    schedule: {
      startDate: subDays(todayAt2pm, DAYS_AGO_START - 6),
      endDate: todayAt2pm,
      unit: 1 * 60 * 60 * 24 * 7,
      quantity: 1,
    },
    file: {
      name: "exercise_pickleball.json",
      content: exercisePickleballContent,
    },
    variations: [
      {
        variationId: "9",
        fieldName: "timestamp",
        min: -3000,
        max: 6000,
      },
      {
        variationId: "96",
        fieldName: "duration",
        min: -2400,
        max: 2400,
      },
      {
        variationId: "110",
        fieldName: "intensity",
        min: -3,
        max: 3,
      },
    ],
  },
];
