import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";

export const initialAirElementsOptions = () => {
  return {
    scales: {
      yAxes: [
        {
          id: "o3-so2-no2-y-axis",
          type: "linear",
          position: "left",
          ticks: {
            suggestedMin: 0,
          },
          scaleLabel: {
            display: true,
            labelString: "O3 / SO2 / NO2 (ppb)",
          },
        },
        {
          id: "co-y-axis",
          type: "linear",
          position: "right",
          ticks: {
            suggestedMin: 0,
          },
          scaleLabel: {
            display: true,
            labelString: "CO (ppm)",
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          display: true,
          gridLines: {
            display: false,
          },
          position: "bottom",
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
          },
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
              maxTicksLimit: 6,
            },
            major: {
              enabled: true,
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
      mode: "x",
    },
  };
};

export const initialParticulateOptions = () => {
  return {
    scales: {
      yAxes: [
        {
          id: "pm10-y-axis",
          type: "linear",
          position: "left",
          ticks: {
            suggestedMin: 0,
            // suggestedMax: 75,
          },
          scaleLabel: {
            display: true,
            labelString: "PM10 (μg/m^3)",
          },
        },
        {
          id: "pm25-y-axis",
          type: "linear",
          position: "right",
          ticks: {
            suggestedMin: 20,
            // suggestedMax: 150,
          },
          scaleLabel: {
            display: true,
            labelString: "PM25 (μg/m^3)",
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          display: true,
          gridLines: {
            display: false,
          },
          position: "bottom",
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
          },
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
              maxTicksLimit: 6,
            },
            major: {
              enabled: true,
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
      mode: "x",
    },
  };
};

export const initialAQIOptions = () => {
  return {
    scales: {
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          position: "bottom",
          gridLines: {
            display: false,
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
          },
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          id: "aqi-y-axis",
          type: "linear",
          position: "left",
          ticks: {
            min: 0,
            // max: 100,
          },
        },
      ],

      maintainAspectRatio: false,
    },
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
      mode: "x",
    },
  };
};
