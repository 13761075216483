import { onlyUnique } from "../../../helpers/components/graphHelpers";
import { ESHit, ESSearchResponse } from "../../../types/responses/NewESResponseDataType";

/**
 * Function is only called after there is a change/refetch in searchData.
 *
 * Go through the results of API fetch, find all the different ES indices
 * Index is stored in obj._index
 *
 * Each index gets its own graph.
 * Create a 2D array. Each index of outer array represents one graph
 * The inner array represents the data for the table
 *
 * Example:
 * [[AmazonShoppingIndex], [GoogleYoutubeLikesIndex], [etc]]
 */
export const splitTables = (
  searchValue: string,
  searchData: ESSearchResponse | undefined,
  setTableData: (arg: any[]) => void,
): void => {
  // Use the first element as a reference for the header
  if (searchValue !== "" && searchData && searchData.Values.hits.hits.length > 0) {
    const results = searchData.Values.hits.hits;

    const resultsArr = results.map((obj) => obj._index);
    // different results may need different # of cols.
    // each result, deduped, should be its own table

    const dedupedTableTitles = resultsArr.filter(onlyUnique);
    const arrayOfTables: ESHit[][] = [];

    dedupedTableTitles.forEach((table) => {
      // for each individual table:
      results.forEach((obj: ESHit) => {
        if (obj._index === table) {
          if (!arrayOfTables[dedupedTableTitles.indexOf(obj._index)]) {
            arrayOfTables[dedupedTableTitles.indexOf(obj._index)] = [];
          }
          arrayOfTables[dedupedTableTitles.indexOf(obj._index)].push(obj);
        }
      });
    });
    if (arrayOfTables.length > 0) {
      setTableData(arrayOfTables);
    }
  }
};

/**
 * From the table data fetched from the backend,
 * Get the first row. On that row, see which props are of type string or number
 * Push those into an array of string, so that it can be used
 * to populate the header row of the table
 */
export const generateHeaders = (tableData: any[][] | undefined, setTableHeaders: (arg: any[]) => void): void => {
  if (tableData) {
    const header = [] as Array<any>;
    // Dynamically get the headers from the first row
    tableData.forEach((table: any[]) => {
      const row = table[0];
      const rows = [] as Array<any>; // These will be consistent across almost all

      Object.keys(row._source).forEach((prop: any) => {
        // We should only types that are string and number for table data..
        if ((typeof row._source[prop] === "string" && prop !== "uuid") || typeof row._source[prop] === "number") {
          rows.push(prop);
        }
      });
      header.push(rows);
    });
    setTableHeaders(header);
  }
};
