import React from "react";
import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";

export type shopDataType = "Prices" | "Categories";

export const dataTypeList: shopDataType[] = ["Prices", "Categories"];

type ShopTypeSelectorInterface = {
  dataType: shopDataType;
  setDataType: React.Dispatch<React.SetStateAction<shopDataType>>;
  categoryList: string[];
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
};

export const ShopHistogramSelector = ({
  dataType,
  setDataType,
  categoryList,
  selectedCategory,
  setSelectedCategory,
}: ShopTypeSelectorInterface): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line
    setDataType((event.target as HTMLInputElement).value as shopDataType);
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={8}>
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup row aria-label="type" name="price category selector" value={dataType} onChange={handleChange}>
            <FormControlLabel value={dataTypeList[0]} control={<Radio />} label={dataTypeList[0]} />
            <FormControlLabel value={dataTypeList[1]} control={<Radio />} label={dataTypeList[1]} />
          </RadioGroup>
        </FormControl>
      </Grid>
      {dataType === dataTypeList[1] && (
        <Grid item>
          <Select
            style={{ maxWidth: 100 }}
            id="shop-hist-category-selector"
            value={selectedCategory}
            onChange={(event) => {
              setSelectedCategory(event?.target?.value as string);
            }}
          >
            {categoryList.map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  {item.toLowerCase().replaceAll("_", " ")}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      )}
    </Grid>
  );
};
