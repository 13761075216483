import * as chartjs from "chart.js";
import { perDay, perHour, perMinute, perMonth, perWeek, perYear } from "./timeConstants";

type selectedIntervalGraphXAxeUnitType = { [key: string]: chartjs.TimeUnit };

const chartJSTimeUnitMinute = "minute";
const chartJSTimeUnitHour = "hour";
const chartJSTimeUnitDay = "day";
const chartJSTimeUnitWeek = "week";
const chartJSTimeUnitMonth = "month";
const chartJSTimeUnitYear = "year";

export const selectedIntervalGraphXAxisUnit: selectedIntervalGraphXAxeUnitType = {
  [perYear]: chartJSTimeUnitYear,
  [perMonth]: chartJSTimeUnitMonth,
  [perWeek]: chartJSTimeUnitWeek,
  [perDay]: chartJSTimeUnitDay,
  [perHour]: chartJSTimeUnitHour,
  [perMinute]: chartJSTimeUnitMinute,
};
