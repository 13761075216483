import React from "react";
import {
  WeatherHumidityLineGraphMetadata,
  WeatherOzoneLineGraphMetadata,
  WeatherTemperatureLineGraphMetadata,
} from "../../../../helpers/graphsMetadata";
import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";

export const WeatherPage = (): JSX.Element => {
  const weatherPage = [
    WeatherTemperatureLineGraphMetadata.id,
    WeatherHumidityLineGraphMetadata.id,
    WeatherOzoneLineGraphMetadata.id,
  ];

  return <GraphListWrapper graphNameList={weatherPage} />;
};
