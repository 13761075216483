/* eslint-disable camelcase */
export type Providers = "amazon" | "facebook" | "fitbit" | "google" | "netflix";
export type UploadStates =
  | "unknown"
  | "uploading"
  | "uploaded"
  | "extracting"
  | "extracted"
  | "loading"
  | "finished"
  | "failed";
export interface UploadState {
  provider: Providers;
  state: UploadStates;
  in_progress: boolean;
  loading_progress: number | undefined;
  user_filename: string | undefined;
  timestamp: string;
}

export const REFRESH_INTERVAL_MS = 2000;
