import React from "react";
import { Typography } from "@material-ui/core";

interface GraphDescriptionProps {
  title: string;
  description: string[];
}

export const GraphDescription = ({ title, description }: GraphDescriptionProps) => {
  let descriptionMap;
  if (Array.isArray(description)) {
    descriptionMap = description.map((str) => (
      <Typography key={str} variant="body1" style={{ display: "block" }}>
        {str}
      </Typography>
    ));
  } else {
    descriptionMap = [<Typography variant="body1">{description}</Typography>];
  }

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <hr />
      {descriptionMap}
    </>
  );
};
