import React from "react";
import { Bar } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { API_ACTIVITY_SLEEP, DATA_SERVICE_URL } from "../../../../../api/apiConstants";
import { useGraphSetup } from "../../../../customHooks/useGraphSetup";
import { ActivityQueryParams } from "../../../../../helpers/components/defaultQueryParams";
import { GraphContainer } from "../../../../customComponents/GraphContainer/GraphContainer";
import { defaultGraphHeight, defaultGraphWidth, TITLE_SLEEP_TIME } from "../../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { useProcessSleepTime } from "./useProcessSleepTime";
import { sleepTimeOptions } from "./sleepTimeHelper";
import { SleepTimeGraphMetadata } from "../../../../../helpers/graphsMetadata";

export const SleepTime = (): JSX.Element => {
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultSleepTimeQueryParams,
    API_ACTIVITY_SLEEP,
    DATA_SERVICE_URL,
    true,
  );

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const outputSleepData = useProcessSleepTime(graphData);

  const graphDisplay = (
    <Bar
      data={outputSleepData.sleepLineData}
      options={sleepTimeOptions(selectedInterval)}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
    />
  );

  const description = [
    "Sleep is a naturally recurring state of mind and body, characterized by altered consciousness, relatively inhibited sensory activity, reduced muscle activity and inhibition of nearly all voluntary muscles during rapid eye movement (REM) sleep, and reduced interactions with surroundings. ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_SLEEP_TIME}
        title={TITLE_SLEEP_TIME}
        calendarProps={graphObject}
        id={SleepTimeGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_SLEEP_TIME} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
