import * as chartjs from "chart.js";
import { stepSizeCalc } from "../../../../helpers/timeConstants";

export const MAX_TICKS_LIMIT = 6;
export const WEB_ACTIVITY_LABEL = "Web Activities";
export const WEB_ACTIVITY_AXIS_LABEL = "web-activity-y-axis";

export const initialHistogramOptions = (
  histogramMaxCount: number,
  min: number,
  max: number,
  stepBucketSize: number,
): chartjs.ChartOptions => {
  let minX = Math.floor(min / stepBucketSize) * stepBucketSize;
  minX -= stepBucketSize;
  let maxX = Math.ceil(max / stepBucketSize) * stepBucketSize;
  maxX += stepBucketSize;
  return {
    scales: {
      yAxes: [
        {
          id: WEB_ACTIVITY_AXIS_LABEL,
          type: "linear",
          ticks: {
            stepSize: stepSizeCalc(histogramMaxCount),
          },
          scaleLabel: {
            display: true,
            labelString: `Counts`,
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          offset: true,
          ticks: {
            min: minX,
            max: maxX,
            stepSize: stepBucketSize,
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
      mode: "x",
      callbacks: {
        title(tooltipItem: any, data: any): string {
          const initValue = tooltipItem[0].label;
          const nextValue = +initValue + +data.stepBucketSize;
          return `Between ${initValue} - ${nextValue}`;
        },
        label(tooltipItem: any): string {
          return `Count: ${tooltipItem.value}`;
        },
      },
    },
  } as chartjs.ChartOptions;
};
