import React, { useContext } from "react";
import { Button, Box, Grid, withStyles, Typography, Link, Paper, CircularProgress } from "@material-ui/core";
import LLIFLogo from "./logo.svg";
import LLIFLogoDark from "./logo-dark.svg";
import { loginStyles } from "./loginPageHelper";
import { SocialLoginBox } from "./SocialLoginBox";
import Image from "../../../assets/login.png";
import { UserSettingsContext, WebsiteTheme } from "../../../types/general/UserSettingsContext";

export const LoginPageComponent = (props: {
  handleGoogleLogin: () => any;
  handleAppleLogin: () => any;
  buttonEnabled: boolean;
}) => {
  const { userSettings } = useContext(UserSettingsContext);

  const PinkButton = withStyles(() => ({
    root: {
      color: "#fff",
      backgroundColor: "#f20089",
      width: "100%",
      padding: 10,
      marginBottom: 5,
    },
  }))(Button);
  const LearnButton = withStyles(() => ({
    root: {
      color: userSettings.websiteTheme === WebsiteTheme.Dark ? "#070707" : "white",
      width: "100%",
      padding: 10,
      marginTop: 5,
    },
  }))(Button);
  const classes = loginStyles();

  return (
    <Grid
      style={{ backgroundImage: `url(${Image})`, backgroundSize: "cover", height: "100%", width: "100%" }}
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box m={3} />
      <Paper elevation={10} className={classes.paper}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item className={classes.fullPercent} xs={6}>
            <img src={userSettings.websiteTheme === WebsiteTheme.Dark ? LLIFLogoDark : LLIFLogo} alt="LLIF" />
          </Grid>
        </Grid>
        <Box m={1} />
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: "lighter", fontFamily: "Roboto" }}>
            Your Data. Your Power. Your LLIF!
          </Typography>
        </Grid>

        {/* Line Separator */}
        <Box m={1} />
        <hr className={classes.hr} />
        <Box m={3} />
        {/* Line Separator */}

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={9}>
            {props.buttonEnabled ? (
              <SocialLoginBox handleGoogleLogin={props.handleGoogleLogin} handleAppleLogin={props.handleAppleLogin} />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
        <Box m={3} />

        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: "lighter", fontFamily: "Roboto" }}>
            You Decide. You Engage. Everyone Wins!
          </Typography>
        </Grid>

        {/* Line Separator */}
        <Box m={3} />
        <hr className={classes.hr} />
        <Box m={3} />
        {/* Line Separator */}
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={9}>
            <Link href="https://www.llif.org/contribute" target="_blank" color="inherit" underline="none">
              <PinkButton variant="contained" color="inherit">
                Donate Today
              </PinkButton>
            </Link>
          </Grid>
          <Grid item xs={9}>
            <LearnButton variant="outlined" color="inherit">
              <Link href="https://www.llif.org/" target="_blank" color="inherit" underline="none">
                Learn More
              </Link>
            </LearnButton>
          </Grid>
        </Grid>

        <Box m={1} />

        <Typography style={{ fontFamily: "Roboto" }} variant="button">
          <Link href="https://www.llif.org/statefundraisingnotice" target="_blank" color="inherit" underline="always">
            State Fundraising Notice
          </Link>
        </Typography>
        <Box m={2} />
      </Paper>
      <Box m={1} />
      <Box m={1} />
      <Typography style={{ fontFamily: "Roboto", color: "white" }} variant="subtitle1" gutterBottom>
        We&apos;re working hard to make your data work for you!
        <br />
        To make your data work for you, we log member data.
      </Typography>
      <Typography style={{ fontFamily: "Roboto", color: "white" }} variant="subtitle1" gutterBottom>
        By signing in, you accept our
      </Typography>
      <Typography style={{ fontFamily: "Roboto", color: "white" }} variant="button">
        <Link
          style={{ fontFamily: "Roboto" }}
          href="https://app.termly.io/document/terms-of-use-for-online-marketplace/28b90807-693a-4816-8871-acb0fec4ad5e"
          target="_blank"
          color="inherit"
          underline="always"
        >
          Terms of Use
        </Link>
        {", "}
        <Link
          style={{ fontFamily: "Roboto", color: "white" }}
          href="https://app.termly.io/document/privacy-policy/2e04aff7-23be-4b2a-82e1-c9894e8005d3"
          target="_blank"
          color="inherit"
          underline="always"
        >
          Privacy Notice
        </Link>
        {", "}
        and{" "}
        <Link
          style={{ fontFamily: "Roboto", color: "white" }}
          href="https://app.termly.io/document/disclaimer/242d6472-b0b8-4e4f-b222-95caf3d041e0"
          target="_blank"
          color="inherit"
          underline="always"
        >
          Disclaimer
        </Link>
      </Typography>
    </Grid>
  );
};
