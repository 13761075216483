import React, { useContext, useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";
import { createStyles, withStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box, Dialog, DialogActions } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { green } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import { UserSettingsContext, initialSettingsState } from "../../../../types/general/UserSettingsContext";
import { saveToServer } from "../../../../helpers/settingsHelper";
import { PrivacySettingsInterface } from "../../../../types/settings/settings";
import { ConfirmDeleteDialog } from "../SettingsPage/ConfirmDeleteDialog";
import { CustomIFrame } from "../../../../components/customComponents/CustomIframe/CustomIframe";
import { useLogout } from "../../../../components/customHooks/useLogout";

const termsOfUseURL =
  "https://app.termly.io/document/terms-of-use-for-online-marketplace/28b90807-693a-4816-8871-acb0fec4ad5e";

const privacyPolicyURL = "https://getbestlife.app/privacy-policy/";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    p: {
      marginTop: "0px",
      marginBottom: "0px",
    },
    subtitle: {
      color: theme.palette.text.secondary,
    },
    paper: {
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(10),
    },
    center: { textAlign: "center" },
  }),
);

export const PrivacyPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [isTermsDialogOpen, setTermsDialogOpen] = React.useState(false);
  const [isPrivacyDialogOpen, setPrivacyDialogOpen] = React.useState(false);
  const { logoutEverywhere } = useLogout();

  const notInitialSave = useRef(false);
  const [settings, setSettings] = React.useState<any>(initialSettingsState);
  const [privacy, setPrivacy] = useState<PrivacySettingsInterface>({
    privacy_policy: settings?.privacy?.privacy_policy || false,
    use_policy: settings?.privacy?.use_policy || false,
  });

  useEffect(() => {
    if (userSettings.settings && settings.privacy === undefined) {
      setSettings(userSettings.settings);
      setPrivacy({
        privacy_policy: userSettings?.settings?.privacy?.privacy_policy || false,
        use_policy: userSettings?.settings?.privacy?.use_policy || false,
      });
    }
  }, [userSettings]);

  useEffect(() => {
    if (settings.privacy === undefined) return;
    if (notInitialSave.current) {
      saveToServer(settings);
      const copySettings = { ...userSettings };
      copySettings.settings = settings;
      setUserSettings(copySettings);
    } else {
      notInitialSave.current = true;
    }
  }, [settings]);

  const SuccessButton = withStyles((theme: Theme) => ({
    root: {
      color: theme.palette.getContrastText(green[700]),
      backgroundColor: green[600],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
  }))(Button);

  const changeSettings = (field: any, value: any) => {
    setSettings(() => ({
      ...settings,
      privacy: {
        ...settings.privacy,
        [field]: value,
      },
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacy({ ...privacy, [event.target.name]: event.target.checked });
    changeSettings(event.target.name, event.target.checked);
  };

  const handleBtnClick = (str: string) => {
    setPrivacy({ ...privacy, [str]: true });
    changeSettings(str, true);
    setTermsDialogOpen(false);
    setPrivacyDialogOpen(false);
  };
  const PrivacyPreferenceGroup = () => (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Link
            to={{
              pathname: termsOfUseURL,
            }}
            target="_blank"
          >
            <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
              Terms of Use
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          {!privacy.use_policy ? (
            <Button name="use_policy" onClick={() => setTermsDialogOpen(true)} variant="contained" color="primary">
              Accept
            </Button>
          ) : (
            <SuccessButton variant="contained" color="secondary" size="small">
              Accepted
            </SuccessButton>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <p className={classes.p}>
            Our LLIF Acceptable Use Policy describes how your data is used to serve you and community and how it is
            protected.
          </p>
        </Grid>

        <Grid item xs={6} md={3}>
          <Link to={{ pathname: privacyPolicyURL }} target="_blank">
            <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
              Privacy Policy
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          {!privacy.privacy_policy ? (
            <Button
              name="privacy_policy"
              onClick={() => setPrivacyDialogOpen(true)}
              variant="contained"
              color="primary"
            >
              Accept
            </Button>
          ) : (
            <SuccessButton variant="contained" color="secondary" size="small">
              Accepted
            </SuccessButton>
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          <p className={classes.p}>The LLIF privacy policy describes how LLIF protects your data.</p>
        </Grid>
      </Grid>
    </>
  );

  const RetentionPreferenceGroup = () => (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6} md={3}>
          <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
            Delete Data
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <ConfirmDeleteDialog />
        </Grid>
        <Grid item xs={12} md={7}>
          <p className={classes.p}>
            Deleting your account will remove your data within 24 hours and remove your account within 30 days.
          </p>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
            Delete Account
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button disabled variant="contained" color="secondary">
            Delete
          </Button>
        </Grid>
        <Grid item xs={12} md={7}>
          <p className={classes.p}>
            Deleting your account will remove your data within the last 24 hours and removing your account within 30
            days.
          </p>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
            Sign Out Everywhere
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              logoutEverywhere().finally(() => {
                enqueueSnackbar(`All devices will be logged out within 1 minute.`, { variant: "info" });
              });
            }}
          >
            Sign Out
          </Button>
        </Grid>
        <Grid item xs={12} md={7}>
          <p className={classes.p}>This will sign you out of all your other devices.</p>
        </Grid>
      </Grid>
    </>
  );

  const TermsDialog = (
    <Dialog
      fullScreen
      open={isTermsDialogOpen}
      onClose={() => setTermsDialogOpen(false)}
      aria-labelledby="dialog-terms"
      aria-describedby="Terms of use policy"
    >
      <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <CustomIFrame title="terms of use" src={termsOfUseURL} top={0} />
        </Grid>

        <Grid item style={{ background: "white", zIndex: 1 }} xs={12}>
          <DialogActions>
            <Button onClick={() => setTermsDialogOpen(false)}>Disagree</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleBtnClick("use_policy");
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
  const PrivacyDialog = (
    <Dialog
      fullScreen
      open={isPrivacyDialogOpen}
      onClose={() => setPrivacyDialogOpen(false)}
      aria-labelledby="dialog-privacy"
      aria-describedby="Privacy Policy"
    >
      <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <CustomIFrame title="privacy policy" src={privacyPolicyURL} top={0} />
        </Grid>

        <Grid item style={{ background: "white", zIndex: 1 }} xs={12}>
          <DialogActions>
            <Button onClick={() => setPrivacyDialogOpen(false)}>Disagree</Button>
            <Button color="primary" variant="contained" onClick={() => handleBtnClick("privacy_policy")} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <Box m={2} />
        <Typography variant="h3" align="center" style={{ fontWeight: 600 }}>
          Privacy
        </Typography>

        <Divider variant="middle" />

        <Box m={2} />
        <Divider />
        <Box m={2} />
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          Privacy Policies
        </Typography>

        <PrivacyPreferenceGroup />
        <Box m={3} />
        <Divider />
        <Box m={2} />
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          Retention
        </Typography>
        <Box m={1} />

        <RetentionPreferenceGroup />
        <Box m={8} />
      </Paper>

      {TermsDialog}
      {PrivacyDialog}
    </>
  );
};
