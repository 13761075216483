/* eslint-disable no-extra-parens */
import { useMemo } from "react";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { ShoppingAggregatedType } from "./shoppingTypes";
import { categoryOptions, initialCategoriesData, numCategories, othersLabel } from "./useShoppingDefaults";

export type categoryType = {
  name: string;
  value: number;
};

export const frequencyMap = (valuesData: NewESResponseDataType, topNResults: number) => {
  let topResults: categoryType[] = [];
  let otherResults: categoryType[] = [];
  if (valuesData.Values && haveKeys(valuesData.Values)) {
    const categoriesMap = new Map();

    (valuesData.Values as ShoppingAggregatedType[])
      .filter((obj) => obj.total)
      .forEach((valueObj) => {
        const categories = valueObj.category;

        // Populate categories map
        Object.values(categories)?.forEach((category) => {
          const currentAmount = categoriesMap.get(category.key);
          categoriesMap.set(category.key, currentAmount ? currentAmount + category.doc_count : category.doc_count);
        });

        // Create labels for top 3
        const sortedMap = new Map(
          [...categoriesMap.entries()].sort((a, b) => {
            if (a[1] > b[1]) return -1;
            if (a[1] < b[1]) return 1;
            /* else */ return 0;
          }),
        );

        const result = Array.from(sortedMap).map(([name, value]) => ({ name, value }));
        topResults = result.slice(0, Math.min(topNResults, result.length));
        otherResults = result.slice(Math.min(topNResults, result.length), result.length);
      });
  }

  return { topResults, otherResults };
};

export const calculateSum = (obj: any, field: string): any =>
  obj.map((items: any) => items[field]).reduce((prev: any, curr: any) => prev + curr, 0);

export const useProcessCategoryData = (shoppingData: NewESResponseDataType): any => {
  const data = initialCategoriesData();

  const outputData = useMemo(() => {
    if (shoppingData?.Values && haveKeys([shoppingData.Values])) {
      const { topResults, otherResults } = frequencyMap(shoppingData, numCategories);

      data.labels = topResults.map((x) => x.name);

      topResults.forEach((result) => {
        data.datasets[0].data.push(result.value);
      });

      if (otherResults.length > 0) {
        data.labels.push(othersLabel);
        const sum = calculateSum(otherResults, "value");
        data.datasets[0].data.push(sum);
      }
    }

    return data;
  }, [shoppingData]);

  return { outputData, categoryOptions };
};
