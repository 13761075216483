import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_ACTIVITY_SLEEP, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { defaultGraphHeight, defaultGraphWidth, TITLE_SLEEP_HISTOGRAM } from "../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { SleepHistogramGraphMetadata } from "../../../../helpers/graphsMetadata";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { useProcessSleepHistogram } from "./useProcessSleepHistogram";
import { sleepTypeList, SleepTypeSelector } from "./SleepTypeSelector";

export const SleepHistogramGraph = () => {
  const [sleepType, setSleepType] = useState<string>(sleepTypeList[0]);

  // Graph setup
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultSleepHistogramQueryParams,
    API_ACTIVITY_SLEEP,
    DATA_SERVICE_URL,
  );
  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessSleepHistogram(graphData, sleepType);
  const graphDisplay = <Bar data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  const description = [
    "Your sleep time variability is seen in your sleep frequency distribution of histogram.  If your sleep is highly variable (high sdev), LLIF will look for correlations that cause poor sleep nights.",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_SLEEP_HISTOGRAM}
        title={TITLE_SLEEP_HISTOGRAM}
        calendarProps={graphObject}
        id={SleepHistogramGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_SLEEP_HISTOGRAM} graphDisplay={graphDisplay} calendarProps={graphObject}>
          <Grid container>
            <SleepTypeSelector sleepType={sleepType} setSleepType={setSleepType} />
          </Grid>
        </GraphContainer>
      </GraphHeader>
    </div>
  );
};
