import React from "react";
import { Line } from "react-chartjs-2";

import { Grid, TableCell, TableRow } from "@material-ui/core";
import * as chartjs from "chart.js";
import { ExerciseDetailGraphPropsType } from "../../../../types/graphs/ExerciseDetailGraphPropsType";
import { defaultGraphWidth, defaultGraphHeight } from "../../../../helpers/graphConstants";
import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { unitRounder } from "../../../../helpers/timeHelpers";
import { timestampKey } from "../../../../types/responses/NewESResponseDataType";

export const ExerciseDetail = ({ exerciseDetailData, reducedVersion = false }: ExerciseDetailGraphPropsType) => {
  const dataDistanceAltitude = {
    labels: [] as Array<string>,
    datasets: [
      {
        label: "Distance",
        data: [] as Array<TupleDataType>,
        fill: false,
      },
      {
        label: "Altitude",
        data: [],
        fill: false,
        yAxisID: "y-axis-altitude",
        tension: 0,
      },
    ],
  };

  const optionsDistanceAltitude: chartjs.ChartOptions = {
    tooltips: {
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-distance",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Distance (m)",
          },
          ticks: {
            autoSkip: true,
          },
        },
        {
          id: "y-axis-altitude",
          position: "right",
          scaleLabel: {
            display: true,
            labelString: "Altitude (m)",
          },
          ticks: {
            autoSkip: true,
          },
        },
      ],
      xAxes: [
        {
          type: "time",
          position: "bottom",
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;

  const HR_MAIN_COLOR = "#ee6666";
  const HR_OFF_COLOR = "#FFCCCB";

  const dataHeartRatePace = {
    labels: [] as Array<string>,
    datasets: [
      {
        label: "Heart Rate",
        data: [] as Array<TupleDataType>,
        fill: false,
        borderColor: HR_OFF_COLOR,
        pointBackgroundColor: HR_MAIN_COLOR,
        pointBorderColor: HR_MAIN_COLOR,
      },
      {
        label: "Pace",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-pace",
      },
    ],
  };

  const optionsHeartRatePace = {
    tooltips: {
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-hr",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Heart rate (bpm)",
          },
          ticks: {
            autoSkip: true,
          },
        },
        {
          id: "y-axis-pace",
          position: "right",
          scaleLabel: {
            display: true,
            labelString: "Pace (m/s)",
          },
          ticks: {
            autoSkip: true,
          },
        },
      ],
      xAxes: [
        {
          type: "time",
          position: "bottom",
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
          },
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;

  const setData = (): void => {
    if (exerciseDetailData?.Values && haveKeys([exerciseDetailData.Values])) {
      if (exerciseDetailData.Values?.Lap && Object.keys(exerciseDetailData.Values.Lap).length) {
        // Each ExerciseGraph Obj can have >= 1 'laps'. Each lap only has 1 track obj
        exerciseDetailData.Values.Lap.forEach((currLap) => {
          const lap = currLap.Track[0];

          // Each track object has several properties.
          Object.keys(lap).forEach((key) => {
            for (let i = 0; i < lap[key].length; i++) {
              const val: number = +lap[key][i];
              let sourceData;
              let sourceIdx = 0;

              if (key === "AltitudeMeters") {
                sourceData = dataDistanceAltitude;
                sourceIdx = 1;
              } else if (key === "DistanceMeters") {
                sourceData = dataDistanceAltitude;
              } else if (key === "HeartRateBpm") {
                sourceData = dataHeartRatePace;
              } else {
                sourceData = undefined;
              }

              // Don't push null or zero values
              if (sourceData && val !== null && val !== 0) {
                pushTupleData(sourceData, sourceIdx, {
                  x: new Date(lap[timestampKey][i]).valueOf(),
                  y: +Math.round(+val),
                });
              }
            }
          });

          // Calculate the pace and insert
          calculatePace(dataDistanceAltitude.datasets[0].data, lap[timestampKey]).forEach((pace: TupleDataType) => {
            pushTupleData(dataHeartRatePace, 1, pace);
          });
        });
      }
    }
  };

  const calculatePace = (distances: Array<any>, timestamps: Array<string>): Array<TupleDataType> => {
    const numericTimestamps: Array<number> = timestamps.map((x): number => {
      return new Date(x).valueOf();
    });
    const results = new Array<TupleDataType>();
    distances.slice(0, distances.length - 1).forEach((distance, index) => {
      const pace =
        ((distances[index + 1].y - distance.y) / (numericTimestamps[index + 1] - numericTimestamps[index])) * 1000;
      // Humans dont run faster than 13 m/s, but you can bike up to 68 m/s
      if (pace >= 0 && pace <= 68) results.push({ x: numericTimestamps[index], y: unitRounder(pace) });
    });

    return results;
  };

  setData();
  if (exerciseDetailData) {
    if (reducedVersion) {
      return (
        <Line
          data={dataHeartRatePace}
          options={optionsHeartRatePace}
          width={defaultGraphWidth}
          height={defaultGraphHeight / 2}
        />
      );
    }
    return (
      <>
        <TableRow>
          <TableCell colSpan={5}>
            <Grid container direction="row" alignItems="center" className="justify-content-xs-center">
              {Object.keys(exerciseDetailData.Values?.Summary).map((fieldName) => {
                const value = exerciseDetailData.Values?.Summary[fieldName];
                return (
                  <Grid item key={fieldName} xs={4}>
                    <strong>{fieldName}</strong>: {value || "Unknown"}
                  </Grid>
                );
              })}
            </Grid>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={5}>
            {dataHeartRatePace.datasets[0].data.length || dataHeartRatePace.datasets[1].data.length ? (
              <Line data={dataHeartRatePace} options={optionsHeartRatePace} height={75} />
            ) : (
              <p>No Heart Rate/Pace Data Available</p>
            )}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={5}>
            {dataDistanceAltitude.datasets[0].data.length || dataDistanceAltitude.datasets[1].data.length ? (
              <Line data={dataDistanceAltitude} options={optionsDistanceAltitude} height={75} />
            ) : (
              <p>No Distance/Altitude Data Available</p>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  }
  return null;
};
