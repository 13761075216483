import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { LocationSettingsInterface, defaultLocationValue } from "../../../../types/settings/settings";
import AutocompleteInput from "./PlaceAutocomplete";

interface MapDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settings: LocationSettingsInterface;
  saveLocation: (location: any) => void;
}

const MapDialog = ({ open, setOpen, settings, saveLocation }: MapDialogProps) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    saveLocation(location);
  };
  const [center, setCenter] = useState({
    lat: settings.lat ? settings.lat : 0,
    lng: settings.lng ? settings.lng : 0,
  });

  const [option, setOption] = useState<string>(settings.option);
  const [placeholder, setPlaceholder] = useState<string>("");
  useEffect(() => {
    if (option !== "mailing") {
      if (option === "zip") setPlaceholder("Zip code");
      else setPlaceholder("state, country");
    }
  }, [option]);

  const componentForm = ["street_number", "route", "location", "locality", "country", "postal_code"];

  const [location, setLocation] = useState(defaultLocationValue);

  const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const location: LocationSettingsInterface = { ...defaultLocationValue };
    location.option = option;
    if (option === "zip") {
      location.postal_code = inputValue;
    } else {
      const val = inputValue.split(",");

      /* eslint-disable */
      if (val.length > 1) {
        location.country = val[0];
        location.location = val[1];
      } else location.country = val[0];
    }

    setLocation(location);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setOption(inputValue);
  };

  const fillInAddress = (place: any, lat: number, lng: number) => {
    const getAddressComp = (type: string) => {
      let name = "";
      place.address_components.forEach((value: any) => {
        if (value.types[0] === type) {
          name = value.short_name;
        }
      });
      return name;
    };

    const location: any = {};
    componentForm.forEach((value: string) => {
      const result = getAddressComp(value);
      if (value === "locality" && result === "") {
        location[value] = getAddressComp("administrative_area_level_1");
      } else location[value] = result;
    });
    // eslint-disable-next-line
    location.lat = lat;
    // eslint-disable-next-line
    location.lng = lng;
    location.option = option;
    setLocation(location);
  };

  const processAddress = (place: any) => {
    const placeLat = place.geometry.location.lat();
    const placeLng = place.geometry.location.lng();
    setCenter({
      ...center,
      lat: placeLat,
      lng: placeLng,
    });
    fillInAddress(place, placeLat, placeLng);
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        disableBackdropClick
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enter your location</DialogTitle>
        <DialogContent>
          {option === "mailing" && (
            <div>
              <AutocompleteInput processAddress={processAddress} />
              {center.lat !== 0 && (
                <MapContainer
                  key={JSON.stringify(center.lat)}
                  center={center}
                  style={{ marginTop: "30px" }}
                  zoom={17}
                  scrollWheelZoom={false}
                >
                  <TileLayer url="http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga" />
                  <Marker position={center}>
                    <Popup>Location</Popup>
                  </Marker>
                </MapContainer>
              )}
            </div>
          )}
          {option !== "mailing" && (
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                color="primary"
                label="Location"
                placeholder={placeholder}
                onBlur={blurHandler}
                variant="outlined"
              />
            </div>
          )}
          <FormControl component="fieldset">
            <RadioGroup name="option" value={option} onChange={handleChange} style={{ flexDirection: "row" }}>
              <FormControlLabel value="mailing" control={<Radio />} label="Mailing address" />
              <FormControlLabel value="zip" control={<Radio />} label="Zip code" />
              <FormControlLabel value="country" control={<Radio />} label="State and Country" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={handleSave} color="primary">
            Save location
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MapDialog;
