import React from "react";
import TextField from "@material-ui/core/TextField";
import { usePlacesWidget } from "react-google-autocomplete";

interface Autocomplete {
  processAddress: (place: any) => void;
}

export const Debounce = ({ processAddress }: Autocomplete) => {
  const { ref: materialRef } = usePlacesWidget({
    apiKey: "AIzaSyByG_uFPmwsLxOfh02eB4c6rjPeL0QYfhM",
    onPlaceSelected: (place) => {
      if (place && place.geometry.location) {
        processAddress(place);
      }
    },
    options: {
      types: "address",
    },
  });

  return (
    <div>
      <div style={{ marginTop: "5px" }}>
        <TextField fullWidth color="primary" label="Location" variant="outlined" inputRef={materialRef} />
      </div>
    </div>
  );
};

export default Debounce;
