import React from "react";

import { Bar } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_CONSUME_NETFLIX_ACTIVITY, DATA_SERVICE_URL_OLD } from "../../../../api/apiConstants";
import { initialNetflixBarOptions } from "./netflixOptions";
import { useProcessProfileTotals } from "./useProcessProfileTotals";

import {
  defaultGraphHeight,
  defaultGraphWidth,
  TITLE_NETFLIX_TOTAL_VIEWING_TIME,
} from "../../../../helpers/graphConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { NetflixTotalBarMetadata } from "../../../../helpers/graphsMetadata";

export const NetflixTotalBar = (): JSX.Element => {
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultNetflixViewingQueryParams,
    API_CONSUME_NETFLIX_ACTIVITY,
    DATA_SERVICE_URL_OLD,
  );

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const outputTotalNetflixData = useProcessProfileTotals(graphData);

  const graphDisplay = (
    <Bar
      data={outputTotalNetflixData}
      options={initialNetflixBarOptions(selectedInterval)}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
    />
  );

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_NETFLIX_TOTAL_VIEWING_TIME}
        title={TITLE_NETFLIX_TOTAL_VIEWING_TIME}
        calendarProps={graphObject}
        id={NetflixTotalBarMetadata.id}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_NETFLIX_TOTAL_VIEWING_TIME} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
