import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { defaultGraphWidth, defaultGraphHeight, TITLE_SHOPPING } from "../../../../helpers/graphConstants";
import { useProcessShopData } from "./useProcessShopData";
import { totalsOptions } from "./useShoppingDefaults";
import { API_ACTIVITY_SHOPPING, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { ShoppingSpendGraphMetadata } from "../../../../helpers/graphsMetadata";

export const ShoppingSpendGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultShoppingTotalQueryParams,
    API_ACTIVITY_SHOPPING,
    DATA_SERVICE_URL,
  );
  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;

  const { outputData } = useProcessShopData(graphData);
  const description = [
    "Your shopping spend over time helps to maintain your budget but may also help to identify emotional spending related patterns.",
  ];

  const graphDisplay = (
    <Line
      data={outputData}
      options={totalsOptions(selectedInterval)}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
    />
  );

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_SHOPPING}
        title={TITLE_SHOPPING}
        calendarProps={graphObject}
        id={ShoppingSpendGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_SHOPPING} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
