import React from "react";
import { Box, Grid } from "@material-ui/core";
import _ from "lodash";
import {
  ActivityQueryParams,
  BodyQueryParams,
  EventsQueryParams,
} from "../../../helpers/components/defaultQueryParams";
import {
  API_ACTIVITY_SLEEP,
  API_MEASURE_HEARTRATE,
  API_MEASURE_RESTING_HEART_RATE,
  API_MEASURE_WEIGHT,
  API_TAGS,
} from "../../../api/apiConstants";
import {
  TITLE_EVENTS_TAGS,
  TITLE_HEARTRATE,
  TITLE_RESTING_HEARTRATE,
  TITLE_SLEEP_STATES,
  TITLE_WEIGHT,
  TITLE_TEMPERATURE,
} from "../../../helpers/graphConstants";

import {
  initialSleepData,
  sleepOptions,
  sleepProcessor,
} from "../../../components/graphs/activity/SleepGraph/useProcessSleepData";

import {
  initialWeightFatData,
  initialBMIOptions,
  weightProcessor,
} from "../../../components/graphs/body/WeightBMILineGraph/useProcessWeightBmiData";
import {
  heartrateProcessor,
  initialHRData,
  initialHROptions,
} from "../../../components/graphs/body/HeartRateOxygenLineGraph/useProcessHeartRateOxygenData";

import {
  temperatureProcessor,
  initialTemperatureData,
  initialTemperatureOptions,
} from "../../../components/graphs/location/TemperatureGraph/useProcessTemperatureData";

import { tagsProcessor } from "../../../components/graphs/events/TagGraph/useProcessTagsData";
import { initialRHROptions } from "../../../components/graphs/body/RestingHeartRateLineGraph/restingHeartRateOptions";
import {
  initialRHRData,
  restingHeartrateProcessor,
} from "../../../components/graphs/body/RestingHeartRateLineGraph/useProcessRestingHeartRateData";
import { initialTagData, initialTagOptions } from "../../../components/graphs/events/TagGraph/useTagOptions";
import { MultiGraphWrapper } from "../../../components/customComponents/MultiGraphContainer/MultiGraphWrapper";
import { MultiGraphInputType } from "../../../components/customComponents/MultiGraphContainer/multiGraphTypes";

const heartInsights: MultiGraphInputType = {
  [TITLE_RESTING_HEARTRATE]: {
    active: false,
    processor: restingHeartrateProcessor,
    title: TITLE_RESTING_HEARTRATE,
    endpoint: API_MEASURE_RESTING_HEART_RATE,
    initialData: initialRHRData,
    initialOptions: initialRHROptions,
    initialQueryParams: _.cloneDeep(BodyQueryParams.defaultRestingHeartRateQueryParams),
  },
  [TITLE_HEARTRATE]: {
    active: false,
    processor: heartrateProcessor,
    title: TITLE_HEARTRATE,
    endpoint: API_MEASURE_HEARTRATE,
    initialData: initialHRData,
    initialOptions: initialHROptions,
    initialQueryParams: _.cloneDeep(BodyQueryParams.defaultOxygenQueryParams),
  },

  [TITLE_EVENTS_TAGS]: {
    active: false,
    processor: tagsProcessor,
    title: TITLE_EVENTS_TAGS,
    endpoint: API_TAGS,
    initialData: initialTagData,
    initialOptions: initialTagOptions,
    disabledUntilSelection: true,
    initialQueryParams: _.cloneDeep(EventsQueryParams.defaultTagsQueryParams),
  },
  [TITLE_TEMPERATURE]: {
    active: false,
    processor: temperatureProcessor,
    title: TITLE_TEMPERATURE,
    endpoint: API_MEASURE_HEARTRATE,
    initialData: initialTemperatureData,
    initialOptions: initialTemperatureOptions,
    initialQueryParams: _.cloneDeep(BodyQueryParams.defaultOxygenQueryParams),
  },
};

export const sleepInsights: MultiGraphInputType = {
  [TITLE_SLEEP_STATES]: {
    active: false,
    processor: sleepProcessor,
    title: TITLE_SLEEP_STATES,
    endpoint: API_ACTIVITY_SLEEP,
    initialData: initialSleepData,
    initialOptions: sleepOptions,
    initialQueryParams: _.cloneDeep(ActivityQueryParams.defaultSleepStatesQueryParams),
  },
  [TITLE_RESTING_HEARTRATE]: {
    active: false,
    processor: restingHeartrateProcessor,
    title: TITLE_RESTING_HEARTRATE,
    endpoint: API_MEASURE_RESTING_HEART_RATE,
    initialData: initialRHRData,
    initialOptions: initialRHROptions,
    initialQueryParams: _.cloneDeep(BodyQueryParams.defaultRestingHeartRateQueryParams),
  },
};

export const dummyStressInsights: MultiGraphInputType = {
  [TITLE_WEIGHT]: {
    active: false,
    processor: weightProcessor,
    title: TITLE_WEIGHT,
    endpoint: API_MEASURE_WEIGHT,
    initialData: initialWeightFatData,
    initialOptions: initialBMIOptions,
    initialQueryParams: _.cloneDeep(BodyQueryParams.defaultWeightQueryParams),
  },
  [TITLE_EVENTS_TAGS]: {
    active: false,
    processor: tagsProcessor,
    title: TITLE_EVENTS_TAGS,
    endpoint: API_TAGS,
    initialData: initialTagData,
    initialOptions: initialTagOptions,
    disabledUntilSelection: true,
    initialQueryParams: _.cloneDeep(EventsQueryParams.defaultTagsQueryParams),
  },
  Stress: {
    active: false,
    processor: undefined,
    title: "Stress",
    endpoint: "",
    initialData: undefined,
    initialOptions: undefined,
    disabled: true,
    initialQueryParams: undefined,
  },
};

export const dummyCovidGraph: MultiGraphInputType = {
  [TITLE_RESTING_HEARTRATE]: {
    active: false,
    processor: restingHeartrateProcessor,
    title: TITLE_RESTING_HEARTRATE,
    endpoint: API_MEASURE_RESTING_HEART_RATE,
    initialData: initialRHRData,
    initialOptions: initialRHROptions,
    initialQueryParams: _.cloneDeep(BodyQueryParams.defaultRestingHeartRateQueryParams),
  },
  [TITLE_EVENTS_TAGS]: {
    active: false,
    processor: tagsProcessor,
    title: TITLE_EVENTS_TAGS,
    endpoint: API_TAGS,
    initialData: initialTagData,
    initialOptions: initialTagOptions,
    disabledUntilSelection: true,
    initialQueryParams: _.cloneDeep(EventsQueryParams.defaultTagsQueryParams),
  },
  "VO2 Max": {
    active: false,
    processor: undefined,
    title: "VO2 Max",
    endpoint: "",
    initialData: undefined,
    initialOptions: undefined,
    disabled: true,
    initialQueryParams: undefined,
  },
  Temperature: {
    active: false,
    processor: undefined,
    title: "Temperature",
    endpoint: "",
    initialData: undefined,
    initialOptions: undefined,
    disabled: true,
    initialQueryParams: undefined,
  },
};

export const LearnPage = () => {
  return (
    <div>
      {/* Spacer */}
      <Box m={3} />
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        {/* Row 1 */}
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs>
            <MultiGraphWrapper initialButtons={heartInsights as MultiGraphInputType} groupName="Heart Insights" />
          </Grid>
          <Grid item xs>
            <MultiGraphWrapper initialButtons={sleepInsights as MultiGraphInputType} groupName="Sleep Insights" />
          </Grid>
        </Grid>
        {/* Spacer */}
        <Box m={3} />

        {/* Row 2 */}
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs>
            <MultiGraphWrapper
              initialButtons={dummyStressInsights as MultiGraphInputType}
              groupName="Stress Insights"
            />
          </Grid>
          <Grid item xs>
            <MultiGraphWrapper initialButtons={dummyCovidGraph as MultiGraphInputType} groupName="COVID Insights" />
          </Grid>
        </Grid>
        {/* Spacer */}
        <Box m={3} />
      </Grid>
    </div>
  );
};
