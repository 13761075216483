import { Dict } from "./components/graphHelpers";

/**
 * Split cookies to object properties
 * @returns cookie Object
 */
export const generateCookies = (): Dict => {
  const cookies: Dict = {};
  document.cookie.split(";").forEach((element) => {
    const [key, value] = element.split("=");
    cookies[key.trim()] = value;
  });
  return cookies;
};
