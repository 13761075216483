import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { DefaultQueryParamsType } from "../../../../types/graphs/DefaultQueryParamsType";
import { CalendarRange } from "../GraphCalendar/calendarBodyHelper";

interface CalendarResetButtonProps {
  initialQueryParams: DefaultQueryParamsType | undefined;
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>>;
  setSelectedInterval: React.Dispatch<React.SetStateAction<string>> | undefined;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

export const CalendarResetButton = ({
  initialQueryParams,
  setDateRange,
  setSelectedInterval,
  setRefetch,
}: CalendarResetButtonProps) => {
  return (
    <Tooltip title="Reset View">
      <IconButton
        size="small"
        onClick={(): void => {
          if (initialQueryParams) {
            setDateRange({
              startDate: new Date(initialQueryParams.time_gte),
              endDate: new Date(initialQueryParams.time_lte),
            });
            if (setSelectedInterval) {
              setSelectedInterval(initialQueryParams.interval);
            }
          }
          if (setRefetch) {
            setRefetch(true);
          }
        }}
      >
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  );
};
