import _ from "lodash";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { useProcessStackedBar } from "./useProcessStackedBar";
import { defaultGraphHeight, defaultGraphWidth, TITLE_NETFLIX_BAR_TITLE } from "../../../../helpers/graphConstants";
import { API_CONSUME_NETFLIX_ACTIVITY, DATA_SERVICE_URL_OLD } from "../../../../api/apiConstants";
import { initialStackedBarOptions } from "./netflixOptions";
import { netflixStackedBarIMDBURL } from "../../../../helpers/components/graphHelpers";
import { PROFILE_ALL_STRING } from "./netflixHelper";
import { NetflixProfileSelector } from "./NetflixProfileSelector";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { NetflixTimeStackedBarMetadata } from "../../../../helpers/graphsMetadata";

export const NetflixTimeStackedBar = (): JSX.Element => {
  const [profile, setProfile] = useState<string>(_.cloneDeep(PROFILE_ALL_STRING));
  const [profileList, setProfileList] = useState<string[]>([]);

  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultNetflixViewingQueryParams,
    API_CONSUME_NETFLIX_ACTIVITY,
    DATA_SERVICE_URL_OLD,
  );

  const { graphData, selectedInterval, isLoaded, setIsLoaded, elemRef } = graphObject;

  const outputViewingHistoryData = useProcessStackedBar(graphData, profile, setProfileList, setIsLoaded);
  const graphDisplay = (
    <Bar
      data={outputViewingHistoryData}
      options={initialStackedBarOptions(selectedInterval)}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
      getElementAtEvent={(event): void => {
        const url = netflixStackedBarIMDBURL(event, outputViewingHistoryData, "_datasetIndex");
        if (url) window.open(url);
      }}
    />
  );

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_NETFLIX_BAR_TITLE}
        title={TITLE_NETFLIX_BAR_TITLE}
        calendarProps={graphObject}
        id={NetflixTimeStackedBarMetadata.id}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_NETFLIX_BAR_TITLE} graphDisplay={graphDisplay} calendarProps={graphObject}>
          <Grid container>
            <NetflixProfileSelector profile={profile} setProfile={setProfile} profileList={profileList} />
          </Grid>
        </GraphContainer>
      </GraphHeader>
    </div>
  );
};
