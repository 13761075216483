import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_ACTIVITY_WEB, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import {
  defaultGraphHeight,
  defaultGraphWidth,
  TITLE_ACTIVITY_WEB_HISTOGRAM,
} from "../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { useProcessWebActivityHistogram } from "./useProcessWebActivityHistogram";
import { WebActivityHistogramGraphMetadata } from "../../../../helpers/graphsMetadata";
import { WebActivitySelector } from "./WebActivitySelector";

export const WebActivityHistogram = () => {
  const [selectedActivity, setSelectedActivity] = useState<string>("All");
  const [activityList, setActivityList] = useState<string[]>(["All"]);
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultWebActivityHistogram,
    API_ACTIVITY_WEB,
    DATA_SERVICE_URL,
  );

  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessWebActivityHistogram(graphData, selectedActivity, activityList, setActivityList);

  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;
  const description = [
    "For LLIF, web activity interactions involve consuming web content or applications.  ",
    "The possible web activity actions are searched, clicked, dismissed, liked, listened, navigated, played, read," +
      " downloaded, initiated, installed, managed, received, requested, subscribed, used, viewed, visited, or watched.  ",
    "If you can think of more, please let us know.",
  ];
  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_ACTIVITY_WEB_HISTOGRAM}
        title={TITLE_ACTIVITY_WEB_HISTOGRAM}
        calendarProps={graphObject}
        id={WebActivityHistogramGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_ACTIVITY_WEB_HISTOGRAM} graphDisplay={graphDisplay} calendarProps={graphObject}>
          <Grid container>
            <WebActivitySelector
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActivity}
              activityList={activityList}
            />
          </Grid>
        </GraphContainer>
      </GraphHeader>
    </div>
  );
};
