import { useMemo } from "react";
import * as chartjs from "chart.js";
import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { unitRounder } from "../../../../helpers/timeHelpers";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { useAddSimpleMovingAverage } from "../../../customHooks/useAddSimpleMovingAverage";

const weightDatasetIndex = 0;

export const weightProcessor = (weightFatData: NewESResponseDataType, data: any) => {
  if (weightFatData?.Values && haveKeys([weightFatData.Values])) {
    weightFatData.Values.forEach((weightObj) => {
      if (weightObj.weight) {
        const timestamp = +new Date(weightObj[timestampKey]);
        data.labels.push(timestamp);

        pushTupleData(data, weightDatasetIndex, {
          x: timestamp,
          y: unitRounder(+weightObj.weight),
        });
      }
    });
  }

  return data;
};

// TODO(spk): Proper types
export const useProcessWeightFatData = (weightFatData: NewESResponseDataType, selectedInterval: string): any => {
  // Populate data
  const data = initialWeightFatData();
  const outputData = useMemo(() => {
    return weightProcessor(weightFatData, data);
  }, [weightFatData]);

  const initVal = weightFatData?.Values?.find((val) => val.weight);

  let weightMin = initVal ? +initVal.weight : 0;
  let weightMax = 0;

  if (weightFatData?.Values && haveKeys([weightFatData.Values])) {
    weightFatData.Values.forEach((value) => {
      const { weight } = value;

      if (typeof weight === "number" && weight > 0) {
        weightMin = Math.min(weight, weightMin);
        weightMax = Math.max(weight, weightMax);
      }
    });
  }

  const options = initialBMIOptions(selectedInterval, weightMin, weightMax);
  useAddSimpleMovingAverage(outputData);

  return { outputData, options };
};

export const initialWeightFatData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Weight",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-weight",
        xAxisID: "x-axis",
        type: "line",
      },
    ],
  };
};

export const initialBMIOptions = (selectedInterval: string, min: number, max: number): chartjs.ChartOptions => {
  return {
    tooltips: {
      intersect: false,
      mode: "x",
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-weight",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Weight (lb)",
          },
          ticks: {
            // stepSize: 0.5,
            max: Math.round(max + 20),
            min: Math.round(min - 10),
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          position: "bottom",
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
