import React from "react";
import { Grid, InputLabel, MenuItem, Select } from "@material-ui/core";

const SELECT_PROFILE_STRING = "Number of places to display";

type ProfileSelectorPropsType = {
  numPlaces: number;
  setNumPlaces: React.Dispatch<React.SetStateAction<number>>;
};

export const PlacePieSelector = ({ numPlaces, setNumPlaces }: ProfileSelectorPropsType): JSX.Element => {
  return (
    <Grid container direction="row" alignItems="center" spacing={8}>
      <Grid item>
        <InputLabel id="place-pie-selector-label">{SELECT_PROFILE_STRING}</InputLabel>
        <Select
          id="select-place-pie-amount"
          value={numPlaces}
          onChange={(event) => {
            setNumPlaces(event?.target?.value as number);
          }}
        >
          {/* Generates an array from 5 to 30 incremented by 5 */}
          {[5, 10, 15, 20, 25, 30].map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    </Grid>
  );
};
