// Routes API
export const API_VERSION_1 = "api/v0.1/";
export const API_VERSION_2 = "api/v0.2/";
export const API_VERSION_1_0 = "api/v1.0/";

export const FILE_SERVICE_URL = process.env.REACT_APP_FILE_SERVICE_URL + API_VERSION_2;
export const NOTIFICATION_SERVICE_URL = process.env.REACT_APP_NOTIFICATION_SERVICE_URL + API_VERSION_2;
export const DATA_SERVICE_URL = process.env.REACT_APP_DATA_SERVICE_URL + API_VERSION_2;
export const DATA_SERVICE_URL_OLD = process.env.REACT_APP_DATA_SERVICE_URL + API_VERSION_1;
export const USER_SERVICE_URL = process.env.REACT_APP_USER_SERVICE_URL + API_VERSION_2;
export const DATA_SERVICE_1_0 = process.env.REACT_APP_DATA_SERVICE_URL + API_VERSION_1_0;

// Events
export const API_USER_LOGS = "user/logs/";
export const API_UPLOAD_STATES = "user/upload_states/";
export const API_UPLOAD_EVENTS = "event/";

// Diary
export const API_TAGS = "diary/events/";

// Measure
export const API_MEASURE_WEIGHT = "measure/weight_and_body_fat/";
export const API_MEASURE_HEARTRATE = "measure/heart_rate/";
export const API_MEASURE_RESTING_HEART_RATE = "measure/resting_heart_rate/";
export const API_MEASURE_FITBIT_OXYGEN = "measure/oxygen/";
export const API_BODY_MENSTRUAL_CYCLES = "measure/fitbit_female_cycles/";
export const API_BODY_MENSTRUAL_SYMPTOMS = "measure/fitbit_female_symptoms/";

// Weather
export const API_WEATHER = "environment/weather/";
export const API_POLLEN = "environment/pollen/";

// Activity
export const API_ACTIVITY_MOVEMENT = "location/movement/";
export const API_ACTIVITY_SHOPPING = "activity/shopping_activity/";
export const API_ACTIVITY_SLEEP = "activity/sleep/";
export const API_ACTIVITY_STEPS = "activity/steps/";
export const API_ACTIVITY_INTERACT_FACEBOOK_SOCIAL = "activity/interact_social_totals_in_time/";
export const API_ACTIVITY_WEB = "activity/web_activity/";

// ExerciseGraph
export const API_EXERCISE_LIST = "exercise/exercise_list/";
export const API_EXERCISE_DETAIL = "exercise/exercise_detail/";
export const API_LATEST_EXERCISE_DETAIL = "exercise/latest_exercise_detail/";

// Location
export const API_LOCATION_PLACE = "location/location_place/";
export const API_LOCATION_ENVIRONMENT_AIR = "environment/air_quality/";

// Consume
export const API_CONSUME_YOUTUBE_COUNTS = "consume/youtube_activity_counts/";
export const API_CONSUME_NETFLIX_ACTIVITY = "consume/netflix_viewing_activity/";

// Auth
export const API_GET_LOGIN_URL = "auth/get_login_auth_url/";
export const API_GET_API_URL = "api_auth/get_api_auth_url/";
export const API_CONNECT_ACCOUNT = "api_auth/grant_api_access_by_auth_code/";
export const API_REFRESH_ACCESS_TOKEN = "auth/refresh_access_token/";
export const API_REGISTER_USER_BY_CODE = "auth/register_or_login_user_by_code/";
export const API_LOGOUT = "auth/logout/";
export const API_REFRESH = "auth/refresh/";
export const API_LOGOUT_EVERYWHERE = "user/sign_out_from_all/";
export const API_USER_PROFILE = "user/profile/";
export const API_GET_LOGIN_PROVIDERS = "user/get_login_providers/";
export const API_REMOVE_LOGIN_PROVIDERS = "user/unlink_login_provider/";

// Upload your data
export const API_SUPPORTED_PROVIDERS = "supported_data_providers/";
export const API_UPLOAD = "upload_provider_file/";

// Delete your data
export const API_DELETED_ALL_PROVIDERS_DATA = "/delete_all_user_data/";

// Routes FE
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const DOMAIN_URL = CLIENT_URL?.split(":")[0];
export const DASHBOARD = "live";
export const LOGIN = "login";
export const SETTING_DATA_PROVIDERS = "settings/data-providers";

// Setting
export const SETTING_SAVE = "user/settings/save/";

// Token constants
export const API_ACCESS_TOKEN = "api_access_token";
export const API_ACCESS_TOKEN_HEADER = "api-access-token";
export const API_EXPIRATION_TIME = "exp";

// Search
export const API_SEARCH_ENDPOINT = "search/basic/";

// Data Link
export const API_GET_SCOPES_ENDPOINT = "api_auth/get_provider_scopes/";
export const API_GET_API_AUTH_URL = "api_auth/get_api_auth_url/";
export const API_GET_GRANT_ACCESS_BY_AUTH_CODE = "api_auth/grant_api_access_by_auth_code/";
