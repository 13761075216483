import { useMemo } from "react";
import { ObjectType } from "../../types/graphs/ObjectType";
import { simpleMovingAverage } from "../../helpers/components/graphHelpers";
import { TupleDataType } from "../../types/graphs/lineGraphs/TupleDataType";

type DatasetDefinitionType = {
  type: string;
  label: string;
  data: TupleDataType[];
  fill: boolean;
  order: number;
  yAxisID: string;
  xAxisID: string;
};

export const averageSuffix = "Pt Avg";

const nDayAvgDefinition = (numPoints: number, datasetName: string, yAxisId: string): DatasetDefinitionType => {
  return {
    type: "line",
    label: `${datasetName} ${numPoints} ${averageSuffix}`,
    data: [] as Array<TupleDataType>,
    fill: false,
    order: -1,
    yAxisID: yAxisId,
    xAxisID: "x-axis",
  };
};

export const totalNDayAvgDefinition = (
  numPoints: number,
  datasetName: string,
  yAxisId: string,
): DatasetDefinitionType => {
  return {
    type: "line",
    label: `${datasetName} Total ${numPoints} ${averageSuffix}`,
    data: [] as Array<TupleDataType>,
    fill: false,
    order: -1,
    yAxisID: yAxisId,
    xAxisID: "x-axis",
  };
};

const addTotalDataset = (
  data: { labels: number[]; datasets: ObjectType[] },
  datasetName: string,
  movingAverageDataPoints: number,
  yAxisId: string,
  ignoreNullZero: boolean,
): DatasetDefinitionType => {
  const combinedDataset = totalNDayAvgDefinition(movingAverageDataPoints, datasetName, yAxisId);
  const combinedDataArr: TupleDataType[] = [];

  const { datasets } = data;

  datasets.forEach((dataset) => {
    dataset.data.forEach((datasetData: TupleDataType, dataIndex: number) => {
      if (combinedDataArr[dataIndex]) {
        combinedDataArr[dataIndex].y += datasetData.y;
      } else {
        combinedDataArr[dataIndex] = { x: datasetData.x, y: datasetData.y };
      }
    });
  });

  combinedDataArr.sort((a, b) => (a.x > b.x ? 1 : -1));
  combinedDataset.data = simpleMovingAverage(combinedDataArr, movingAverageDataPoints, ignoreNullZero);

  return combinedDataset;
};

/**
 * Create a list of datasets containing the moving average for each dataset
 * @param data Base data object
 * @param movingAverageDataPoints
 * @param yAxisId
 * @param ignoreNullZero
 */
const addIndividualDatasets = (
  data: { labels: number[]; datasets: ObjectType[] },
  movingAverageDataPoints: number,
  yAxisId: string,
  ignoreNullZero: boolean,
): DatasetDefinitionType[] => {
  const movingAverageDatasets: DatasetDefinitionType[] = [];

  data.datasets.forEach((dataset) => {
    const movingAverage = simpleMovingAverage(dataset.data, movingAverageDataPoints, ignoreNullZero);
    if (movingAverage?.length) {
      const movingAverageDataset = nDayAvgDefinition(movingAverageDataPoints, dataset.label, yAxisId);
      movingAverageDataset.data = movingAverage;

      movingAverageDatasets.push(movingAverageDataset);
    }
  });

  const combinedData: TupleDataType[] = [];
  movingAverageDatasets.forEach((dataset, i) => {
    dataset.data.forEach((val: TupleDataType, index) => {
      if (i === 0) {
        combinedData.push({ x: val.x, y: +val?.y });
      } else {
        combinedData[index].y += +val?.y;
      }
    });
  });

  return movingAverageDatasets;
};

export const useAddMultiDatasetMovingAverage = (
  data: { labels: number[]; datasets: ObjectType[] },
  datasetName: string,
  yAxisId: string,
  showIndividualDatasets = false,
  showTotalDataset = true,
  ignoreNullZero = true,
  movingAverageScaleFactor = 5,
) => {
  useMemo(() => {
    // ensure the datasets have been populated
    if (data.datasets.length) {
      // Since all datasets data array should be the same size, we can take thr first dataset's length for reference
      const totalDataPoints: number = data.datasets[0].data.length;

      // Scale it by a factor of 5. I,e, if 1 month selected, use 6 point average
      const movingAverageDataPoints = Math.floor(totalDataPoints / movingAverageScaleFactor);

      if (movingAverageDataPoints > 1) {
        let movingAverageDatasets: DatasetDefinitionType[] | undefined;
        let totalDataset: DatasetDefinitionType | undefined;

        // If graph wants each individual dataset displayed (example, light sleep, rem sleep, rem sleep, deep sleep)
        if (showIndividualDatasets) {
          movingAverageDatasets = addIndividualDatasets(data, movingAverageDataPoints, yAxisId, ignoreNullZero);
        }
        // if graph only wants the total dataset displayed (example, netflix stacked time bar)
        if (showTotalDataset) {
          totalDataset = addTotalDataset(data, datasetName, movingAverageDataPoints, yAxisId, ignoreNullZero);
        }

        // After doing both calculations on the `data` object, we can push to it
        /*
        The order here is important. We want to FIRST create the datasets for both total and individual, THEN push
         them to the data object.

         If we first create individual, then push to data, then create total, then push to data, we will end up with
          weird accumulations
         */
        if (movingAverageDatasets) {
          movingAverageDatasets.forEach((dataset) => {
            data.datasets.push(dataset);
          });
        }

        if (totalDataset) {
          data.datasets.push(totalDataset);
        }
      }
    }
  }, [data]);

  return data;
};
