import React, { useCallback, useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { AppleLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import OauthPopup from "react-oauth-popup";
import { useSnackbar } from "notistack";
import { getAction, postAction } from "../../../helpers/actions/actions";
import {
  API_GET_LOGIN_PROVIDERS,
  API_GET_LOGIN_URL,
  API_REGISTER_USER_BY_CODE,
  API_REMOVE_LOGIN_PROVIDERS,
  USER_SERVICE_URL,
} from "../../../api/apiConstants";
import { Google } from "../../login/providersConfig";
import LinkingAccountModalInfo from "../../AccountLinking/LinkingAccountsModalInfo";
import { ConnectResponseType, ErrorResponseType, LoginResponseType } from "../../../types/responses/LoginResponseType";
import UsersTable from "../../AccountLinking/AccountTable";
import { LoginGoogleProvidersResponse } from "../../../types/responses/LinkingResponseType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(5),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    center: { textAlign: "center" },
  }),
);
const ConnectedAccountsGroup = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [authUrl, setUrl] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("Getting your account ready!");
  const [connectedAccounts, setConnectedAccounts] = useState<Array<LoginGoogleProvidersResponse>>([]);
  const [isDone, setIsDone] = useState(false);

  const handleGoogleLogin = useCallback(async () => {
    const qParams = {
      provider: Google.PROVIDER,
      redirect_uri: Google.LINK_REDIRECT_URI,
      scope: Google.SCOPE,
      state: Google.STATE,
      client: Google.CLIENT,
    };

    const response = await getAction(API_GET_LOGIN_URL, undefined, USER_SERVICE_URL, qParams);
    if (response?.message) {
      setUrl(response.message);
    }
  }, []);

  const handleAppleLogin = () => {
    enqueueSnackbar("Not available yet", {
      variant: "warning",
    });
  };

  const getConnectedAccounts = async () => {
    const connectedAccount = await getAction(API_GET_LOGIN_PROVIDERS, undefined, USER_SERVICE_URL);
    if (connectedAccount?.user) {
      const mergedResults = connectedAccount.login_google.concat(connectedAccount.login_apple);
      setConnectedAccounts(mergedResults);
    }
  };

  const resetValues = () => {
    setErrorMessage("");
    setModalMessage("");
    setIsError(false);
    setIsDone(false);
  };

  const unlinkAccount = (id: string, provider: string) => {
    const data = {
      provider_account_id: id,
      provider,
    };
    postAction(API_REMOVE_LOGIN_PROVIDERS, USER_SERVICE_URL, undefined, data)
      .then((response: ConnectResponseType | ErrorResponseType) => {
        // eslint-disable-next-line no-extra-parens
        if ((response as ConnectResponseType).message) {
          // eslint-disable-next-line no-extra-parens
          enqueueSnackbar((response as ConnectResponseType).message, {
            variant: "success",
          });
          getConnectedAccounts();
          // eslint-disable-next-line no-extra-parens
        } else if ((response as ErrorResponseType).detail) {
          // eslint-disable-next-line no-extra-parens
          enqueueSnackbar(`Error: ${(response as ErrorResponseType).detail}`, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getConnectedAccounts();
    if (!authUrl) handleGoogleLogin();
  }, []);

  const registerUserFromCode = (queryParameters: any, data: any) => {
    resetValues();
    postAction(API_REGISTER_USER_BY_CODE, USER_SERVICE_URL, queryParameters, data)
      .then((response: LoginResponseType | ConnectResponseType) => {
        setModalMessage("Almost done!");
        // Check if the response was successful
        // eslint-disable-next-line no-extra-parens
        if ((response as LoginResponseType).user) {
          setTimeout(() => {
            setIsDone(true);
            setModalMessage("Done!");
          }, 1200);
          getConnectedAccounts();
          // eslint-disable-next-line no-extra-parens
        } else if ((response as ConnectResponseType).message) {
          const errorResponse = response as ConnectResponseType;
          // Currently only happens if a user not on our whitelist is selected
          setErrorMessage(errorResponse.message);
          setIsError(true);
          getConnectedAccounts();
        }
      })
      .catch((error) => {
        setErrorMessage(JSON.stringify(error));
        setIsError(true);
      });
  };

  const ConnectedAccounts = () => {
    return (
      <>
        <Grid container spacing={1}>
          {connectedAccounts && <UsersTable users={connectedAccounts} unlinkAccount={unlinkAccount} />}
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "25px" }}>
          <Grid item xs={12} sm={6}>
            <AppleLoginButton onClick={() => handleAppleLogin()} align="center">
              Connect new Apple account
            </AppleLoginButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <OauthPopup
              url={authUrl}
              onCode={onCode}
              onClose={() => {
                // Empty
              }}
              title="Google login"
              width={500}
              height={500}
            >
              <GoogleLoginButton align="center">Connect new Google account</GoogleLoginButton>
            </OauthPopup>
          </Grid>
        </Grid>
      </>
    );
  };

  const onCode = (code: string) => {
    const queryParameters = {
      provider: Google.PROVIDER,
      should_link_account: true,
    };

    const data = {
      client: Google.CLIENT,
      code,
      redirect_uri: Google.LINK_REDIRECT_URI,
      state: "",
    };
    setIsModalOpen(true);
    registerUserFromCode(queryParameters, data);
  };

  return (
    <>
      <Paper elevation={2} className={classes.paper}>
        <div>
          <Box fontSize="h5.fontSize" fontWeight={600} mt={3} mb={1.5}>
            Linked accounts
          </Box>
          <Box m={3} />
          <ConnectedAccounts />

          {isModalOpen && (
            <LinkingAccountModalInfo
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              isError={isError}
              errorMessage={errorMessage}
              modalMessage={modalMessage}
              isDone={isDone}
            />
          )}

          <Box m={3} />
          <Divider />
        </div>

        <Box m={8} />
      </Paper>
    </>
  );
};

export default ConnectedAccountsGroup;
