import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { unitRounder } from "../../../../helpers/timeHelpers";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { initialElementsData } from "./useInitialAirQualityData";
import { initialAirElementsOptions } from "./useInitialAirQualityOptions";

export const useProcessElementsData = (airElementsData: NewESResponseDataType): any => {
  const outputAirElementsOptions = initialAirElementsOptions();
  const sourceData = initialElementsData();

  if (airElementsData?.Values && haveKeys([airElementsData.Values])) {
    // Populate labels
    sourceData.labels = airElementsData?.Values?.map((x) => Number(+new Date(x[timestampKey])));

    // Populate data
    airElementsData.Values.forEach((airObject) => {
      Object.keys(airObject).forEach((key) => {
        if (key === "NO2" || key === "OZONE" || key === "CO" || key === "SO2") {
          pushTupleData(sourceData, stringToDatasetIndex(sourceData, key), {
            x: Number(+new Date(airObject[timestampKey])),
            y: unitRounder(airObject[key]),
          });
        }
      });
    });
  }

  return {
    data: sourceData,
    options: outputAirElementsOptions,
  };
};

/**
 * Given a dataset and data, find the index of where to insert it.
 * Dataset can track multiple types of data. Ex: No2, O3, CO, SO2.
 * This function ensures that the data goes into the correct one.
 *
 * @param datasets Dataset for the air data.
 * @param dataType The type of air quality data
 */
export const stringToDatasetIndex = (datasets: { datasets: any[] }, dataType: string): number => {
  return datasets.datasets.findIndex(
    // Find index where label is the word before the "_". prop "o2_value", should be added to dataset label "O2"
    (entry: { label: string }) => entry.label === dataType.toUpperCase(),
  );
};
