import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { initialWindData, initialWindOptions } from "./windHelper";
import { haveKeys } from "../../../../helpers/components/graphHelpers";

export const useProcessWindData = (graphData: NewESResponseDataType) => {
  const data = initialWindData();
  if (graphData.Values && haveKeys(graphData.Values)) {
    data.labels = graphData.Values.map((val) => +new Date(val[timestampKey]));
    data.datasets[0].data = graphData.Values.filter((val) => val.windSpeed).map((val) => {
      return { x: +new Date(val[timestampKey]), y: val.windSpeed.toFixed(2), r: 5, b: val.windBearing.toFixed(2) };
    });
    data.datasets[1].data = graphData.Values.map((val) => {
      return {
        x: +new Date(val[timestampKey]),
        y: val.windGust?.toFixed(2) || null,
        r: 5,
        b: val.windBearing?.toFixed(2),
      };
    });
  }

  return { data, options: initialWindOptions() };
};
