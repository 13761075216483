import React, { useContext } from "react";
import { DebounceInput } from "react-debounce-input";
import { AppBar, IconButton, Tab, Tabs, Toolbar, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory, useLocation } from "react-router-dom";
import { useAppBarStyles } from "./navigationStyles";
import { SearchContext, SideNavigationContext } from "../../../helpers/context/contextVariables";
import { UserDetail } from "../../../containers/componentContainers/navBarUserIcon/UserDetail";
import { topNavTabs } from "../../../helpers/navigationConstants";

import { TopNavSearchBox } from "./TopNavSearchBox";

interface TopNavigationProps {
  handleDrawer: () => void;
}

export const TopNavigation = ({ handleDrawer }: TopNavigationProps) => {
  // STATE VARIABLES
  const [value, setValue] = React.useState(0);

  // CONTEXT VARIABLES
  const { searchValue, setSearchValue } = useContext(SearchContext);
  const { isSideNavOpen } = useContext(SideNavigationContext);

  // CUSTOM HOOKS
  const history = useHistory();
  const location = useLocation();
  const classes = useAppBarStyles();

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleSearchValueChange = (event: { target: { value: any } }) => {
    setSearchValue(event.target.value || "");
  };

  const searchBar = (
    <DebounceInput
      element={TopNavSearchBox}
      minLength={1}
      debounceTimeout={400}
      value={searchValue}
      onChange={handleSearchValueChange}
    />
  );
  const tabsPrefix = (
    <>
      {(!isSideNavOpen && location.pathname.includes("live")) ||
      location.pathname.includes("find") ||
      location.pathname.includes("settings") ? (
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handleDrawer}>
          <MenuIcon />
        </IconButton>
      ) : (
        <></>
      )}
    </>
  );
  const mediumPx = isSideNavOpen ? 1500 : 1400;
  const smallPx = isSideNavOpen ? 1111 : 1000;

  const isMedium = useMediaQuery(`(max-width:${mediumPx}px)`, { noSsr: true });
  const isSmall = useMediaQuery(`(max-width:${smallPx}px)`, { noSsr: true });

  const tabsStyle = clsx({
    [classes.tabLarge]: !isMedium && !isSmall,
    [classes.tabMed]: isMedium && !isSmall,
    [classes.tabSmall]: isMedium && isSmall,
  });
  /**
   * These are the "main" navigation tabs at the top of the screen
   * Learn more about the passed props here:
   * https://material-ui.com/components/tabs/
   */
  const tabs = (
    <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" indicatorColor="secondary">
      {Object.values(topNavTabs).map((tab) => (
        <Tab
          key={tab.route}
          label={tab.label}
          disabled={tab.disabled}
          icon={tab.icon}
          className={tabsStyle}
          style={{ textTransform: "none" }}
          onClick={(): void => {
            history.push(tab.route);
          }}
        />
      ))}
    </Tabs>
  );

  return (
    <div className={classes.grow}>
      {/* TOP NAVIGATION APP BAR */}
      <AppBar
        position="fixed"
        // EXPAND/SHRINK CONTENT SIZE WITH SIDE NAV
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isSideNavOpen,
        })}
      >
        <Toolbar>
          {/* ITEMS THAT ARE LISTED BEFORE NAVIGATION TABS */}
          {tabsPrefix}
          {/* NAVIGATION TABS */}
          {tabs}
          {/* SEARCH BAR */}
          <div className={classes.grow} />
          {!isSmall && searchBar}
          <UserDetail />
        </Toolbar>
      </AppBar>
    </div>
  );
};
