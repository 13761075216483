import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { BodyQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_MEASURE_RESTING_HEART_RATE, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { RHRProcessedDataType } from "../RestingHeartRateLineGraph/restingHeartRateTypes";
import {
  defaultGraphHeight,
  defaultGraphWidth,
  TITLE_RESTING_HEARTRATE_HISTOGRAM,
} from "../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { RestingHeartRateHistogramGraphMetadata } from "../../../../helpers/graphsMetadata";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { useProcessRHRHistogram } from "./useProcessRHRHistogram";

export const RestingHeartRateHistogramGraph = () => {
  const graphObject = useGraphSetup(
    BodyQueryParams.defaultRestingHeartRateHistogramQueryParams,
    API_MEASURE_RESTING_HEART_RATE,
    DATA_SERVICE_URL,
  );
  const { graphData, isLoaded, elemRef } = graphObject;

  const { data, options }: RHRProcessedDataType = useProcessRHRHistogram(graphData);
  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  const description = [
    "The basal or resting heart rate is defined as the heart rate when a person is awake, in a neutrally temperate environment, and has not been subject to any recent exertion or stimulation, such as stress or surprise.  ",
  ];
  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_RESTING_HEARTRATE_HISTOGRAM}
        title={TITLE_RESTING_HEARTRATE_HISTOGRAM}
        calendarProps={graphObject}
        id={RestingHeartRateHistogramGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer
          id={TITLE_RESTING_HEARTRATE_HISTOGRAM}
          graphDisplay={graphDisplay}
          calendarProps={graphObject}
        />
      </GraphHeader>
    </div>
  );
};
