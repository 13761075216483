import { useMemo } from "react";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import {
  YoutubeDataOutputType,
  YoutubeOptionsType,
  YoutubeOutputType,
} from "../../../../types/graphs/lineGraphs/YoutubeCountDataType";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";

export const useProcessYoutubeData = (
  youtubeData: NewESResponseDataType,
  selectedInterval: string,
): YoutubeOutputType => {
  const SEARCH_INDEX = 0;
  const VIEW_COUNT = 1;
  const LIKE_COUNT = 2;

  const outputOptions = initialOptions(selectedInterval);

  const outputData = useMemo(() => {
    const data = initialData();

    if (youtubeData?.Values && haveKeys([youtubeData?.Values])) {
      youtubeData.Values.forEach((youtubeDataObject) => {
        const timestamp = +new Date(youtubeDataObject[timestampKey]);

        data.labels.push(+timestamp);

        data.datasets[SEARCH_INDEX].data.push({ x: timestamp, y: +youtubeDataObject.search_count });
        data.datasets[VIEW_COUNT].data.push({ x: timestamp, y: +youtubeDataObject.view_count });
        data.datasets[LIKE_COUNT].data.push({ x: timestamp, y: +youtubeDataObject.like_count });
      });
    }

    return data;
  }, [youtubeData]);

  return { outputData, outputOptions };
};

export const initialData = (): YoutubeDataOutputType => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Search",
        data: [] as Array<{ x: number; y: number }>,
        backgroundColor: "#000072",
        yAxisID: "y-axis",
      },
      {
        label: "View",
        data: [] as Array<{ x: number; y: number }>,
        backgroundColor: "#0000cd",
        yAxisID: "y-axis",
      },
      {
        label: "Like",
        data: [] as Array<{ x: number; y: number }>,
        yAxisID: "y-axis",
      },
    ],
  };
};

export const initialOptions = (selectedInterval: string): YoutubeOptionsType => {
  return {
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label(tooltipItem: any, data: any): string {
          const index = tooltipItem.datasetIndex;
          return `YouTube - ${data.datasets[index].label}: ${tooltipItem.yLabel}`;
        },
      },
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          offset: true,
          type: "time",
          ticks: {
            source: "labels",
            autoSkip: true,
          },
          time: {
            displayFormats: { hour: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: selectedIntervalGraphXAxisUnit[selectedInterval],
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          id: "y-axis",
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: "Amount",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  };
};
