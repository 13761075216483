import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  createStyles,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { Calendar } from "./Calendar";
import { Dict } from "../../../../helpers/components/graphHelpers";
import { CalendarRange } from "./calendarBodyHelper";
import { useWebsiteTheme, WebsiteTheme } from "../../../../types/general/UserSettingsContext";

interface CalendarModalProps {
  id: string;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  intervals: Dict;
  selectedInterval: string | undefined;
  setSelectedInterval: React.Dispatch<React.SetStateAction<string>> | undefined;
  dateRange: CalendarRange;
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>>;
  graphDisplay: JSX.Element | undefined;
}

export const CalendarModal = ({
  id,
  isOpen,
  setOpen,
  intervals,
  selectedInterval,
  setSelectedInterval,
  dateRange,
  setDateRange,
  graphDisplay,
}: CalendarModalProps) => {
  const classes = useStyles();
  const theme = useWebsiteTheme();

  useEffect(() => {
    if (theme === WebsiteTheme.Dark) {
      import("./CalendarStyles/darkTheme.css");
    } else {
      import("./CalendarStyles/lightTheme.css");
    }
  }, [theme]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    if (setSelectedInterval) setSelectedInterval(event.target.value as string);
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <FullscreenExitIcon />
              </IconButton>
              <Typography variant="h6" className={classes.text}>
                {id}
              </Typography>
              <Typography variant="body1" className={classes.centeredText}>
                Date Range:
                {` ${dateRange.startDate?.toLocaleDateString()} - ${dateRange.endDate?.toLocaleDateString()}`}
              </Typography>
              <div className={classes.centeredText}>
                <FormControl disabled={!setSelectedInterval}>
                  <FormControlLabel
                    value="interval"
                    label="Interval: "
                    labelPlacement="start"
                    control={
                      <Select value={selectedInterval} onChange={handleChange} className={classes.select}>
                        {Object.keys(intervals).map((keyInterval) => {
                          return (
                            <MenuItem value={keyInterval} key={keyInterval}>
                              {intervals[keyInterval]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                  />
                </FormControl>
              </div>
            </Toolbar>
          </AppBar>
        </Grid>
        {/* SPACE BETWEEN TOP NAV BAR AND CONTENT */}
        <div className={classes.drawerHeader} />
        <Grid item xs={12}>
          <Box m={2} />
        </Grid>
        <Grid item xs={12} md={10} lg={8}>
          <Paper className={classes.paper} variant="outlined" square>
            {graphDisplay}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box m={2} />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.center}>
            <Calendar setDateRange={setDateRange} isOpen={isOpen} dateRange={dateRange} />
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    center: {
      textAlign: "center",
    },
    appBar: {
      position: "fixed",
    },
    text: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    centeredText: {
      marginLeft: theme.spacing(2),
      flex: 1,
      textAlign: "center",
    },
    select: {
      marginLeft: theme.spacing(1),
      color: "white",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
  }),
);
