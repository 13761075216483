import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * Performs a axios get request to specified API endpoint
 * @param endpointRoute Endpoint string URI
 * @param queryParameters Optional Object with keys / values to be passed as query parameters
 * @param config Optional AxiosRequestConfig object
 * @param baseURL Specify the base url to make a request
 * @returns AxiosResponse promise
 */
export const apiGet = (
  endpointRoute: string,
  queryParameters: unknown,
  config: AxiosRequestConfig,
  baseURL: string,
): Promise<AxiosResponse> => {
  try {
    // TODO (jaja): Axios should be refactored to singleton
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    return axios.request({
      method: "get",
      baseURL,
      url: endpointRoute,
      params: queryParameters,
      withCredentials: true,
      ...config,
    });
  } catch (err) {
    throw new Error("Unable to fetch data from API.");
  }
};

/**
 * Performs a axios post request to specified API endpoint
 * @param endpointRoute Endpoint string URI
 * @param queryParameters Optional Object with keys / values to be passed as query parameters
 * @param data Optional Object with keys / values to be passed as post data
 * @param config Optional AxiosRequestConfig object
 * @param baseURL
 * @returns AxiosResponse promise
 */
export const apiPost = (
  endpointRoute: string,
  queryParameters: unknown,
  data: unknown,
  config: AxiosRequestConfig = {},
  baseURL: string,
): Promise<AxiosResponse> => {
  try {
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    return axios.request({
      method: "post",
      baseURL,
      url: endpointRoute,
      params: queryParameters,
      data,
      withCredentials: true,
      ...config,
    });
  } catch (err) {
    throw new Error("Unable to post data to API.");
  }
};

/**
 * Performs a axios delete request to specified API endpoint
 * @param endpointRoute Endpoint string URI
 * @param queryParameters Optional Object with keys / values to be passed as query parameters
 * @param data Optional Object with keys / values to be passed as post data
 * @param config Optional AxiosRequestConfig object
 * @param baseURL
 * @returns AxiosResponse promise
 */
export const apiDelete = (
  endpointRoute: string,
  queryParameters: unknown,
  data: unknown,
  config: AxiosRequestConfig = {},
  baseURL: string,
): Promise<AxiosResponse> => {
  try {
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    return axios.request({
      method: "delete",
      baseURL,
      url: endpointRoute,
      params: queryParameters,
      data,
      withCredentials: true,
      ...config,
    });
  } catch (err) {
    throw new Error("Unable to finish delete request to API.");
  }
};
