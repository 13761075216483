import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { defaultGraphHeight, defaultGraphWidth, TITLE_AIR_ELEMENTS } from "../../../../helpers/graphConstants";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_LOCATION_ENVIRONMENT_AIR, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { AirElementsMetaData } from "../../../../helpers/graphsMetadata";
import { useProcessElementsData } from "./useProcessElementsData";

export const ElementsGraphs = (): JSX.Element => {
  const graphObject = useGraphSetup(
    LocationQueryParams.defaultAirQueryParams,
    API_LOCATION_ENVIRONMENT_AIR,
    DATA_SERVICE_URL,
  );

  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessElementsData(graphData);
  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  // TODO: Link https://www.epa.gov/no2-pollution
  const description = [
    " Nitrogen dioxide (NO2), sulfur dioxide (SO2), carbon monoxide, ozone are important ambient air pollutants. The EPA has identified six pollutants as “criteria” air pollutants because it regulates them by developing human health-based and/or environmentally-based criteria (science-based guidelines) for setting permissible levels. These six pollutants are carbon monoxide, lead, nitrogen oxides, ground-level ozone, particle pollution (often referred to as particulate matter), and sulfur oxides.",
  ];
  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_AIR_ELEMENTS}
        title={TITLE_AIR_ELEMENTS}
        calendarProps={graphObject}
        id={AirElementsMetaData.id}
        descriptio={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_AIR_ELEMENTS} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
