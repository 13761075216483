import React, { useMemo } from "react";
import _ from "lodash";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { useHistogramGenerator } from "../../../customHooks/useHistogramGenerator";
import { initialHistogramOptions, WEB_ACTIVITY_AXIS_LABEL } from "./webActivityHelper";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { haveKeys } from "../../../../helpers/components/graphHelpers";

export const useProcessWebActivityHistogram = (
  graphData: NewESResponseDataType,
  selectedActivity: string,
  activityList: string[],
  setActivityList: React.Dispatch<React.SetStateAction<string[]>>,
): any => {
  const initialValues = {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "bar",
        label: "Activity",
        data: [] as Array<number>,
        fill: false,
        yAxisID: WEB_ACTIVITY_AXIS_LABEL,
        xAxisID: "x-axis",
      },
    ] as Array<any>,
  };

  type activityType = { name: string; count: number };

  let allActivities = [];
  if (graphData?.Values && haveKeys(graphData.Values)) {
    // List of ALL activities, may contain repeats
    allActivities = graphData.Values.filter((val) => val?.activities?.length).map((val) =>
      val.activities.map((activity: ObjectType) => activity.name),
    );
  }

  const uniqueActivities = useUniqueActivityList(allActivities);
  // Save to state if it's not the same as current state
  if (!_.isEqual(uniqueActivities, activityList)) {
    setActivityList(uniqueActivities);
  }

  let activities: number[] = [];
  // If all is selected, do a full sum. Otherwise, only sum the selected activity
  if (graphData?.Values && haveKeys(graphData.Values)) {
    if (selectedActivity === "All") {
      activities = graphData?.Values?.filter((obj: any) => obj.activities.length).map((obj) => {
        return obj.activities.reduce((a: any, b: activityType) => {
          return a + b.count;
        }, 0);
      });
    } else {
      activities = graphData?.Values?.filter((obj: any) => obj.activities.length).map((obj) =>
        obj.activities
          .filter((activity: activityType) => activity.name === selectedActivity)
          .reduce((a: number, b: activityType) => {
            return a + b.count;
          }, 0),
      );
    }
    // Filter out zeroes since they dont make sense
    activities = activities.filter((num: number) => num !== 0);
  }

  const { buckets, largestBucket, min, max, stepBucketSize } = useHistogramGenerator(activities, initialValues, 20);
  initialValues.datasets[0].data = buckets;
  const histogramLargestBucket = largestBucket;

  const options = initialHistogramOptions(histogramLargestBucket, min, max, stepBucketSize);

  return { data: initialValues, options };
};

/**
 * Given a list of all activities, only return the unique ones.
 * @param allActivities List of activities
 */
const useUniqueActivityList = (allActivities: string[]) => {
  return useMemo(() => {
    // Convert 2d array to 1d array. Put into a set to keep only unique elements, then make it an array
    // eslint-disable-next-line
    const uniqueActivities: string[] = [...new Set(([] as string[]).concat(...allActivities))];
    // Add "All" back to it
    return ["All"].concat(uniqueActivities);
  }, [allActivities]);
};
