import React from "react";
import { FormHelperText, TextField } from "@material-ui/core";
import { RowType, VariationType } from "../handleUpload";

export interface Props {
  variation: VariationType;
  currRowIndex: number;
  varyIndex: number;
  setRows: React.Dispatch<React.SetStateAction<RowType[]>>;
  varyValue: number;
}

export const VariationMaxInput = ({ variation, setRows, currRowIndex, varyIndex: vi }: Props) => {
  const handleMaxChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    variationIndex: number,
  ) => {
    const { value } = event.target;

    setRows((prev) => {
      const newRows = [...prev];
      newRows[index].variations[variationIndex].max = parseFloat(value);
      return newRows;
    });
  };

  return (
    <TextField
      type="number"
      key={`Variation ${variation.variationId} Max`}
      value={variation.max}
      onChange={(e) => handleMaxChange(e, currRowIndex, vi)}
      label="Max"
      id="Max input"
      error={variation.max === "" || variation.min > variation.max}
      required
    />
  );
};

export const VariationMinInput = ({ varyValue, variation, setRows, currRowIndex, varyIndex: vi }: Props) => {
  const handleMinChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    variationIndex: number,
  ) => {
    const { value } = event.target;

    setRows((prev) => {
      const newRows = [...prev];
      newRows[index].variations[variationIndex].min = parseFloat(value);
      return newRows;
    });
  };

  return (
    <>
      <TextField
        key={`Variation ${variation.variationId} Min`}
        type="number"
        value={variation.min}
        onChange={(e) => handleMinChange(e, currRowIndex, vi)}
        label="Min"
        id="Min input"
        error={
          variation.min === "" ||
          variation.min > variation.max ||
          (variation.fieldName === "duration" && varyValue + variation.min < 0)
        }
        required
      />
      {variation.fieldName === "duration" && varyValue + variation.min < 0 && (
        <FormHelperText error id="helper-text-field-name">
          Duration cannot be negative
        </FormHelperText>
      )}
    </>
  );
};
