import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { isAfter, isBefore, isValid } from "date-fns";
import _ from "lodash";
import { LinearProgress } from "@material-ui/core";
import { getActionPromise } from "../../../helpers/actions/actions";
import { multiGraphCombiner } from "./multiGraphCombiner";
import { ContainerType, FetchedPromiseType, RenderedGraphsType } from "./multiGraphTypes";
import { genericProcessor } from "./multiGraphHelper";
import { DATA_SERVICE_URL } from "../../../api/apiConstants";

export const RenderedGraphs = ({ graphs, dateRange, setDateRange, selectedInterval }: RenderedGraphsType) => {
  const [toFetchPromiseList, setToFetchPromiseList] = useState<Promise<any>[]>([]);
  const [finalData, setFinalData] = useState<any>(multiGraphCombiner([], []));
  const [preProcessList, setPreProcessList] = useState<any[]>([]);
  const [isLoaded, setLoaded] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    /**
     * When either graphs, date range, or selected interval change
     *
     * For each of the graphs, push the promise resolve into a toFetchPromiseList
     */
    const promiseList: Promise<any>[] = [];
    if (graphs.length > 0) {
      setLoaded(false);

      Object.values(graphs).forEach((graph: ContainerType) => {
        if (graph?.endpoint?.length && isValid(dateRange?.startDate) && isValid(dateRange.endDate)) {
          promiseList.push(
            Promise.resolve(
              getActionPromise(
                graph.endpoint,
                undefined,
                {
                  time_gte: dateRange?.startDate?.toISOString() ?? "",
                  time_lte: dateRange?.endDate?.toISOString() ?? "",
                  interval: selectedInterval,
                  re_fetch_most_recent: true,
                },
                DATA_SERVICE_URL,
              ),
            ),
          );
        }
      });

      setToFetchPromiseList(promiseList);
    }

    // When there's no graphs selected, reset the final data and first load state
    setFinalData(multiGraphCombiner([], []));
    setFirstLoad(true);
  }, [graphs, dateRange, selectedInterval]);

  /**
   * When the toFetchPromiseList changes
   *
   * Wait for the promises to resolve,
   * Refetch if necessary,
   * Set it as the processed state
   */
  useEffect(() => {
    // Wait for all graphs to resolve
    Promise.all(toFetchPromiseList).then((response: FetchedPromiseType[]) => {
      // On the first load, set the date range to the graph that was just loaded, and refetch if needed
      if (firstLoad && response?.length) {
        setFirstLoad(false);

        const refetchedTimeQueries = response?.[0]?.data?.Status?.NewTimeQueries;

        // Means that there was a refetch and we should adjust the dateRange of the calendar intervals
        if (refetchedTimeQueries) {
          // Add spinner since we will refetch
          setLoaded(false);
          setDateRange({
            startDate: new Date(refetchedTimeQueries.time_gte),
            endDate: new Date(refetchedTimeQueries.time_lte),
          });
        }
      }
      // Set state variable to process
      setPreProcessList(response);
    });
    // Nothing specific needs to happen if there's an uncaught error. The graph just won't load.
  }, [toFetchPromiseList]);

  /**
   * When the processed state changes
   *
   * Call each graph's useProcess on the list. Store result in new list
   * Then, combine the results of the new list
   *
   * See if there's any ranges that can be expanded by checking the labels
   * Set the final data state
   */
  useEffect(() => {
    setLoaded(false);
    // Run the useProcess on each graph
    const processedResult = genericProcessor(preProcessList, graphs as ContainerType[]);
    // Combine the results of the useProcess
    const combinedResult = multiGraphCombiner(processedResult, graphs as ContainerType[]);

    // With the labels, we can do some date range calculations
    const { labels } = combinedResult.dataObject;

    // Ensure that we have labels and a valid date range before we try to extend it
    if (labels?.length && dateRange?.startDate && dateRange.endDate && labels[0] && labels[labels.length - 1]) {
      const newRange = _.cloneDeep(dateRange);
      // If the labels in the graph are BEFORE the current selected range, move the calendar start date
      if (isBefore(new Date(labels[0]), dateRange.startDate)) {
        newRange.startDate = new Date(labels[0]);
      }
      // If the labels in the graph are AFTER the current selected range, move the calendar end date
      if (isAfter(new Date(labels[labels.length - 1]), dateRange.endDate)) {
        newRange.endDate = new Date(labels[labels.length - 1]);
      }

      // If there were any changes made, set the calendar to the new range
      if (!_.isEqual(newRange, dateRange)) {
        setLoaded(false);
        setDateRange(newRange);
      }
    }

    setLoaded(true);
    setFinalData(combinedResult);
  }, [preProcessList]);

  return (
    <div>
      <div>
        {/* Loading Spinner */}
        {!isLoaded ? <LinearProgress /> : null}
        {/* End Loading Spinner */}
      </div>

      <div>
        {/* Warning Labels */}
        {graphs.length ? (
          <div>
            {/* Check to see if there's loaded data at index 0 or index.length-1 */}
            {finalData.dataObject?.datasets?.[finalData.dataObject.datasets.length - 1]?.data?.length ||
            finalData.dataObject?.datasets?.[0]?.data?.length ? null : (
              <h6 style={{ color: "red" }}>No data found in this range</h6>
            )}
          </div>
        ) : (
          <h6 style={{ color: "red" }}>No graphs selected</h6>
        )}
        {/* End Warning Labels */}
      </div>

      {/* Graph */}
      <div>
        <Line data={finalData.dataObject} options={finalData.outputOptions} />
      </div>
    </div>
  );
};
