import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { FileSelectionComponent } from "./FileSelectionComponent";
import { FileProcessor } from "./FileProcessor";
import { getAction } from "../../../../helpers/actions/actions";
import { API_SUPPORTED_PROVIDERS, FILE_SERVICE_URL } from "../../../../api/apiConstants";
import { UploadState } from "./progressStatesHelper";

interface UploadComponentInterface {
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  fetchProgressStatus: () => void;
  uploadStates: UploadState[];
}

export const UploadComponent = ({ setProgress, fetchProgressStatus, uploadStates }: UploadComponentInterface) => {
  const [isUploadDisabled, setUploadDisabled] = useState<boolean>(true);
  const [fileList, setFileList] = useState<File[]>([]);
  const [supportedProviders, setSupportedProviders] = useState<string[]>([]);

  useEffect(() => {
    getAction(API_SUPPORTED_PROVIDERS, setSupportedProviders, FILE_SERVICE_URL).then(() => {
      // Only allow uploading after we have a list of things we can accept
      setUploadDisabled(false);
    });
  }, []);

  return (
    <div>
      {!isUploadDisabled ? <FileSelectionComponent setFileList={setFileList} /> : <CircularProgress />}

      <Box m={3} />
      <FileProcessor
        fileList={fileList}
        supportedProviders={supportedProviders}
        uploadStates={uploadStates}
        setUploadDisabled={setUploadDisabled}
        setFileList={setFileList}
        setProgress={setProgress}
        fetchProgressStatus={fetchProgressStatus}
      />
    </div>
  );
};
