import { LinearProgress, Box } from "@material-ui/core";
import React from "react";
import { Bar } from "react-chartjs-2";
import { API_LOCATION_PLACE } from "../../../../api/apiConstants";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { placeGoogleMapsURL } from "../../../../helpers/components/graphHelpers";
import { defaultGraphHeight, defaultGraphWidth, TITLE_PLACES_DAILY } from "../../../../helpers/graphConstants";
import { useProcessPlaceData } from "./useProcessPlaceBarData";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { initialBarOptions } from "./timePlaceHelper";
import { PlaceBarGraphMetadata } from "../../../../helpers/graphsMetadata";

export const PlaceBarGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(LocationQueryParams.defaultPlaceStatesQueryParams, API_LOCATION_PLACE);
  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const outputBarData = useProcessPlaceData(graphData);

  const graphDisplay = (
    <Bar
      data={outputBarData.placeData}
      options={initialBarOptions(selectedInterval)}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
      getElementAtEvent={(event): void => {
        const url = placeGoogleMapsURL(event, outputBarData.placeData);
        if (url) window.open(url);
      }}
    />
  );

  const description = [
    "Time in Places shows the time you spend in specific recognizable places according to your" +
      " primary map source.  ",
    "It may show your time at work, level of extroversion, or correlate to an allergy.",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_PLACES_DAILY}
        title={TITLE_PLACES_DAILY}
        calendarProps={graphObject}
        id={PlaceBarGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_PLACES_DAILY} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
