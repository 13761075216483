import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, LinearProgress, Typography } from "@material-ui/core";

export interface LinkingAcountModalInterface {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isError: boolean;
  errorMessage: string;
  modalMessage: string;
  isDone: boolean;
}

export default function LinkingAccountModalInfo({
  isOpen,
  setIsOpen,
  isError,
  errorMessage,
  modalMessage,
  isDone,
}: LinkingAcountModalInterface) {
  const [imageLink, setImageLink] = React.useState("");

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isError && !isDone) {
      setImageLink(`${process.env.PUBLIC_URL}/images/error.jpg`);
    } else if (isDone) {
      setImageLink(`${process.env.PUBLIC_URL}/images/done.jpg`);
    } else if (!isDone) {
      setImageLink(`${process.env.PUBLIC_URL}/images/working.jpg`);
    }
  }, [isError, isDone]);

  return (
    <div>
      <Dialog
        disableBackdropClick
        open={isOpen}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!isDone ? (
          <DialogTitle id="alert-dialog-title">
            {isError ? "Something went wrong 🤕" : "We are working on it 🥳"}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">Done! 🥳</DialogTitle>
        )}
        <DialogContent style={{ textAlign: "center" }}>
          <img alt="LLIF" style={{ width: "250px" }} src={imageLink} />

          {!isDone && !isError && <LinearProgress color="secondary" />}
          <DialogContentText>
            <Typography gutterBottom>{isError ? errorMessage : modalMessage}</Typography>
          </DialogContentText>

          {(isDone || isError) && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
