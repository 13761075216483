import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { DASHBOARD } from "../../api/apiConstants";

export const LoginIOSCallback = React.memo(() => {
  const history = useHistory();

  useEffect(() => {
    history.push(`/${DASHBOARD}`);
  }, []);

  return null;
});
