import { useMemo } from "react";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { initialNetflixBarData } from "./netflixOptions";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { BucketType } from "./netflixTypes";
import { ObjectType } from "../../../../types/graphs/ObjectType";

/**
 * Given a list of unique profiles, transform them into an object with a number value of 0 initially.
 * When summing the profile, it will use this array
 * @param listOfProfiles List of unique profiles
 */
const profilePushObjectTemplate = (listOfProfiles: string[]) => {
  const objectTemplate: ObjectType = {};

  listOfProfiles.forEach((profile) => {
    objectTemplate[profile] = 0;
  });

  return objectTemplate;
};

export const useProcessProfileTotals = (fetchedNetflixData: NewESResponseDataType): any => {
  const initialData = initialNetflixBarData();
  return useMemo(() => {
    if (fetchedNetflixData?.Values && haveKeys([fetchedNetflixData.Values]) && fetchedNetflixData.Values.length) {
      // Populate data
      const values: BucketType[] = fetchedNetflixData.Values as BucketType[];

      const uniqueProfileList: string[] = [];

      // First go through each bucket and create a unique profles list
      values.forEach((bucket) => {
        // Check if profile exists in datasets
        bucket?.profiles?.forEach((profile) => {
          const profileName = Object.keys(profile)[0];
          if (!uniqueProfileList.includes(profileName)) {
            uniqueProfileList.push(profileName);
          }
        });
      });

      // Create one dataset per unique profile
      uniqueProfileList.forEach((profileName) => {
        initialData.datasets.push({ label: profileName, data: [] });
      });

      // Now sum up the profiles and push into appropriate dataset
      values.forEach((bucket) => {
        const sums = profilePushObjectTemplate(uniqueProfileList);
        const timestamp = +new Date(bucket[timestampKey]);

        bucket?.profiles?.forEach((profile) => {
          const profileName = Object.keys(profile)[0];

          // Sum up the profile
          if (sums?.[profileName] !== undefined) {
            // eslint-disable-next-line
            sums[profileName] += profile?.[profileName].reduce((acc, obj) => acc + (obj.duration / 60), 0);
          }
        });

        initialData.labels.push(timestamp);

        initialData.datasets.forEach((dataset) => {
          dataset?.data.push({ x: timestamp, y: sums[dataset.label] });
        });
      });
    }

    return initialData;
  }, [fetchedNetflixData]);
};
