export const onFoot = "ON FOOT";
export const inLand = "IN LAND VEHICLE";
export const inWater = "IN WATER";
export const inAir = "IN AIR";
export const still = "STILL";

export const dataConstants: { [id: string]: string } = {
  WALKING: onFoot,
  ON_FOOT: onFoot,
  RUNNING: onFoot,
  CYCLING: onFoot,
  ON_BICYCLE: onFoot,
  SKIING: onFoot,
  IN_VEHICLE: inLand,
  IN_ROAD_VEHICLE: inLand,
  IN_FOUR_WHEELER_VEHICLE: inLand,
  MOTORCYCLE: inLand,
  IN_PASSENGER_VEHICLE: inLand,
  IN_BUS: inLand,
  IN_RAIL_VEHICLE: inLand,
  IN_TRAIN: inLand,
  IN_SUBWAY: inLand,
  IN_TRAM: inLand,
  SAILING: inWater,
  IN_FERRY: inWater,
  FLYING: inAir,
};
