import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { defaultGraphHeight, defaultGraphWidth, TITLE_AIR_PM } from "../../../../helpers/graphConstants";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_LOCATION_ENVIRONMENT_AIR, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { PMMetadata } from "../../../../helpers/graphsMetadata";
import { useProcessPMData } from "./useProcessPMData";

export const PMGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    LocationQueryParams.defaultAirQueryParams,
    API_LOCATION_ENVIRONMENT_AIR,
    DATA_SERVICE_URL,
  );

  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessPMData(graphData);
  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  // TODO: Link https://www.epa.gov/pm-pollution
  const description = [
    "Particulate Matter (PM) is a measure of the solid particles and liquid droplets found in the" +
      " air.  PM is segmented by size 10 micrometers and smaller and 2.5 micrometers and smaller.  ",
    "PM comes from construction sites, unpaved roads, fields, smokestacks or fires.  PM may get into your lungs or your bloodstream causing serious health problems.",
  ];
  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_AIR_PM}
        title={TITLE_AIR_PM}
        calendarProps={graphObject}
        id={PMMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_AIR_PM} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
