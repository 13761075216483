import { differenceInCalendarDays } from "date-fns";
import { Dict } from "../../../helpers/components/graphHelpers";
import {
  dayString,
  hourString,
  minuteString,
  monthString,
  perDay,
  perHour,
  perMinute,
  perMonth,
  perWeek,
  perYear,
  weekString,
  yearString,
} from "../../../helpers/timeConstants";
import { CalendarRange } from "./GraphCalendar/calendarBodyHelper";

export const intervalsAll: Dict = {
  [perYear]: yearString,
  [perMonth]: monthString,
  [perWeek]: weekString,
  [perDay]: dayString,
  [perHour]: hourString,
  [perMinute]: minuteString,
};

const intervalsDayWeekMonthYear = {
  [perYear]: yearString,
  [perMonth]: monthString,
  [perWeek]: weekString,
  [perDay]: dayString,
};

const intervalsWeekMonthYear = {
  [perWeek]: weekString,
  [perYear]: yearString,
  [perMonth]: monthString,
};

export const applyRules = (
  dateRange: CalendarRange,
  setIntervals: (interval: Dict) => void,
  selectedInterval: string,
  setSelectedInterval: ((interval: string) => void) | undefined,
): void => {
  const numDaysSelected =
    dateRange?.startDate && dateRange.endDate ? differenceInCalendarDays(dateRange.endDate, dateRange.startDate) : 0;

  if (numDaysSelected >= 0 && numDaysSelected <= 3) {
    setIntervals(intervalsAll);
  } else if (numDaysSelected > 3 && numDaysSelected <= 365 * 10) {
    if (setSelectedInterval) {
      if (selectedInterval === perMinute || selectedInterval === perHour) {
        setSelectedInterval(perDay);
      }
    }
    setIntervals(intervalsDayWeekMonthYear);
  } else if (numDaysSelected > 365 * 10) {
    setIntervals(intervalsWeekMonthYear);
    if (setSelectedInterval) {
      if (selectedInterval === perMinute || selectedInterval === perHour || selectedInterval === perDay) {
        setSelectedInterval(perWeek);
      }
    }
  }
};
