import React from "react";
import { Pie } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { API_ACTIVITY_MOVEMENT, DATA_SERVICE_URL_OLD } from "../../../../api/apiConstants";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { defaultGraphWidth, defaultGraphHeight, TITLE_MOVEMENT_STATES } from "../../../../helpers/graphConstants";
import { options, useProcessPieData } from "./useProcessPieData";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { MovementPieGraphMetadata } from "../../../../helpers/graphsMetadata";

export const MovementPieGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultMovementStatesQueryParams,
    API_ACTIVITY_MOVEMENT,
    DATA_SERVICE_URL_OLD,
    true,
  );
  const { graphData, isLoaded, dateRange, elemRef } = graphObject;
  const { outputBarData } = useProcessPieData(graphData, dateRange);

  const graphDisplay = (
    <Pie data={outputBarData} options={options()} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  const description = [
    "Percent Time in Movement States is derived from your map data and is a good indicator of your travel quality of life or how often that you are sedentary.  ",
  ];
  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_MOVEMENT_STATES}
        title={TITLE_MOVEMENT_STATES}
        calendarProps={graphObject}
        id={MovementPieGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_MOVEMENT_STATES} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
