import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { API_DELETED_ALL_PROVIDERS_DATA, FILE_SERVICE_URL } from "../../../../api/apiConstants";
import { deleteAction } from "../../../../helpers/actions/actions";

export const ConfirmDeleteDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!buttonsDisabled) setOpen(false);
  };

  const successfulDelete = (status: string) => {
    enqueueSnackbar(`${status}`, { variant: "success" });
  };

  const failureDelete = () => {
    enqueueSnackbar(`Failed to delete data`, { variant: "error" });
  };

  return (
    <Grid container direction="row">
      <Grid item>
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          Delete
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete All Data</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete <strong>ALL</strong> your data?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {buttonsDisabled ? (
              <CircularProgress />
            ) : (
              <>
                <Button onClick={handleClose} variant="contained" color="primary">
                  No
                </Button>
                <Button
                  onClick={() => {
                    setButtonsDisabled(true);
                    deleteAction(API_DELETED_ALL_PROVIDERS_DATA, FILE_SERVICE_URL)
                      .then((status) => {
                        setButtonsDisabled(false);
                        if (status?.message) {
                          successfulDelete(status?.message);
                        } else {
                          enqueueSnackbar(`Something went wrong...`, { variant: "warning" });
                        }
                        handleClose();
                      })
                      .catch(() => failureDelete());
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Yes
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};
