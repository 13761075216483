import React, { useContext, useEffect, useRef } from "react";
import Divider from "@material-ui/core/Divider";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import {
  UserSettingsContext,
  WebsiteTheme,
  initialSettingsState,
  LOCAL_STORAGE_THEME,
} from "../../../../types/general/UserSettingsContext";
import UnitContainer from "../../../../components/settings/General/UnitsGroup";
import TimeContainer from "../../../../components/settings/General/TimeGroup";
import ThemeContainer from "../../../../components/settings/General/ThemeGroup";
import { saveToServer } from "../../../../helpers/settingsHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "75ch",
    },
    p: {
      marginTop: "0px",
      marginBottom: "0px",
    },
    subtitle: {
      color: theme.palette.text.secondary,
    },
    paper: {
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(10),
    },
    center: { textAlign: "center" },
  }),
);
export const GeneralPage = () => {
  const classes = useStyles();
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);

  const notInitialSave = useRef(false);
  const [settings, setSettings] = React.useState<any>(initialSettingsState);

  useEffect(() => {
    if (userSettings.settings && settings.general === undefined) {
      setSettings(userSettings.settings);
    }
  }, [userSettings]);

  useEffect(() => {
    if (settings.general === undefined) return;
    if (notInitialSave.current) {
      saveToServer(settings);
      const copySettings = { ...userSettings };
      copySettings.settings = settings;
      setUserSettings(copySettings);
    } else {
      notInitialSave.current = true;
    }
  }, [settings]);

  const changeSettings = (field: string, value: any, group?: string | undefined) => {
    if (group === undefined) {
      setSettings(() => ({
        ...settings,
        general: {
          ...settings.general,
          [field]: value,
        },
      }));
    } else {
      setSettings(() => ({
        ...settings,
        general: {
          ...settings.general,
          [group]: {
            ...settings.general[group],
            [field]: value,
          },
        },
      }));
    }
  };

  const changeWebsiteTheme = (value: WebsiteTheme) => {
    const copySettings = { ...userSettings };
    const newTheme = WebsiteTheme[value as keyof typeof WebsiteTheme];
    copySettings.websiteTheme = newTheme;
    window.localStorage.setItem(LOCAL_STORAGE_THEME, newTheme);
    setUserSettings(copySettings);
  };

  return (
    <>
      <Typography component="div">
        {settings?.general?.units && (
          <div>
            <Divider />
            <Box fontSize="h5.fontSize" fontWeight={700} mt={3} mb={1.5}>
              Timezone
            </Box>

            <TimeContainer classes={classes} timezone={settings.general.timezone} setSettings={changeSettings} />

            <Divider />
            {/* TEMPERATURE SETTINGS (C/F) */}
            <Box fontSize="h5.fontSize" fontWeight={700} mt={3} mb={1.5}>
              Units
            </Box>
            <UnitContainer classes={classes} settings={settings.general.units} setSettings={changeSettings} />
            <Box m={3} />
            <Divider />
            {/* WEBSITE THEME (LIGHT/DARK/SYSTEM) */}
            <Box fontSize="h5.fontSize" fontWeight={700} mt={3} mb={1.5}>
              Display
            </Box>

            <ThemeContainer
              classes={classes}
              changeWebsiteTheme={changeWebsiteTheme}
              websiteTheme={userSettings.websiteTheme}
            />
            <Box m={3} />
          </div>
        )}
      </Typography>
      <Box m={8} />
    </>
  );
};
