import React from "react";
import { Bar } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_ACTIVITY_INTERACT_FACEBOOK_SOCIAL, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { defaultGraphHeight, defaultGraphWidth, TITLE_FACEBOOK_INTERACTIONS } from "../../../../helpers/graphConstants";
import { useProcessFacebookInteractions } from "./useProcessFacebookInteractions";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { FacebookLineGraphMetadata } from "../../../../helpers/graphsMetadata";

export const FacebookTimeChart = () => {
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultFacebookFrequency,
    API_ACTIVITY_INTERACT_FACEBOOK_SOCIAL,
    DATA_SERVICE_URL,
  );

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;

  const { data, options } = useProcessFacebookInteractions(graphData, selectedInterval);
  const graphDisplay = <Bar data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_FACEBOOK_INTERACTIONS}
        title={TITLE_FACEBOOK_INTERACTIONS}
        calendarProps={graphObject}
        id={FacebookLineGraphMetadata.id}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_FACEBOOK_INTERACTIONS} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
