import React from "react";
import { Bubble } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_WEATHER, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { defaultGraphHeight, defaultGraphWidth, TITLE_WIND } from "../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { WeatherWindLineGraphMetadata } from "../../../../helpers/graphsMetadata";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { useProcessWindData } from "./useProcessWindData";

export const WindGraph = () => {
  const graphObject = useGraphSetup(LocationQueryParams.defaultWeatherParams, API_WEATHER, DATA_SERVICE_URL);
  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessWindData(graphData);
  const graphDisplay = <Bubble data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  const description = [
    "Wind is the natural movement of air or other gases relative to a planet's surface.  Your personal wind is recorded so that you can better understand the impact it has on your life (e.g.  outdoor time, exercise frequency, exercise performance, congestion, mood, etc.). ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_WIND}
        title={TITLE_WIND}
        calendarProps={graphObject}
        id={WeatherWindLineGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_WIND} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
