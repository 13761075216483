import { useLocation } from "react-router-dom";
import React, { useContext } from "react";
import { IconButton, InputBase, Paper, useMediaQuery } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { SearchContext } from "../../../helpers/context/contextVariables";
import { TopNavSearchBoxRangeSelector } from "./TopNavSearchBoxRangeSelector";
import { useSearchBarStyles } from "./navigationStyles";

export const TopNavSearchBox = (props: any) => {
  // CONTEXT VARIABLES
  const { setSearchValue } = useContext(SearchContext);

  // CUSTOM HOOKS
  const location = useLocation();
  const classes = useSearchBarStyles();

  // MEDIA QUERIES
  const isSmall = useMediaQuery("(max-width:1150px)"); // Less than 1150
  const isTiny = useMediaQuery("(max-width:800px)"); // Less than 800

  const handleEnter = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setSearchValue(event.target.value);
    }
  };

  return (
    <Paper component="form" className={classes.root}>
      {!isTiny && (
        // HIDE SEARCH ICON ON SMALL SCREEN
        <IconButton className={classes.iconButton} aria-label="menu" size="small">
          <SearchIcon />
        </IconButton>
      )}
      <InputBase
        key="input-with-textfield"
        className={classes.input}
        placeholder={location.pathname.includes("live") ? "Filter Graphs…" : "Search…"}
        inputProps={{ "aria-label": "search your data" }}
        value={props.value}
        onChange={props.onChange}
        onKeyPress={handleEnter}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        type="text"
        spellCheck="false"
      />
      {/* ONLY SHOW CLEAR BUTTON IF THERE'S SOME TEXT TYPED */}
      {props.value.length ? (
        <>
          {!isTiny && (
            // HIDE REST ICON ON SMALL SCREEN
            <IconButton
              color="secondary"
              onClick={() => {
                // RESET TEXT ON CLICK
                setSearchValue("");
              }}
              size="small"
            >
              <ClearIcon />
            </IconButton>
          )}
        </>
      ) : (
        <></>
      )}

      {/* IF THERE'S SPACE FOR IT ON FIND PAGE, SHOW A RANGE SELECTOR */}
      {location.pathname.includes("/find") && !isSmall && <TopNavSearchBoxRangeSelector />}
    </Paper>
  );
};
