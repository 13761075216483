import React from "react";
import { DailyStepsGraph } from "../../../../components/graphs/activity/StepsGraphs/DailyStepsGraph";
import { StepsHistogram } from "../../../../components/graphs/activity/StepsGraphs/StepsHistogram";

export const StepsPage = (): JSX.Element => {
  return (
    <div className="container-fluid">
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%", height: "auto", padding: "7.5px" }}>
          <DailyStepsGraph />
        </div>
        <div style={{ width: "50%", height: "auto", padding: "7.5px" }}>
          <StepsHistogram />
        </div>
      </div>
    </div>
  );
};
