import { useMemo } from "react";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import {
  generateAllPlaces,
  initialPieData,
  populatePieDataset,
  removeExcessDataPoints,
  sumPieTimeInPlaces,
} from "./timePlaceHelper";
import { ObjectType } from "../../../../types/graphs/ObjectType";

const placePieProcessor = (locationPlaceData: NewESResponseDataType, placeDataset: any, numPlaces: number) => {
  if (locationPlaceData?.Values && haveKeys([locationPlaceData.Values])) {
    // First create a unique list of places
    const { placesSet } = generateAllPlaces(locationPlaceData);
    const uniquePlaces: string[] = [...placesSet] as string[];

    const allPlaces: any = {};
    uniquePlaces.forEach((place: string) => {
      allPlaces[place] = { time: 0, location: null };
    });

    sumPieTimeInPlaces(locationPlaceData, allPlaces);
    populatePieDataset(placeDataset, allPlaces);

    let toSortObj = placeDataset.datasets[0].data.map((data: ObjectType, i: number) => {
      return {
        time: data.time,
        location: data.location,
        label: placeDataset.labels[i],
      };
    });

    toSortObj = removeExcessDataPoints(toSortObj, numPlaces);

    placeDataset.labels = toSortObj.map((entry: ObjectType) => entry.label);
    placeDataset.datasets[0].data = toSortObj.map((entry: ObjectType) => entry.time);
    placeDataset.datasets[0].rawData = toSortObj;
  }
  return placeDataset;
};

export const useProcessPlacePieData = (locationPlaceData: NewESResponseDataType, numPlaces: number): any => {
  const placeDataset = initialPieData();

  const outputPlacePieData = useMemo(() => {
    return placePieProcessor(locationPlaceData, placeDataset, numPlaces);
  }, [locationPlaceData, numPlaces]);

  return { placeData: outputPlacePieData };
};
