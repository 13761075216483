import React, { useState } from "react";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import { Pie } from "react-chartjs-2";
import { API_LOCATION_PLACE, DATA_SERVICE_URL_OLD } from "../../../../api/apiConstants";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { placePieGoogleMapsURL } from "../../../../helpers/components/graphHelpers";
import { defaultGraphWidth, defaultGraphHeight, TITLE_PLACES_PIE } from "../../../../helpers/graphConstants";
import { useProcessPlacePieData } from "./useProcessPlacePieData";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { initialDatasets, initialPieOptions } from "./timePlaceHelper";
import { PlacePieSelector } from "./PlacePieSelector";
import { PlacePieGraphMetadata } from "../../../../helpers/graphsMetadata";

export const PlacePieGraph = (): JSX.Element => {
  const [numPlaces, setNumPlaces] = useState<number>(10);

  const graphObject = useGraphSetup(
    LocationQueryParams.defaultPlacePercentQueryParams,
    API_LOCATION_PLACE,
    DATA_SERVICE_URL_OLD,
    true,
  );
  const { graphData, isLoaded, elemRef } = graphObject;
  const outputPieData = useProcessPlacePieData(graphData, numPlaces);

  const graphDisplay = (
    <Pie
      data={outputPieData?.placeData ?? initialDatasets()}
      options={initialPieOptions()}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
      getElementAtEvent={(event): void => {
        const url = placePieGoogleMapsURL(event, outputPieData.placeData);
        if (url) window.open(url);
      }}
    />
  );

  const description = [
    "Your percent time in places is a good indicator of your work home balance and your priorities.  ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_PLACES_PIE}
        title={TITLE_PLACES_PIE}
        calendarProps={graphObject}
        id={PlacePieGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_PLACES_PIE} graphDisplay={graphDisplay} calendarProps={graphObject}>
          <Grid container>
            <PlacePieSelector numPlaces={numPlaces} setNumPlaces={setNumPlaces} />
          </Grid>
        </GraphContainer>
      </GraphHeader>
    </div>
  );
};
