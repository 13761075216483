import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_ACTIVITY_WEB, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { defaultGraphHeight, defaultGraphWidth, TITLE_ACTIVITY_WEB } from "../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { useProcessWebActivityGraph, webActivityOptions } from "./useProcessWebActivityGraph";
import { WebActivityBarGraphMetadata } from "../../../../helpers/graphsMetadata";

export const WebActivityGraph = () => {
  const graphObject = useGraphSetup(ActivityQueryParams.defaultWebActivity, API_ACTIVITY_WEB, DATA_SERVICE_URL);

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const output = useProcessWebActivityGraph(graphData);

  const graphDisplay = (
    <Line
      data={output.outputWebActivityData}
      options={webActivityOptions(selectedInterval)}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
    />
  );

  const description = [
    "Web activity accounts for all actions on the Internet outside of messaging and posting.  ",
    "It is a good indication of the time and effort you're putting into your digital life and may be compared with the time in places to understand balance.",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_ACTIVITY_WEB}
        title={TITLE_ACTIVITY_WEB}
        calendarProps={graphObject}
        id={WebActivityBarGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_ACTIVITY_WEB} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
