import { Dict, haveKeys } from "../../../../helpers/components/graphHelpers";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { avgYValue, maxYValue } from "./loadMenstrualCycleData";

/**
 * These values are based on the ordering in useDataOptionsMenstrualFertility.ts
 * The aforementioned file has the order listed as:
 * [Heartrate, conditions, cycle_altering_event, flow, fluids, mood, ovulation_test, sex]
 * @param stringPassed Convert this string into the corresponsing dataset index in useDataOptionsMenstrualFertility.ts
 */
const stringToDatasetIndex = (stringPassed: string): number => {
  const index: Dict = {
    conditions: 1,
    cycle_altering_event: 2,
    flow: 3,
    fluids: 4,
    mood: 5,
    ovulation_test: 6,
    sex: 7,
  };

  return index[stringPassed] as number;
};

/**
 * Take the symptom data and plot it on the graph.
 * The y value is set to the HEARTRATE AVERAGE
 * @param fitbitFemaleSymptomData female Symptom Data from api endpoint
 * @param data the output data
 */
export const loadMenstrualSymptomData = (fitbitFemaleSymptomData: NewESResponseDataType, data: any): void => {
  if (fitbitFemaleSymptomData?.Values && haveKeys([fitbitFemaleSymptomData.Values])) {
    const symptomData = fitbitFemaleSymptomData.Values;

    symptomData.forEach((symptomObj) => {
      Object.keys(symptomObj).forEach((prop: string) => {
        const conditionArr = symptomObj[prop] as any[];

        for (let i = 0; i < conditionArr.length; i++) {
          if (prop !== timestampKey) {
            if (conditionArr[i]?.key !== null && conditionArr[i]?.key !== "null") {
              const targetDataset = data?.datasets[stringToDatasetIndex(prop)];
              const symptomData = {
                x: +new Date(symptomObj[timestampKey]),
                y: (avgYValue + maxYValue) / 2, // between avg and max
                z: conditionArr[i]?.key,
              };

              targetDataset?.data.push(symptomData);
            }
          }
        }
      });
    });
  }
};
