import React from "react";
import { Button, ButtonGroup, Tooltip } from "@material-ui/core";

interface CalendarIntervalSelectorProps {
  selectedInterval: string | undefined;
  setSelectedInterval: React.Dispatch<React.SetStateAction<string>> | undefined;
}

export const CalendarIntervalSelector = ({ selectedInterval, setSelectedInterval }: CalendarIntervalSelectorProps) => {
  return (
    <Tooltip title="Adjust the aggregation interval">
      <span>
        <ButtonGroup color="primary" size="small" aria-label="interval selector">
          <Button
            variant={selectedInterval === "1d" ? "contained" : "outlined"}
            onClick={() => (setSelectedInterval ? setSelectedInterval("1d") : null)}
            disabled={!setSelectedInterval}
          >
            D
          </Button>
          <Button
            variant={selectedInterval === "1w" ? "contained" : "outlined"}
            onClick={() => (setSelectedInterval ? setSelectedInterval("1w") : null)}
            disabled={!setSelectedInterval}
          >
            W
          </Button>
          <Button
            variant={selectedInterval === "1M" ? "contained" : "outlined"}
            onClick={() => (setSelectedInterval ? setSelectedInterval("1M") : null)}
            disabled={!setSelectedInterval}
          >
            M
          </Button>
          <Button
            variant={selectedInterval === "1y" ? "contained" : "outlined"}
            onClick={() => (setSelectedInterval ? setSelectedInterval("1y") : null)}
            disabled={!setSelectedInterval}
          >
            Y
          </Button>
        </ButtonGroup>
      </span>
    </Tooltip>
  );
};
