import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { useProcessGraphData } from "./useProcessMenstrualFertilityData";
import { defaultGraphWidth, defaultGraphHeight, TITLE_MENSTRUAL_CYCLE } from "../../../../helpers/graphConstants";
import { BodyQueryParams } from "../../../../helpers/components/defaultQueryParams";
import {
  API_BODY_MENSTRUAL_CYCLES,
  API_BODY_MENSTRUAL_SYMPTOMS,
  DATA_SERVICE_URL_OLD,
} from "../../../../api/apiConstants";
import { useGraphAPIFetch } from "../../../../api/useGraphAPIFetch";
import { Dict } from "../../../../helpers/components/graphHelpers";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { applyRules, intervalsAll } from "../../../customComponents/GraphContainer/calendarRules";
import { CalendarRange } from "../../../customComponents/GraphContainer/GraphCalendar/calendarBodyHelper";
import { MenstrualLineGraphMetadata } from "../../../../helpers/graphsMetadata";

const initialQueryParams = _.cloneDeep(BodyQueryParams.defaultFemaleCyclesQueryParams);

export const MenstrualCycleGraph = (): JSX.Element => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [intervals, setIntervals] = useState<Dict>(_.cloneDeep(intervalsAll));
  const [dateRange, setDateRange] = useState<CalendarRange>({
    startDate: new Date(initialQueryParams.time_gte),
    endDate: new Date(initialQueryParams.time_lte),
  });
  const [selectedInterval, setSelectedInterval] = useState<string>(initialQueryParams.interval);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(true);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      applyRules(dateRange, setIntervals, selectedInterval, setSelectedInterval);
    }
  }, [dateRange, intervals]);

  const menstrualSymptomData = useGraphAPIFetch(
    dateRange,
    setDateRange,
    selectedInterval,
    API_BODY_MENSTRUAL_SYMPTOMS,
    setIsLoaded,
    refetch,
    setRefetch,
    DATA_SERVICE_URL_OLD,
    true,
  );
  const menstrualCycleData = useGraphAPIFetch(
    dateRange,
    undefined,
    selectedInterval,
    API_BODY_MENSTRUAL_CYCLES,
    setIsLoaded,
    false,
    setRefetch,
    DATA_SERVICE_URL_OLD,
    true,
  );

  const calendarModalProps = {
    isOpen,
    setOpen,
    intervals,
    selectedInterval,
    setSelectedInterval,
    dateRange,
    setDateRange,
    initialQueryParams,
    setRefetch,
    isVisible: true,
    isLoaded: true,
  };

  const { outputData, outputOptions } = useProcessGraphData(menstrualCycleData, menstrualSymptomData, selectedInterval);

  const graphDisplay = (
    <Line data={outputData} options={outputOptions} width={defaultGraphWidth} height={defaultGraphHeight} />
  );
  const description = [
    "With app and voice data collection, understand your period cycle start, stop, and cramp patterns.  ",
  ];
  return (
    <GraphHeader
      key={TITLE_MENSTRUAL_CYCLE}
      title={TITLE_MENSTRUAL_CYCLE}
      calendarProps={calendarModalProps}
      id={MenstrualLineGraphMetadata.id}
      description={description}
    >
      {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
      <GraphContainer id={TITLE_MENSTRUAL_CYCLE} graphDisplay={graphDisplay} calendarProps={calendarModalProps} />
    </GraphHeader>
  );
};
