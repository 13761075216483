import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { defaultGraphHeight, defaultGraphWidth, TITLE_AIR_AQI } from "../../../../helpers/graphConstants";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_LOCATION_ENVIRONMENT_AIR, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { AQIMetadata } from "../../../../helpers/graphsMetadata";
import { useProcessAQIData } from "./useProcessAQIData";

export const AQIGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    LocationQueryParams.defaultAirQueryParams,
    API_LOCATION_ENVIRONMENT_AIR,
    DATA_SERVICE_URL,
  );

  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessAQIData(graphData);
  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  const description = [
    "The Air Quality Index (AQI) is used for reporting daily air quality. It tells you how clean" +
      " or polluted your air is, and what associated health effects might be a concern for you. ",
    "The AQI focuses on health effects you may experience within a few hours or days after breathing polluted air." +
      " EPA calculates the AQI for five major air pollutants regulated by the Clean Air Act: ground-level ozone, particle pollution (also known as particulate matter), carbon monoxide, sulfur dioxide, and nitrogen dioxide. ",
    "For each of these pollutants, EPA has established national air quality standards to protect public health. Ground-level ozone and airborne particles are the two pollutants that pose the greatest threat to human health in this country.",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_AIR_AQI}
        title={TITLE_AIR_AQI}
        calendarProps={graphObject}
        id={AQIMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_AIR_AQI} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
