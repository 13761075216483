import React, { createRef, useContext, useEffect, useState } from "react";
import { Box, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import {
  amazonShoppingColDef,
  diaryEventsColDefs,
  facebookMessagesColDef,
  googleSemanticLocationColDef,
  locationHistoryColDefs,
  netflixViewingActivityColDef,
  webActivityColDef,
} from "./findTableDefinitions";
import { API_SEARCH_ENDPOINT, DATA_SERVICE_URL_OLD } from "../../../api/apiConstants";
import { SearchContext, SideNavigationContext } from "../../../helpers/context/contextVariables";
import { RenderTree } from "../../../components/navigation/NavigationRoutes";
import { graphStaticRanges } from "../../../components/customComponents/GraphContainer/calendarConstants";
import { ObjectType } from "../../../types/graphs/ObjectType";
import { generateHeaders, splitTables } from "./tableHelpers";
import { FindDataTable } from "./FindDataTable";
import { emptyTablesSkeleton, noGraphsFoundText } from "./FindTableSkeletons";
import { ESSearchResponse } from "../../../types/responses/NewESResponseDataType";
import { getActionPromise } from "../../../helpers/actions/actions";

export const FindPageTables = (): JSX.Element => {
  // STATE VARIABLES
  const [isLoading, setLoading] = useState<boolean>();
  const [searchData, setSearchData] = useState<ESSearchResponse | undefined>();
  // TODO (SPK): REFACTOR ONCE NEW SCHEMA IS COMPLETED
  const [tableData, setTableData] = useState<any[][]>();
  const [tableHeaders, setTableHeaders] = useState<string[][]>();
  // CONTEXT VARIABLES
  const { searchValue, range } = useContext(SearchContext);
  const { sideNavElRefs, sideNavItems, setSideNavItems } = useContext(SideNavigationContext);
  // CUSTOM HOOKS
  const location = useLocation();

  /**
   * After the endpoint is fetched, it will store the data into setSearchData
   */
  useEffect(() => {
    if (searchValue.length > 0) {
      setLoading(true);
      getActionPromise(
        API_SEARCH_ENDPOINT,
        setSearchData,
        {
          format: "json",
          q: searchValue,
          scope: "",
          time_lte: graphStaticRanges.find((searchRange) => searchRange.label === range).range().endDate,
          time_gte: graphStaticRanges.find((searchRange) => searchRange.label === range).range().startDate,
        },
        DATA_SERVICE_URL_OLD,
      ).finally(() => {
        setLoading(false);
      });
    }
    // TODO (SPK): REFACTOR ONCE NEW SCHEMA IS COMPLETED
    setTableData([]);
    setTableHeaders([]);
  }, [searchValue, range]);

  /**
   * Whenever data changes searchData, regenerate the table matrix
   */
  useEffect(() => {
    splitTables(searchValue, searchData, setTableData);
  }, [searchData]);

  /**
   * The method that splits tables into a matrix calls setTableHeaders, which triggers this
   */
  useEffect(() => {
    // TODO (SPK): REFACTOR ONCE NEW SCHEMA IS COMPLETED
    generateHeaders(tableData, setTableHeaders);
    if (location.pathname.includes("find")) {
      const indexSet = new Set<ObjectType>();
      tableData?.forEach((outerArr) => {
        outerArr.forEach((innerArr) => {
          indexSet.add(innerArr._index);
        });
      });

      const copyOfCurr: RenderTree | RenderTree[] = { ...sideNavItems };
      const newChildren: RenderTree[] = [...indexSet].map((index) => {
        return { name: index, id: index } as unknown as RenderTree;
      });
      if ("children" in copyOfCurr) {
        copyOfCurr.children = newChildren;
      }
      setSideNavItems(copyOfCurr);
      // add or remove refs
      sideNavElRefs.current = newChildren.map((tree) => {
        return { [tree.name]: createRef() };
      });
    }
  }, [tableData]);

  /**
   *
   * @param data The current dataset
   * @param index Index of the OUTER array in the matrix. I.e, which ES Index are we on.
   */
  const FindTableComposition = (data: any, index: any): JSX.Element | undefined => {
    if (tableHeaders?.[index]) {
      return (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaders[index].map((row, rowidx) => {
                if (row && rowidx === 0) {
                  return <TableCell key={row}>{row}</TableCell>;
                }
                return (
                  <TableCell align="right" key={row}>
                    {row}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any) => {
              return (
                <TableRow>
                  {tableHeaders[index]
                    .filter((col) => {
                      return (
                        (typeof row._source[col] === "string" && row._source[col] !== "uuid") ||
                        typeof row._source[col] === "number"
                      );
                    })
                    .map((col) => {
                      return <TableCell align="right">{row._source[col]}</TableCell>;
                    })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
    return undefined;
  };

  if (tableData?.length === 0) {
    return (
      <Box m={3}>
        {isLoading ? <LinearProgress /> : <Box m={0.5} />}
        {noGraphsFoundText(searchValue)}
        {emptyTablesSkeleton}
      </Box>
    );
  }

  return (
    <>
      {isLoading ? <LinearProgress /> : <></>}
      {tableData?.map((data, index) => {
        // TODO: These special cases will becomes the default once schema conversion is complete
        // For now we need to handle them separately.
        const tableRef = sideNavElRefs?.current.find((index: any) => {
          return Object.keys(index)[0] === data[0]._index;
        }) as unknown as React.RefObject<HTMLDivElement>;

        const tableName = data[0]._index;
        // TODO: These special cases will becomes the default once schema conversion is complete

        if (tableName === "web_activity") {
          return (
            <FindDataTable data={data} indexName="Web Activity" columnDefs={webActivityColDef} tableRef={tableRef} />
          );
        }
        // TODO: These special cases will becomes the default once schema conversion is complete
        if (tableName === "amazon_shopping") {
          return (
            <div ref={tableRef}>
              <FindDataTable
                data={data}
                indexName="Amazon Shopping"
                columnDefs={amazonShoppingColDef}
                tableRef={tableRef}
              />
            </div>
          );
        }
        // TODO: These special cases will becomes the default once schema conversion is complete
        if (tableName === "facebook_messages") {
          return (
            <div ref={tableRef}>
              <FindDataTable
                data={data}
                indexName="Facebook Messages"
                columnDefs={facebookMessagesColDef}
                tableRef={tableRef}
              />
            </div>
          );
        }
        // TODO: These special cases will becomes the default once schema conversion is complete

        if (tableName === "netflix_viewing_activity") {
          return (
            <div ref={tableRef}>
              <FindDataTable
                data={data}
                indexName="Web Activity Watch Netflix"
                columnDefs={netflixViewingActivityColDef}
                tableRef={tableRef}
              />
            </div>
          );
        }
        // TODO: These special cases will becomes the default once schema conversion is complete

        if (tableName === "google_semantic_location_history_place_visit") {
          return (
            <div ref={tableRef}>
              <FindDataTable
                data={data}
                indexName="Google Semantic Location"
                columnDefs={googleSemanticLocationColDef}
                tableRef={tableRef}
              />
            </div>
          );
        }
        if (tableName === "location_history") {
          return (
            <div ref={tableRef}>
              <FindDataTable
                data={data}
                indexName="Location History"
                columnDefs={locationHistoryColDefs}
                tableRef={tableRef}
              />
            </div>
          );
        }
        if (tableName === "diary_events") {
          return (
            <div ref={tableRef}>
              <FindDataTable data={data} indexName="Diary Events" columnDefs={diaryEventsColDefs} tableRef={tableRef} />
            </div>
          );
        }

        return (
          <div ref={tableRef}>
            <Box m={3}>
              <Typography variant="h4">{tableName}</Typography>
              {FindTableComposition(data, index)}
            </Box>
          </div>
        );
      })}
    </>
  );
};
