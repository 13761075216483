import React from "react";
import { Grid } from "@material-ui/core";
import { createButton, createSvgIcon } from "react-social-login-buttons";

const GoogleIcon = () => {
  return (
    <svg id="logo_googleg_48dp" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path
        id="Shape"
        d="M17.64,9.2a10.341,10.341,0,0,0-.164-1.841H9v3.481h4.844a4.14,4.14,0,0,1-1.8,2.716V15.82h2.909A8.777,8.777,0,0,0,17.64,9.2Z"
        fill="#4285f4"
        fillRule="evenodd"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M9,18a8.592,8.592,0,0,0,5.956-2.18l-2.909-2.258A5.43,5.43,0,0,1,3.964,10.71H.957v2.332A9,9,0,0,0,9,18Z"
        fill="#34a853"
        fillRule="evenodd"
      />
      <path
        id="Shape-3"
        data-name="Shape"
        d="M3.964,10.71a5.321,5.321,0,0,1,0-3.42V4.958H.957a9.011,9.011,0,0,0,0,8.084L3.964,10.71Z"
        fill="#fbbc05"
        fillRule="evenodd"
      />
      <path
        id="Shape-4"
        data-name="Shape"
        d="M9,3.58a4.862,4.862,0,0,1,3.44,1.346l2.581-2.581A8.649,8.649,0,0,0,9,0,9,9,0,0,0,.957,4.958L3.964,7.29A5.364,5.364,0,0,1,9,3.58Z"
        fill="#ea4335"
        fillRule="evenodd"
      />
      <path id="Shape-5" data-name="Shape" d="M0,0H18V18H0Z" fill="none" fillRule="evenodd" />
    </svg>
  );
};
const AppleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19">
      <path
        id="_"
        data-name=""
        d="M28.223,20.385a3.583,3.583,0,0,0,2.5-1.353,4.4,4.4,0,0,0,.973-2.657A1.844,1.844,0,0,0,31.656,16a4.06,4.06,0,0,0-2.707,1.449,4.166,4.166,0,0,0-1.008,2.573,1.707,1.707,0,0,0,.035.338A1.308,1.308,0,0,0,28.223,20.385ZM25.293,35c1.137,0,1.641-.785,3.059-.785,1.441,0,1.758.761,3.023.761,1.242,0,2.074-1.184,2.859-2.343A10.8,10.8,0,0,0,35.5,29.939,4.159,4.159,0,0,1,33.039,26.1a4.323,4.323,0,0,1,1.98-3.624,4.285,4.285,0,0,0-3.645-1.884c-1.395,0-2.531.87-3.246.87-.773,0-1.793-.821-3-.821-2.3,0-4.629,1.957-4.629,5.653a11.934,11.934,0,0,0,1.934,6.293C23.348,33.913,24.145,35,25.293,35Z"
        transform="translate(-20.5 -16)"
        fill="#fff"
      />
    </svg>
  );
};

export const SocialLoginBox = (props: {
  handleGoogleLogin: (() => any) | undefined;
  handleAppleLogin: (() => any) | undefined;
}) => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <GoogleLoginButtonCustom align="center" onClick={props.handleGoogleLogin} />
      </Grid>
      <Grid item xs={12}>
        <AppleLoginButtonCustom align="center" onClick={props.handleAppleLogin} />
      </Grid>
    </Grid>
  );
};
const GoogleLoginButtonCustom = createButton({
  text: "SIGN IN WITH GOOGLE",

  icon: createSvgIcon(GoogleIcon),
  style: {
    background: "#FFFFFF",
    color: "black",
    fontFamily: "Roboto",
    letterSpacing: 1.25,
    fontSize: 14,
    lineHeight: 16,
  },
});

const AppleLoginButtonCustom = createButton({
  text: "SIGN IN WITH APPLE",
  style: {
    background: "black",
    color: "white",
    fontFamily: "Roboto",
    letterSpacing: 1.25,
    fontSize: 14,
    lineHeight: 16,
  },
  icon: createSvgIcon(AppleIcon),
});
