import React, { useMemo } from "react";
import Dropzone, { IDropzoneProps, ILayoutProps, IPreviewProps } from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { Grid, Typography } from "@material-ui/core";
import { isSafari } from "react-device-detect";

import { MAX_FILE_UPLOAD_SIZE } from "../../../../helpers/services/fileUploaderService";

interface FileSelectionComponentInterface {
  setFileList: React.Dispatch<React.SetStateAction<File[]>>;
}

export const NoDropzoneLayout = React.memo(({ previews, submitButton, input, files, dropzoneProps }: ILayoutProps) => {
  const { ref, className, style } = dropzoneProps;
  return (
    <div ref={ref} className={className} style={style}>
      {previews}

      {input}

      {files.length > 0 && submitButton}
    </div>
  );
});

// Inject preview prop
export const Preview = React.memo(({ meta }: IPreviewProps) => {
  const useHumanFileSize = (size: number) => {
    return useMemo(() => {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      // eslint-disable-next-line
      return `${(size / 1024 ** i).toFixed(2)} ${["B", "kB", "MB", "GB", "TB"][i]}`;
    }, [size]);
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <Typography variant="h5">{meta.name}&nbsp;</Typography>
        <Typography variant="caption" color="textSecondary">
          ({useHumanFileSize(meta.size)})
        </Typography>
      </div>
    </Grid>
  );
});

export const FileSelectionComponent = ({ setFileList }: FileSelectionComponentInterface) => {
  const handleSubmit: IDropzoneProps["onSubmit"] = (files) => {
    setFileList(files.map((file) => file.file));
    files.forEach((f) => f.remove());
  };

  return (
    <div>
      <Dropzone
        onSubmit={handleSubmit}
        inputContent={(_files, extra) => {
          if (extra.reject) {
            return ".zip files only please";
          }
          if (isSafari) {
            return (
              <Grid container style={{ textAlign: "center" }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Click here to select files</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    Can&apos;t drag and drop files on Safari
                  </Typography>
                </Grid>
              </Grid>
            );
          }
          return <Typography variant="h5">Click here to select files</Typography>;
        }}
        accept=".zip"
        maxFiles={1}
        maxSizeBytes={MAX_FILE_UPLOAD_SIZE}
        LayoutComponent={isSafari ? NoDropzoneLayout : undefined}
        PreviewComponent={Preview}
        styles={{ dropzone: { overflow: "auto" } }}
      />
    </div>
  );
};
