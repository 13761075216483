import * as chartjs from "chart.js";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { minutesToHourMinute } from "../../../../helpers/timeHelpers";
import { datasetReducer } from "../../location/Places/timePlaceHelper";
import { inAir, inLand, inWater, onFoot, still } from "../../location/locationConstants";

export const data = () => {
  return {
    labels: [] as Array<number | undefined>,
    datasets: [
      {
        label: onFoot,
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-stacked-movement",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: inLand,
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-stacked-movement",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: inWater,
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-stacked-movement",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: inAir,
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-stacked-movement",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: still,
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-stacked-movement",
        xAxisID: "x-axis",
        type: "bar",
      },
    ],
  };
};

export const stackedMovementOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      mode: "x",
      intersect: true,
      callbacks: {
        label(tooltipItem: any, data: any): string {
          if (Number(tooltipItem.yLabel) <= 0) return "";
          const dataIndex = tooltipItem.index;

          const total = data.datasets.reduce((a: any, b: any) => {
            return a + (b.data?.[dataIndex]?.y || 0);
          }, 0);

          let percent = Number(tooltipItem.yLabel) / total;
          percent *= 100;
          percent = Math.round(percent);

          const time: string = minutesToHourMinute(Number(tooltipItem.yLabel), true);

          const { label } = data.datasets[tooltipItem.datasetIndex];
          return `${label} ${time} (${percent}%)`;
        },
        footer(toolTipItem: any, data: any): string {
          const dataIndex = toolTipItem[0].index;
          return `Total ${minutesToHourMinute(
            data.datasets.reduce((a: any, b: any) => {
              return a + (b.data?.[dataIndex]?.y || 0);
            }, 0),

            true,
          )}`;
        },
      },
    },
    // Only show datasets that have data in their datasets
    legend: {
      display: true,
      labels: {
        filter(legendItem: any, data: any) {
          const { datasetIndex } = legendItem;
          return data.datasets[datasetIndex].data.reduce(datasetReducer, 0) > 0;
        },
      },
    },
    scales: {
      xAxes: [
        {
          id: "x-axis",
          offset: true,
          stacked: true,
          type: "time",
          time: {
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
          },
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-stacked-movement",
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback(label: any): string {
              return +label % 60 === 0 ? minutesToHourMinute(Number(label), false) : "";
            },
            stepSize: 60, // 60 minutes
          },
          scaleLabel: {
            display: true,
            labelString: "Time Spent (hours)",
          },
        },
      ],
    },
  };
};
