import { useMediaQuery } from "@material-ui/core";
import React, { useContext } from "react";
import {
  AgeSettingsInterface,
  IdentitySettingsInterface,
  LocationSettingsInterface,
  PrivacySettingsInterface,
  UnitsSettingsInterface,
} from "../settings/settings";

export const LOCAL_STORAGE_THEME = "LLIF_THEME";

export enum WebsiteTheme {
  Dark = "Dark",
  Light = "Light",
}

export enum SettingsGroups {
  AGE = "age",
  IDENTITY = "identity",
  UNITS = "units",
  LOCATION = "location",
}

export enum TemperaturePreference {
  CELSIUS = "°C",
  FAHRENHEIT = "°F",
}

export enum DistancePreference {
  KILOMETERS = "KM",
  MILES = "MI",
}

export interface generalProps {
  units: UnitsSettingsInterface;
  timezone: string;
}

export interface profileProps {
  location: LocationSettingsInterface;
  identity: IdentitySettingsInterface;
  age: AgeSettingsInterface;
}

export interface SettingsProps {
  profile: profileProps | undefined;
  privacy: PrivacySettingsInterface | undefined;
  general: generalProps | undefined;
  favorites: any;
}

export const initialSettingsState: SettingsProps = {
  profile: undefined,
  privacy: undefined,
  general: undefined,
  favorites: undefined,
};

export interface UserSettingsInterface {
  websiteTheme: WebsiteTheme;
  settings: SettingsProps;
}

export const initialUserSettingsState: UserSettingsInterface = {
  websiteTheme: WebsiteTheme.System,
  settings: initialSettingsState,
};

export interface UserSettingsContextType {
  userSettings: UserSettingsInterface;
  setUserSettings: (value: UserSettingsInterface) => void;
}

export const UserSettingsContext = React.createContext<UserSettingsContextType>({
  userSettings: initialUserSettingsState,
  setUserSettings: (): void => {
    /* setter function */
  },
});

/**
 * Given the current user settings and if the user prefers dark mode, return the new theme if a button is toggled
 * If the user is in dark mode, switch to light mode
 * if the user is in system mode but prefers dark mode, switch to light mode
 * if the user is in system mode but prefers light mode, switch to dark mode
 * if the user is in light mode, switch to dark mode
 *
 * @param currentSettings Current website theme
 * @param prefersDarkMode if the user prefers dark mode
 */
export const newWebsiteTheme = (currentSettings: UserSettingsInterface, prefersDarkMode: boolean) => {
  let newWebsiteTheme;
  if (
    currentSettings.websiteTheme === WebsiteTheme.Dark ||
    (currentSettings.websiteTheme === WebsiteTheme.System && prefersDarkMode)
  ) {
    newWebsiteTheme = WebsiteTheme.Light;
  } else {
    newWebsiteTheme = WebsiteTheme.Dark;
  }

  window.localStorage.setItem(LOCAL_STORAGE_THEME, newWebsiteTheme);

  return newWebsiteTheme;
};

/**
 * Given the current user settings and if the user prefers dark mode, return the current mode
 * If the user is in dark mode, return dark mode
 * if the user is in system mode but prefers dark mode, return dark mode
 * if the user is in system mode but prefers light mode, return light mode
 * if the user is in light mode, return light mode
 *
 * @param currentSettings Current website theme
 * @param prefersDarkMode if the user prefers dark mode
 */
export const useWebsiteTheme = () => {
  const { userSettings } = useContext(UserSettingsContext);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  if (
    userSettings.websiteTheme === WebsiteTheme.Dark ||
    (userSettings.websiteTheme === WebsiteTheme.System && prefersDarkMode)
  ) {
    return WebsiteTheme.Dark;
  }

  return WebsiteTheme.Light;
};
