import "react-date-range/dist/styles.css"; // main style file
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { endOfDay, startOfDay } from "date-fns/esm";
import { graphStaticRanges } from "../calendarConstants";
import { CalendarRange, setStartEndDate } from "./calendarBodyHelper";

interface CalendarProps {
  setDateRange: (range: CalendarRange) => void;
  isOpen: boolean;
  dateRange: CalendarRange;
}

export const Calendar = ({ setDateRange, isOpen, dateRange }: CalendarProps): JSX.Element => {
  const [state, setState] = useState([setStartEndDate(dateRange)]);

  useEffect(() => {
    setState([setStartEndDate(dateRange)]);
  }, [dateRange]);

  if (isOpen) {
    return (
      <DateRangePicker
        onChange={(item: any) => {
          setState([item.selection]);
          setDateRange({
            startDate: startOfDay(item.selection.startDate as Date),
            endDate: endOfDay(item.selection.endDate as Date),
          });
        }}
        months={2}
        moveRangeOnFirstSelection
        staticRanges={graphStaticRanges}
        ranges={state}
        editableDateInputs
        dragSelectionEnabled
        direction="horizontal"
      />
    );
  }
  return <></>;
};
