import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";

interface ProviderSelectionDialogInterface {
  isDialogOpen: boolean;
  fileList: File[];
  currFileIndex: number;
  supportedProviders: string[];
  setCurrProvider: React.Dispatch<React.SetStateAction<string>>;
  setCurrFileIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const ProviderSelectionDialog = ({
  isDialogOpen,
  fileList,
  currFileIndex,
  supportedProviders,
  setCurrProvider,
  setCurrFileIndex,
}: ProviderSelectionDialogInterface) => {
  return (
    <Dialog open={isDialogOpen}>
      <DialogTitle id="simple-dialog-title">
        Select the source of the file &quot;<strong>{fileList?.[currFileIndex]?.name}</strong>&quot;
      </DialogTitle>
      {supportedProviders?.length ? (
        <List>
          {supportedProviders.map((provider) => (
            <ListItem
              button
              onClick={() => {
                // We got down here because file provider wasn't automically detectecd, so we need user input.
                // Set the provider they pick into this state var
                setCurrProvider(provider);
              }}
              key={provider}
            >
              <ListItemAvatar>
                <CloudQueueIcon />
              </ListItemAvatar>
              <ListItemText primary={provider} />
            </ListItem>
          ))}
        </List>
      ) : (
        <>
          {/* Show a loading progress spinner if list isn't fetched yet */}
          <Typography id="circular-progress">Loading cloud providers...</Typography>
          <CircularProgress />
        </>
      )}
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          setCurrProvider("");
          setCurrFileIndex(currFileIndex + 1);
        }}
      >
        Skip
      </Button>
    </Dialog>
  );
};
