import { useMemo } from "react";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { loadMenstrualCycleData } from "./loadMenstrualCycleData";
import { loadMenstrualSymptomData } from "./loadMenstrualSymptomData";
import { initialData, initialOptions } from "./useDataOptionsMenstrualFertility";

// TODO(spk): Proper typing
export const useProcessGraphData = (
  fitbitMenstrualCycleData: NewESResponseDataType,
  fitbitMenstrualSymptomData: NewESResponseDataType,
  selectedInterval: string,
): { outputData: any; outputOptions: any } => {
  const outputOptions = initialOptions(selectedInterval);
  const data = initialData();

  const outputData = useMemo(() => {
    if (
      fitbitMenstrualCycleData?.Values &&
      fitbitMenstrualSymptomData?.Values &&
      haveKeys([fitbitMenstrualCycleData.Values, fitbitMenstrualSymptomData.Values])
    ) {
      loadMenstrualSymptomData(fitbitMenstrualSymptomData, data);
      loadMenstrualCycleData(fitbitMenstrualCycleData, data);
    }

    return data;
  }, [fitbitMenstrualCycleData, fitbitMenstrualSymptomData]);

  return { outputData, outputOptions };
};
