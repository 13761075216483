import React from "react";

export interface UserDataInterface {
  accessToken: string;
  userName: string;
  userEmail: string;
  userPicURL: string;
  userSettings: any;
}

export const initialUserDataState: UserDataInterface = {
  accessToken: "",
  userName: "",
  userEmail: "",
  userPicURL: "",
  userSettings: "",
};

export interface IdentityContextType {
  userData: UserDataInterface;
  setUserData: React.Dispatch<React.SetStateAction<UserDataInterface>>;
}

export const IdentityContext = React.createContext<IdentityContextType>({
  userData: initialUserDataState,
  setUserData: (_value: any): void => {
    // return
  },
});
