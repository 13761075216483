import React from "react";
import { Box, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { format } from "date-fns";
import { RowType } from "../handleUpload";

interface Props {
  row: RowType;
  currRowIndex: number;
  setRows: React.Dispatch<React.SetStateAction<RowType[]>>;
}

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm";

export const ScheduleStartEndDate = ({ row, currRowIndex: i, setRows }: Props) => {
  const handleStartChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    setRows((prev) => {
      const newRows = [...prev];
      newRows[index].schedule.startDate = new Date(value);
      return newRows;
    });
  };

  const handleEndChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    setRows((prev) => {
      const newRows = [...prev];
      newRows[index].schedule.endDate = new Date(value);
      return newRows;
    });
  };

  return (
    <>
      <Box>
        <TextField
          id="start-date"
          label="From"
          type="datetime-local"
          onChange={(e) => handleStartChange(e, i)}
          value={format(row.schedule.startDate, DATE_FORMAT)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box>
        <TextField
          id="end-date"
          label="To"
          type="datetime-local"
          onChange={(e) => handleEndChange(e, i)}
          value={format(row.schedule.endDate, DATE_FORMAT)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
    </>
  );
};

export const ScheduleQuantityUnit = ({ row, currRowIndex: i, setRows }: Props) => {
  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    setRows((prev) => {
      const newRows = [...prev];
      newRows[index].schedule.quantity = parseInt(value, 10);
      return newRows;
    });
  };

  const handleUnitChange = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const { value } = event.target;

    setRows((prev) => {
      const newRows = [...prev];
      newRows[index].schedule.unit = value;
      return newRows;
    });
  };

  return (
    <>
      <TextField
        id="quantity"
        label="Quantity"
        type="number"
        value={row.schedule.quantity}
        onChange={(e) => handleQuantityChange(e, i)}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <InputLabel id="unit">Unit</InputLabel>
      <Select labelId="unit" id="unit" value={row.schedule.unit} label="Unit" onChange={(e) => handleUnitChange(e, i)}>
        <MenuItem value={60}>Minute</MenuItem>
        <MenuItem value={3600}>Hour</MenuItem>
        <MenuItem value={86400}>Day</MenuItem>
        <MenuItem value={604800}>Week</MenuItem>
      </Select>
    </>
  );
};
