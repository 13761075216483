import { useMemo } from "react";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import {
  createUniqueDatasets,
  generateAllPlaces,
  initialDatasets,
  populateDatasets,
  sortDatasets,
} from "./timePlaceHelper";

const placeBarProcessor = (locationPlaceData: NewESResponseDataType, placeDataset: any) => {
  if (locationPlaceData?.Values && haveKeys([locationPlaceData.Values])) {
    // Get a list of all possible places
    const { placesSet } = generateAllPlaces(locationPlaceData);
    const uniquePlaces: string[] = [...placesSet] as string[];

    // Create unique list, populate, then sort
    createUniqueDatasets(uniquePlaces, placeDataset);
    populateDatasets(locationPlaceData, placeDataset, uniquePlaces);
    sortDatasets(placeDataset);
  }
  return placeDataset;
};

export const useProcessPlaceData = (locationPlaceData: NewESResponseDataType): any => {
  const placeDataset = initialDatasets();

  const outputPlaceData = useMemo(() => {
    return placeBarProcessor(locationPlaceData, placeDataset);
  }, [locationPlaceData]);

  return { placeData: outputPlaceData };
};
