import React from "react";
import { Grid, InputLabel, MenuItem, Select } from "@material-ui/core";

const SELECT_LABEL = "Filter Activity Type";

type ActivityTypeSelectorInterface = {
  selectedActivity: string;
  setSelectedActivity: React.Dispatch<React.SetStateAction<string>>;
  activityList: string[];
};

export const WebActivitySelector = ({
  selectedActivity,
  setSelectedActivity,
  activityList,
}: ActivityTypeSelectorInterface): JSX.Element => {
  return (
    <Grid container direction="row" alignItems="center" spacing={8}>
      <Grid item>
        <InputLabel id="activity-selector-label">{SELECT_LABEL}</InputLabel>
        <Select
          id="activity-selector"
          value={selectedActivity}
          onChange={(event) => {
            setSelectedActivity(event?.target?.value as string);
          }}
        >
          {activityList.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    </Grid>
  );
};
