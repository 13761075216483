import axios from "axios";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getAction } from "../../helpers/actions/actions";
import {
  API_ACCESS_TOKEN_HEADER,
  FILE_SERVICE_URL,
  DATA_SERVICE_URL,
  USER_SERVICE_URL,
  DATA_SERVICE_URL_OLD,
  API_USER_PROFILE,
  DOMAIN_URL,
  LOGIN,
  API_REFRESH,
  DATA_SERVICE_1_0,
} from "../../api/apiConstants";
import { IdentityContext, initialUserDataState } from "../../types/general/IdentityContext";
import { UserProfile } from "../../types/responses/LoginResponseType";
import { apiPost } from "../../api/actions";
import { useLogout } from "../customHooks/useLogout";

interface AuthenticationGuardProps {
  setButtonEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthenticationGuard = ({ setButtonEnabled }: AuthenticationGuardProps) => {
  const { userData, setUserData } = useContext(IdentityContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { logout } = useLogout();

  /**
   * Sets request and response interceptors, as a callback method when AccessToken changes.
   */
  const SetInterceptors = useCallback(() => {
    // Request interceptors
    axios.interceptors.request.use(
      (config) => {
        // config is of type AxiosRequestConfig, thus cannot be undefined.
        // On the other hand, config.header can indeed be.
        if (config.headers === undefined) {
          config.headers = {};
        }
        if (
          config.baseURL === FILE_SERVICE_URL ||
          config.baseURL === DATA_SERVICE_URL ||
          config.baseURL === USER_SERVICE_URL ||
          config.baseURL === DATA_SERVICE_URL_OLD ||
          config.baseURL === DATA_SERVICE_1_0
        ) {
          if (!config.headers.authorization) {
            config.headers.authorization = userData?.accessToken?.length
              ? `Bearer ${userData?.accessToken}`
              : undefined;
          }
        } else {
          config.headers.authorization = undefined;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // Response interceptors
    axios.interceptors.response.use(
      /*
        If there is a new API Access token given by the backend via headers
        update the user context.
         */
      (response) => {
        const newToken = response?.headers?.[API_ACCESS_TOKEN_HEADER];

        if (newToken && newToken !== userData.accessToken) {
          setUserData((prevState) => ({
            ...prevState,
            accessToken: newToken,
          }));
        }

        return response;
      },
      /*
        if token expired, server will delete cookie for me and return expired exception error 401
        if 401, remove entire context for the user - require login again
        only happen if both tokens expire
       */
      (error) => {
        if (error?.response.status === 401 && error?.response.config.baseURL.includes(DOMAIN_URL)) {
          // Null access token and identity and redirect to login
          // Throw away user profile
          setUserData(initialUserDataState);
          history.push(`/${LOGIN}`);
        }
        return Promise.reject(error);
      },
    );
  }, [userData.accessToken, setUserData]);

  /**
   * Make a request to the Profile endpoint.
   *
   * If the endpoint returns back a valid response, set the user profile details:
   * userName, userEmail, and userPicURL
   *
   * if valid user, update context
   */
  const tryProfileFetch = useCallback(() => {
    setButtonEnabled(false);
    getAction(API_USER_PROFILE, undefined, USER_SERVICE_URL)
      .then((response: UserProfile | null | undefined) => {
        if (response) {
          setUserData((prevState) => ({
            ...prevState,
            userName: response.display_name,
            userEmail: response.primary_email,
            userPicURL: response.picture_link,
            userSettings: response.settings,
          }));
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Error: ${JSON.stringify(error)}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setButtonEnabled(true);
      });
  }, [setUserData]);

  useEffect(() => {
    SetInterceptors();
  }, [SetInterceptors]);

  useEffect(() => {
    apiPost(API_REFRESH, {}, {}, {}, USER_SERVICE_URL)
      .then((response) => {
        if (response?.status === 200) {
          tryProfileFetch();
        } else if (response?.status === 401) {
          logout();
          setButtonEnabled(true);
        }
      })
      .catch(() => {
        setButtonEnabled(true);
      });
  }, [tryProfileFetch]);

  return null;
};
