import { Box, FormControlLabel, Checkbox, IconButton, Typography } from "@material-ui/core";
import React from "react";
import _ from "lodash";
import { RowType } from "../handleUpload";

interface Props {
  row: RowType;
  currRowIndex: number;
  setRows: React.Dispatch<React.SetStateAction<RowType[]>>;
}
export const VariationsToggle = ({ row, currRowIndex: i, setRows }: Props) => {
  const handleToggleVariation = (index: number) => {
    setRows((prev) => {
      const newRows = [...prev];
      if (newRows[index].variations) {
        newRows[index].variations = null;
      } else {
        newRows[index].variations = [
          {
            variationId: _.uniqueId(),
            fieldName: "",
            min: 0,
            max: 10,
          },
        ];
      }
      return newRows;
    });
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={row.variations !== null}
            color="primary"
            onChange={() => handleToggleVariation(i)}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Add variations?"
      />
    </Box>
  );
};
