import React, { useContext, useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { RenderTree } from "../NavigationRoutes";
import { SideNavigationContext } from "../../../helpers/context/contextVariables";

export const twoColBreakpointPx = 780;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(5),
    },
    item: {
      minHeight: "auto",
    },
  }),
);

export const SideNavigation = () => {
  // SIDE NAVIGATION CONTEXT VARIABLES
  const { sideNavItems, sideNavElRefs, setSideNavOpen } = useContext(SideNavigationContext);
  const [selected, setSelected] = useState<string>("");
  // CUSTOM HOOKS
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const isSmall = useMediaQuery(`(max-width:${twoColBreakpointPx}px)`, { noSsr: true }); // Less than 780px

  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  /** START HELPER FUNCTIONS * */
  const handleParentNodes = (rTree: RenderTree) => {
    if (isSmall) setSideNavOpen(false);
    if (location.pathname.includes("find")) {
      window.scrollTo(0, 0);
    } else {
      history.push(rTree.id);
    }
  };

  const handleLeafNodes = (rTree: RenderTree) => {
    if (location.pathname.includes("find")) {
      // If we're on the find page, scroll the table into view
      const tableRef = sideNavElRefs.current.find((el: any) => {
        return Object.keys(el)[0] === rTree.name;
      });
      tableRef.current.scrollIntoView();
      // Move a bit further down to include the title
      window.scrollBy(0, -100);
    } else {
      if (isSmall) setSideNavOpen(false);
      // If we're anywhere else, go to that page that's clicked
      history.push(rTree.id);
    }
  };

  /**
   * Function to recursively add all items of the render tree to the side navigation menu
   * @param tree Initial render tree
   * @param current array of components that will be printed as the end result
   */
  const navigationTreeToList = (tree: RenderTree[] | RenderTree, current: any[] = []) => {
    const recursiveList = (rTree: RenderTree) => {
      // Parent Nodes
      if (rTree?.children && rTree.children.length > 0) {
        current.push(
          <ListItem button key={rTree.id} selected={selected === rTree.id} onClick={() => handleParentNodes(rTree)}>
            {rTree.icon ?? <></>}
            <ListItemText primary={rTree.name} />
          </ListItem>,
        );
        // Now that we've appended the parent to current, recur through the children
        rTree?.children?.forEach((child: RenderTree) => {
          // We keep passing the current array to keep the whole list to render at the end.
          navigationTreeToList(child, current);
        });
      } else {
        current.push(
          <List component="div" disablePadding key={rTree.id}>
            <ListItem
              button
              disabled={rTree.disabled}
              style={rTree.style}
              className={clsx(classes.nested, classes.item)}
              selected={selected === rTree.id}
              onClick={() => handleLeafNodes(rTree)}
            >
              {rTree.icon ?? <></>}
              <ListItemText primary={rTree.name} />
            </ListItem>
          </List>,
        );
      }
    };

    // First check if the provided input is a render tree or an array of render trees
    // If RenderTree[], handle each RenderTree separately and combine results
    if (Array.isArray(tree)) {
      tree.forEach((node) => {
        recursiveList(node);
      });
    } else {
      recursiveList(tree);
    }
    return current;
  };

  /** END HELPER FUNCTIONS * */

  // Render the "current" array of the recursive function
  return <>{navigationTreeToList(sideNavItems as RenderTree[])}</>;
};
