import React, { useContext, useEffect } from "react";

import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";
import {
  DailyStepsGraphMetadata,
  FacebookLineGraphMetadata,
  HeartRateOxygenLineGraphMetadata,
  MenstrualLineGraphMetadata,
  MovementPieGraphMetadata,
  MovementStackedBarMetadata,
  NetflixPieChartMetadata,
  NetflixTimeStackedBarMetadata,
  NetflixTotalBarMetadata,
  PlaceBarGraphMetadata,
  PlacePieGraphMetadata,
  RestingHeartRateLineGraphMetadata,
  ShoppingCategoriesGraphMetadata,
  ShoppingSpendGraphMetadata,
  SleepBarGraphMetadata,
  SleepTimeGraphMetadata,
  StepsHistogramMetadata,
  TagsGraphMetadata,
  WebActivityBarGraphMetadata,
  WebActivityHistogramGraphMetadata,
  WeightBMILineGraphMetadata,
  WeatherTemperatureLineGraphMetadata,
  SleepHistogramGraphMetadata,
  HeartRateHistogramGraphMetadata,
  RestingHeartRateHistogramGraphMetadata,
  MovementHistogramMetadata,
  ShoppingHistogramGraphMetadata,
  WeightHistogramGraphMetadata,
  WeatherHumidityLineGraphMetadata,
  WeatherOzoneLineGraphMetadata,
  WeatherPollenLineGraphMetadata,
} from "../../../../helpers/graphsMetadata";
import { SearchContext } from "../../../../helpers/context/contextVariables";

export const DashboardPage = (): JSX.Element => {
  const { setSearchValue } = useContext(SearchContext);
  useEffect(() => {
    // Open side nav on first page load
    setSearchValue("");
  }, []);

  const dashboardPage = [
    HeartRateOxygenLineGraphMetadata.id,
    HeartRateHistogramGraphMetadata.id,
    RestingHeartRateLineGraphMetadata.id,
    RestingHeartRateHistogramGraphMetadata.id,
    SleepTimeGraphMetadata.id,
    SleepBarGraphMetadata.id,
    SleepHistogramGraphMetadata.id,
    TagsGraphMetadata.id,
    DailyStepsGraphMetadata.id,
    StepsHistogramMetadata.id,
    MovementPieGraphMetadata.id,
    MovementStackedBarMetadata.id,
    MovementHistogramMetadata.id,
    PlaceBarGraphMetadata.id,
    PlacePieGraphMetadata.id,
    ShoppingSpendGraphMetadata.id,
    ShoppingCategoriesGraphMetadata.id,
    ShoppingHistogramGraphMetadata.id,
    WebActivityBarGraphMetadata.id,
    WebActivityHistogramGraphMetadata.id,
    NetflixPieChartMetadata.id,
    NetflixTotalBarMetadata.id,
    NetflixTimeStackedBarMetadata.id,
    WeightBMILineGraphMetadata.id,
    WeightHistogramGraphMetadata.id,
    MenstrualLineGraphMetadata.id,
    FacebookLineGraphMetadata.id,
    WeatherTemperatureLineGraphMetadata.id,
    WeatherHumidityLineGraphMetadata.id,
    WeatherOzoneLineGraphMetadata.id,
    WeatherPollenLineGraphMetadata.id,
  ];

  return <GraphListWrapper graphNameList={dashboardPage} />;
};
