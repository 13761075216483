import React from "react";
import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";
import {
  AirElementsMetaData,
  AQIMetadata,
  PMMetadata,
  WeatherPollenLineGraphMetadata,
  WeatherHumidityLineGraphMetadata,
  WeatherOzoneLineGraphMetadata,
  WeatherTemperatureLineGraphMetadata,
} from "../../../../helpers/graphsMetadata";

export const EnviromentPage = (): JSX.Element => {
  const airGraphs = [
    AQIMetadata.id,
    PMMetadata.id,
    AirElementsMetaData.id,
    WeatherPollenLineGraphMetadata.id,
    WeatherHumidityLineGraphMetadata.id,
    WeatherOzoneLineGraphMetadata.id,
    WeatherTemperatureLineGraphMetadata.id,
  ];

  return <GraphListWrapper graphNameList={airGraphs} />;
};
