import React from "react";
import { Bar } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { defaultGraphWidth, defaultGraphHeight, TITLE_STEPS_HIST } from "../../../../helpers/graphConstants";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_ACTIVITY_STEPS, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useProcessStepsHistogram } from "./useProcessStepsHistogram";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { StepsHistogramMetadata } from "../../../../helpers/graphsMetadata";

export const StepsHistogram = (): JSX.Element => {
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultStepsHistogramQueryParams,
    API_ACTIVITY_STEPS,
    DATA_SERVICE_URL,
  );

  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessStepsHistogram(graphData);

  const graphDisplay = <Bar data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  const description = [
    "Your step frequency distribution shows the variability and patterns of your steps.  ",
    "If you are a weekend warrior, you'll see a bi-model distribution on a daily interval but a unimodal distribution for a week interval.  If you are outside your normal range, we may notify. ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_STEPS_HIST}
        title={TITLE_STEPS_HIST}
        calendarProps={graphObject}
        id={StepsHistogramMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_STEPS_HIST} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
