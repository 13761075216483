import { useMemo } from "react";
import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { millisToMinutes } from "../../../../helpers/timeHelpers";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { dataConstants } from "../../location/locationConstants";
import { data } from "./stackedBarHelper";

const activityDataObject = (): ObjectType => {
  return {
    "ON FOOT": 0,
    "IN LAND VEHICLE": 0,
    "IN WATER": 0,
    "IN AIR": 0,
  };
};

const indexOnFoot = 0;
const inLandVehicle = 1;
const inWater = 2;
const inAir = 3;

export const useProcessStackedBar = (fetchedData: NewESResponseDataType): any => {
  const initialData = data();
  const outputBarData = useMemo(() => {
    if (fetchedData?.Values && haveKeys([fetchedData.Values])) {
      const dates: any = [];

      fetchedData.Values.forEach((element) => {
        const timestamp = +new Date(element[timestampKey]);
        const activities = element?.activities as any[];
        // populate dates, which later becomes labels of graph
        if (activities) {
          dates.push(timestamp);
        }

        // const toPush = dataObject();

        const dataObject = activityDataObject();

        // Push each activity into var dataConstants
        activities?.forEach((activity) => {
          if (dataConstants[activity.activity_type]) {
            const activityStr = dataConstants[activity.activity_type];
            dataObject[activityStr] += millisToMinutes(activity.duration_total_ms);
          }
        });

        pushTupleData(initialData, indexOnFoot, { x: timestamp, y: +dataObject["ON FOOT"] });
        pushTupleData(initialData, inLandVehicle, { x: timestamp, y: +dataObject["IN LAND VEHICLE"] });
        pushTupleData(initialData, inWater, { x: timestamp, y: +dataObject["IN WATER"] });
        pushTupleData(initialData, inAir, { x: timestamp, y: +dataObject["IN AIR"] });

        initialData.labels = dates;
      });
    }
    return initialData;
  }, [fetchedData]);

  return { outputBarData };
};
