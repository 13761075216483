import React, { useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { API_GET_LOGIN_URL, USER_SERVICE_URL } from "../../../api/apiConstants";
import { Google, Apple } from "../../../components/login/providersConfig";
import { getAction } from "../../../helpers/actions/actions";
import { loginStyles } from "./loginPageHelper";
import { LoginPageComponent } from "./LoginPageComponent";

export interface ButtonEnabledInterface {
  buttonEnabled: boolean;
  setButtonEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoginPage = ({ buttonEnabled, setButtonEnabled }: ButtonEnabledInterface): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Sign in with Google button handler
   */

  const handleGoogleLogin = useCallback(async () => {
    const qParams = {
      provider: Google.PROVIDER,
      redirect_uri: Google.REDIRECT_URI,
      scope: Google.SCOPE,
      state: Google.STATE,
      client: Google.CLIENT,
    };
    setButtonEnabled(false);
    const response = await getAction(API_GET_LOGIN_URL, undefined, USER_SERVICE_URL, qParams);
    if (response?.message) {
      window.location.assign(response.message);
    } else {
      enqueueSnackbar(`Error: Unable to login. Please try again later`, {
        variant: "error",
      });
      setButtonEnabled(true);
    }
  }, []);

  const handleAppleLogin = useCallback(async () => {
    const qParams = {
      provider: Apple.PROVIDER,
      redirect_uri: Apple.REDIRECT_URI,
      scope: Apple.SCOPE,
      state: Apple.STATE,
      client: Apple.CLIENT,
      nonce: Apple.NONCE,
    };
    setButtonEnabled(false);

    const response = await getAction(API_GET_LOGIN_URL, undefined, USER_SERVICE_URL, qParams);
    if (response?.message) {
      window.location.assign(response.message);
    } else {
      enqueueSnackbar(`Error: Unable to login. Please try again later`, {
        variant: "error",
      });
      setButtonEnabled(true);
    }
  }, []);
  const classes = loginStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.root} container direction="row">
        <Grid item xs={12} md={12}>
          <LoginPageComponent
            handleGoogleLogin={handleGoogleLogin}
            handleAppleLogin={handleAppleLogin}
            buttonEnabled={buttonEnabled}
          />
        </Grid>
      </Grid>
    </div>
  );
};
