import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { BodyQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_MEASURE_HEARTRATE, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { defaultGraphHeight, defaultGraphWidth, TITLE_HEARTRATE_HISTOGRAM } from "../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { HeartRateHistogramGraphMetadata } from "../../../../helpers/graphsMetadata";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { useProcessHRHistogram } from "./useProcessHRHistogram";

export const HeartRateHistogramGraph = () => {
  // Graph setup
  const graphObject = useGraphSetup(
    BodyQueryParams.defaultHeartRateHistogramQueryParams,
    API_MEASURE_HEARTRATE,
    DATA_SERVICE_URL,
  );

  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessHRHistogram(graphData);
  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  const description = [
    "Heart rate is the speed of the heartbeat measured by the number of contractions (beats) of the heart per minute (bpm). ",
  ];
  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_HEARTRATE_HISTOGRAM}
        title={TITLE_HEARTRATE_HISTOGRAM}
        calendarProps={graphObject}
        id={HeartRateHistogramGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_HEARTRATE_HISTOGRAM} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
