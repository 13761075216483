import React from "react";
import { Box, FormHelperText, TextField } from "@material-ui/core";
import { VariationType, RowType } from "../handleUpload";

export interface Props {
  variation: VariationType;
  currRowIndex: number;
  varyIndex: number;
  setRows: React.Dispatch<React.SetStateAction<RowType[]>>;
  varyValue: number;
}

export const VariationFieldNameInput = ({ varyValue, variation, setRows, currRowIndex, varyIndex: vi }: Props) => {
  const handleFieldNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    variationIndex: number,
  ) => {
    const { value } = event.target;

    setRows((prev) => {
      const newRows = [...prev];
      newRows[index].variations[variationIndex].fieldName = value.toLocaleLowerCase();
      return newRows;
    });
  };

  return (
    <Box marginBottom={1} key={`Variation ${variation.variationId} Field Name Box`}>
      <TextField
        key={`Variation ${variation.variationId} Fieldname`}
        onBlur={(e) => handleFieldNameChange(e, currRowIndex, vi)}
        onChange={(e) => handleFieldNameChange(e, currRowIndex, vi)}
        value={variation.fieldName}
        label="Field name"
        id="Field name input"
        required
        error={
          variation.fieldName === "" || !varyValue || (typeof varyValue !== "string" && typeof varyValue !== "number")
        }
      />
      {variation.fieldName?.length > 0 && varyValue !== null && Array.isArray(varyValue) && (
        <FormHelperText error id="helper-text-field-name">
          Input a index for array field. Ex: [{varyValue.length - 1}]
        </FormHelperText>
      )}
      {variation.fieldName?.length > 0 &&
        varyValue !== null &&
        !Array.isArray(varyValue) &&
        typeof varyValue === "object" && (
          <FormHelperText error id="helper-text-field-name">
            Pick a field on the object. Ex: {Object.keys(varyValue).join(", ")}
          </FormHelperText>
        )}
      {variation.fieldName?.length > 0 && varyValue === null && (
        <FormHelperText error id="helper-text-field-name">
          Field name not found in file
        </FormHelperText>
      )}
    </Box>
  );
};
