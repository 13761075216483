import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { BodyQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { useProcessHeartRateOxygenData } from "./useProcessHeartRateOxygenData";
import { defaultGraphHeight, defaultGraphWidth, TITLE_HEARTRATE } from "../../../../helpers/graphConstants";
import { API_MEASURE_HEARTRATE, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { HeartRateOxygenLineGraphMetadata } from "../../../../helpers/graphsMetadata";

export const HeartRateOxygenLineGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    BodyQueryParams.defaultHeartRateQueryParams,
    API_MEASURE_HEARTRATE,
    DATA_SERVICE_URL,
  );
  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const { outputData, outputOptions } = useProcessHeartRateOxygenData(graphData, selectedInterval);
  const graphDisplay = (
    <Line data={outputData} options={outputOptions} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  const description = [
    "Heart rate is the speed of the heartbeat measured by the number of contractions (beats) of the heart per minute" +
      " (bpm). ",
    "The heart rate can vary according to the body's physical needs, including the need to absorb oxygen and excrete carbon dioxide, but is also modulated by a myriad of factors including but not limited to genetics, physical fitness, stress or psychological status, diet, drugs, hormonal status, environment, and disease/illness as well as the interaction between and among these factors.",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_HEARTRATE}
        title={TITLE_HEARTRATE}
        calendarProps={graphObject}
        id={HeartRateOxygenLineGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_HEARTRATE} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
