import axios from "axios";
import { API_UPLOAD, FILE_SERVICE_URL } from "../../api/apiConstants";

export const MAX_FILE_UPLOAD_SIZE = 5_000_000_000;
/**
 * Validates if file has expected zip extension
 * @param file File to be validated
 */
export const validateFileExtension = (file: File): boolean => {
  const extension = file.name.split(".").pop();
  return extension === "zip";
};

/**
 * Validates if file has expected size
 * @param file File to be validated
 */
export const validateFileSize = (file: File): boolean => {
  return !(file.size > MAX_FILE_UPLOAD_SIZE || file.size === 0);
};

/**
 * Uploads given file and tracks it progress via onUploadProgress event
 * @param fileInput File to be uploaded
 * @param providerInput Data provider of the uploaded file
 * @param onUploadProgress ProgressEvent to track uploading progress
 */
export const uploadFile = (
  fileInput: File,
  providerInput: string,
  onUploadProgress: (progressEvent: ProgressEvent<EventTarget>) => void,
) => {
  const formData = new FormData();
  formData.append("zip_file", fileInput);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
    onUploadProgress,
  };
  // TODO(jaja): As part of axios refactoring, abstract this call
  return axios.request({
    method: "post",
    baseURL: FILE_SERVICE_URL,
    url: API_UPLOAD,
    params: { provider: providerInput },
    data: formData,
    withCredentials: true,
    ...config,
  });
};
