import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { initialAQIData } from "./useInitialAirQualityData";
import { initialAQIOptions } from "./useInitialAirQualityOptions";

export const useProcessAQIData = (airElementsData: NewESResponseDataType): any => {
  const outputAQIOptions = initialAQIOptions();
  const aqiData = initialAQIData();

  if (airElementsData?.Values && haveKeys([airElementsData.Values])) {
    // Populate labels
    aqiData.labels = airElementsData?.Values?.map((x) => Number(+new Date(x[timestampKey])));

    // Populate data
    aqiData.datasets[0].data = airElementsData.Values.map((airObject) => {
      return { x: Number(+new Date(airObject[timestampKey])), y: airObject.AQI };
    });
  }

  return {
    data: aqiData,
    options: outputAQIOptions,
  };
};
