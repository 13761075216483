import React from "react";
import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import { inAir, inLand, inWater, onFoot } from "../../../location/locationConstants";

export const moveTypeList = ["distance", "duration"];
export const activityTypeList = ["ALL", onFoot, inLand, inWater, inAir];

type MoveTypeSelectorInterface = {
  moveType: string;
  setMoveType: React.Dispatch<React.SetStateAction<string>>;
  activityType: string;
  setActivityType: React.Dispatch<React.SetStateAction<string>>;
};

export const MovementHistogramSelector = ({
  moveType,
  setMoveType,
  activityType,
  setActivityType,
}: MoveTypeSelectorInterface): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<any>) => {
    setMoveType(event.target.value.toLowerCase());
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={8} justifyContent="center">
      <Grid item>
        <Select
          id="move-hist-activity-amount"
          value={activityType}
          onChange={(event) => {
            setActivityType(event?.target?.value as string);
          }}
        >
          {activityTypeList.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item.replace("IN ", "")}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item>
        <FormControl component="fieldset">
          <RadioGroup row aria-label="display_type" name="distance_duration" value={moveType} onChange={handleChange}>
            <FormControlLabel value="distance" control={<Radio />} label="Distance" />
            <FormControlLabel value="duration" control={<Radio />} label="Duration" />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};
