import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const noGraphsFoundText = (searchValue: string) => (
  <>
    <Grid container alignItems="center" justifyContent="center" direction="column">
      {searchValue.length ? (
        <>
          <Grid item>
            <Typography variant="h5" display="inline">
              No results for&nbsp;
            </Typography>
            <Typography variant="h6" color="secondary" display="inline">
              &quot;{searchValue}&quot;
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" display="inline">
              Try expanding the&nbsp;
            </Typography>
            <Typography variant="body1" display="inline" style={{ textDecoration: "underline" }}>
              date range
            </Typography>
            <Typography variant="body1" display="inline">
              &nbsp;or type something else
            </Typography>
          </Grid>
          <Divider style={{ width: "100%" }} />
        </>
      ) : (
        <>
          <Grid item style={{ marginTop: 10 }}>
            <Typography variant="h5">Type something above</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              to search <strong>your</strong> data
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  </>
);

export const emptyTablesSkeleton = (
  <>
    {[0, 1, 2, 3].map((i) => {
      // eslint-disable-next-line
      const width = (min: number, max: number) => `${Math.random() * (max - min) + min}%`;

      return (
        <div key={`Skeleton ${i}`}>
          <Typography variant="h3">
            <Skeleton style={{ width: width(20, 25) }} />
          </Typography>

          {/* "HEADER" SKELETON */}
          <Grid container alignItems="center" justifyContent="center" direction="row">
            {[0, 1, 2].map((key: number) => {
              return (
                <Grid item xs>
                  <Skeleton style={{ width: width(10, 30), margin: 2 }} key={`skeleton-table-header-${key}`} />
                </Grid>
              );
            })}
          </Grid>

          <Paper variant="outlined">
            {[0, 2, 3, 4, 5, 6, 7, 8].map((key) => {
              return (
                <Grid container alignItems="center" justifyContent="center" direction="row" key={key}>
                  <Grid item xs>
                    <Skeleton
                      style={{ width: width(30, 70), margin: 2 }}
                      animation="wave"
                      key="skeleton-table-body-1"
                    />
                    <Divider style={{ width: "100%" }} key="skeleton-table-body-hr-divider1" />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ marginRight: "5px" }}
                    key="skeleton-table-body-vr-divider1"
                  />
                  <Grid item xs>
                    <Skeleton
                      style={{ width: width(30, 70), margin: 2 }}
                      animation="wave"
                      key="skeleton-table-body-2"
                    />
                    <Divider style={{ width: "100%" }} key="skeleton-table-body-hr-divider2" />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ marginRight: "5px" }}
                    key="skeleton-table-body-vr-divider2"
                  />
                  <Grid item xs>
                    <Skeleton
                      style={{ width: width(30, 70), margin: 2 }}
                      animation="wave"
                      key="skeleton-table-body-3"
                    />
                    <Divider style={{ width: "100%" }} key="skeleton-table-body-hr-divider3" />
                  </Grid>
                </Grid>
              );
            })}
          </Paper>

          <Box m={5} />
        </div>
      );
    })}
  </>
);
