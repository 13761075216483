import { ErrorResponseType, ConnectResponseType } from "../types/responses/LoginResponseType";
import { SETTING_SAVE, USER_SERVICE_URL } from "../api/apiConstants";
import { postAction } from "./actions/actions";
import { timezones } from "./timezones";

export const saveToServer = (newSettings: any) => {
  postAction(SETTING_SAVE, USER_SERVICE_URL, undefined, newSettings).then(
    (response: ConnectResponseType | ErrorResponseType) => {
      return response;
    },
  );
};

export const timezoneKeyToGMTOffset = (key: string): number => {
  const value = timezones.find((val) => val.utc[0] === key);
  if (value) return value.offset;

  return 0;
};

export const timezoneKeyToText = (key: string): string => {
  const value = timezones.find((val) => val.utc[0] === key);
  if (value) return value.text;

  return "";
};
