import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { BodyQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { defaultGraphHeight, defaultGraphWidth, TITLE_RESTING_HEARTRATE } from "../../../../helpers/graphConstants";
import { API_MEASURE_RESTING_HEART_RATE, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useProcessRestingHeartRateData } from "./useProcessRestingHeartRateData";
import { RHRProcessedDataType } from "./restingHeartRateTypes";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { RestingHeartRateLineGraphMetadata } from "../../../../helpers/graphsMetadata";

export const RestingHeartRateLineGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    BodyQueryParams.defaultRestingHeartRateQueryParams,
    API_MEASURE_RESTING_HEART_RATE,
    DATA_SERVICE_URL,
  );

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;

  const { data, options }: RHRProcessedDataType = useProcessRestingHeartRateData(graphData, selectedInterval);
  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;
  const description = [
    " The basal or resting heart rate is defined as the heart rate when a person is awake, in a neutrally temperate environment, and has not been subject to any recent exertion or stimulation, such as stress or surprise.  ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_RESTING_HEARTRATE}
        title={TITLE_RESTING_HEARTRATE}
        calendarProps={graphObject}
        id={RestingHeartRateLineGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_RESTING_HEARTRATE} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
