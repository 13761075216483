import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { defaultGraphWidth, defaultGraphHeight, TITLE_STEPS_LINE } from "../../../../helpers/graphConstants";
import { useProcessStepsGraphData } from "./useProcessStepsData";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_ACTIVITY_STEPS, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { DailyStepsGraphMetadata } from "../../../../helpers/graphsMetadata";

export const DailyStepsGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultDailyStepsQueryParams,
    API_ACTIVITY_STEPS,
    DATA_SERVICE_URL,
  );

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;

  const { data, options } = useProcessStepsGraphData(graphData, selectedInterval);

  const graphDisplay = (
    <Line data={data.stepsLineData} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  const description = [
    "Steps may be tracked by mobile phone or fitness tracker.  When they have a GPS, the length of" +
      " the step is typically calibrated. ",
    "There is no set optimum number of steps per day.  LLIF does not hold you accountable to fictitious goal like 10,000 but we will notify if we see step activity improving your health.  ",
    "Generally, steps are a good indicator of overall mental and physical health.",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_STEPS_LINE}
        title={TITLE_STEPS_LINE}
        calendarProps={graphObject}
        id={DailyStepsGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_STEPS_LINE} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
