import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { numSelectedDays, shiftLeft } from "../calendarShifterHelper";
import { CalendarRange } from "../GraphCalendar/calendarBodyHelper";

interface CalendarLeftArrowProps {
  dateRange: CalendarRange;
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>>;
}

export const CalendarLeftArrow = ({ dateRange, setDateRange }: CalendarLeftArrowProps) => {
  const numDays = numSelectedDays(dateRange);
  return (
    <Tooltip title={`Previous ${numDays === 0 ? 1 : numDays} ${numDays === 0 ? "day" : "days"}`}>
      <IconButton onClick={(): void => shiftLeft(dateRange, setDateRange)} size="small">
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
};
