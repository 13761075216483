export const bagelContent = `{
    "tags": [
      {
        "tag": "food"
      }
    ],
    "timestamp": "2024-02-28T00:00:00.000+00:00",
    "doc_id": "adf2d165-ec45-4a95-9d50-8abcab0fec6a",
    "type": "food",
    "name": "everything bagel",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Ounce",
      "quantity": 1.0,
      "amount": 3.3,
      "calories": 260.0,
      "fat": 5.0,
      "saturated_fat": 0.5,
      "trans_fat": 0.0,
      "sodium": 0.35,
      "carbohydrates": 44.0,
      "fiber": 5.0,
      "sugar": 4.0,
      "protein": 13.0,
      "iron": 0.002,
      "calcium": 0.07
    }
  }`;

export const eggsContent = `{
    "timestamp": "2024-02-28T00:00:00.000+00:00",
    "doc_id": "646c43d9-1432-4ba1-8fe6-9e686b74e843",
    "type": "food",
    "name": "eggs over easy",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Gram",
      "quantity": 3.0,
      "quantity_type": "serving",
      "amount": 50.0,
      "calories": 72.0,
      "fat": 5.0,
      "saturated_fat": 1.6,
      "polyunsaturated_fat": 1.0,
      "monounsaturated_fat": 1.8,
      "cholesterol": 0.186,
      "sodium": 0.071,
      "potassium": 0.069,
      "carbohydrates": 0.4,
      "sugar": 0.2,
      "protein": 6.0,
      "vitamin_a": 0.00004,
      "iron": 0.0009,
      "calcium": 0.028
    }
  }`;

export const subwayContent = `{
    "timestamp": "2024-02-05T16:00:00.000+00:00",
    "type": "food",
    "name": "subway turkey footlong",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "quantity": 1.0,
      "quantity_type": "serving",
      "calories": 780.0,
      "fat": 29.0,
      "saturated_fat": 11.0,
      "cholesterol": 0.24,
      "sodium": 0.22,
      "carbohydrates": 84.0,
      "fiber": 4.0,
      "sugar": 10.0,
      "protein": 41.0
    }
  }`;

export const salmonContent = `{
    "timestamp": "2024-02-05T00:00:00.000+00:00",
    "type": "food",
    "name": "salmon",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Ounce",
      "quantity": 1.0,
      "amount": 12.0,
      "calories": 495.0,
      "fat": 14.0,
      "potassium": 1.2,
      "carbohydrates": 36.0,
      "fiber": 6.0,
      "protein": 34.0
    }
  }`;

export const dietCokeContent = `{
    "timestamp": "2024-02-03T22:30:00.000+00:00",
    "type": "drink",
    "name": "diet coke",
    "is_standard": false,
    "is_archived": false,
    "custom_data": [],
    "consumables_extension": {
      "units": "Fluid Ounce",
      "quantity": 2.0,
      "amount": 12.0
    }
  }`;

export const turkeySlicesContent = `{
    "timestamp": "2024-02-02T18:00:00.000+00:00",
    "type": "food",
    "name": "sliced turkey low sodium",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Ounce",
      "quantity": 1.0,
      "quantity_type": "serving",
      "quantity_per_serving": 4.0,
      "amount": 2.0,
      "calories": 70.0,
      "fat": 1.5,
      "cholesterol": 0.03,
      "sodium": 0.34,
      "carbohydrates": 3.0,
      "sugar": 1.0,
      "protein": 10.0,
      "iron": 0.00032
    }
  }`;

export const proteinBarContent = `  {
    "tags": [
      {
        "tag": "food"
      },
      {
        "tag": "snack"
      }
    ],
    "timestamp": "2024-02-02T19:00:00.000-05:00",
    "type": "food",
    "name": "think brownie crunch protein bar",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Gram",
      "quantity": 1.0,
      "amount": 60.0,
      "calories": 230.0,
      "fat": 8.0,
      "saturated_fat": 3.0,
      "sodium": 0.19,
      "potassium": 0.17,
      "carbohydrates": 23.0,
      "fiber": 1.0,
      "sugar": 11.0,
      "protein": 20.0,
      "iron": 0.0021,
      "calcium": 0.12
    }
  }`;

export const yellowCurryContent = `  {
    "tags": [
      {
        "tag": "food"
      },
      {
        "tag": "restaurant"
      }
    ],
    "timestamp": "2024-02-02T00:00:00.000+00:00",
    "type": "food",
    "name": "yellow curry chicken",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Gram",
      "quantity": 1.0,
      "amount": 226.0,
      "calories": 520.0,
      "fat": 28.0,
      "saturated_fat": 14.0,
      "cholesterol": 0.07,
      "sodium": 0.48,
      "potassium": 0.53,
      "carbohydrates": 52.0,
      "fiber": 2.0,
      "sugar": 12.0,
      "protein": 30.0,
      "vitamin_a": 0.0012,
      "vitamin_c": 0.03,
      "iron": 0.003,
      "calcium": 0.12
    }
  }`;

export const lasagnaContent = `{
    "timestamp": "2024-02-27T01:00:00.000+00:00",
    "type": "food",
    "name": "lasagna",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Cup",
      "quantity": 1.0,
      "amount": 1.0,
      "calories": 270.0,
      "fat": 10.0,
      "saturated_fat": 5.0,
      "trans_fat": 0.0,
      "cholesterol": 0.035,
      "sodium": 0.84,
      "carbohydrates": 34.0,
      "fiber": 3.0,
      "sugar": 5.0,
      "protein": 17.0,
      "vitamin_a": 0.0008,
      "vitamin_c": 0.008,
      "iron": 0.0012,
      "calcium": 0.15
    }
  }`;
