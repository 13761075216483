import React, { useContext, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { FindPageTables } from "./FindTable";
import { SideNavigationContext } from "../../../helpers/context/contextVariables";

export const FindPage = (): JSX.Element => {
  const { setSideNavOpen } = useContext(SideNavigationContext);

  useEffect(() => {
    // Open side nav on page load
    setSideNavOpen(true);
  }, []);

  return (
    <Paper>
      <FindPageTables />
    </Paper>
  );
};
