import React from "react";
import { Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";
import _ from "lodash";
import { CheckboxWrapperType, MultiGraphInputType } from "./multiGraphTypes";

export const CheckboxWrapper = ({
  allGraphStates,
  setAllGraphStates,
  disabled,
  title,
  disabledWhenNoSelect,
}: CheckboxWrapperType) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Toggle the active property of a graph
    const copyOfStates: MultiGraphInputType = _.cloneDeep(allGraphStates);
    copyOfStates[event.target.name].active = !copyOfStates[event.target.name].active;

    setAllGraphStates(copyOfStates);
  };

  const tooltipString = () => {
    // If a checkbox is disabled
    if (disabled) {
      return `${title} insight coming soon!`;
    }
    // Force user to pick at least one graph before this one
    // Example: Force the user to pick Resting HR before they can click the box for tags
    if (disabledWhenNoSelect) {
      return `Select at least 1 graph before adding ${title}`;
    }
    const isActive = allGraphStates[title]?.active;

    // If graph is active, show "remove", else show "Add"
    return isActive ? `Remove ${title}` : `Add ${title}`;
  };

  return (
    <Tooltip title={tooltipString()}>
      <FormControlLabel
        control={
          <Checkbox
            key={title}
            checked={allGraphStates[title]?.active}
            onChange={handleChange}
            disabled={disabled || disabledWhenNoSelect}
          />
        }
        name={title}
        label={title}
      />
    </Tooltip>
  );
};
