import React, { useContext, useState, useEffect } from "react";
import { Box, createStyles, Grid, IconButton, Paper, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ErrorBoundary } from "react-error-boundary";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FavouriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavouriteIconFilled from "@material-ui/icons/Favorite";
import { ErrorBoundaryFallback } from "../../../components/customComponents/ErrorBoundaryFallback";
import { GraphDescription } from "../../../components/customComponents/GraphContainer/GraphHeader/GraphDescription";
import { UserSettingsContext } from "../../../types/general/UserSettingsContext";
import { saveToServer } from "../../../helpers/settingsHelper";
import {} from "../../../components/navigation/navigationDrawer/SideNavigationDrawer";

export const graphDisplayStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      boxShadow: theme.shadows[1],
    },
    textCenter: {
      textAlign: "center",
    },
  }),
);

export const GraphHeader = ({ title, children, calendarProps, description, id }: any) => {
  const { isOpen, setOpen, dateRange } = calendarProps;
  const classes = graphDisplayStyles();
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [favorites, setFavorites] = useState<Array<string>>([]);
  const [loaded, setLoaded] = useState(false);
  const disableFullscreen = useMediaQuery(`(max-width:500px)`, { noSsr: true }); // Less than 780px

  const setFav = () => {
    let charts = favorites;
    if (charts.includes(id)) {
      charts = charts.filter(function filter(e: string) {
        return e !== id;
      });
    } else {
      charts.push(id);
    }

    setFavorites(charts);
    changeFavorite(charts);
  };

  useEffect(() => {
    if (!userSettings.settings.favorites) return;
    setFavorites(userSettings.settings.favorites.fav_graphs || []);
    setLoaded(true);
  }, [userSettings]);

  useEffect(() => {
    if (favorites) {
      setLoaded(true);
    }
  }, [favorites]);

  const changeFavorite = (value: Array<string>) => {
    const copySettings = { ...userSettings };
    copySettings.settings.favorites.fav_graphs = value;
    setUserSettings(copySettings);
    saveToServer(copySettings.settings);
  };

  const getTooltipText = () => {
    if (favorites.includes(id)) return "Remove from favorite";
    return "Add to favorite";
  };

  return (
    <Box boxShadow={3}>
      <Paper className={classes.paper} variant="outlined">
        <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.textCenter}>
          <Grid item xs={1}>
            <Tooltip title={disableFullscreen ? "Device too small to view fullscreen" : "Advanced View"}>
              <span>
                <IconButton onClick={(): void => setOpen(!isOpen)} size="small" disabled={disableFullscreen}>
                  <FullscreenIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={8}>
            <>
              {description ? (
                <Tooltip title={<GraphDescription title={title} description={description} />}>
                  <Typography variant="h6">{title}</Typography>
                </Tooltip>
              ) : (
                <Typography variant="h6">{title}</Typography>
              )}

              <Typography variant="caption">
                Date Range: {dateRange.startDate?.toLocaleDateString()} - {dateRange.endDate?.toLocaleDateString()}
              </Typography>
            </>
          </Grid>

          <Grid item xs={1}>
            {/* <Tooltip title="Share on Social Media">
              <IconButton size="small">
                <ShareIcon />
              </IconButton>
            </Tooltip> */}
          </Grid>
          <Grid item xs={1}>
            {loaded && (
              <Tooltip title={getTooltipText()}>
                <IconButton onClick={() => setFav()} size="small">
                  {!favorites.includes(id) ? <FavouriteIcon /> : <FavouriteIconFilled style={{ color: "red" }} />}
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <ErrorBoundary fallbackRender={(props) => <ErrorBoundaryFallback {...props} title={title} />}>
          {children}
        </ErrorBoundary>
      </Paper>
    </Box>
  );
};
