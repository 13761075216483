/* eslint-disable no-console */
import { AxiosRequestConfig } from "axios";
import { apiGet, apiPost, apiDelete } from "../../api/actions";
import { ObjectType } from "../../types/graphs/ObjectType";

/**
 * Wraps a get request to specified API endpoint
 * @param endpointRoute Endpoint string URI
 * @param onSuccess Optional Setter method for the returned data
 * @param queryParameters Optional Object with keys / values to be passed as query parameters
 * @param config Optional AxiosRequestConfig object
 * @param OnFailedHook Callback for failure
 * @param baseURL Pass in what URL
 * @returns AxiosResponse.data
 */
export const getAction = async (
  endpointRoute: string,
  onSuccess: (responseData: any) => void = () => null,
  baseURL: string,
  queryParameters: ObjectType = {},
  config: AxiosRequestConfig = {},
  OnFailedHook?: (arg: any) => void,
) => {
  try {
    const result = await apiGet(endpointRoute, queryParameters, config, baseURL);
    // TODO: If Empty result.data throw error
    onSuccess(result.data);
    return result.data;
  } catch (error) {
    // TODO: Error handler
    if (OnFailedHook) {
      OnFailedHook(undefined);
    }
    console.error(error.message);
  }
  return null;
};

/**
 * Wraps a delete request to specified API endpoint
 * @param endpointRoute Endpoint string URI
 * @param baseURL
 * @param queryParameters Optional Object with keys / values to be passed as query parameters
 * @param data Optional Object with keys / values to be passed as post data
 * @param config Optional AxiosRequestConfig object
 * @returns AxiosResponse.data
 */
export const deleteAction = async (
  endpointRoute: string,
  baseURL: string,
  queryParameters: ObjectType = {},
  data: ObjectType = {},
  config: AxiosRequestConfig = {},
) => {
  try {
    const result = await apiDelete(endpointRoute, queryParameters, data, config, baseURL);
    // TODO: If Empty result.data throw error
    return result.data;
  } catch (error) {
    // TODO: Error handler
    // TODO: OnFailedHook();
    console.error(endpointRoute, error.message);
  }
  return null;
};

/**
 * Wraps a get request to specified API endpoint.
 * @param endpointRoute Endpoint string URI
 * @param onSuccess Optional Setter method for the returned data
 * @param queryParameters Optional Object with keys / values to be passed as query parameters
 * @param config Optional AxiosRequestConfig object
 * @param baseURL
 * @returns Promise
 */
export const getActionPromise = async (
  endpointRoute: string,
  onSuccess: (responseData: any) => void = () => null,
  queryParameters: ObjectType = {},
  baseURL: string,
  config: AxiosRequestConfig = {},
): Promise<any> => {
  try {
    const result = await apiGet(endpointRoute, queryParameters, config, baseURL);
    // TODO: If Empty result.data throw error
    onSuccess(result.data);
    return result;
  } catch (error) {
    // TODO: Error handler
    // TODO: OnFailedHook();
    console.error(endpointRoute, error.message);
    if (error.response) {
      console.error(endpointRoute, error.response.data.error);
    }
  }
  return null;
};

/**
 * Wraps a post request to specified API endpoint
 * @param endpointRoute Endpoint string URI
 * @param baseURL
 * @param queryParameters Optional Object with keys / values to be passed as query parameters
 * @param data Optional Object with keys / values to be passed as post data
 * @param config Optional AxiosRequestConfig object
 * @returns AxiosResponse.data
 */
export const postAction = async (
  endpointRoute: string,
  baseURL: string,
  queryParameters: ObjectType = {},
  data: ObjectType = {},
  config: AxiosRequestConfig = {},
) => {
  try {
    const result = await apiPost(endpointRoute, queryParameters, data, config, baseURL);
    // TODO: If Empty result.data throw error
    return result.data;
  } catch (error) {
    // TODO: Error handler
    // TODO: OnFailedHook();
    console.error(endpointRoute, error.message);
    if (error.response) {
      console.error(endpointRoute, error.response.data.error);
    }
    return error?.response?.data;
  }
};
