import * as chartjs from "chart.js";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";

const markerArrow = new Image(12, 20);

markerArrow.src = `${process.env.PUBLIC_URL}/images/wind-direction-yellow.png`;

const gustArrow = new Image(12, 20);
gustArrow.src = `${process.env.PUBLIC_URL}/images/wind-gust-blue.png`;

const IMAGE_OFFSET = 180; // degrees

export const initialWindData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Wind",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-wind",
        xAxisID: "x-axis",
        type: "bubble",
        pointStyle: markerArrow,
        rotation(dataset: any) {
          const { dataIndex } = dataset;
          return Number(dataset.dataset?.data?.[dataIndex]?.b) + IMAGE_OFFSET;
        },
      },
      {
        label: "Wind Gust",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-wind",
        xAxisID: "x-axis",
        type: "bubble",
        pointStyle: gustArrow,
        rotation(dataset: any) {
          const { dataIndex } = dataset;
          return Number(dataset.dataset?.data?.[dataIndex]?.b) + IMAGE_OFFSET;
        },
      },
    ],
  };
};

export const initialWindOptions = (): chartjs.ChartOptions => {
  return {
    tooltips: {
      mode: "point",
      intersect: false,
      callbacks: {
        label(tooltipItem: any, data: any): string {
          const { index } = tooltipItem;
          const bearing = data.datasets[tooltipItem.datasetIndex]?.data?.[index]?.b;
          const degreeSymbol = `\xB0`;

          return `${Number(tooltipItem.yLabel)} kph at ${bearing}${degreeSymbol}`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-wind",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Wind Speed (kph)",
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          position: "bottom",
          ticks: {
            source: "labels",
            autoSkip: true,
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: "day",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
