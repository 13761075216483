import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { unitRounder } from "../../../../helpers/timeHelpers";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { initialParticulateData } from "./useInitialAirQualityData";
import { initialParticulateOptions } from "./useInitialAirQualityOptions";
import { stringToDatasetIndex } from "./useProcessElementsData";

export const useProcessPMData = (airElementsData: NewESResponseDataType): any => {
  const outputParticulateMatterOptions = initialParticulateOptions();

  const particleData = initialParticulateData();

  if (airElementsData?.Values && haveKeys([airElementsData.Values])) {
    // Populate labels
    particleData.labels = airElementsData?.Values?.map((x) => Number(+new Date(x[timestampKey])));

    // Populate data

    airElementsData.Values.forEach((airObject) => {
      Object.keys(airObject).forEach((key) => {
        if (key === "PM10" || key === "PM25") {
          pushTupleData(particleData, stringToDatasetIndex(particleData, key), {
            x: Number(+new Date(airObject[timestampKey])),
            y: unitRounder(+airObject[key]),
          });
        }
      });
    });
  }

  return {
    data: particleData,
    options: outputParticulateMatterOptions,
  };
};
