import * as chartjs from "chart.js";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { selectedIntervalGraphXAxisUnit } from "../../../../../helpers/timeGraphUnitConstants";
import { secondsInDay } from "../../../../../helpers/timeConstants";

export const NORMALIZATION_HOURS = 3600 * 6;
const sleepBarColor = "#1f78b4";

export const initialSleepTimeData = () => {
  const labels = [] as Array<any>;
  return {
    labels,
    datasets: [
      {
        label: "Sleep Time1",
        data: [] as Array<Array<number>>, // Array of arrays
        yAxisID: "y-axis-sleep-time",
        xAxisID: "x-axis1",
        backgroundColor: sleepBarColor,
      },
      {
        label: "Sleep Time2",
        data: [] as Array<Array<number>>, // Array of arrays
        yAxisID: "y-axis-sleep-time",
        xAxisID: "x-axis2",
        backgroundColor: sleepBarColor,
      },
      {
        label: "Sleep Time3",
        data: [] as Array<Array<number>>, // Array of arrays
        yAxisID: "y-axis-sleep-time",
        xAxisID: "x-axis3",
        backgroundColor: sleepBarColor,
      },
    ],
  };
};
export const sleepTimeOptions = (selectedInterval: string) => {
  return {
    tooltips: {
      mode: "point",
      intersect: false,
      callbacks: {
        title(tooltipItem: any): string {
          const titleDate: Date = parse(tooltipItem[0].label, "MMM d, yyyy h:ss aa", new Date());
          return `${format(titleDate, "MMM do yyyy")}`;
        },
        label(tooltipItem: any, data: any): string {
          const { datasetIndex } = tooltipItem;
          const { index } = tooltipItem;

          const res = data.datasets[datasetIndex]?.data?.[index];
          const startTime = -res[1] - NORMALIZATION_HOURS;
          const endTime = -res[0] - NORMALIZATION_HOURS;
          if (res?.[0] && res[1]) {
            // Should be 12am on this day
            const todayStartDate = +new Date(tooltipItem.xLabel);
            const todayStartOffsetMillis = startTime * 1000;
            const startTotal = format(new Date(todayStartDate + todayStartOffsetMillis), "h:mm");

            const todayEndDate = +new Date(tooltipItem.xLabel);
            const todayEndOffsetMillis = endTime * 1000;
            const endTotal = format(new Date(todayEndDate + todayEndOffsetMillis), "h:mm");

            // If person slept on the night before (i,e, before 12am)
            if (Math.abs(res[0]) < NORMALIZATION_HOURS || Math.abs(res[1]) < NORMALIZATION_HOURS) {
              return `${endTotal} to ${startTotal}`;
            }
            return `${startTotal} to ${endTotal}`;
          }

          return ``;
        },
      },
    },

    responsive: true,

    legend: {
      display: false,
    },
    scales: {
      maintainAspectRatio: false,

      xAxes: [
        {
          id: "x-axis1",
          type: "time",
          offset: true,
          ticks: {
            source: "auto",
            autoSkip: true,
          },
          time: {
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
            tooltipFormat: "lll",
          },
        },
        {
          id: "x-axis2",
          type: "time",
          offset: true,
          display: false,
          ticks: {
            source: "auto",
            autoSkip: true,
          },
          time: {
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
            tooltipFormat: "lll",
          },
        },
        {
          id: "x-axis3",
          type: "time",
          offset: true,
          display: false,
          ticks: {
            source: "auto",
            autoSkip: true,
          },
          time: {
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
            tooltipFormat: "lll",
          },
        },
      ],
      yAxes: [
        {
          type: "linear",
          id: "y-axis-sleep-time",
          scaleLabel: {
            display: false,
            labelString: "Time",
          },
          ticks: {
            display: true,
            min: -secondsInDay,
            max: 0,
            stepSize: 3600,
            beginAtZero: false,
            callback(label: number): string {
              const time = ((label + NORMALIZATION_HOURS) % secondsInDay) / 60 / 60;
              if (time === -12) {
                return `Noon`;
              }
              if (time === 0) {
                return `Midnight`;
              }
              if (time > 0) {
                return `${12 - time} pm`;
              }
              return `${time < -12 ? `${-time - 12} pm` : `${-time} am`}`;
            },
          },
        },
      ],
    },
  } as chartjs.ChartOptions;
};
