import { useMemo } from "react";
import * as chartjs from "chart.js";
import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { minutesToHourMinute } from "../../../../helpers/timeHelpers";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { useAddMultiDatasetMovingAverage } from "../../../customHooks/useAddMultiDatasetMovingAverage";

const DEEP_SECONDS_STR = "deep_seconds";
const WAKE_SECONDS_STR = "awake_seconds";
const LIGHT_SECONDS_STR = "light_seconds";
const REM_SECONDS_STR = "rem_seconds";
const ASLEEP_SECONDS_STR = "asleep_seconds";

export const sleepProcessor = (fetchedSleepData: NewESResponseDataType, sleepLineData: any) => {
  if (fetchedSleepData?.Values && haveKeys([fetchedSleepData.Values])) {
    const DEEP_BAR = 0;
    const REM_BAR = 1;
    const LIGHT_BAR = 2;
    const WAKE_BAR = 3;

    fetchedSleepData.Values.forEach((sleepObj) => {
      // If general sleep is provided, display it as light sleep
      const lightOrAsleep =
        +sleepObj[LIGHT_SECONDS_STR] > 0 ? +sleepObj[LIGHT_SECONDS_STR] / 60 : +sleepObj[ASLEEP_SECONDS_STR] / 60;

      const timestamp = +new Date(sleepObj[timestampKey]);
      if (
        +sleepObj[DEEP_SECONDS_STR] +
          +sleepObj[REM_SECONDS_STR] +
          +sleepObj[LIGHT_SECONDS_STR] +
          +sleepObj[ASLEEP_SECONDS_STR] +
          +sleepObj[WAKE_SECONDS_STR] >
        0
      ) {
        sleepLineData.labels.push(timestamp);
        pushTupleData(sleepLineData, DEEP_BAR, { x: timestamp, y: +sleepObj[DEEP_SECONDS_STR] / 60 });
        pushTupleData(sleepLineData, REM_BAR, { x: timestamp, y: +sleepObj[REM_SECONDS_STR] / 60 });
        pushTupleData(sleepLineData, LIGHT_BAR, { x: timestamp, y: lightOrAsleep });
        pushTupleData(sleepLineData, WAKE_BAR, { x: timestamp, y: +sleepObj[WAKE_SECONDS_STR] / 60 });
      }
    });
  }
  return sleepLineData;
};

export const useProcessSleepData = (fetchedSleepData: NewESResponseDataType): any => {
  const sleepLineData = initialSleepData();

  const outputSleepData = useMemo(() => {
    return sleepProcessor(fetchedSleepData, sleepLineData);
  }, [fetchedSleepData]);

  useAddMultiDatasetMovingAverage(outputSleepData, "Sleep", "y-axis-sleep-avg", true, true, false);
  return { outputSleepData };
};

export const initialSleepData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Deep",
        data: [] as Array<TupleDataType>,
        backgroundColor: "#000072",
        yAxisID: "y-axis-sleep",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "REM",
        data: [] as Array<TupleDataType>,
        backgroundColor: "#0000cd",
        yAxisID: "y-axis-sleep",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Light",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-sleep",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Awake",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-sleep",
        xAxisID: "x-axis",
        type: "bar",
      },
    ],
  };
};

export const sleepOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      mode: "point",
      intersect: false,
      callbacks: {
        footer(toolTipItem: any, data: any): string {
          const { datasetIndex } = toolTipItem[0];

          const dataIndex = toolTipItem[0].index;
          if (data.datasets?.[datasetIndex]?.label?.includes("Pt Avg")) {
            return "";
          }
          return `Total ${minutesToHourMinute(
            Number(data.datasets[0].data[dataIndex].y ?? 0) +
              Number(data.datasets[1].data[dataIndex].y ?? 0) +
              Number(data.datasets[2].data[dataIndex].y ?? 0),
            true,
          )}`;
        },
        label(tooltipItem: any, data: any): string {
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${minutesToHourMinute(
            Number(tooltipItem.yLabel),
            true,
          )}`;
        },
      },
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          id: "x-axis",
          offset: true,
          type: "time",
          ticks: {
            source: "labels",
            autoSkip: true,
          },
          time: {
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
            tooltipFormat: "lll",
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          id: "y-axis-sleep",
          stacked: true,
          ticks: {
            callback(label: any): string {
              return +label % 60 === 0 ? minutesToHourMinute(Number(label), false) : "";
            },
            stepSize: 15,
          },
          scaleLabel: {
            display: true,
            labelString: "Hours Asleep",
          },
        },
        {
          id: "y-axis-sleep-avg",
          stacked: false,
          ticks: {
            callback(label: any): string {
              return +label % 60 === 0 ? minutesToHourMinute(Number(label), false) : "";
            },
            stepSize: 15,
            min: 0,
          },
          position: "right",
          scaleLabel: {
            display: true,
            labelString: "Avg",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
