import React, { useContext } from "react";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Drawer from "@material-ui/core/Drawer";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useMediaQuery } from "@material-ui/core";
import { useNavigationDrawerStyles } from "../navigationHelpers";
import { SideNavigationContext } from "../../../helpers/context/contextVariables";
import { Logo } from "../navigationBar/Logo";
import { SideNavigation, twoColBreakpointPx } from "./SideNavigation";

interface NavigationDrawerInterface {
  handleDrawer: () => void;
}

export const SideNavigationDrawer = ({ handleDrawer }: NavigationDrawerInterface): JSX.Element => {
  const { isSideNavOpen, setSideNavOpen } = useContext(SideNavigationContext);
  const isSmall = useMediaQuery(`(max-width:${twoColBreakpointPx}px)`, { noSsr: true }); // Less than 780px

  const classes = useNavigationDrawerStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant={isSmall ? undefined : "persistent"}
      anchor="left"
      open={isSideNavOpen}
      onClose={() => {
        setSideNavOpen(false);
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        // Prevent tree calculation each open
        keepMounted: true,
      }}
    >
      <Scrollbars>
        {/* SIDE NAVIGATION DRAWER TOGGLE */}
        <div className={classes.drawerHeader}>
          <Logo />
          <IconButton onClick={handleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        {/* SIDE NAVIGATION CONTENT */}
        <SideNavigation />
      </Scrollbars>
    </Drawer>
  );
};
