import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

interface CustomIframePros {
  src: string;
  title: string;
  top?: number;
}

export const CustomIFrame = ({ src, title, top }: CustomIframePros): JSX.Element => {
  const [isLoaded, setIsLoad] = React.useState<boolean>(false);

  return (
    <div className="container rsvp-wrapper">
      {!isLoaded && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}

      <iframe
        src={src}
        onLoad={() => {
          setTimeout(() => setIsLoad(true), 500);
        }}
        title={title}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: top ?? "-40px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        }}
      />
    </div>
  );
};
