import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { RHRAnnotationsType } from "../../body/RestingHeartRateLineGraph/restingHeartRateTypes";
import { average, standardDeviation } from "../../../../helpers/components/graphHelpers";

const PERSONAL_RANGE_STRING = "In Personal Range";
const NUM_OF_STD_DEVIATIONS = 2.0;

export const NUM_BUCKETS = 20;
export const NUM_LABELS = 6;

const STEPS_LINE_COLOR = "RED";

export const initialStepsLine = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "line",
        label: "Step Count",
        data: [] as Array<number>,
        fill: false,
        yAxisID: "y-axis-steps-line",
        xAxisID: "x-axis",
        borderColor: STEPS_LINE_COLOR,
        backgroundColor: STEPS_LINE_COLOR,
        pointBackgroundColor: STEPS_LINE_COLOR,
        pointBorderColor: STEPS_LINE_COLOR,
        pointHoverBackgroundColor: STEPS_LINE_COLOR,
        pointHoverBorderColor: STEPS_LINE_COLOR,
      },
    ] as Array<any>,
  };
};

export const initialStepsHistogram = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        type: "bar",
        label: "Steps Histogram",
        data: [] as Array<number>,
        fill: false,
        yAxisID: "y-axis-steps-hist",
        xAxisID: "x-axis",
      },
    ] as Array<any>,
  };
};

export type StepsProcessedType = {
  stepsLineData: { datasets: ObjectType[]; labels: number[] };
};

export const initialStepsLineOptions = (data: StepsProcessedType, selectedInterval: string) => {
  if (data) {
    // Find the std deviation
    const stepsData = data.stepsLineData.datasets[0].data
      .filter((tupleObj: TupleDataType) => tupleObj.y > 0)
      .map((tupleObj: TupleDataType) => tupleObj.y);
    // We first calculate your baseline RHR with up to 30 days of data.
    const baselineRHR = average(stepsData);
    const stdDev = standardDeviation(stepsData);

    // Your personal range is plus or minus 2 standard deviations from your baseline.
    let personalRangeLower = baselineRHR;
    personalRangeLower -= stdDev * NUM_OF_STD_DEVIATIONS;

    let personalRangeUpper = baselineRHR;
    personalRangeUpper += stdDev * NUM_OF_STD_DEVIATIONS;

    return {
      tooltips: {
        intersect: false,
      },
      responsive: true,
      annotation: data.stepsLineData.datasets[0].data?.length
        ? annotationPluginOptions(PERSONAL_RANGE_STRING, personalRangeLower, personalRangeUpper)
        : undefined,
      scales: scalesOptions(selectedInterval),
    };
  }
  return {
    responsive: true,
    scales: scalesOptions(selectedInterval),
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
    },
  };
};

const scalesOptions = (selectedInterval: string) => {
  return {
    yAxes: [
      {
        id: "y-axis-steps-line",
        type: "linear",
        ticks: {
          min: 0,
        },
      },
    ],
    xAxes: [
      {
        id: "x-axis",
        type: "time",
        time: {
          displayFormats: { day: localDateFormatMonthDay },
          unit: selectedIntervalGraphXAxisUnit[selectedInterval]
            ? selectedIntervalGraphXAxisUnit[selectedInterval]
            : "day",
          tooltipFormat: "lll",
        },
        ticks: {
          minor: {
            autoSkip: true,
            source: "auto",
          },
          major: {
            enabled: true,
          },
        },
        scaleLabel: {
          display: true,
        },
      },
    ],
    maintainAspectRatio: false,
  };
};

const stepSizeCalc = (size: number) => {
  if (size <= 10) return 1;
  if (size <= 25) return 5;
  if (size > 100) return 25;
  return undefined;
};

export const initialStepsHistogramOptions = (
  histogramMaxCount: number,
  min: number,
  max: number,
  stepBucketSize: number,
) => {
  let minX = Math.floor(min / stepBucketSize) * stepBucketSize;
  minX -= stepBucketSize;
  let maxX = Math.ceil(max / stepBucketSize) * stepBucketSize;
  maxX += stepBucketSize;
  return {
    scales: {
      yAxes: [
        {
          id: "y-axis-steps-hist",
          type: "linear",
          ticks: {
            stepSize: stepSizeCalc(histogramMaxCount),
          },
          scaleLabel: {
            display: true,
            labelString: `Counts`,
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          ticks: {
            min: minX,
            max: maxX,
            stepSize: stepBucketSize,
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: true,
    },
    tooltips: {
      intersect: true,
      mode: "x",
      callbacks: {
        title(tooltipItem: any, data: any): string {
          const initValue = tooltipItem[0].label;
          const nextValue = +initValue + +data.stepBucketSize;
          return `Between ${initValue} - ${nextValue}`;
        },
        label(tooltipItem: any): string {
          return `Count: ${tooltipItem.value}`;
        },
      },
    },
  };
};

const annotationPluginOptions = (title: string, rangeMin: number, rangeMax: number): RHRAnnotationsType => {
  return {
    annotations: [
      // CREATE BOX FOR PERSONAL RANGE
      {
        type: "box",
        // ID of the X and y scale to bind onto, default is 'x' and 'y'
        yScaleID: "y-axis-steps-line",
        yMin: rangeMin,
        yMax: rangeMax,
        backgroundColor: "rgba(255, 99, 132, 0.15)",
      },
      // CREATE LINE AT TOP OF PERSONAL RANGE
      {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-steps-line",
        value: rangeMax,
        borderColor: "black",

        // LABEL
        label: {
          content: title,
          enabled: true,
        },
      },
      // CREATE LINE AT BOTTOM OF PERSONAL RANGE
      {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-steps-line",
        value: rangeMin,
        borderColor: "black",
      },
    ],
  };
};
