import React from "react";
import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";
import {
  AirElementsMetaData,
  AQIMetadata,
  PMMetadata,
  WeatherPollenLineGraphMetadata,
} from "../../../../helpers/graphsMetadata";

export const AirPage = (): JSX.Element => {
  const airGraphs = [AQIMetadata.id, PMMetadata.id, AirElementsMetaData.id, WeatherPollenLineGraphMetadata.id];

  return <GraphListWrapper graphNameList={airGraphs} />;
};
