import React from "react";
import { Line } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { LocationQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { API_WEATHER, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { defaultGraphHeight, defaultGraphWidth, TITLE_VISIBILITY } from "../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { WeatherTemperatureLineGraphMetadata } from "../../../../helpers/graphsMetadata";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { useProcessVisibility } from "./useProcessVisibility";

export const VisibilityGraph = () => {
  const graphObject = useGraphSetup(LocationQueryParams.defaultWeatherParams, API_WEATHER, DATA_SERVICE_URL);
  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessVisibility(graphData);

  const graphDisplay = <Line data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;
  // TODO: spk Links
  /*
  https://en.wikipedia.org/wiki/Cloud_cover
  https://en.wikipedia.org/wiki/Sky
  https://en.wikipedia.org/wiki/Cloud
  https://en.wikipedia.org/wiki/Okta
  https://en.wikipedia.org/wiki/Visibility
  https://en.wikipedia.org/wiki/Distance
  https://en.wikipedia.org/wiki/Transparency_and_translucency
  https://en.wikipedia.org/wiki/Atmosphere_of_Earth
   */
  const description = [
    "Cloud cover refers to the fraction of the sky obscured by clouds when observed from a",
    " particular location.  ",
    "Okta is the usual unit of measurement of the cloud cover. ",
    "Visibility is a measure of the distance at which an object or light can be clearly discerned. It depends only" +
      " on the transparency of the surrounding air; as such, it is unchanging no matter the ambient light level or time of day. ",
    " Your personal outdoor cover and visibility is recorded so that you can better understand the impact it has on your life (e.g.  outdoor time, exercise frequency, exercise performance, symptoms, mood, etc.).",
  ];
  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_VISIBILITY}
        title={TITLE_VISIBILITY}
        calendarProps={graphObject}
        id={WeatherTemperatureLineGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_VISIBILITY} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
