import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Radio from "@material-ui/core/Radio";
import { AgeSettingsInterface } from "../../../types/settings/settings";
import { SettingsGroups } from "../../../types/general/UserSettingsContext";

interface AgeGroupProps {
  classes: any;
  settings: AgeSettingsInterface;
  setSettings: (key: string, value: any, group: string) => void;
}
const AgeContainer = ({ classes, settings, setSettings }: AgeGroupProps) => {
  const [age, setAge] = useState({
    option: settings.option,
    bday: settings.dob,
  });

  const [dateFormat, setDateFormat] = useState<string>("yyyy");
  const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    setSettings(inputName, inputValue, SettingsGroups.AGE);
  };

  const handleDateChange = (date: Date | null) => {
    if (date != null) {
      setAge({
        ...age,
        bday: date.toLocaleDateString(),
      });

      setSettings("dob", date.toLocaleDateString(), SettingsGroups.AGE);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    setAge({
      ...age,
      [inputName]: inputValue,
    });
  };

  useEffect(() => {
    if (age.option === "date") setDateFormat("MM/dd/yyyy");
    else setDateFormat("yyyy");
  }, [age.option]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
          Age:
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <FormControl component="fieldset">
          <RadioGroup aria-label="option" name="option" value={age.option} onBlur={blurHandler} onChange={handleChange}>
            <FormControlLabel value="date" control={<Radio />} label="Date of Birth" />
            <FormControlLabel value="year" control={<Radio />} label="Year of Birth" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            name="bday"
            label="Select date"
            format={dateFormat}
            value={age.bday}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

export default AgeContainer;
