import { useMemo } from "react";
import * as chartjs from "chart.js";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { useAddMultiDatasetMovingAverage } from "../../../customHooks/useAddMultiDatasetMovingAverage";

const webActivityProcessor = (fetchedSleepData: NewESResponseDataType, webActivityDatasets: any) => {
  if (fetchedSleepData?.Values && haveKeys([fetchedSleepData.Values])) {
    fetchedSleepData.Values.forEach((sleepObj: ObjectType) => {
      const timestamp = +new Date(sleepObj[timestampKey]);

      let totalCount = 0;
      const finalPush: { index: number; data: TupleDataType }[] = [];

      webActionIndexMapping.forEach((actionList) => {
        const exists = sleepObj?.activities?.find(
          (action: ObjectType) => action.name.toLowerCase() === actionList.toLowerCase(),
        );

        totalCount += exists ? exists.count : 0;

        finalPush.push({ index: webActionIndex(actionList), data: { x: timestamp, y: exists ? exists.count : 0 } });
      });

      if (totalCount > 0) {
        webActivityDatasets.labels.push(timestamp);
        finalPush.forEach((item) => {
          pushTupleData(webActivityDatasets, item.index, item.data);
        });
      }
    });
  }
  return webActivityDatasets;
};

export const useProcessWebActivityGraph = (fetchedWebData: NewESResponseDataType) => {
  const webActivityDatasets = initialWebActivityDatasets();

  const outputWebActivityData = useMemo(() => {
    return webActivityProcessor(fetchedWebData, webActivityDatasets);
  }, [fetchedWebData]);

  useAddMultiDatasetMovingAverage(outputWebActivityData, "Web Activity", "y-axis-web-activity-avg", false, true, false);
  return { outputWebActivityData };
};

/**
 * Given a string, get the index it belongs to
 */
const webActionIndex = (input: string) => {
  return webActionIndexMapping.findIndex((item) => item === input);
};

export const initialWebActivityDatasets = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Dismissed",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Liked",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Visited",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Viewed",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Read",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Listened",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Watched",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Played",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Searched",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Used",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Navigated",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Installed",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Initiated",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Requested",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Downloaded",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Managed",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
      {
        label: "Received",
        data: [] as Array<TupleDataType>,
        yAxisID: "y-axis-web-activity",
        xAxisID: "x-axis",
        type: "bar",
      },
    ],
  };
};

const webActionIndexMapping = initialWebActivityDatasets().datasets.map((dataset) => dataset.label);

export const webActivityOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      mode: "point",
      intersect: true,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          id: "x-axis",
          offset: true,
          type: "time",
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
          time: {
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
            tooltipFormat: "lll",
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          id: "y-axis-web-activity",
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: "Actions",
          },
        },
        {
          id: "y-axis-web-activity-avg",
          stacked: false,
          position: "right",
          scaleLabel: {
            display: true,
            labelString: "Avg",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
