import { useMemo } from "react";
import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { unitRounder } from "../../../../helpers/timeHelpers";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { initialTotalsData } from "./useShoppingDefaults";
import { useAddSimpleMovingAverage } from "../../../customHooks/useAddSimpleMovingAverage";
import { ShoppingAggregatedType } from "./shoppingTypes";

export const useProcessShopData = (shoppingData: NewESResponseDataType): any => {
  const outputData = useMemo(() => {
    const data = initialTotalsData();

    if (shoppingData?.Values && haveKeys([shoppingData.Values])) {
      // eslint-disable-next-line no-extra-parens
      (shoppingData.Values as ShoppingAggregatedType[]).forEach((value) => {
        const timestamp = +new Date(value[timestampKey]);
        data.labels.push(timestamp);
        pushTupleData(data, 0, { x: timestamp, y: unitRounder(+value.total) });
      });
    }

    return data;
  }, [shoppingData]);

  useAddSimpleMovingAverage(outputData);

  return { outputData };
};
