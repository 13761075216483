import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { IdentityContext } from "../../../types/general/IdentityContext";
import { loginStyles } from "../../pages/Login/loginPageHelper";
import { useLogout } from "../../../components/customHooks/useLogout";

export const UserDetail = (): JSX.Element => {
  const { userData } = useContext(IdentityContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: "36ch",
      backgroundColor: theme.palette.background.paper,
    },
    settingsParent: {
      "&:hover $settingsChild": {
        color: "black",
      },
    },
    settingsChild: {},
    uploadParent: {
      "&:hover $uploadChild": {
        color: "blue",
      },
    },
    uploadChild: {},
    logoutParent: {
      "&:hover $logoutChild": {
        color: "red",
      },
    },
    logoutChild: {},
  }));

  /**
   * Opens the user detail component on click
   */
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the user detail component
   */
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  const { logout } = useLogout();

  return (
    <div>
      <IconButton edge="end" onClick={handleClick}>
        <Avatar {...avatarFallback(userData.userName)} src={`${userData.userPicURL}`} />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div>
          <Grid container direction="column" alignItems="center">
            <Box m={1} />

            <Typography variant="body1">{userData.userName}</Typography>
            <Typography variant="caption">{userData.userEmail}</Typography>

            <Box m={1} />

            {/* Line Separator */}
            <hr className={loginStyles().hr} />
            {/* Line Separator */}

            <List component="nav" aria-label="secondary mailbox folders" className={classes.root}>
              <ListItem
                button
                onClick={() => {
                  history.push("/settings");
                  handleClose();
                }}
                className={classes.settingsParent}
              >
                <ListItemIcon className={classes.settingsChild}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>

              <ListItem
                button
                onClick={() => {
                  history.push("/settings/data-upload");
                  handleClose();
                }}
                className={classes.uploadParent}
              >
                <ListItemIcon className={classes.uploadChild}>
                  <CloudUploadIcon />
                </ListItemIcon>
                <ListItemText primary="Upload Data" />
              </ListItem>

              <ListItem
                button
                onClick={() => {
                  logout();
                }}
                className={classes.logoutParent}
              >
                <ListItemIcon className={classes.logoutChild}>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </ListItem>
            </List>
          </Grid>
        </div>
      </Popover>
    </div>
  );
};

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
const avatarFallback = (name: string | null) => {
  let displayInitials = "";
  const FALLBACK_INITIALS = "P"; // P for Profile
  if (!name) {
    displayInitials = FALLBACK_INITIALS; // P for Profile
  } else {
    const nameSplit = name?.split(" ");
    if (nameSplit?.length > 0) {
      displayInitials += nameSplit[0].charAt(0);
      if (nameSplit[1]) {
        displayInitials += nameSplit[1].charAt(0);
      }
    } else {
      displayInitials = FALLBACK_INITIALS;
    }
  }

  return {
    sx: {
      bgcolor: stringToColor(name || FALLBACK_INITIALS),
    },
    children: `${displayInitials}`,
  };
};
