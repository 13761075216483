import { FormHelperText } from "@material-ui/core";
import { addSeconds } from "date-fns";
import React from "react";
import { VariationType } from "../handleUpload";

interface Props {
  variation: VariationType;
  varyValue: number;
  isDateVariation: boolean;
}

export const VariationHelper = ({ variation, varyValue, isDateVariation }: Props) => {
  return (
    <FormHelperText id="helper-text-range">
      {varyValue !== null && (
        <>
          <strong>File {variation.fieldName} value:</strong> {JSON.stringify(varyValue)} <br />
          {isDateVariation ? (
            <>
              <strong>Range:</strong> {addSeconds(new Date(varyValue), variation.min).toLocaleString()} to{" "}
              {addSeconds(new Date(varyValue), variation.max).toLocaleString()}
            </>
          ) : (
            <>
              <strong>Will generate range:</strong> {varyValue + variation.min} to {varyValue + variation.max}
            </>
          )}
        </>
      )}
    </FormHelperText>
  );
};
