import { endOfDay, startOfDay } from "date-fns/esm";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { ObjectType } from "../../../../types/graphs/ObjectType";

export interface CalendarRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export const setStartEndDate = (dateRange: CalendarRange): ObjectType => {
  return {
    startDate: startOfDay(dateRange?.startDate as Date),
    endDate: endOfDay(dateRange?.endDate as Date),
    key: "selection",
  };
};

export const calendarStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);
