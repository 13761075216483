import React, { useState } from "react";
import _ from "lodash";
import { Pie } from "react-chartjs-2";

import { Box, Grid, LinearProgress } from "@material-ui/core";
import { ActivityQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { initialNetflixPieOptions } from "./netflixOptions";
import { defaultGraphHeight, defaultGraphWidth, TITLE_NETFLIX_PIE_TITLE } from "../../../../helpers/graphConstants";
import { API_CONSUME_NETFLIX_ACTIVITY, DATA_SERVICE_URL_OLD } from "../../../../api/apiConstants";
import { useProcessTitlesData } from "./useProcessTitlesData";
import { netflixPieIMDBURL } from "../../../../helpers/components/graphHelpers";
import { PROFILE_ALL_STRING } from "./netflixHelper";
import { NetflixProfileSelector } from "./NetflixProfileSelector";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { NetflixPieChartMetadata } from "../../../../helpers/graphsMetadata";

export const NetflixPieChart = (): JSX.Element => {
  const [profile, setProfile] = useState<string>(_.cloneDeep(PROFILE_ALL_STRING));
  const [profileList, setProfileList] = useState<string[]>([]);

  const graphObject = useGraphSetup(
    ActivityQueryParams.defaultNetflixViewingQueryParams,
    API_CONSUME_NETFLIX_ACTIVITY,
    DATA_SERVICE_URL_OLD,
    true,
  );
  const { graphData, isLoaded, elemRef } = graphObject;

  const outputTotalTitlesData = useProcessTitlesData(graphData, profile, setProfileList);

  const graphDisplay = (
    <Pie
      data={outputTotalTitlesData}
      options={initialNetflixPieOptions()}
      width={defaultGraphWidth}
      height={defaultGraphHeight}
      getElementAtEvent={(event): void => {
        const url = netflixPieIMDBURL(event, outputTotalTitlesData, "_index");
        if (url) window.open(url);
      }}
    />
  );

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_NETFLIX_PIE_TITLE}
        title={TITLE_NETFLIX_PIE_TITLE}
        calendarProps={graphObject}
        id={NetflixPieChartMetadata.id}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_NETFLIX_PIE_TITLE} graphDisplay={graphDisplay} calendarProps={graphObject}>
          <Grid container>
            <NetflixProfileSelector profile={profile} setProfile={setProfile} profileList={profileList} />
          </Grid>
        </GraphContainer>
      </GraphHeader>
    </div>
  );
};
