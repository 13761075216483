import React, { useContext, useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AgeContainer from "../../../../components/settings/Profile/AgeGroup";
import LocationContainer from "../../../../components/settings/Profile/LocationGroup";
import IdentityContainer from "../../../../components/settings/Profile/IdentityGroup";
import ConnectedAccount from "../../../../components/settings/Profile/ConnectedAccountsGroup";
import { saveToServer } from "../../../../helpers/settingsHelper";
import { UserSettingsContext, initialSettingsState } from "../../../../types/general/UserSettingsContext";
import { IdentityContext } from "../../../../types/general/IdentityContext";
import { SideNavigationContext } from "../../../../helpers/context/contextVariables";
import { GeneralPage } from "./General";

export const useProfileStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "75ch",
    },
    textFieldSmall: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "35ch",
    },
    subtitle: {
      color: theme.palette.text.secondary,
    },
    paper: {
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(10),
    },
    large: {
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    center: { textAlign: "center" },
  }),
);

export const ProfilePage = () => {
  const classes = useProfileStyles();
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const { userData } = useContext(IdentityContext);
  const notInitialSave = useRef(false);
  const [settings, setSettings] = React.useState<any>(initialSettingsState);

  const [dateFormat, setDateFormat] = React.useState<string>("yyyy");

  const changeSettings = (field: string, value: any, group?: any) => {
    setSettings(() => ({
      ...settings,
      profile: {
        ...settings.profile,
        [group]: {
          ...settings.profile[group],
          [field]: value,
        },
      },
    }));
  };

  const { setSideNavOpen } = useContext(SideNavigationContext);

  useEffect(() => {
    // Open side nav on page load
    setSideNavOpen(true);
  }, []);

  const changeLocation = (field: string, value: any) => {
    setSettings(() => ({
      ...settings,
      profile: {
        ...settings.profile,
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    if (userSettings.settings && settings.profile === undefined) {
      setSettings(userSettings.settings);
    }
  }, [userSettings]);

  useEffect(() => {
    setDateFormat(dateFormat);
  }, [dateFormat]);

  useEffect(() => {
    if (settings.profile === undefined) return;
    if (notInitialSave.current) {
      saveToServer(settings);
      const copySettings = { ...userSettings };
      copySettings.settings = settings;
      setUserSettings(copySettings);
    } else {
      notInitialSave.current = true;
    }
  }, [settings]);

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Typography component="div">
          <Box fontSize="h3.fontSize" className={classes.center} fontWeight={700} mt={3} mb={1.5}>
            Profile
          </Box>
          {settings?.profile?.location && (
            <div>
              <Divider />
              <Box fontSize="h5.fontSize" fontWeight={700} mt={3} mb={1.5}>
                Identity
              </Box>
              <IdentityContainer
                settings={settings.profile.identity}
                user={userData}
                classes={classes}
                setSettings={changeSettings}
              />
              <Box m={3} />
              <Divider />
              <Box fontSize="h5.fontSize" fontWeight={700} mt={3} mb={1.5}>
                Personal details (Optional)
              </Box>

              <AgeContainer classes={classes} settings={settings.profile.age} setSettings={changeSettings} />
              <Box m={3} />
              <Divider />
              <Box fontSize="h5.fontSize" fontWeight={700} mt={3} mb={1.5}>
                Location details (Optional)
              </Box>

              <LocationContainer classes={classes} settings={settings.profile.location} setSettings={changeLocation} />
            </div>
          )}
        </Typography>

        <GeneralPage />

        <Box m={3} />
        <Divider />
        <Box fontSize="h5.fontSize" fontWeight={700} mt={3} mb={1.5}>
          Account linking
        </Box>
        <ConnectedAccount />
      </Paper>
    </>
  );
};
