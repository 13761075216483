export const exerciseRunContent = `{
    "tags": [
      {
        "tag": "cardio"
      },
      {
        "tag": "exercise"
      }
    ],
    "timestamp": "2024-02-19T16:30:00.000-05:00",
    "duration": 2400,
    "type": "exercise",
    "name": "run",
    "is_standard": false,
    "is_archived": false,
    "custom_data": [
      {
        "key": "distance",
        "value": 4.3
      }
    ]
  }`;

export const exerciseWalkContent = `{
    "tags": [
      {
        "tag": "cardio"
      },
      {
        "tag": "exercise"
      }
    ],
    "timestamp": "2024-02-19T16:30:00.000-05:00",
    "duration": 1500,
    "type": "exercise",
    "name": "walk",
    "is_standard": false,
    "is_archived": false,
    "custom_data": [
      {
        "key": "distance",
        "value": 1.0
      }
    ]
  }`;

export const exerciseYogaContent = `{
    "tags": [
      {
        "tag": "yoga"
      },
      {
        "tag": "exercise"
      }
    ],
    "timestamp": "2024-02-19T16:30:00.000-05:00",
    "duration": 1800,
    "type": "exercise",
    "name": "yoga",
    "is_standard": false,
    "is_archived": false,
    "custom_data": null
  }
  `;

export const exercisePickleballContent = `{
    "tags": [
      {
        "tag": "cardio"
      },
      {
        "tag": "exercise"
      }
    ],
    "timestamp": "2024-02-19T16:30:00.000-05:00",
    "duration": 3000,
    "type": "exercise",
    "name": "pickleball",
    "intensity": 6,
    "is_standard": false,
    "is_archived": false,
    "custom_data": null
  }
  `;

export const exerciseP90xChestShoulderTriceps = `{
    "tags": [
      {
        "tag": "strength"
      },
      {
        "tag": "exercise"
      }
    ],
    "timestamp": "2024-02-26T00:00:00.000+00:00",
    "duration": 3000,
    "type": "exercise",
    "name": "p90x chest shoulders triceps",
    "is_standard": false,
    "is_archived": false,
    "intensity": 6
  }
  `;

export const exerciseP90xLegsBack = `{
    "tags": [
      {
        "tag": "exercise"
      },
      {
        "tag": "strength"
      }
    ],
    "timestamp": "2024-02-23T22:30:00.000+00:00",
    "duration": 3000,
    "type": "exercise",
    "name": "p90x legs back",
    "is_standard": false,
    "is_archived": false,
    "intensity": 7
  }`;
