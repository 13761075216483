/**
 * Given a array, find the first and last occurrence of an element
 * If no element is specified, look for falsy values
 *
 * @returns the first and last occurrence
 */
export const firstLastNonNull = (array: unknown[], element = null): [number, number] => {
  const firstNonNull = array.findIndex((val: unknown) => val !== element);
  let lastNonNull = array.length;
  for (let i = lastNonNull - 1; i > firstNonNull; i--) {
    if (array[i] !== element) {
      lastNonNull = i;
      break;
    }
  }

  return [firstNonNull, lastNonNull];
};
