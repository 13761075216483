import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { initialVisibilityData, initialVisibilityOptions } from "./visibilityHelper";

export const useProcessVisibility = (graphData: NewESResponseDataType) => {
  const data = initialVisibilityData();
  if (graphData?.Values && haveKeys([graphData.Values])) {
    data.labels = graphData.Values.map((val) => +new Date(val[timestampKey]));
    data.datasets[0].data = graphData.Values.map((val) => {
      return { x: +new Date(val[timestampKey]), y: val.visibility };
    });
    data.datasets[1].data = graphData.Values.map((val) => {
      return { x: +new Date(val[timestampKey]), y: val.cloudCover };
    });
  }

  return { data, options: initialVisibilityOptions() };
};
