/* eslint-disable camelcase */
import { CardContent, Typography, CardActions, Button, Card, Grid, CircularProgress, Tooltip } from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect } from "react";
import { LinearProgressWithLabel } from "../../../../components/customComponents/LinearProgressWithLabel";
import { REFRESH_INTERVAL_MS, UploadState } from "./progressStatesHelper";

// Capitalize the first letter in the word
const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

interface IProgressIndicators {
  uploadStates: UploadState[];
  fetchProgressStatus: () => void;
  uploadStatesLoading: boolean;
}

export const ProgressIndicators = ({ uploadStates, fetchProgressStatus }: IProgressIndicators) => {
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!intervalId) {
      const intervalId = setInterval(fetchProgressStatus, REFRESH_INTERVAL_MS);
      setIntervalId(intervalId);
    }
  }, [uploadStates]);

  const cancelRefresh = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  // Cancel the interval if all uploads are finished or failed
  if (
    intervalId &&
    uploadStates.filter((state) => state.state !== "finished" && state.state !== "failed").length === 0
  ) {
    cancelRefresh();
  }

  const ProgressCard = (state: UploadState) => (
    <>
      <CardContent>
        {/* Provider */}
        <Typography color="primary">{capitalize(state.provider)} Progress</Typography>

        {/* State */}
        <Typography variant="h6" color={state.state === "failed" ? "error" : undefined}>
          {capitalize(state.state)}
        </Typography>

        {/* Loading Progress */}
        {state.in_progress && <LinearProgressWithLabel value={state.loading_progress} />}

        {/* File Name */}
        {/* Only show the first 20 characters of filename. If more, append ... */}
        <Tooltip title={state.user_filename ? state.user_filename : ""}>
          <Typography variant="body2">
            {state.user_filename && state.user_filename.length > 20
              ? `${state.user_filename.substring(0, 20)}...`
              : state.user_filename}
          </Typography>
        </Tooltip>

        {/* Timestamp */}
        {(state.state === "finished" || state.state === "failed") && (
          <Typography variant="caption">{format(new Date(state.timestamp), "LLL do yyyy, h:mm bb")}</Typography>
        )}
      </CardContent>

      {state.in_progress && (
        <CardActions>
          <Button size="small" onClick={fetchProgressStatus} disabled={!state.in_progress || intervalId !== null}>
            {intervalId !== null && <CircularProgress size={15} />}
            {intervalId !== null ? "Refreshing " : "Refresh"}
          </Button>
        </CardActions>
      )}
    </>
  );

  return (
    <Grid container spacing={2}>
      {/* Show only non-finished. Finished will be displayed in upload logs */}
      {uploadStates.map((state) => {
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={`${state.timestamp}${state.provider}`}>
            <Card style={{ margin: 10 }}>{ProgressCard(state)}</Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
