import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import { useGraphSetup } from "../../../../customHooks/useGraphSetup";
import { ActivityQueryParams } from "../../../../../helpers/components/defaultQueryParams";
import { API_ACTIVITY_MOVEMENT } from "../../../../../api/apiConstants";
import { defaultGraphHeight, defaultGraphWidth, TITLE_MOVEMENT_HISTOGRAM } from "../../../../../helpers/graphConstants";
import { GraphHeader } from "../../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { MovementHistogramMetadata } from "../../../../../helpers/graphsMetadata";
import { GraphContainer } from "../../../../customComponents/GraphContainer/GraphContainer";
import { activityTypeList, MovementHistogramSelector, moveTypeList } from "./MovementHistogramSelector";
import { useProcessMoveHistogram } from "./useProcessMoveHistogram";

export const MovementHistogramGraph = () => {
  const [moveType, setMoveType] = useState<string>(moveTypeList[0]);
  const [activityType, setActivityType] = useState<string>(activityTypeList[0]);

  const graphObject = useGraphSetup(ActivityQueryParams.defaultMovementHistogramQueryParams, API_ACTIVITY_MOVEMENT);
  const { graphData, isLoaded, elemRef } = graphObject;
  const { data, options } = useProcessMoveHistogram(graphData, moveType, activityType);

  const graphDisplay = <Bar data={data} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />;

  const description = [
    "Time in Movement is derived from your Google map data and is a good indicator of your travel quality of life.  ",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_MOVEMENT_HISTOGRAM}
        title={TITLE_MOVEMENT_HISTOGRAM}
        calendarProps={graphObject}
        id={MovementHistogramMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_MOVEMENT_HISTOGRAM} graphDisplay={graphDisplay} calendarProps={graphObject}>
          <Grid container>
            <MovementHistogramSelector
              moveType={moveType}
              setMoveType={setMoveType}
              activityType={activityType}
              setActivityType={setActivityType}
            />
          </Grid>
        </GraphContainer>
      </GraphHeader>
    </div>
  );
};
