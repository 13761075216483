import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import { UserDataInterface } from "../../../types/general/IdentityContext";
import { IdentitySettingsInterface } from "../../../types/settings/settings";

import { SettingsGroups } from "../../../types/general/UserSettingsContext";

interface IdentityGroupProps {
  classes: any;
  setSettings: (key: string, value: any, group: string) => void;
  settings: IdentitySettingsInterface;
  user: UserDataInterface;
}

const sexIdentity = [
  { key: "1", value: "Male", label: "Male" },
  { key: "2", value: "Female", label: "Female" },
  { key: "3", value: "Other", label: "Other" },
  { key: "4", value: "No answer", label: "Prefer not to answer" },
];

const IdentityContainer = ({ classes, settings, user, setSettings }: IdentityGroupProps) => {
  const [identity, setIdentity] = useState({
    name: settings.name ? settings.name : user.userName,
    email: user.userEmail,
    sex: settings.sex,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    // eslint-disable-next-line
    setIdentity({
      ...identity,
      [inputName]: inputValue,
    });
  };

  const onInputchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    setIdentity({
      ...identity,
      [inputName]: inputValue,
    });
  };

  const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    setSettings(inputName, inputValue, SettingsGroups.IDENTITY);
  };

  return (
    <Grid container spacing={1}>
      <Grid container item xs={10}>
        <Grid item xs={12} sm={3}>
          <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
            Email
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            disabled
            className={classes.textFieldSmall}
            value={identity.email}
            type="email"
            variant="outlined"
            size="small"
            name="email"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
            Display name
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            value={identity.name}
            className={classes.textFieldSmall}
            onChange={onInputchange}
            onBlur={blurHandler}
            variant="outlined"
            size="small"
            name="name"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
            Sex:
          </Typography>
        </Grid>
        <Grid item xs={2} sm={9}>
          <TextField
            select
            defaultValue="No answer"
            onBlur={blurHandler}
            onChange={handleChange}
            value={identity.sex}
            className={classes.textFieldSmall}
            variant="outlined"
            size="small"
            name="sex"
          >
            {sexIdentity.map((sex) => (
              <MenuItem key={sex.key} value={sex.value}>
                {sex.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Grid item xs={12}>
          <Avatar alt="Avatar Image" src="/static/images/avatar/1.jpg" className={classes.large} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IdentityContainer;
