import { useMemo } from "react";
import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import {
  initialTagData,
  yAxisAlgorithm,
  yMappingsKey,
  initialTagOptions,
  newTagObject,
  defaultPointSize,
} from "./useTagOptions";

export const tagsProcessor = (fetchedData: NewESResponseDataType, data: any) => {
  if (fetchedData?.Values && haveKeys([fetchedData.Values])) {
    Object.values(fetchedData.Values)
      .filter((diaryEvent) => diaryEvent?.events?.length > 0)
      .forEach((diaryEvent: any) => {
        diaryEvent?.events?.forEach((tag: any) => {
          // Find the index in dataset that has the same value
          let datasetIndex = data.datasets.findIndex((element: any) => element.label === tag.name);

          if (datasetIndex === -1) {
            // If dataset doesn't exist, create it and add
            data.datasets.push(newTagObject(tag.name));
            datasetIndex = data.datasets.length - 1;
          }

          const yValue = yAxisAlgorithm(datasetIndex);
          data.labels.push(+new Date(diaryEvent?.[timestampKey]));
          // Arbitrary formula for choosing Y value

          pushTupleData(data, datasetIndex, {
            x: +new Date(diaryEvent?.[timestampKey]),
            y: yValue,
            r: defaultPointSize + tag?.count || 0,
          });

          data[yMappingsKey][yValue] = tag.name;
        });
      });
  }
  return data;
};

export const useProcessTagsData = (fetchedData: NewESResponseDataType, selectedInterval: string): any => {
  const data = initialTagData();
  const outputData = useMemo(() => {
    return tagsProcessor(fetchedData, data);
  }, [fetchedData]);

  const options = initialTagOptions(outputData, selectedInterval);

  return { outputData, options };
};
