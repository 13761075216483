import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import FavouriteIconFilled from "@material-ui/icons/Favorite";
import { UserSettingsContext } from "../../../../types/general/UserSettingsContext";
import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";

export const FavoritesPage = (): JSX.Element => {
  const { userSettings } = useContext(UserSettingsContext);
  const [hasFavoriteGraphs, setHasFavorites] = useState(false);

  useEffect(() => {
    if (userSettings.settings.favorites) {
      setHasFavorites(userSettings?.settings.favorites.fav_graphs?.filter((chart: string) => chart !== "").length > 0);
    }
  }, [userSettings]);

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Typography variant="h4">Favorite Graphs</Typography>

        {!hasFavoriteGraphs && (
          <>
            <Typography variant="h6">You haven&apos;t favorited any graphs yet.</Typography>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <Typography variant="h6" display="inline">
                Click
              </Typography>
              <Typography variant="h6" display="inline" gutterBottom style={{ position: "relative", top: 5 }}>
                <FavouriteIconFilled style={{ color: "red" }} />
              </Typography>
              <Typography variant="h6" display="inline">
                on graphs to add them here
              </Typography>
            </div>
          </>
        )}
      </Grid>

      {hasFavoriteGraphs ? (
        <>
          <GraphListWrapper graphNameList={userSettings.settings.favorites.fav_graphs} favoritePage />
        </>
      ) : (
        <>
          <GraphListWrapper graphNameList={[]} />
        </>
      )}
    </>
  );
};
