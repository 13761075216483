import { Dict } from "../../../../helpers/components/graphHelpers";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";

export const graphType = "bubble";

export const yMappingsKey = "yMappings";
export const defaultPointSize = 3;

export const yAxisAlgorithm = (datasetIndex: number): number => {
  // Every 10 y units, display a tag
  return (datasetIndex + 1) * 10;
};

export const newTagObject = (name: string): ObjectType => {
  return {
    type: graphType,
    label: name, // Add a dataset index with this value
    data: [] as Array<TupleDataType>,
    fill: false,
  };
};

export const initialTagOptions = (data: any, selectedInterval: string): any => {
  return {
    scales: {
      yAxes: [
        {
          ticks: {
            display: true,
            callback: (value: number): string => {
              return data?.[yMappingsKey]?.[value] || "";
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Tag",
          },
        },
      ],
      xAxes: [
        {
          type: "time",
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
            tooltipFormat: "lll",
          },
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: true,
            },
          },
          scaleLabel: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      maintainAspectRatio: false,
    },
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
      mode: "point",
      callbacks: {
        title(tooltipItem: any, toolData: any): string {
          return toolData.datasets[tooltipItem[0].datasetIndex].label;
        },
        label(tooltipItem: any): string {
          return tooltipItem.label;
        },
      },
    },
  };
};

export const initialTagData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [] as Array<any>,
    [yMappingsKey]: {} as Dict,
  };
};
