import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorBoundary } from "react-error-boundary";
import { AllGraphsContext, SearchContext } from "../../../helpers/context/contextVariables";
import { ErrorBoundaryFallback } from "../../../components/customComponents/ErrorBoundaryFallback";
import { twoColBreakpointPx } from "../../../components/navigation/navigationDrawer/SideNavigation";

type GraphPagesWrapperType = {
  graphNameList: string[];
  favoritePage?: boolean;
};

const useStyles = makeStyles(() => ({
  graphSkeletonContainer: {
    height: 0,
    overflow: "hidden",
    paddingTop: "75%",
    position: "relative",
  },
  graphLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

export const GraphListWrapper = (props: GraphPagesWrapperType) => {
  const allGraphs = useContext(AllGraphsContext);
  const context = useContext(SearchContext);
  const classes = useStyles();
  const history = useHistory();

  const isSmall = useMediaQuery(`(max-width:${twoColBreakpointPx}px)`, { noSsr: true }); // Less than 780px

  const numCols = () => {
    return isSmall ? 1 : 2;
  };

  let foundGraphsList: string[] = props.graphNameList;
  const graphs = useMemo(() => {
    const inputText = context.searchValue.toLowerCase().replace(" ", "");

    // FIND GRAPHS WHOSE TITLE OR TOPICS/CLOUD TAGS MATCH THE FILTER

    if (inputText.length > 0) {
      foundGraphsList = props.graphNameList.filter((key) => {
        const { title, graphTopicsTags, graphCloudTags } = allGraphs?.[key]?.metadataField;
        const boolTitle = title.toLowerCase().replace(" ", "").includes(inputText);
        const boolGraphTopicsTags: boolean = graphTopicsTags.some((tag) =>
          tag.toLowerCase().replace(" ", "").includes(inputText),
        );
        const boolGraphCloudTags: boolean = graphCloudTags.some((tag) =>
          tag.toLowerCase().replace(" ", "").includes(inputText),
        );

        return allGraphs[key].render && (boolTitle || boolGraphCloudTags || boolGraphTopicsTags);
      });
    }

    const noGraphsFoundText = (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="h4">No graphs found</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Didn&apos;t find any graphs with <strong>{context.searchValue}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">Search your data instead?</Typography>
        </Grid>
        <Box m={2} />

        <Grid item>
          <ButtonGroup variant="contained" color="primary" aria-label="contained button group for reset and search">
            <Button
              color="secondary"
              onClick={() => {
                context.setSearchValue("");
              }}
            >
              Reset
            </Button>

            <Button
              onClick={() => {
                history.push("/find");
              }}
            >
              Search Data
            </Button>
          </ButtonGroup>

          <Box m={2} />
        </Grid>
      </Grid>
    );

    const noGraphsFoundSkeletons = (
      <>
        <ImageList cellHeight="auto" gap={15} cols={numCols()}>
          {/* When no data available, show 6 skeleton graphs */}
          {/* 6 is the LCD of 1, 2, 3 */}
          {/* 1, 2, 3 are the possible number of columns for a grid tile list */}
          {[0, 1, 2, 3, 4, 5].map((id) => {
            return (
              <ImageListItem key={id}>
                <Paper elevation={4}>
                  <div className={classes.graphSkeletonContainer}>
                    <Skeleton variant="rect" animation="wave" className={classes.graphLoader} />
                  </div>
                </Paper>
                <Box m={0.75} />
              </ImageListItem>
            );
          })}
        </ImageList>
      </>
    );

    // IF NO GRAPHS WERE FOUND
    if (foundGraphsList.length === 0 && !props.favoritePage) {
      return (
        <>
          {inputText.length > 0 && <>{noGraphsFoundText}</>}
          {noGraphsFoundSkeletons}
        </>
      );
    }

    // NORMAL RETURN WITH GRAPHS
    return (
      <ErrorBoundary fallbackRender={(props) => <ErrorBoundaryFallback {...props} title="graph page" />}>
        <ImageList rowHeight="auto" gap={15} cols={numCols()}>
          {foundGraphsList
            .filter((id) => allGraphs?.[id]?.render)
            .map((id) => {
              return (
                <ImageListItem key={id}>
                  {allGraphs[id].graph}
                  <Box m={0.75} />
                </ImageListItem>
              );
            })}
        </ImageList>
      </ErrorBoundary>
    );
  }, [context.searchValue, numCols(), props.favoritePage, props.graphNameList]);

  return <>{graphs}</>;
};
