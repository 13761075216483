/* eslint-disable camelcase */
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { useHistogramGenerator } from "../../../customHooks/useHistogramGenerator";
import { initialSleepDatasets, sleepHistogramOptions } from "./sleepHistogramHelper";

type SleepAggResponse = {
  timestamp: string;
  efficiency: number;
  events_count: number;
  fall_asleep_seconds: number;
  after_wakeup_seconds: number;
  awake_seconds: number;
  asleep_seconds: number;
  in_bed_seconds: number;
  deep_seconds: number;
  light_seconds: number;
  rem_seconds: number;
};

export const useProcessSleepHistogram = (fetchedSleepData: NewESResponseDataType, sleepType: string): any => {
  const sleepInitialHistogramDatasets = initialSleepDatasets();

  let sleepValues: (string | number)[] = [];
  if (fetchedSleepData?.Values && haveKeys([fetchedSleepData.Values])) {
    // If "all" is selected in the dropdown, sum all the sleep states
    if (sleepType.toLowerCase() === "all") {
      // eslint-disable-next-line no-extra-parens
      sleepValues = (fetchedSleepData.Values as SleepAggResponse[])
        ?.map(
          (obj: SleepAggResponse) => (obj.deep_seconds + obj.awake_seconds + obj.light_seconds + obj.rem_seconds) / 60,
        )
        .filter((num) => num > 0);
    } else {
      // Only show the sleep state the user selected
      // eslint-disable-next-line no-extra-parens
      sleepValues = (fetchedSleepData.Values as SleepAggResponse[])
        ?.map((obj: SleepAggResponse) => {
          return sleepType.toLowerCase() === "awake_seconds"
            ? obj.awake_seconds / 60
            : Number(obj[sleepType.toLowerCase() as keyof SleepAggResponse]) / 60;
        })
        .filter((num) => num > 0);
    }
  }

  const { buckets, largestBucket, min, max, stepBucketSize } = useHistogramGenerator(
    sleepValues,
    sleepInitialHistogramDatasets,
    30,
  );
  sleepInitialHistogramDatasets.datasets[0].data = buckets;
  const histogramLargestBucket = largestBucket;
  const options = sleepHistogramOptions(histogramLargestBucket, sleepType, min, max, stepBucketSize);

  return { data: sleepInitialHistogramDatasets, options };
};
