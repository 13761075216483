import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Close from "@material-ui/icons/Close";
import _ from "lodash";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { useSnackbar } from "notistack";
import { Add, Delete } from "@material-ui/icons";
import { processRows, RowType, uploadEvents } from "./handleUpload";
import { DataTemplates } from "./DataTemplates";
import { DataVariationsInput } from "./DataVariations";
import { GeneratorOutputPreview } from "./GeneratorOutputPreview";
import { GeneratorFileInput } from "./Components/GeneratorFileInput";
import { VariationsToggle } from "./Components/VariationsToggle";
import { ScheduleQuantityUnit, ScheduleStartEndDate } from "./Components/ScheduleComponents";

const monthAgo = subDays(new Date(), 30);

const initialRow = (): RowType => {
  return {
    id: _.uniqueId(),
    file: null,
    schedule: {
      startDate: startOfDay(monthAgo),
      endDate: endOfDay(new Date()),
      unit: 1 * 60 * 60 * 24,
      quantity: 1,
    },
    variations: null,
  };
};

export const DataGeneratorPage = () => {
  const [rows, setRows] = useState<RowType[]>([initialRow()]);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddRow = () => {
    setRows([...rows, initialRow()]);
  };

  const handleRemoveRow = (index: number) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleSubmit = async () => {
    const events = processRows(rows);
    const result = await uploadEvents(events);

    if (result?.status === 200) {
      enqueueSnackbar(`${result?.data.documents.length} events uploaded successfully`, { variant: "success" });
    } else {
      enqueueSnackbar("Error uploading events", { variant: "error" });
    }
    setRows([initialRow()]);
  };

  const handleAddVariationRow = (index: number) => {
    setRows((prev) => {
      const newRows = [...prev];

      newRows[index].variations?.push({
        variationId: _.uniqueId(),
        fieldName: "",
        min: 0,
        max: 10,
      });

      return newRows;
    });
  };

  return (
    <>
      <DataTemplates setRows={setRows} />
      <br />
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>JSON File</TableCell>
              <TableCell>Variation?</TableCell>
              <TableCell>From / To</TableCell>
              <TableCell>Schedule</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow key={`Table row ${row.id}`}>
                  <TableCell component="th" scope="row">
                    {!row.file?.content?.length && <GeneratorFileInput row={row} currRowIndex={i} setRows={setRows} />}
                    {row.file?.content?.length && <pre style={{ overflowY: "scroll" }}>{row.file.content}</pre>}
                  </TableCell>
                  <TableCell>
                    <VariationsToggle row={row} currRowIndex={i} setRows={setRows} />
                    <DataVariationsInput rows={rows} currRowIndex={i} setRows={setRows} />
                    {row.variations !== null && (
                      <IconButton color="primary" onClick={() => handleAddVariationRow(i)}>
                        <Add /> <Typography variant="body1">Add more</Typography>
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <ScheduleStartEndDate row={row} currRowIndex={i} setRows={setRows} />
                  </TableCell>
                  <TableCell>
                    <ScheduleQuantityUnit row={row} currRowIndex={i} setRows={setRows} />
                  </TableCell>
                  <TableCell>
                    <IconButton color="secondary" onClick={() => handleRemoveRow(i)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Box m={5} textAlign="center" key="Add Event Button">
          <Button color="primary" variant="contained" onClick={handleAddRow}>
            Add file row
          </Button>
        </Box>
        <Box m={5} textAlign="center" key="Submit Button">
          <Button disabled={!rows.some((row) => row.file)} color="primary" variant="contained" onClick={handleSubmit}>
            POST Events
          </Button>
        </Box>
      </TableContainer>

      <GeneratorOutputPreview rows={rows} />
    </>
  );
};
