import * as chartjs from "chart.js";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";

export const WEED_INDEX = 0;
export const GRASS_INDEX = 1;
export const TREE_INDEX = 2;

export const initialPollenData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Weed Pollen",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-weed-pollen",
        xAxisID: "x-axis",
        type: "line",
        order: 50,
      },
      {
        label: "Grass Pollen",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-weed-pollen",
        xAxisID: "x-axis",
        type: "line",
        order: 50,
      },
      {
        label: "Tree Pollen",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-weed-pollen",
        xAxisID: "x-axis",
        type: "line",
      },
    ],
  };
};

const numberToPollenSeverityMap = (index: number) => {
  if (index === 0) {
    return "None";
  }
  if (index === 1) {
    return "Low";
  }
  if (index === 2) {
    return "Moderate";
  }
  if (index === 3) {
    return "High";
  }
  if (index >= 4) {
    return "Very High";
  }
  return "";
};

export const initialPollenOptions = (): chartjs.ChartOptions => {
  return {
    tooltips: {
      mode: "x",
      intersect: false,
      callbacks: {
        label(tooltipItem: any, data: any): string {
          const { datasetIndex } = tooltipItem;
          const { index } = tooltipItem;
          const pollenType = data.datasets[datasetIndex].label;
          const rawValue = data.datasets[datasetIndex].data[index].raw;

          return `${pollenType}: ${Number(rawValue)}`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-weed-pollen",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Pollen Risk",
          },
          ticks: {
            min: 0,
            max: 4,
            stepSize: 1,
            callback(value: number) {
              return `${numberToPollenSeverityMap(value)}`;
            },
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          position: "bottom",
          ticks: {
            source: "labels",
            autoSkip: true,
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: "day",
          },
        },
      ],
      maintainAspectRatio: false,
    },
    annotation: {
      annotations: [
        {
          type: "box",
          yScaleID: "y-axis-weed-pollen",
          yMin: 0,
          yMax: 1,
          backgroundColor: "rgba(34,139,34, 0.25)",
        },
        {
          type: "box",
          yScaleID: "y-axis-weed-pollen",
          yMin: 1,
          yMax: 2,
          backgroundColor: "rgba(255,215,0, 0.25)",
        },
        {
          type: "box",
          yScaleID: "y-axis-weed-pollen",
          yMin: 2,
          yMax: 3,
          backgroundColor: "rgba(255,127,80, 0.25)",
        },
        {
          type: "box",
          yScaleID: "y-axis-weed-pollen",
          yMin: 3,
          yMax: 4,
          backgroundColor: "rgba(255,0,0, 0.25)",
        },
      ],
    },
  } as chartjs.ChartOptions;
};
