import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import Chart from "chart.js";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "@material-ui/core";
import { Dict } from "../../helpers/components/graphHelpers";
import { DefaultQueryParamsType } from "../../types/graphs/DefaultQueryParamsType";
import { NewESResponseDataType } from "../../types/responses/NewESResponseDataType";
import { useGraphAPIFetch } from "../../api/useGraphAPIFetch";
import { applyRules, intervalsAll } from "../customComponents/GraphContainer/calendarRules";
import { CalendarRange } from "../customComponents/GraphContainer/GraphCalendar/calendarBodyHelper";
import { DATA_SERVICE_URL_OLD } from "../../api/apiConstants";
import { useWebsiteTheme, UserSettingsContext, WebsiteTheme } from "../../types/general/UserSettingsContext";

export const useGraphSetup = (
  initialQueryParams: DefaultQueryParamsType,
  API_ENDPOINT: string,
  baseURL = DATA_SERVICE_URL_OLD,
  disableIntervals = false,
) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const [intervals, setIntervals] = useState<Dict>(_.cloneDeep(intervalsAll));
  const [dateRange, setDateRange] = useState<CalendarRange>({
    startDate: new Date(initialQueryParams.time_gte),
    endDate: new Date(initialQueryParams.time_lte),
  });
  const [selectedInterval, setSelectedInterval] = useState<string>(_.cloneDeep(initialQueryParams.interval));
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(true);

  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0.1,
  });

  const { userSettings } = useContext(UserSettingsContext);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // Handle changing the interval if a large range is selected
  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      applyRules(dateRange, setIntervals, selectedInterval, setSelectedInterval);
    }
  }, [dateRange, intervals]);

  const theme = useWebsiteTheme();

  const changeTheme = () => {
    if (theme === WebsiteTheme.Dark) {
      Chart.defaults.global.defaultFontColor = "#dddddd";
    } else {
      Chart.defaults.global.defaultFontColor = "#555555";
    }

    // Force updates to all charts
    Chart.helpers.each(Chart.instances, (instance: any) => {
      instance.chart.update();
    });
  };

  // Handle changing text color of chartjs graphs when theme changes
  useEffect(changeTheme, [userSettings.websiteTheme, prefersDarkMode]);

  const graphData: NewESResponseDataType = useGraphAPIFetch(
    dateRange,
    setDateRange,
    selectedInterval,
    API_ENDPOINT,
    setIsLoaded,
    refetch,
    setRefetch,
    baseURL,
    inView,
  );

  return {
    isOpen,
    setOpen,
    intervals,
    dateRange,
    setDateRange,
    selectedInterval,
    setSelectedInterval: !disableIntervals ? setSelectedInterval : undefined, // Don't allow changing of interval
    // for pie
    isLoaded,
    setIsLoaded,
    graphData,
    initialQueryParams,
    setRefetch,
    elemRef: ref,
    isVisible: inView,
  };
};
