import React from "react";
import {
  HeartRateOxygenLineGraphMetadata,
  RestingHeartRateLineGraphMetadata,
  HeartRateHistogramGraphMetadata,
  RestingHeartRateHistogramGraphMetadata,
} from "../../../../helpers/graphsMetadata";
import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";

export const HeartRatePage = (): JSX.Element => {
  const bodyGraphs = [
    HeartRateOxygenLineGraphMetadata.id,
    RestingHeartRateLineGraphMetadata.id,
    HeartRateHistogramGraphMetadata.id,
    RestingHeartRateHistogramGraphMetadata.id,
  ];

  return <GraphListWrapper graphNameList={bodyGraphs} />;
};
