import { Box, Grid } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";
import { rowToBuckets, RowType, secondsToHumanReadable } from "./handleUpload";

const CUT_OFF = 100;

interface Props {
  rows: RowType[];
}

export const GeneratorOutputPreview = ({ rows }: Props) => {
  return (
    <div>
      {rows
        ?.filter((row) => row.file && row.file?.content?.length)
        .map((row) => {
          const dates = rowToBuckets(row);

          return (
            <>
              <h2>{row.file.name}</h2>
              <h3>Every {secondsToHumanReadable(row.schedule.quantity * row.schedule.unit)}</h3>

              <h3>{dates.length} events will be generated with the following timestamps excluding variations: </h3>

              <div
                style={{ height: 200, maxHeight: 200, overflowY: "scroll", marginBottom: 150 }}
                key={`Result ${row.id}`}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1}>
                    {dates.slice(0, CUT_OFF).map((date) => (
                      <Grid item xs={1} style={{ padding: 5 }} key={date.startDate.toISOString()}>
                        <h4 style={{ padding: 0, margin: 0 }}>{format(date.startDate, "yyyy/MM/dd")}</h4>
                        <h4 style={{ padding: 0, margin: 0 }}>{format(date.startDate, "hh:mm aa")}</h4>
                      </Grid>
                    ))}
                  </Grid>
                  {dates.length > CUT_OFF && <h4>(Showing first {CUT_OFF})</h4>}
                </Box>
              </div>
            </>
          );
        })}
    </div>
  );
};
