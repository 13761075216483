/* eslint-disable no-extra-parens */
import React from "react";
import _ from "lodash";
import { NewESResponseDataType } from "../../../../../types/responses/NewESResponseDataType";
import { useHistogramGenerator } from "../../../../customHooks/useHistogramGenerator";
import { initialShoppingHistDataset, shoppingHistogramOptions } from "./shoppingHistHelper";
import { dataTypeList } from "./ShopHistogramSelector";
import { frequencyMap } from "../useProcessCategoryData";
import { haveKeys } from "../../../../../helpers/components/graphHelpers";
import { ShoppingAggregatedType } from "../shoppingTypes";

export const useProcessShoppingHist = (
  fetchedCategoryData: NewESResponseDataType,
  dataType: string,
  categoryList: string[],
  selectedCategory: string,
  setCategoryList: React.Dispatch<React.SetStateAction<string[]>>,
): any => {
  const initialHistData = initialShoppingHistDataset();
  let shopValues: number[] = [];

  if (fetchedCategoryData.Values && haveKeys(fetchedCategoryData.Values)) {
    if (dataType === dataTypeList[0]) {
      shopValues = (fetchedCategoryData?.Values as ShoppingAggregatedType[])
        ?.filter((obj) => obj?.total)
        .map((obj) => Math.round(obj.total));
    } else {
      const { topResults } = frequencyMap(fetchedCategoryData, 10);
      const newResults = ["All"].concat(topResults.map((category) => category.name));
      if (!_.isEqual(newResults, categoryList)) {
        setCategoryList(newResults);
      }

      if (selectedCategory === "All") {
        shopValues = topResults.map((category) => category.value);
      } else {
        shopValues = topResults
          .filter((category) => category.name === selectedCategory)
          .map((category) => category.value);
      }
    }
  }

  const { buckets, largestBucket, min, max, stepBucketSize } = useHistogramGenerator(shopValues, initialHistData, 30);
  initialHistData.datasets[0].data = buckets;
  const histogramLargestBucket = largestBucket;

  const options = shoppingHistogramOptions(histogramLargestBucket, dataType, min, max, stepBucketSize);
  return { data: initialHistData, options };
};
