/* eslint-disable no-mixed-operators */
import { GRASS_INDEX, initialPollenData, initialPollenOptions, TREE_INDEX, WEED_INDEX } from "./pollenHelper";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { haveKeys } from "../../../../helpers/components/graphHelpers";

const changeInterval = (rawValue: number, valMin: number, valMax: number, mapMin: number, mapMax: number) => {
  // Goal is to map [valMin-valMax] to [mapStart-mapEnd]
  // SPK: Remember slope intercept from grade school? :)

  // ∆Y/∆X
  // 14 = Max for this case
  // 1 = Max for this case

  // 1 = highest mapped value
  // 0 = lowest mapped value
  const slope = (mapMax - mapMin) / (valMax - valMin);
  // Solve for 'b' yIntercept plugging in one coordinate
  // y = mx + b
  const yIntercept = mapMin - slope * valMin;

  // Put it together to get the Y value desired. Solve.
  // y = (slope)(raw) + (yIntercept)
  return slope * rawValue + yIntercept;
};

const weedRawToYValueMap = (rawValue: number) => {
  if (rawValue >= 1 && rawValue <= 9) {
    return changeInterval(rawValue, 1, 9, 0, 1);
  }
  if (rawValue >= 10 && rawValue <= 49) {
    return changeInterval(rawValue, 10, 49, 1, 2);
  }
  if (rawValue >= 50 && rawValue <= 499) {
    return changeInterval(rawValue, 50, 499, 2, 3);
  }
  if (rawValue >= 500) {
    return 3.5;
  }
  return 0;
};

const grassRawToYValueMap = (rawValue: number) => {
  if (rawValue >= 1 && rawValue <= 4) {
    // LOW POLLEN COUNT 1-4
    return changeInterval(rawValue, 1, 4, 0, 1);
  }
  if (rawValue >= 5 && rawValue <= 19) {
    // MODERATE POLLEN COUNT 5-19
    return changeInterval(rawValue, 5, 19, 1, 2);
  }
  if (rawValue >= 20 && rawValue <= 199) {
    // HIGH POLLEN COUNT 20-199
    return changeInterval(rawValue, 20, 199, 2, 3);
  }
  if (rawValue >= 200) {
    // VERY HIGH POLLEN COUNT > 200
    return 3.5;
  }
  return 0;
};

const treeRawToYValueMap = (rawValue: number) => {
  if (rawValue >= 1 && rawValue <= 14) {
    // LOW POLLEN COUNT 1-14
    return changeInterval(rawValue, 1, 14, 0, 1);
  }
  if (rawValue >= 15 && rawValue <= 89) {
    // MODERATE POLLEN COUNT 15-89
    return changeInterval(rawValue, 15, 89, 1, 2);
  }
  if (rawValue >= 90 && rawValue <= 1499) {
    // HIGH POLLEN COUNT 90-1499
    return changeInterval(rawValue, 90, 1499, 2, 3);
  }
  if (rawValue >= 1500) {
    // VERY HIGH POLLEN COUNT > 1500
    return 3.5;
  }
  return 0;
};

export const useProcessPollenDataProcessor = (graphData: NewESResponseDataType) => {
  const data = initialPollenData();
  if (graphData.Values && haveKeys(graphData.Values)) {
    data.labels = graphData.Values.map((val) => +new Date(val[timestampKey]));
    data.datasets[WEED_INDEX].data = graphData.Values.map((val) => {
      return { x: +new Date(val[timestampKey]), y: weedRawToYValueMap(val.weed_count), raw: val.weed_count };
    });
    data.datasets[GRASS_INDEX].data = graphData.Values.map((val) => {
      return { x: +new Date(val[timestampKey]), y: grassRawToYValueMap(val.grass_count), raw: val.grass_count };
    });
    data.datasets[TREE_INDEX].data = graphData.Values.map((val) => {
      return { x: +new Date(val[timestampKey]), y: treeRawToYValueMap(val.tree_count), raw: val.tree_count };
    });
  }

  return { data, options: initialPollenOptions() };
};
