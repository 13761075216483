import React, { useMemo } from "react";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { initialNetflixStackedBarData } from "./netflixOptions";
import { haveKeys } from "../../../../helpers/components/graphHelpers";

import {
  calcDurationPerProfile,
  generateDatasetPerShow,
  generateTimestampBuckets,
  populateProfileList,
  PROFILE_ALL_STRING,
  pushDataToDatasets,
} from "./netflixHelper";
import { NetflixInitialStackedBarType, ProfileDurationType, ProfileList, TimestampBucketType } from "./netflixTypes";
import { useAddMultiDatasetMovingAverage } from "../../../customHooks/useAddMultiDatasetMovingAverage";

export const useProcessStackedBar = (
  fetchedNetflixData: NewESResponseDataType,
  profile: string,
  setProfileList: React.Dispatch<React.SetStateAction<string[]>>,
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>,
): any => {
  const initialData: NetflixInitialStackedBarType = initialNetflixStackedBarData();
  const data = useMemo(() => {
    if (fetchedNetflixData?.Values && haveKeys([fetchedNetflixData.Values])) {
      setIsLoaded(false);

      // Populate data
      const profileData: ProfileList = populateProfileList(fetchedNetflixData);

      const viewingDurations: ProfileDurationType = calcDurationPerProfile(profileData, profile);

      generateDatasetPerShow(initialData, viewingDurations, profile);

      const timestampBucket: TimestampBucketType = generateTimestampBuckets(viewingDurations, profile);
      // Set labels with the now unique timestamps
      initialData.labels = Object.keys(timestampBucket).map((ts) => +ts);

      pushDataToDatasets(timestampBucket, initialData);

      // Generate the labels for MenuItem selection
      const profileList = Object.keys(profileData);
      profileList.push(PROFILE_ALL_STRING);
      setProfileList(profileList);
      setIsLoaded(true);
    }

    return initialData;
  }, [fetchedNetflixData, profile]);

  useAddMultiDatasetMovingAverage(data, "Netflix", "y-axis-netflix-avg");

  return data;
};
