import React, { useEffect, useRef, useContext } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import _ from "lodash";
import { PageRouter } from "../PageRouter";
import { AllGraphsContext, SearchContext, SideNavigationContext } from "../../helpers/context/contextVariables";
import "chartjs-plugin-colorschemes";
import "chartjs-plugin-annotation";

import { SideNavigationDrawer } from "./navigationDrawer/SideNavigationDrawer";
import { useNavigationStyles } from "./navigationHelpers";

import { TopNavigation } from "./navigationBar/TopNavigation";
import { allGraphs } from "./AllGraphList";
import { graphRangeLastYear } from "../customComponents/GraphContainer/calendarConstants";
import { navigationTree, RenderTree } from "./NavigationRoutes";
import { twoColBreakpointPx } from "./navigationDrawer/SideNavigation";
import { UserSettingsContext } from "../../types/general/UserSettingsContext";
import { MenstrualLineGraphMetadata } from "../../helpers/graphsMetadata";
import { IdentityContext } from "../../types/general/IdentityContext";

export const Navigation = React.memo((): JSX.Element => {
  // CUSTOM HOOKS
  const isSmall = useMediaQuery(`(max-width:${twoColBreakpointPx}px)`, { noSsr: true }); // Less than 780px
  const location = useLocation();
  const sideNavElRefs = useRef<any>();
  const classes = useNavigationStyles();
  const { userSettings } = useContext(UserSettingsContext);
  const { userData } = useContext(IdentityContext);

  // STATE VARIABLES
  const [isSideNavOpen, setSideNavOpen] = React.useState<boolean>(!isSmall);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [sideNavItems, setSideNavItems] = React.useState<RenderTree[] | RenderTree>(navigationTree.live as RenderTree);
  const [range, setRange] = React.useState(graphRangeLastYear);

  /**
   * Set the content of the side navigation based on the page navigated to
   */
  useEffect(() => {
    if (location.pathname.includes("live")) {
      setSideNavItems(navigationTree.live as RenderTree);
    } else if (location.pathname.includes("find")) {
      setSideNavItems(navigationTree.find as RenderTree);
    } else if (location.pathname.includes("settings")) {
      if (userData.userEmail.endsWith("llif.org")) {
        const currSideNavItems = _.cloneDeep(navigationTree.settings as RenderTree[]);
        currSideNavItems[0].children?.push({ name: "Data generator", id: "/settings/data-generator" });

        setSideNavItems(currSideNavItems);
      } else {
        setSideNavItems(navigationTree.settings as RenderTree[]);
      }
    } else {
      setSideNavOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const sex: string | undefined = userSettings.settings.profile?.identity.sex;
    const displayMenstrualGraph: boolean = sex === "Female";
    allGraphs[MenstrualLineGraphMetadata.id].render = displayMenstrualGraph;
  }, [allGraphs]);

  /**
   * Open and close the side navigation based on the page navigated to.
   */
  const handleDrawer = (): void => {
    // Live, Find, and Settings pages have a side menu but the others do not
    if (
      location.pathname.includes("live") ||
      location.pathname.includes("find") ||
      location.pathname.includes("settings")
    ) {
      setSideNavOpen(!isSideNavOpen);
    }
  };

  return (
    <>
      <div className={classes.root}>
        {/* SIDE NAVIGATION CONTEXT */}
        <SideNavigationContext.Provider
          value={{ sideNavItems, setSideNavItems, sideNavElRefs, isSideNavOpen, setSideNavOpen }}
        >
          {/* SIDE NAVIGATION BAR */}
          <SideNavigationDrawer handleDrawer={handleDrawer} />

          <main
            // SHIFT CONTENT WHEN SIDE NAV BAR IS OPEN
            className={
              !isSmall
                ? clsx(classes.content, {
                    [classes.contentShift]: isSideNavOpen,
                  })
                : ""
            }
          >
            {/* SEARCH BAR CONTEXT */}
            <SearchContext.Provider
              value={{
                searchValue,
                setSearchValue,
                range,
                setRange,
              }}
            >
              {/* SEARCH BAR RANGE CONTEXT */}
              {/* TOP NAVIGATION BAR */}
              <TopNavigation handleDrawer={handleDrawer} />

              {/* SPACE BETWEEN TOP NAV BAR AND CONTENT */}
              <div className={classes.drawerHeader} />

              {/* CONTENT */}
              <AllGraphsContext.Provider value={allGraphs}>
                {/* GRAPHS AND PAGES */}
                <PageRouter />
              </AllGraphsContext.Provider>
            </SearchContext.Provider>
          </main>
        </SideNavigationContext.Provider>
      </div>
    </>
  );
});
