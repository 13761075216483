import React from "react";
import {
  SleepBarGraphMetadata,
  SleepHistogramGraphMetadata,
  SleepTimeGraphMetadata,
} from "../../../../helpers/graphsMetadata";
import { GraphListWrapper } from "../../../componentContainers/DisplayWrappers/GraphListWrapper";

export const BodyPage = () => {
  const sleepPage = [SleepBarGraphMetadata.id, SleepTimeGraphMetadata.id, SleepHistogramGraphMetadata.id];
  return <GraphListWrapper graphNameList={sleepPage} />;
};
