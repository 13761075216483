import React from "react";
import { Grid, InputLabel, MenuItem, Select } from "@material-ui/core";

const SELECT_SLEEP_LABEL = "Filter Sleep Type";

export const sleepTypeList = ["All", "deep_seconds", "light_seconds", "awake_seconds", "rem_seconds"];

type SleepTypeSelectorInterface = {
  sleepType: string;
  setSleepType: React.Dispatch<React.SetStateAction<string>>;
};

export const SleepTypeSelector = ({ sleepType, setSleepType }: SleepTypeSelectorInterface): JSX.Element => {
  return (
    <Grid container direction="row" alignItems="center" spacing={8}>
      <Grid item>
        <InputLabel id="sleep-hist-selector-label">{SELECT_SLEEP_LABEL}</InputLabel>
        <Select
          id="sleep-hist-pie-amount"
          value={sleepType}
          onChange={(event) => {
            setSleepType(event?.target?.value as string);
          }}
        >
          {sleepTypeList.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item.split("_")[0].toUpperCase()}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    </Grid>
  );
};
