import React from "react";
import { Grid, InputLabel, MenuItem, Select } from "@material-ui/core";

const SELECT_PROFILE_STRING = "Select your Netflix profile";

type ProfileSelectorPropsType = {
  profile: string;
  setProfile: React.Dispatch<React.SetStateAction<string>>;
  profileList: string[];
};

export const NetflixProfileSelector = ({ profile, setProfile, profileList }: ProfileSelectorPropsType): JSX.Element => {
  // >1 because there will always be the "All" profile
  if (profileList.length > 1) {
    return (
      <Grid container direction="row" alignItems="center" spacing={8}>
        <Grid item>
          <InputLabel id="netflix-stacked-bar-label">{SELECT_PROFILE_STRING}</InputLabel>
          <Select
            id="select-netflix-stacked-profile"
            value={profile}
            onChange={(event) => {
              setProfile(event?.target?.value as string);
            }}
          >
            {profileList.map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
    );
  }
  return <></>;
};
