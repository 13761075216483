import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Logo.module.css";
import LLIF from "./logo.svg";

export const Logo = () => {
  return (
    <div className="side-brand">
      <NavLink to="/live" className="nav-link">
        <img alt="LLIF" src={LLIF} className={styles["nav-logo"]} style={{ height: "60px", width: "60px" }} />
      </NavLink>
    </div>
  );
};
