import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  TemperaturePreference,
  SettingsGroups,
  DistancePreference,
  UserSettingsContext,
  WebsiteTheme,
} from "../../../types/general/UserSettingsContext";
import { UnitsSettingsInterface } from "../../../types/settings/settings";

interface UnitsGroupProps {
  classes: any;
  setSettings: (key: string, value: any, group: string) => void;
  settings: UnitsSettingsInterface;
}

const UnitsContainer = ({ classes, settings, setSettings }: UnitsGroupProps) => {
  const [units, setUnits] = useState({
    temperature: settings.temperature,
    distance: settings.distance,
  });
  const { userSettings } = useContext(UserSettingsContext);

  const handleBtnClick = (key: string, value: string) => {
    setUnits({ ...units, [key]: value });
    setSettings(key, value, SettingsGroups.UNITS);
  };

  return (
    <Grid container spacing={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Typography gutterBottom variant="h6" className={classes.subtitle} component="h1">
            Temperature:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <ButtonGroup
            aria-label="outlined secondary button group"
            color={userSettings.websiteTheme === WebsiteTheme.Dark ? "default" : "primary"}
          >
            {Object.entries(TemperaturePreference).map((tempPreference: [string, TemperaturePreference]) => {
              const tempKey = tempPreference[0];
              const tempUnit = tempPreference[1];
              return (
                <Button
                  key={tempKey}
                  variant={units.temperature === tempUnit ? "contained" : "outlined"}
                  onClick={() => {
                    handleBtnClick("temperature", tempUnit);
                  }}
                  style={{ width: "75px" }}
                >
                  {tempUnit}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Typography gutterBottom variant="h6" className={classes.subtitle} component="h1">
            Distance:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <ButtonGroup
            aria-label="outlined secondary button group"
            color={userSettings.websiteTheme === WebsiteTheme.Dark ? "default" : "primary"}
          >
            {Object.entries(DistancePreference).map((distancePreference: [string, DistancePreference]) => {
              const distKey = distancePreference[0];
              const distUnit = distancePreference[1];
              return (
                <Button
                  key={distKey}
                  onClick={() => {
                    handleBtnClick("distance", distUnit);
                  }}
                  variant={units.distance === distUnit ? "contained" : "outlined"}
                  style={{ width: "75px" }}
                >
                  {distUnit}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UnitsContainer;
