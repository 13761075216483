import { useMemo } from "react";
import { NewESResponseDataType, timestampKey } from "../../../../types/responses/NewESResponseDataType";
import { haveKeys, pushTupleData } from "../../../../helpers/components/graphHelpers";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { initialFacebookData, initialFacebookOptions } from "./facebookGraphsHelper";
import { useAddMultiDatasetMovingAverage } from "../../../customHooks/useAddMultiDatasetMovingAverage";

export const useProcessFacebookInteractions = (graphData: NewESResponseDataType, selectedInterval: string) => {
  const sentDatasetIndex = 0;
  const receivedDatasetIndex = 1;

  const outputData = useMemo(() => {
    const data = initialFacebookData();

    if (graphData?.Values && haveKeys(graphData.Values)) {
      graphData.Values.forEach((val: ObjectType) => {
        const timestamp = +new Date(val[timestampKey]);
        const sent = val.facebook?.sent;
        const received = val.facebook?.received;

        data.labels.push(timestamp);
        pushTupleData(data, sentDatasetIndex, { x: timestamp, y: sent });
        pushTupleData(data, receivedDatasetIndex, { x: timestamp, y: received });
      });
    }

    const options = initialFacebookOptions(selectedInterval);

    return { data, options };
  }, [graphData]);

  useAddMultiDatasetMovingAverage(outputData.data, "Messages", "y-axis-facebook-messages", true, true, false);

  return { data: outputData.data, options: outputData.options };
};
