import { CLIENT_URL, USER_SERVICE_URL } from "../../api/apiConstants";

export const Google = {
  PROVIDER: "google",
  REDIRECT_URI: `${CLIENT_URL}login/oauth_callback`,
  LINK_REDIRECT_URI: `${CLIENT_URL}link_account_callback`,
  SCOPE: "openid profile email",
  CLIENT: "web",
  STATE: "google",
};

export const Amazon = {
  PROVIDER: "amazon",
  REDIRECT_URI: `${CLIENT_URL}amazon_callback`,
  // SCOPE: "profile" should work, but doesn't
  // but user_id is sufficient for our connect => hotfix to just user_id
  SCOPE: "profile:user_id",
  CLIENT: "web",
  STATE: "amazon",
};

export const Apple = {
  PROVIDER: "apple",
  REDIRECT_URI: `${USER_SERVICE_URL}auth/sign_in_with_apple_callback/`,
  SCOPE: "name email",
  CLIENT: "web",
  STATE: `${CLIENT_URL}login/ios_oauth_callback`,
  NONCE: null,
};
