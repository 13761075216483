import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { useHistogramGenerator } from "../../../customHooks/useHistogramGenerator";
import { initialWeightHistogramDataset, weightHistogramOptions } from "./weightGraphHelper";

export type WeightHistogramType = {
  weightHistogramData: { datasets: ObjectType[]; labels: number[] };
  histogramLargestBucket: number;
};

export const useProcessWeightHistogram = (fetchedWeightValues: NewESResponseDataType): any => {
  const weightData = initialWeightHistogramDataset();

  type weightDataType = { timestamp: string; weight: number; bmi: number; fat: number };
  // eslint-disable-next-line no-extra-parens
  const weigthValues = fetchedWeightValues?.Values ? (fetchedWeightValues?.Values as weightDataType[]) : [];

  const weightArr: number[] = weigthValues
    .filter((obj: weightDataType) => obj.weight !== null)
    .map((obj: weightDataType) => obj.weight);

  const { buckets, largestBucket, min, max, stepBucketSize } = useHistogramGenerator(weightArr, weightData, 50);
  weightData.datasets[0].data = buckets;
  const histogramLargestBucket = largestBucket;

  const options = weightHistogramOptions(histogramLargestBucket, min, max, stepBucketSize);

  return { data: weightData, options };
};
