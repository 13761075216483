import * as chartjs from "chart.js";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { selectedIntervalGraphXAxisUnit } from "../../../../helpers/timeGraphUnitConstants";

export const numCategories = 10;
export const othersLabel = "Others";

export const initialTotalsData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Totals",
        yAxisID: "totals_axis",
        pointRadius: 5,
        pointHoverRadius: 10,
        data: [] as Array<TupleDataType>,
      },
    ],
  };
};

export const initialCategoriesData = () => {
  return {
    labels: [] as Array<string>,
    datasets: [
      {
        type: "pie",
        data: [] as Array<number>,
      },
    ],
  };
};

export const totalsOptions = (selectedInterval: string): chartjs.ChartOptions => {
  return {
    tooltips: {
      intersect: false,
    },
    responsive: true,

    legend: {
      display: true,
    },

    scales: {
      xAxes: [
        {
          type: "time",
          ticks: {
            minor: {
              autoSkip: true,
              source: "auto",
            },
            major: {
              enabled: false,
            },
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: selectedIntervalGraphXAxisUnit[selectedInterval] ?? "day",
          },
        },
      ],
      yAxes: [
        {
          // Right axis for frequencies of categories
          id: "category_axis",
          position: "right",
          ticks: {
            // Arbitrary choice based on aesthetics for this data set
            suggestedMin: 0,
            suggestedMax: 5,
          },
          scaleLabel: {
            display: false,
            labelString: "Amount of Purchases",
          },
        },
        {
          // Left axis for totals for month
          id: "totals_axis",
          ticks: {
            // Arbitrary choice based on aesthetics for this data set
            min: 0,
            stepSize: 100,
          },
          scaleLabel: {
            display: true,
            labelString: "Amount Spent (USD)",
          },
        },
      ],
    },
    maintainAspectRatio: true,
  };
};
export const categoryOptions = () => {
  return {
    tooltips: {},
    responsive: true,

    legend: {
      display: true,
    },
  };
};
