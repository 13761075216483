import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { WebsiteTheme } from "../../../types/general/UserSettingsContext";

interface ThemeGroupProps {
  classes: any;
  changeWebsiteTheme: any;
  websiteTheme: string;
}
const ThemeContainer = ({ classes, changeWebsiteTheme, websiteTheme }: ThemeGroupProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={3}>
        <Typography gutterBottom variant="h6" className={classes.subtitle} component="h5">
          Theme
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <ButtonGroup
          aria-label="outlined secondary button group"
          color={websiteTheme === WebsiteTheme.Dark ? "default" : "primary"}
        >
          {Object.entries(WebsiteTheme).map((webThemePreference: [string, WebsiteTheme]) => {
            const webThemeKey = webThemePreference[0];
            const webTheme = webThemePreference[1];
            return (
              <Button
                key={webThemeKey}
                variant={websiteTheme === webTheme ? "contained" : "outlined"}
                onClick={() => {
                  changeWebsiteTheme(webTheme);
                }}
              >
                {webTheme}
              </Button>
            );
          })}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default ThemeContainer;
