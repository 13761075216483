import React, { useMemo } from "react";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
import { initialNetflixPieData } from "./netflixOptions";
import { addToProfile, NUM_SHOWS, populateProfileList, PROFILE_ALL_STRING } from "./netflixHelper";
import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { ProfileDurationType, ProfileList } from "./netflixTypes";

export const useProcessTitlesData = (
  fetchedNetflixData: NewESResponseDataType,
  profile: string,
  setProfileList: React.Dispatch<React.SetStateAction<string[]>>,
): any => {
  const initialData = initialNetflixPieData();
  return useMemo(() => {
    if (fetchedNetflixData?.Values && haveKeys([fetchedNetflixData.Values])) {
      // Populate data
      const profileData: ProfileList = populateProfileList(fetchedNetflixData);
      const viewingDurations: ProfileDurationType = {};

      // Now that data's populated, we create a profile per user
      Object.entries(profileData).forEach((key) => {
        const profileName = key[0];
        const profileData = key[1];

        // Only grab data for the profile user selected
        if (profile === PROFILE_ALL_STRING || profileName === profile) {
          profileData.forEach((show) => {
            const title: string = show?.title.split(/[_:]/)[0] as string;
            const duration = show?.duration / 60;
            addToProfile(viewingDurations, profile, title, duration);
          });
        }
      });

      // Sort the profile
      Object.entries(viewingDurations).forEach((key) => {
        const profileData = key[1];
        profileData.sort((a, b) => (a.duration < b.duration ? 1 : -1));
      });

      // Populate data
      initialData.datasets.push({
        type: "pie",
        label: profile, // change
        data: [] as Array<number>,
      });

      // Only show top X
      for (let i = 0; i < Math.min(viewingDurations[profile]?.length, NUM_SHOWS); i++) {
        const show = viewingDurations[profile][i];
        initialData.labels.push(show.title);
        initialData.datasets[0].data.push(show.duration);
      }
      const profileList = Object.keys(profileData);
      profileList.push(PROFILE_ALL_STRING);
      setProfileList(profileList);
    }

    return initialData;
  }, [fetchedNetflixData, profile]);
};
