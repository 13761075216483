import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { timezones } from "../../../helpers/timezones";

interface TimeGroupProps {
  classes: any;
  setSettings: (field: string, value: any, group?: string | undefined) => void;
  timezone: string;
}
const TimeContainer = ({ classes, setSettings, timezone }: TimeGroupProps) => {
  const [selectedTimezone, setTimezone] = useState<string>(timezone);

  const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    setSettings(inputName, inputValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setTimezone(inputValue);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={9}>
        <TextField
          select
          name="timezone"
          placeholder="Timezone"
          onBlur={blurHandler}
          onChange={handleChange}
          value={selectedTimezone}
          helperText="Please select your timezone"
          className={classes.textField}
          variant="outlined"
          size="small"
        >
          {timezones.map((timezone) => (
            <MenuItem key={timezone.abbr} value={timezone.utc[0]}>
              {timezone.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default TimeContainer;
