import { haveKeys } from "../../../../helpers/components/graphHelpers";
import { SHAPE_RECT_ROUNDED, SHAPE_CROSS, SHAPE_STAR, FILL_FROM_BOTTOM } from "../../../../helpers/graphConstants";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { NewESResponseDataType } from "../../../../types/responses/NewESResponseDataType";
// Colors
const PERIOD_LINE_COLOR = "#ff4f75";
const OVULATION_LINE_COLOR = "#388b47";
const FERTILE_WINDOW_LINE_COLOR = "#8afe9f";
const CYCLE_LINE_COLOR = "#47bbce";

// Dot Radius
const PERIOD_RADIUS = 0;
const OVULATION_RADIUS = 5;
const FERTILE_WINDOW_RADIUS = 0;
const CYCLE_RADIUS = 0;

// Line Width
const PERIOD_WIDTH = 5;
const OVULATION_WIDTH = 5;
const FERTILE_WINDOW_WIDTH = 5;
const CYCLE_WIDTH = 30;

export const maxYValue = 80;
export const minYValue = 60;
export const avgYValue = (maxYValue + minYValue) / 2;

const PERIOD_STRING = "Period";
const OVULATION_STRING = "Ovulation";
const FERTILITY_STRING = "Fertile Window";
const CYCLE_STRING = "Cycle";

export const loadMenstrualCycleData = (
  fitbitFemaleCycleData: NewESResponseDataType,
  data: {
    labels: number[];
    datasets: any[];
  },
): void => {
  if (fitbitFemaleCycleData?.Values && haveKeys([fitbitFemaleCycleData.Values])) {
    fitbitFemaleCycleData.Values.forEach((element) => {
      // TODO(spk): this is all really ugly. at some point, refactor
      const periodData = [] as Array<ObjectType>;
      const ovulationData = [] as Array<ObjectType>;
      const fertilityData = [] as Array<ObjectType>;
      const cycleDatasetData = [] as Array<ObjectType>;

      if (element?.period_start_date !== null && element?.period_end_date !== null) {
        periodData.push({ x: +new Date(element.period_start_date), y: avgYValue });
        periodData.push({ x: +new Date(element.period_end_date), y: avgYValue });

        pushToDataset(
          data,
          PERIOD_STRING,
          "line",
          periodData,
          PERIOD_RADIUS,
          PERIOD_WIDTH,
          PERIOD_LINE_COLOR,
          SHAPE_RECT_ROUNDED,
          FILL_FROM_BOTTOM,
        );
      }

      if (element?.fertile_start_date && element?.fertile_end_date) {
        fertilityData.push({ x: +new Date(element.fertile_start_date), y: avgYValue });
        fertilityData.push({ x: +new Date(element.fertile_end_date), y: avgYValue });

        pushToDataset(
          data,
          FERTILITY_STRING,
          "line",
          fertilityData,
          FERTILE_WINDOW_RADIUS,
          FERTILE_WINDOW_WIDTH,
          FERTILE_WINDOW_LINE_COLOR,
          SHAPE_CROSS,
          FILL_FROM_BOTTOM,
        );
      }

      if (element?.ovulation_start_date !== null) {
        ovulationData.push({ x: +new Date(element.ovulation_start_date), y: avgYValue });

        pushToDataset(
          data,
          OVULATION_STRING,
          "line",
          ovulationData,
          OVULATION_RADIUS,
          OVULATION_WIDTH,
          OVULATION_LINE_COLOR,
          SHAPE_STAR,
        );
      }

      if (element?.cycle_start_date !== null && element?.period_end_date !== null) {
        cycleDatasetData.push({ x: +new Date(element.cycle_start_date), y: minYValue });
        cycleDatasetData.push({ x: +new Date(element.period_end_date), y: minYValue });

        pushToDataset(
          data,
          CYCLE_STRING,
          "line",
          cycleDatasetData,
          CYCLE_RADIUS,
          CYCLE_WIDTH,
          CYCLE_LINE_COLOR,
          SHAPE_RECT_ROUNDED,
        );
      }
    });
  }
};

/**
 *
 * @param dataObject The destination
 * @param dataLabel The label of the input data
 * @param dataType Line, bar, scatter, etc.
 * @param dataValues Input data
 * @param radius How thick the points should be. 0 turns them off. https://www.chartjs.org/docs/master/configuration/elements/#point-configuration
 * @param width  How thick the line connecting points should be. https://www.chartjs.org/docs/master/axes/styling/#grid-line-configuration
 * @param color This can be hex, or rgba. https://www.chartjs.org/docs/master/general/colors/
 * @param style Style of the points. https://www.chartjs.org/docs/master/configuration/elements/#point-styles
 * @param fillObj What to fill with
 */
const pushToDataset = (
  dataObject: any,
  dataLabel: string,
  dataType: string,
  dataValues: any,
  radius: number,
  width: number,
  color: string,
  style: string | typeof Image,
  fillObj: string | boolean | number | ObjectType = false,
): void => {
  dataObject?.datasets?.push({
    label: dataLabel,
    type: dataType,
    data: dataValues,
    pointRadius: radius,
    hitRadius: radius * 2,
    pointHoverRadius: radius + 5,
    borderWidth: width,
    borderColor: color,
    backgroundColor: color,
    pointBackgroundColor: color,
    pointBorderColor: color,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    pointStyle: style,
    fill: fillObj,
  });
};
