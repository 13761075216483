import React from "react";
import "../App.css";
import { Typography } from "@material-ui/core";

/**
 * When a route is not found, this page is displayed.
 */
const NotFound = () => (
  <>
    <div className="notFoundPage">
      <div>
        <Typography variant="h4">We&apos;re working hard to make your data work for you!</Typography>
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          This page is almost ready! Check back soon!
        </Typography>
      </div>
    </div>
  </>
);

export default NotFound;
