// Shapes - https://www.chartjs.org/docs/master/configuration/elements/#point-styles
export const SHAPE_CIRCLE = "circle"; // DEFAULT
export const SHAPE_DEFAULT = "circle"; // DEFAULT

export const SHAPE_CROSS = "cross";
export const SHAPE_CROSS_ROT = "crossRot";

export const SHAPE_DASH = "dash";
export const SHAPE_LINE = "line";

export const SHAPE_RECT = "rect";
export const SHAPE_RECT_ROUNDED = "rectRounded";
export const SHAPE_RECT_ROT = "rectRot";

export const SHAPE_STAR = "star";
export const SHAPE_TRIANGLE = "triangle";

export const defaultGraphWidth = 100;
export const defaultGraphHeight = 70;
// Fill
export const FILL_FROM_BOTTOM = "start";
export const FILL_FROM_TOP = "end";
export const FILL_FROM_Y0 = "origin";

// Graph titles
export const TITLE_YOUTUBE_COUNTS = "YouTube Video Counts";
// Netflix
export const TITLE_NETFLIX_BAR_TITLE = "Netflix Video Program Time";
export const TITLE_NETFLIX_PIE_TITLE = "Netflix Program Distribution";
export const TITLE_NETFLIX_TOTAL_VIEWING_TIME = "Netflix Profile Viewing Time";
// Exercise
export const TITLE_EXERCISE_ACTIVITIES = "Exercise Activities";
export const TITLE_EXERCISE_LATEST = "Latest Exercise";
// Weight
export const TITLE_WEIGHT = "Body Weight";
export const TITLE_WEIGHT_HISTOGRAM = "Body Weight Histogram";
// Movement
export const TITLE_MOVEMENT = "Time in Movement";
export const TITLE_MOVEMENT_HISTOGRAM = "Movement Histogram";
export const TITLE_MOVEMENT_STATES = "Percent Time in Movement States";
// Steps
export const TITLE_STEPS_HIST = "Steps Histogram";
export const TITLE_STEPS_LINE = "Steps Line";
// Amazon
export const TITLE_SHOPPING = "Amazon Shopping";
export const TITLE_SHOPPING_CATEGORY = "Amazon Shopping Items by Category";
export const TITLE_SHOPPING_HISTOGRAM = "Amazon Shopping Histogram";
// Sleep
export const TITLE_SLEEP_STATES = "Sleep";
export const TITLE_SLEEP_TIME = "Sleep Time";
export const TITLE_SLEEP_HISTOGRAM = "Sleep Histogram";
// Heart Rate Pulse Ox
export const TITLE_HEARTRATE = "Heart Rate";
export const TITLE_HEARTRATE_HISTOGRAM = "Heart Rate Histogram";
export const TITLE_RESTING_HEARTRATE = "Resting Heart Rate";
export const TITLE_RESTING_HEARTRATE_HISTOGRAM = "Resting Heart Rate Histogram";
// Female
export const TITLE_MENSTRUAL_CYCLE = "Menstrual/Fertility Cycle";
// Air
export const TITLE_AIR_AQI = "Air Quality Index";
export const TITLE_AIR_PM = "Air Particulate Matter";
export const TITLE_AIR_ELEMENTS = "Air Elements";
// Places
export const TITLE_PLACES_PIE = "Percent Time In Places";
export const TITLE_PLACES_DAILY = "Time in Places";
// Events
export const TITLE_EVENTS = "My Events";
export const TITLE_EVENTS_TAGS = "My Tags";

// Weather
export const TITLE_TEMPERATURE = "Temperature";
export const TITLE_HUMIDITY = "Humidity";
export const TITLE_OZONE = "Ozone";
export const TITLE_VISIBILITY = "Cloud Cover & Visibility";
export const TITLE_PRESSURE = "Barometric Pressure";
export const TITLE_WIND = "Wind";
export const TITLE_POLLEN = "Pollen";

export const TITLE_FACEBOOK_INTERACTIONS = "Facebook Interactions";
export const TITLE_ACTIVITY_WEB = "Content";
export const TITLE_ACTIVITY_WEB_HISTOGRAM = "Content Histogram";
