import * as chartjs from "chart.js";
import { TupleDataType } from "../../../../types/graphs/lineGraphs/TupleDataType";
import { localDateFormatMonthDay } from "../../../../helpers/timeConstants";

export const initialVisibilityData = () => {
  return {
    labels: [] as Array<number>,
    datasets: [
      {
        label: "Cloud Cover",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-cloudcover",
        xAxisID: "x-axis",
        type: "line",
      },
      {
        label: "Visibility",
        data: [] as Array<TupleDataType>,
        fill: false,
        yAxisID: "y-axis-visibility",
        xAxisID: "x-axis",
        type: "line",
      },
    ],
  };
};

export const initialVisibilityOptions = (): chartjs.ChartOptions => {
  return {
    tooltips: {
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          id: "y-axis-visibility",
          position: "right",
          scaleLabel: {
            display: true,
            labelString: "Visibility",
          },
        },
        {
          id: "y-axis-cloudcover",
          position: "left",
          scaleLabel: {
            display: true,
            labelString: "Cloud Cover",
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis",
          type: "time",
          position: "bottom",
          ticks: {
            source: "labels",
            autoSkip: true,
          },
          time: {
            displayFormats: { day: localDateFormatMonthDay },
            tooltipFormat: "lll",
            unit: "day",
          },
        },
      ],
      maintainAspectRatio: false,
    },
  } as chartjs.ChartOptions;
};
