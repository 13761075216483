import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Marker from "@material-ui/icons/Room";
import IconButton from "@material-ui/core/IconButton";
import { LocationSettingsInterface, defaultLocationValue } from "../../../types/settings/settings";
import MapDialog from "./LocationDialog/MapDialog";
import { UserSettingsContext } from "../../../types/general/UserSettingsContext";

interface LocationGroupProps {
  classes: any;
  setSettings: (field: string, value: any) => void;
  settings: LocationSettingsInterface;
}
const LocationContainer = ({ settings, setSettings }: LocationGroupProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { userSettings } = useContext(UserSettingsContext);

  const saveLocation = (location: LocationSettingsInterface) => {
    setSettings("location", location);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={2} />
      <Grid container>
        {settings.lat !== 0 && settings.option === "mailing" && (
          <Grid item xs={12}>
            <div style={{ paddingBottom: "30px", display: "flex", alignItems: "center" }}>
              <Marker fontSize="large" color="secondary" /> {settings.route} {settings.street_number}{" "}
              {settings.locality} {settings.country}{" "}
            </div>
          </Grid>
        )}
        {settings.option === "zip" && (
          <Grid item xs={12}>
            <div style={{ paddingBottom: "30px", display: "flex", alignItems: "center" }}>
              <Marker fontSize="large" color="secondary" /> {settings.postal_code}
            </div>
          </Grid>
        )}

        {settings.option === "country" && (
          <Grid item xs={12}>
            <div style={{ paddingBottom: "30px", display: "flex", alignItems: "center" }}>
              <Marker fontSize="large" color="secondary" /> {settings.location}, {settings.country}
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            style={{ marginRight: "10px" }}
            onClick={() => {
              setOpen(true);
            }}
            color={userSettings.websiteTheme === "Dark" ? "default" : "primary"}
          >
            {settings.lat || settings.postal_code || settings.country ? "Edit location" : "Add location"}
          </Button>

          {/* address */}
          {(settings.lat || settings.postal_code || settings.country) && (
            <IconButton
              aria-label="delete"
              onClick={() => {
                saveLocation(defaultLocationValue);
              }}
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          )}

          <MapDialog open={open} setOpen={setOpen} settings={settings} saveLocation={saveLocation} />
          <p>
            Location data will help with making better comparisons to people in your region, participating in location
            targeted engagements, and providing better contributions to community insights.
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LocationContainer;
