export const ibuprofenContent = `{
    "tags": [
      {
        "tag": "medication"
      }
    ],
    "timestamp": "2024-02-29T20:29:02.129+00:00",
    "type": "medication",
    "name": "ibuprofen",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Milligram",
      "quantity": 2.0,
      "amount": 200.0
    }
  }`;

export const tylenolContent = `{
    "tags": [
      {
        "tag": "medication"
      }
    ],
    "timestamp": "2024-02-21T21:00:00.000+00:00",
    "doc_id": "8dd34c6b-7597-488a-88c2-def436182aa0",
    "type": "medication",
    "name": "acetaminophen",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Milligram",
      "quantity": 2.0,
      "quantity_type": "serving",
      "amount": 500.0
    }
  }`;

export const antibioticContent = `{
    "tags": [
      {
        "tag": "medication"
      }
    ],
    "timestamp": "2024-02-21T21:00:00.000+00:00",
    "doc_id": "8dd34c6b-7597-488a-88c2-def436182aa0",
    "type": "medication",
    "name": "Amoxicillin",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Milligram",
      "quantity": 1.0,
      "quantity_type": "serving",
      "amount": 400.0
    }
  }`;

export const thyroidContent = `{
    "tags": [
      {
        "tag": "medication"
      }
    ],
    "timestamp": "2024-02-21T21:00:00.000+00:00",
    "type": "medication",
    "name": "levothyroxine for thyroid",
    "is_standard": false,
    "is_archived": false,
    "consumables_extension": {
      "units": "Milligram",
      "quantity": 1.0,
      "quantity_type": "serving",
      "amount": 100.0
    }
  }`;
