import React from "react";
import { Bubble } from "react-chartjs-2";
import { Box, LinearProgress } from "@material-ui/core";
import { API_TAGS, DATA_SERVICE_URL } from "../../../../api/apiConstants";
import { EventsQueryParams } from "../../../../helpers/components/defaultQueryParams";
import { defaultGraphHeight, defaultGraphWidth, TITLE_EVENTS_TAGS } from "../../../../helpers/graphConstants";
import { useProcessTagsData } from "./useProcessTagsData";
import { useGraphSetup } from "../../../customHooks/useGraphSetup";
import { GraphHeader } from "../../../../containers/componentContainers/DisplayWrappers/GraphHeader";
import { GraphContainer } from "../../../customComponents/GraphContainer/GraphContainer";
import { TagsGraphMetadata } from "../../../../helpers/graphsMetadata";

export const TagsGraph = (): JSX.Element => {
  const graphObject = useGraphSetup(EventsQueryParams.defaultTagsQueryParams, API_TAGS, DATA_SERVICE_URL);

  const { graphData, selectedInterval, isLoaded, elemRef } = graphObject;
  const { outputData, options } = useProcessTagsData(graphData, selectedInterval);
  const graphDisplay = (
    <Bubble data={outputData} options={options} width={defaultGraphWidth} height={defaultGraphHeight} />
  );

  // TODO: Match mobile description here
  const description = [
    "A LLIF Tag is a way of recording an event named by the member.  As long as you are consistent with the naming" +
      " across mobile and voice then we'll track it for correlations to other aspects of your life. ",
    " Tags may be health related like a symptom or pain or non-health related like gave your dog heartworm pill or" +
      " your roof leaked.  ",
    " When health related, understanding when the symptom started and whether the frequency of occurrence is increasing will help you decide when to seek medical attention.",
  ];

  return (
    <div ref={elemRef}>
      <GraphHeader
        key={TITLE_EVENTS_TAGS}
        title={TITLE_EVENTS_TAGS}
        calendarProps={graphObject}
        id={TagsGraphMetadata.id}
        description={description}
      >
        {!isLoaded ? <LinearProgress /> : <Box m={0.5} />}
        <GraphContainer id={TITLE_EVENTS_TAGS} graphDisplay={graphDisplay} calendarProps={graphObject} />
      </GraphHeader>
    </div>
  );
};
