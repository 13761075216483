import { TupleDataType } from "../../types/graphs/lineGraphs/TupleDataType";

/**
 * Given a list of values and the initial dataset, generate a histogram
 * @param values Array of numbers for which the histogram should be created
 * @param initialDataset ChartJS initial dataset used to set the labels
 * @param bucketCount Optionally defined the number of buckets
 * @return {
 *    buckets: This is a Chartjs data array. Assign with x.datasets[i].data = buckets,
 *    largestBucket: Gives the largest bucket
 *  }
 */
export const useHistogramGenerator = (
  values: (string | number)[],
  initialDataset: any,
  bucketCount = 30,
): { buckets: Array<TupleDataType>; largestBucket: number; min: number; max: number; stepBucketSize: number } => {
  // GET THE MIN AND MAX FOR THE DATASET
  const min = Math.min(...values.map((v) => Number(v)));
  const max = Math.max(...values.map((v) => Number(v)));

  // HOW BIG IS EACH BUCKET
  const bucketSize = Math.ceil(max / bucketCount);
  // How many buckets were not used, i.e, from 0 to min
  const unUsedBuckets = Math.floor(min / bucketSize);
  const labelCount = bucketCount + unUsedBuckets;
  const stepBucketSize = Math.ceil(bucketSize);

  // Bucket size calculated after data load. In order to callback for labels, store it inside the data object itself.
  initialDataset.stepBucketSize = stepBucketSize;
  // Setup the labels. Array long, and incrementing by stepBucketSize
  initialDataset.labels = Array.from({ length: labelCount }, (_, i) => i * stepBucketSize);

  // Create buckets of same length as labels,
  const buckets: Array<TupleDataType> = Array.from({ length: labelCount }, (_, i) => {
    return {
      x: i * bucketSize,
      y: 0, // init counts to 0
    };
  });

  let largestBucket = 0;

  // Populate the buckets array
  values.forEach((val) => {
    // Find correct bucket - First bucket where adding "bucketRange" takes you past "step"
    const desiredIndex = buckets.findIndex((bucket) => bucket.x + bucketSize > val);

    // Increment bucket range found and the largest bucket
    if (desiredIndex > -1) {
      buckets[desiredIndex].y++;
      largestBucket = Math.max(largestBucket, buckets[desiredIndex].y);
    }
  });

  return { buckets, largestBucket, min, max, stepBucketSize };
};
