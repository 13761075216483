export type TrackpointType = { [key: string]: Array<string> };
export type TrackType = { Track: Array<TrackpointType> };
export type ValuesType = {
  _id: string;
  Lap: Array<TrackType>;
  Summary: { [key: string]: string | number | Date };
};
export type ExerciseTrackResponseType = { Values: ValuesType };

export const InitialValues: ExerciseTrackResponseType = {
  Values: { Summary: {}, _id: "", Lap: [] },
};

export type ExerciseDetailGraphPropsType = {
  exerciseDetailData: ExerciseTrackResponseType;
  reducedVersion: boolean;
};
