import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { numSelectedDays, shiftRight } from "../calendarShifterHelper";
import { CalendarRange } from "../GraphCalendar/calendarBodyHelper";

interface CalendarRightArrowProps {
  dateRange: CalendarRange;
  setDateRange: React.Dispatch<React.SetStateAction<CalendarRange>>;
}

export const CalendarRightArrow = ({ dateRange, setDateRange }: CalendarRightArrowProps) => {
  const numDays = numSelectedDays(dateRange);
  return (
    <Tooltip title={`Next ${numDays === 0 ? 1 : numDays} ${numDays === 0 ? "day" : "days"}`}>
      <IconButton onClick={(): void => shiftRight(dateRange, setDateRange)} size="small">
        <ArrowForwardIcon />
      </IconButton>
    </Tooltip>
  );
};
