import { FAHRENHEIT, MILE } from "../../helpers/settingsContstants";

export interface profileProps {
  location: LocationSettingsInterface;
  identity: IdentitySettingsInterface;
  age: AgeSettingsInterface;
}

export interface generalProps {
  units: UnitsSettingsInterface;
  timezone: string;
}

export interface SettingsProps {
  profile: profileProps | undefined;
  privacy: PrivacySettingsInterface | undefined;
  general: generalProps | undefined;
  favorites: any;
}

export const initialSettingsState: SettingsProps = {
  profile: undefined,
  privacy: undefined,
  general: undefined,
  favorites: undefined,
};
export interface LocationSettingsInterface {
  // eslint-disable-next-line camelcase
  street_number: string;
  route: string;
  location: string;
  locality: string;
  country: string;
  // eslint-disable-next-line camelcase
  postal_code: string;
  lat: number;
  lng: number;
  option: string;
}

export interface IdentitySettingsInterface {
  name: string;
  email: string;
  sex: string;
}

export interface AgeSettingsInterface {
  dob: string;
  option: string;
}

export interface UnitsSettingsInterface {
  temperature: string;
  distance: string;
}

export interface PrivacySettingsInterface {
  // eslint-disable-next-line camelcase
  privacy_policy: boolean;
  // eslint-disable-next-line camelcase
  use_policy: boolean;
}

export const defaultPrivacyValue: PrivacySettingsInterface = {
  // eslint-disable-next-line camelcase
  privacy_policy: false,
  // eslint-disable-next-line camelcase
  use_policy: false,
};

export const defaultLocationValue: LocationSettingsInterface = {
  // eslint-disable-next-line camelcase
  street_number: "",
  route: "",
  location: "",
  locality: "",
  country: "",
  // eslint-disable-next-line camelcase
  postal_code: "",
  lat: 0,
  lng: 0,
  option: "mailing",
};

export const defaultIdentityValue: IdentitySettingsInterface = {
  name: "",
  email: "",
  sex: "",
};

export const defaultBirthValue: AgeSettingsInterface = {
  dob: "",
  option: "date",
};

export const defaultUnitsValue: UnitsSettingsInterface = {
  temperature: FAHRENHEIT,
  distance: MILE,
};

export const defaultProfileValue: profileProps = {
  location: defaultLocationValue,
  identity: defaultIdentityValue,
  age: defaultBirthValue,
};

export const defaultGeneralValue: generalProps = {
  units: defaultUnitsValue,
  timezone: "Etc/GMT",
};

export const defaultFavouritesValue: any = {
  fav_graphs: [""],
};

export const defaultSettingsValue: SettingsProps = {
  profile: defaultProfileValue,
  privacy: defaultPrivacyValue,
  general: defaultGeneralValue,
  favorites: defaultFavouritesValue,
};
